import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import StyledTappable from '../common/StyledTappable';
import LoadingComponent from '../common/LoadingComponent';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import downloadIcon from '../../images/consultations/downloadPdf.svg';
import { useSelector } from 'react-redux';

const OuterContainer = styled.div`
  background: #fff;
  border-radius: 12px 12px 0px 0px;
  height: 90%;
  margin-top: 100px;
  position: absolute;
  bottom: 0;
  transform: translateX(90%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  // align-items: center;

  width: 35vw;
  @media only screen and (min-width: 440px) {
    // width: 80vw;
  }
`;
const Header = styled.div`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  padding: 4px;
  padding-top: 0px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f3f6;
`;
const CloseContainer = styled(StyledTappable)`
  width: 20px;
  height: 1.625rem;
  border-radius: 8px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.2);
  padding: 4px;
`;
const CloseIcon = styled(FaTimes)`
  transform: scale(1.2);
  color: #413958;
`;
const PdfBtn = styled.div`
  padding: 4px;
  font-size: 12px;
  color: white;
  border-radius: 4px;
  background: #714fff;
  width: 24px;
  margin-left: 16px;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease;
  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 0.3;
  }
`;
const PdfContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto; /* Make the container scrollable */
`;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  align-items: center;
  padding: 4px;
  background: #ddd;

  width: 100%;

  padding-bottom: 20px;
`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ pdfUrl, setError, setErrorText, setFetching }) => {
  const authToken = useSelector((state) => state.user.authToken);
  const [pdfData, setPdfData] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const url =
    'https://api.samuraijack.xyz/gringots/consultations/treatmentPlans/2306612/p-34609bdc08a07ace4e1526bbb1777673';
  useEffect(() => {
    const fetchPDF = async () => {
      try {
        const response = await axios.get(pdfUrl, {
          headers: {
            Authorization: authToken,
            'Content-Type': 'application/json',
          },
          responseType: 'arraybuffer',
        });
        console.log('response', response);
        const uint8Array = new Uint8Array(response.data);
        const pdfBlob = new Blob([uint8Array], { type: 'application/pdf' });
        setPdfData(pdfBlob);
        setError(false);
        setErrorText('');
        setFetching(false);
      } catch (error) {
        setPdfData(null);
        setError(true);
        setErrorText(error.message);
        setFetching(false);
        console.error('Error fetching PDF:', error, error.message); // Handle errors appropriately
      }
    };

    fetchPDF();
    return () => {
      URL.revokeObjectURL(pdfUrl);
    };
  }, [pdfUrl, authToken]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className="h-full pb-16">
      {pdfData && (
        <a
          id="prescription-download"
          download={'visit-prescription.pdf'}
          href={URL.createObjectURL(pdfData)}
          className="hidden"
        />
      )}
      {pdfData ? (
        <PdfContainer>
          <Document
            file={pdfData}
            onLoadSuccess={onDocumentLoadSuccess}
            renderTextLayer={false} // Disable text layer
            renderAnnotationLayer={false} // Disable annotation layer
            width={420}
          >
            <PageContainer>
              {[...Array(numPages)].map((_, index) => (
                <Page
                  zoom={1}
                  width={'450'}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  style={{ width: '100vw' }}
                />
              ))}
            </PageContainer>
          </Document>
        </PdfContainer>
      ) : (
        // <div className="flex h-full justify-center items-center">
        <LoadingComponent />
        // </div>
      )}
    </div>
  );
};

const PrescriptionModal = ({ url, close }) => {
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const downloadPdf = () => {
    const link = document.getElementById('prescription-download');
    link.click();
  };

  return (
    <OuterContainer
      onScroll={(e) => {
        e.stopPropagation();
      }}
    >
      <Header>
        <div className="flex flex-row items-center">
          <p className=" text-body-l text-txtlight-new font-semibold mr-4">
            Prescription
          </p>
          {!fetching && !error && (
            <img
              src={downloadIcon}
              alt="download-icon"
              onClick={downloadPdf}
              className="cursor-pointer hover:opacity-70"
            />
          )}
        </div>
        <CloseContainer onTap={close}>
          <CloseIcon />
        </CloseContainer>
      </Header>
      {url?.length === 0 && fetching ? (
        <LoadingComponent />
      ) : error ? (
        <div className="text-body-l mt-4">
          Some Error occurred in fetching prescription:{' '}
          <p className="text-body-l">{errorText}</p>
        </div>
      ) : (
        <PdfViewer
          pdfUrl={url}
          setError={setError}
          setErrorText={setErrorText}
          setFetching={setFetching}
        />
      )}
    </OuterContainer>
  );
};

export default PrescriptionModal;
