import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Async as AsyncSelect } from 'react-select';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { RegularText, Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';
import TappableCard from '../common/TappableCard';
import {
  getSymptoms as getSymptomsService,
  removeLabTest,
} from '../../services';
import { showError } from '../../actions';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 1;
`;

const LabelText = styled(Text)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
  border-bottom: 1px solid #979797 !important;
  padding: 0.33rem 0;
  margin: 0rem 0.625rem;
  /* width: ${(props) => (props.width ? props.width : 'auto')}; */
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const AddedTestsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  /* box-shadow: 0 -0.3rem 0.625rem 0 rgba(0, 0, 0, 0.09); */
  padding: 0.625rem 1rem;
  margin-bottom: 2.5rem;
  background: white;
  flex-shrink: 0;
`;

const AddedTestsInnerContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.75rem;
`;

const AddedTestContainer = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 0.5rem;
  background: #f4f4f4;
  box-shadow: 0 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
`;

const RemoveIcon = styled(FaTimes)`
  margin-left: 1.125rem;
`;

const LabText = styled(RegularText)`
  font-size: 1.6rem;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 16,
    textAlign: 'left',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 12,
    textAlign: 'left',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
};

class AddLabTestsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      symptoms: [],
      selectedSymptoms: {},
    };

    this.symptomsSelectRef = React.createRef();
  }

  getSymptoms = (input) => {
    return getSymptomsService(this.props.authToken, null, input)
      .then((res) => {
        console.log({ res }, 'getSymptoms result');
        const result = res.data;
        result.forEach((symptom) => {
          console.log({ symptom });
          symptom.label = symptom.symptomName;
          symptom.value = symptom.symptomName;
        });
        this.setState({ symptoms: result });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  promiseOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getSymptoms(input.toLowerCase()));
      }, 500);
    });
  };

  onSymptomSelect = (option, action) => {
    console.log({ option, action });
    option.isEditable = true;
    this.setState(
      {
        selectedSymptoms: option,
      },
      () => {
        this.finish();
      }
    );
    // switch (action.action) {
    //   case 'select-option':
    //     // An option has been selected.
    //     this.setState({
    //       selectedSymptoms: [...this.state.selectedSymptoms, ...option]
    //     });
    //     break;
    //   case 'remove-value':
    //     // Remove the option
    //     const removedValue = action.removedValue.value;
    //     this.setState({
    //       selectedSymptoms: this.state.selectedSymptoms.filter(
    //         (symptom, index) => symptom.value !== removedValue
    //       )
    //     });
    //     break;
    //   default:
    //     break;
    // }
  };

  renderSelection = (ele, idx) => (
    <AddedTestContainer
      key={ele.labName}
      onTap={() => this.props.removeLabTest(ele)}
    >
      <LabText>{ele.labName}</LabText>
      <RemoveIcon />
    </AddedTestContainer>
  );

  finish = () => {
    console.log({ state: this.state, symptoms: this.props.symptoms }, 'finish');
    if (
      this.props.symptoms.find(
        (symptom) => symptom.symptomId === this.state.selectedSymptoms.symptomId
      )
    ) {
      console.log(
        { state: this.state, symptoms: this.props.symptoms },
        'finish gadjfn'
      );
      this.props.dispatch(
        showError(
          'Symptom Already Added',
          'Symptom that you are trying to add is already added. Kindly check here and press the save button to make changes to the already added symptom'
        )
      );
      this.props.hideModal();
    } else {
      const symptoms = [this.state.selectedSymptoms];
      this.props.addSymptoms(symptoms);
      this.props.hideModal();
    }
  };

  render() {
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Add Symptoms</HeaderText>
              <ButtonsComponent>
                <ButtonComponent>
                  <Button onTap={() => this.props.hideModal()}>
                    <ButtonText>Done</ButtonText>
                  </Button>
                </ButtonComponent>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <InputContainer>
                <AgeContainer>
                  <LabelText>Add Symptoms</LabelText>
                  <AsyncSelect
                    ref={this.symptomsSelectRef}
                    styles={customStyles}
                    // isMulti
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary50: '#d5d5d5',
                        primary25: '#f5f5f5',
                        primary: '#4F34D2',
                      },
                    })}
                    placeholder="Search Symptoms"
                    backspaceRemovesValue={false}
                    closeMenuOnSelect={false}
                    isClearable={true}
                    defaultOptions={this.state.symptoms}
                    loadOptions={this.promiseOptions}
                    onChange={(option, action) =>
                      this.onSymptomSelect(option, action)
                    }
                  />
                </AgeContainer>
              </InputContainer>
              <AddedTestsContainer>
                <AddedTestsInnerContainer>
                  {/* {this.props.labTests.map(this.renderSelection)} */}
                </AddedTestsInnerContainer>
              </AddedTestsContainer>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  symptoms: state.symptoms.symptoms,
});

export default connect(mapStateToProps)(AddLabTestsModal);
