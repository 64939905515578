import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import video from '../../images/video.svg';
import voice from '../../images/voice.svg';
import CallbacksModal from './CallbacksModal';

const Container = styled.div`
padding: 16px;
width: 352px;
height: auto;
background: #FFFFFF;
border: 1px solid #F0F3F6;
box-shadow: 2px 2px 8px rgba(58, 44, 160, 0.1);
border-radius: 12px;
margin-left: 20px;
margin-top: 19px;
cursor: pointer;
`

const HeadContainer = styled.div`
display: flex;
`

const Name = styled.h1`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 150%;
color: #0F0B28;
letter-spacing: 0.05rem;
`

const DateTimeContainer = styled.div`
height: 15px;
width: 140px;
margin-top: 5px;
margin-left: auto;
`

const DateTime = styled.h1`
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 150%;
color: #0F0B28;
opacity: 0.4;
`

const Sponsor = styled.h1`
font-style: normal;
font-weight: 600;
font-size: 11px;
line-height: 150%;
color: #A5A6BB;
margin-top: 4px;
letter-spacing: 0.05rem;
`

const Line = styled.div`
width: 328px;
height: 0px;
border: 1px solid rgba(228, 228, 228, 0.25);
flex: none;
order: 1;
flex-grow: 0;
margin-top: 8px;
`

const InfoContainer = styled.div`
display: flex;
flex-wrap: wrap;
gap: 8px;
`

const MsgContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 130px;
height: 19px;
margin-top: 8px;
border-radius: 12px;
padding: 5px;
`

const LabelContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 140px;
height: 19px;
margin-top: 8px;
border-radius: 12px;
padding: 5px;
background: #C6F6D5;
`

const Label = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 110px;
height: auto;
font-style: normal;
font-weight: 800;
font-size: 10px;
color: #38A169;
`

const Msg = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 130px;
height: auto;
font-style: normal;
font-weight: 800;
font-size: 10px;
`

const CallBackContainer = styled.div`
display: flex;
gap: 5px;
`

const getDateText = (createdAt) => {
    // converting in moment format
    // createdAt = createdAt * 1000;

    if (!createdAt) {
      return null;
    }

    const latestTime = moment(createdAt);

    const currentTime = moment();
    if (latestTime.format('DD') !== currentTime.format('DD')) {
      return latestTime.format('hh:mm A | DD[th] MMM YYYY');
    } else {
      return latestTime.format('h:mm A | DD[th] MMM YYYY');
    }
  };

const CallbacksCard = (props) => {
  return (
    <Container style={props.activeConsult && props.callBacksData.consultationId === props.activeConsult.consultationId ? {background: '#F7F5FF', border: '2px solid #714FFF'} : {background: '#FFFFFF'}}>
        <HeadContainer>
            <Name>{props.callBacksData.relativeId ? props.callBacksData.relativeName : props.callBacksData.userName}</Name>
            <DateTimeContainer>
                <DateTime>{getDateText(props.callBacksData.timeStamp)}</DateTime>
            </DateTimeContainer>
        </HeadContainer>
        <Sponsor>{props.callBacksData.relativeId ? `${props.callBacksData.userName} | ${props.callBacksData.orgName}` : props.callBacksData.orgName}</Sponsor>
        {
          props.activeConsult && props.callBacksData.consultationId !== props.activeConsult.consultationId && 
          (
            <>
              <Line />
              <InfoContainer>
                  <CallBackContainer>
                    {
                        props.callBacksData.callType.includes('video') && (
                          <LabelContainer>
                            <div style={{display: 'flex'}}>
                                <img src={video} alt='video'/>
                                <Label>Video Call Request</Label>
                            </div>
                          </LabelContainer>
                        )
                    }
                    {
                        props.callBacksData.callType.includes('voice') && (
                          <LabelContainer>
                            <div style={{display: 'flex'}}>
                                <img src={voice} alt='voice'/>
                                <Label>Voice Call Request</Label>
                              </div>
                          </LabelContainer>
                        )
                    }
                  </CallBackContainer>
              </InfoContainer>
            </>
          )
        }
    </Container>
  )
}

export default CallbacksCard