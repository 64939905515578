import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { videoSessionInfo } from '../../../services/consults';
import clock from '../../../images/consultations/clock.svg';
import videoUnmute from '../../../images/consultations/video-unmute.svg';
import clockBlack from '../../../images/consultations/clock-black.svg';
import StyledTappable from '../../common/StyledTappable';
import videoCardUser from '../../../images/consultations/video-card-user.svg';
import { RegularText } from '../../common/Text';
import { setVideoCallData } from '../../../actions';
import videoJoin from '../../../images/chats/videoJoin.svg';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 319px;
  max-width: 80%;
  max-height: 62px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  align-self: flex-end;
  margin: 0.4rem 0.9rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
`;
const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 319px;
  height: 62px;
  background: #4f34d2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const MessageText = styled(RegularText)`
  margin-left: 10px;
  font-size: 1.6rem;
  color: #ffffff;
`;
const ActionIcon = styled.img`
  display: flex;
  margin-top: 5px;
  margin-left: 10px;
  align-self: flex-start;
`;
const JoinIcon = styled.img`
  flex-direction: row;
  display: flex;
`;
const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 319px;
  height: 142px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #ffffff;
`;

const ProfileImg = styled.img`
  align-items: left;
  margin: 10px;
  width: 60px;
  height: 60px;
`;
const JoinButton = styled.button`
  display: flex;
  padding: 1rem 1rem;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  border-radius: 12px;
  background: #38A169;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 10px */
  letter-spacing: -0.165px;
`;

const VideoContainer = styled.div`
display: flex;
min-width: 100%;
min-height: 100%;
padding: 2rem 2rem;
justify-content: space-between;
align-items: center;
`;

const VideoInfo = styled.div`
display: flex;
gap: 8px;
`;

const VideoEnd = styled.p`
color: #FF754C;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 10px */
letter-spacing: -0.165px;
cursor: default;
`;

const VideoIcon = styled.img`
height: 22px;
width: 22px;
`;

const VideoText = styled.p`
color: #0F0B28;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 156%; /* 18.72px */
letter-spacing: -0.165px;
cursor: default;
`;

class VideoCallCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textMessage: '',
      callStatus: '',
      roomDetails: [],
    };
  }
  componentDidMount = () => {
    const { sessionId, authToken, consultationId } = this.props;
    videoSessionInfo(authToken, sessionId, consultationId).then((resp) => {
      this.setState({
        callStatus: resp.callStatus,
        textMessage: resp.textMessage,
        roomDetails: resp.roomDetails,
      });
    });
  };
  joinVideoCall = () => {
    const { sessionId, authToken, consultationId, doctorId } = this.props;
    videoSessionInfo(authToken, sessionId, consultationId).then((resp) => {
      const { dispatch } = this.props;
      const data = {
        sessionId,
        token: resp.roomDetails.token,
        doctorToken: resp.roomDetails.token,
        roomName: resp.roomDetails.roomName,
        authToken,
        userId: resp.roomDetails.userId,
        userName: resp.roomDetails.userName,
        doctorName: resp.roomDetails.doctorName,
        profileImg: resp.roomDetails.doctorProfileImage,
        doctorId,
        startCall: true,
      };
      console.log(data);
      dispatch(setVideoCallData(data));
    });
  };
  render() {
    const { callStatus, roomDetails, textMessage } = this.state;
    console.log(
      { callStatus, roomDetails, textMessage },
      roomDetails.createdAt
    );
    const renderHeader = () => {
      switch (callStatus) {
        case 'initiated':
          return (
            <VideoContainer>
              <VideoInfo>
                <VideoIcon src={videoJoin}/>
                <VideoText>Ongoing Video Call</VideoText>
              </VideoInfo>
              <JoinButton onClick={this.joinVideoCall}>Join Now</JoinButton>
            </VideoContainer>
          );
        case 'ended':
          return (
            <VideoContainer>
              <VideoInfo>
                <VideoIcon src={videoJoin}/>
                <VideoText>Ongoing Video Call</VideoText>
              </VideoInfo>
              <VideoEnd>Ended</VideoEnd>
            </VideoContainer>
          );
        default:
          return false;
      }
    };
    return <OuterContainer>{renderHeader()}</OuterContainer>;
  }
}
const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  doctorId: state.user.doctorId,
});

export default withRouter(connect(mapStateToProps)(VideoCallCard));
