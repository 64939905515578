import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import DateTimePicker from 'react-datetime-picker';
import Link from 'valuelink';
import Select from 'react-select';
import { Input } from 'valuelink/lib/tags';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { RegularText, Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import moment from 'moment';
import { PrimaryButton } from '../common/Buttons';
import TappableCard from '../common/TappableCard';
import greyTick from '../../images/common/greyTick.svg';
import purpleTick from '../../images/common/purpleTick.svg';
import { fetchConsultationEndReasons } from '../../services/consults';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const StyledTappableCard = styled(TappableCard)`
  align-self: flex-start;
  padding: 1rem 3rem;
  margin: 0rem 0.625rem;
  border: ${(props) => (props.isSelected ? '0.1rem solid #4F34D2' : 'null')};
`;

const TappableText = styled(Text)`
  font-size: 1.4rem;
  text-align: center;
  color: ${(props) => (props.isSelected ? '#4F34D2' : 'black')};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const OptionContainer = styled(StyledTappable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-right: 3rem;
`;

const DateTimeComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-left: 0.625rem;
  margin-top: 2rem;
`;

const OptionText = styled(RegularText)`
  /* color: white; */
  font-size: 1.4rem;
  text-align: center;
  margin: 0rem;
  margin-left: 0.25rem;
  padding: 0rem;
`;

const SelectLinkText = styled.div`
  padding: 0.45rem;
`;

const SelectIcon = styled.img`
  width: 2rem;
  align-self: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DateTimeText = styled(BoldText)`
  font-size: 1.6rem;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
  border-bottom: 1px solid black !important;
  padding: 0.33rem 0;
  width: 80%;
`;

const DobContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 2rem;
  margin-left: 0.625rem;
  padding-right: 10rem;
  /* border-bottom: 1px solid black !important; */
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 1.25rem;
  margin-right: 1rem;
  margin-left: 0.625rem;
  flex: 1;
`;

const SelectElement = styled(Select)`
  flex: 1;
  max-width: 30rem;
  /* border: 0.1rem solid #4F34D2; */
`;

const EndRegularText = styled(RegularText)`
  font-size: 1.4rem;
  text-align: left;
`;

const styles = {
  datePicker: {
    backgroundColor: 'dark blue',
  },
};

class CancelConsultationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionChosen: false,
      followupNotes: '',
      date: new Date(),
      endReason: '',
      remark: '',
      endReasons: [],
      cancelReasonDescription: ''
    };

    this.state.date.setDate(this.state.date.getDate() + 5);
  }
  componentDidMount = () => {
    this.fetchConsultationEndReasons();
  };
  fetchConsultationEndReasons = () => {
    fetchConsultationEndReasons(this.props.authToken, 'cancel').then(
      (result) => {
        console.log({ result }, 'fetchConsultationEndReasons');
        if (result.results && result.results.length > 0) {
          const endReasons = result.results.map((element) => {
            return { label: element, value: element };
          });
          this.setState({
            endReasons,
          });
        }
      }
    );
  };
  onChange = (date) => {
    console.log({ date }, 'onChange');
    this.setState({
      date,
    });
  };

  optionClicked = (option) => {
    this.setState({ optionChosen: option });
  };

  closeConsultation = () => {
    const body = {
      endReason: this.state.endReason,
      closedType: 'cancel',
      othersRemark: this.state.endReason === 'Others' ? this.state.cancelReasonDescription : null
    };
    // if (this.state.optionChosen === 'addDate') {
    //   body.followDateTime = moment(this.state.date).format(
    //     'YYYY/MM/DD HH:mm:ss'
    //   );
    // } else {
    //   body.followDateTime = null;
    // }
    this.props.closeConsult(body);
  };

  onOptionSelect = (option) => {
    this.setState({
      endReason: option.value,
    });
  };

  render() {
    const getDateTimeToShow = () => {
      // const format = moment(this.state.date).format('h:mm a, Do MMM YYYY');
      // return format;
      return (
        <DateTimePicker
          style={styles.datePicker}
          onChange={this.onChange}
          value={this.state.date}
          disableClock
          required
          clearIcon={null}
        />
      );
    };

    const followupNotesLink = Link.state(this, 'followupNotes');
    const cancelReasonDescriptionLink = Link.state(this, 'cancelReasonDescription');
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>
                Are you sure you want to cancel this consultation?
              </HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              {/*  <OptionsContainer>
                <OptionContainer
                  onTap={() => {
                    this.optionClicked('addDate');
                  }}
                >
                  <SelectLinkText>
                    {this.state.optionChosen === 'addDate' ? (
                      <SelectIcon src={purpleTick} alt="Success" />
                    ) : (
                      <SelectIcon src={greyTick} alt="Success" />
                    )}
                  </SelectLinkText>
                  <OptionText>Add follow-up date</OptionText>
                </OptionContainer>
                <OptionContainer
                  onTap={() => this.optionClicked('notRequired')}
                >
                  <SelectLinkText>
                    {this.state.optionChosen === 'notRequired' ? (
                      <SelectIcon src={purpleTick} alt="Success" />
                    ) : (
                      <SelectIcon src={greyTick} alt="Success" />
                    )}
                  </SelectLinkText>
                  <OptionText>Follow-up not required</OptionText>
                </OptionContainer>
              </OptionsContainer>

              {this.state.optionChosen === 'addDate' ? (
                <DateTimeComponent onTap={this.handleClick}>
                  <DateTimeText>
                    Date and Time: {getDateTimeToShow()}
                  </DateTimeText>
                </DateTimeComponent>
              ) : null}

              <AgeContainer>
                <EndRegularText>Select End Reason</EndRegularText>
                <SelectElement
                  options={this.props.endReasons}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary50: '#d5d5d5',
                      primary25: '#f5f5f5',
                      primary: '#4F34D2',
                    },
                  })}
                  defaultValue={this.props.endReasons[0]}
                  onChange={(option) => this.onOptionSelect(option)}
                />
              </AgeContainer>

              <DobContainer>
                <StyledInputBox
                  type="text"
                  className="visit-input"
                  valueLink={followupNotesLink}
                  placeholder="Follow-up Notes"
                />
              </DobContainer> */}
              <AgeContainer>
                <EndRegularText>Remark</EndRegularText>
                <SelectElement
                  options={this.state.endReasons}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary50: '#d5d5d5',
                      primary25: '#f5f5f5',
                      primary: '#4F34D2',
                    },
                  })}
                  defaultValue={this.state.endReasons[0]}
                  onChange={(option) => this.onOptionSelect(option)}
                />
                {
                  this.state.endReason === 'Others' ?
                    <StyledInputBox
                      type="text"
                      className="visit-input"
                      valueLink={cancelReasonDescriptionLink}
                      placeholder="Reason"
                      style={{ "marginTop": "12px" }}
                      maxLength="50"
                    />
                    : ''
                }
              </AgeContainer>

              <InputContainer>
                <ButtonComponent>
                  <Button
                    onTap={() => this.closeConsultation()}
                    disabled={this.state.endReason ? false : true}
                  // loading={this.state.loading}
                  >
                    <ButtonText>Cancel Consultation</ButtonText>
                  </Button>
                </ButtonComponent>
              </InputContainer>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  endReasons: state.info.endReasons,
});

export default connect(mapStateToProps)(CancelConsultationModal);
