import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import liveCasesIcon from '../../images/live-cases.svg';
import {FaChevronRight} from 'react-icons/fa';
import { fetchConsults } from '../../services';
import { useSelector } from 'react-redux';

const LiveCasesOuter = styled.div`
width: 400px;
height: 72px;
background: #FFFFFF;
box-shadow: 2px 2px 8px rgba(113, 79, 255, 0.21);
border-radius: 20px;
margin-top: 16px;
margin-left: 20px;
cursor: pointer;
`

const InfoContainer = styled.div`
position: relative;
display: flex;
`

const Img = styled.img`
margin-top: 26px;
margin-left: 26px;
`

const Text = styled.p`
margin-top: 26px;
margin-left: 18px;
/* font-family: 'Montserrat'; */
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 120%;
text-align: center;
color: #0F0B28;
`

const Notify = styled.div`
position: absolute;
border-radius: 100%;
/* background-color: #FF0000; */
background-color: #F84F31;
box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.7);
left: 380px;
top: -5px;
width: 20px;
height: 20px;
`

const LiveCasesCard = (props) => {

  const [notify, setNotify] = useState(false);
  const [consultationData, setConsultationData] = useState([]);
  const [selectedSponsorId, setSelectedSponsorId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [consultationIdKey, setConsultationIdKey] = useState(null);
  const [page, setPage] = useState(1);

  const filters = [{isSelected: true, name: 'Live Cases', verticalId: props.verticalId}];

  const activeConsult = useSelector((state) => state.consults.activeConsult);

  const updatedConsults = useSelector((state) => state.consults.consults);

  console.log(props);

  const getAllConsults = async () => {
    try {
      const consultsData = await fetchConsults(props.authToken, filters, selectedSponsorId, searchText, consultationIdKey, page);
      console.log(consultsData, "consultsData testtttt");
      if(!consultsData.responseBody.consultations.length) {
        return;
      }
      let filterArr = consultsData.responseBody.consultations.filter((consult) => {
        return (!activeConsult || consult.consultationId !== activeConsult.consultationId);
      }); 
      setConsultationData(filterArr);
      // const updatedArr = updatedConsults.filter((c) => !c.closedOn);
      // setConsultationData([...updatedArr]);
    }
    catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllConsults();
  },[updatedConsults]);

  return (
    <LiveCasesOuter onClick={props.switch}>
        <InfoContainer>
            <Img src={liveCasesIcon} />
            <Text>Live Cases</Text>
            <FaChevronRight style={{height: '12px', width: '12px', marginTop: '28px', marginLeft: '210px'}}/>
            {consultationData && consultationData.length > 0 && (<Notify />)}
        </InfoContainer>
    </LiveCasesOuter>
  )
}

export default LiveCasesCard