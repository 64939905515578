import React, { Component } from 'react';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { RegularText, Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import moment from 'moment';
import { PrimaryButton } from '../common/Buttons';
import Select from 'react-select';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const OptionContainer = styled(StyledTappable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-right: 3rem;
`;

const OptionText = styled(RegularText)`
  /* color: white; */
  font-size: 1.4rem;
  text-align: center;
  margin: 0rem;
  margin-left: 0.25rem;
  padding: 0rem;
`;

const SelectLinkText = styled(StyledTappable)`
  padding: 0.45rem;
`;

const SelectIcon = styled.img`
  width: 2rem;
  align-self: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
  border-bottom: 1px solid black !important;
  padding: 0.33rem 0;
  width: ${(props) => (props.width ? props.width : 'auto')};
`;

const InputBoxContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  justify-content: space-around;
  margin-left: 0.625rem;
  padding-right: 10rem;
  /* border-bottom: 1px solid black !important; */
`;
const SelectElement = styled(Select)`
  flex: 1;
  width: ${(props) => (props.width ? props.width : 'auto')};
  padding: 0.33rem 0;

  /* border: 0.1rem solid #4F34D2; */
`;

class ScheduleModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: '',
    };
  }

  render() {
    const dateLink = Link.state(this, 'date');
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Select Date & Time to schedule call</HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <InputBoxContainer>
                <StyledInputBox
                  type="datetime-local"
                  className="visit-input"
                  valueLink={dateLink}
                  placeholder="Date Of Birth"
                />
              </InputBoxContainer>

              <InputContainer>
                <ButtonComponent>
                  <Button
                    onTap={() => this.props.onSubmit(this.state.date)}
                    disabled={this.state.date ? false : true}
                    // loading={this.state.loading}
                  >
                    <ButtonText>Submit</ButtonText>
                  </Button>
                </ButtonComponent>
              </InputContainer>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

export default ScheduleModal;
