import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';
import styled from 'styled-components';
import AsyncSelect from 'react-select/lib/Async';

import Select from 'react-select';
import { FaTimes } from 'react-icons/fa';
import StyledTappable from '../components/common/StyledTappable';
import TappableCard from '../components/common/TappableCard';
import { PrimaryButton } from '../components/common/Buttons';
import {
  BoldText,
  Text,
  ButtonText,
  RegularText,
} from '../components/common/Text';
import AddMedicinesModal from '../components/prescription/AddMedicinesModal2';
import AddTemplateModal from '../components/prescription/AddTemplateModal';
import AddLabTestModal from '../components/prescription/AddLabTestModal';
import PrescriptionSentSuccessModal from '../components/prescription/PrescriptionSentSuccessModal';
import MedicineCard from '../components/prescription/MedicineCard';
import TemplateCard from '../components/prescription/TemplateCard';
import {
  addMedicine,
  getTemplate,
  updateTemplate,
  addTemplate,
  deleteTemplate,
  getTemplatesFromId,
  deleteMedicine,
  addLabTest,
  deleteLabTest,
  previewPrescription,
  sendPrescription,
  addAdvicePrescription,
  removeAdvicePrescription,
  getPrescriptionFiles,
  markInvalidPrescription,
  getDiseases,
  sendSymptoms,
} from '../services';
import {
  setPrescriptionId,
  addMedicines,
  addPrescriptionTemplate,
  removeTemplate,
  addLabTests,
  removeMedicine,
  removeLabTest,
  setTreatmentPlan,
  resetPrescription,
  addAdvices,
  removeAdvice,
  restorePrescription,
  savePrescription,
  fetchConsults,
} from '../actions';
import CommonModal from '../components/CommonModal';
import AddDiagnosisModal from '../components/prescription/AddDiagnosisModal';
import SymptomsContainer from './SymptomsContainer';
import config from '../config';

const OuterContainer = styled.div`
  display: flex;
  flex: 2.5;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  padding: 1.6rem 2rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const SectionContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
`;

const SectionTitle = styled(BoldText)`
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
`;

const StyledInputBox = styled(Input)`
  padding: 0.9rem 0;
  flex: 1 0;
  border-bottom: 1px solid #cecece !important;
`;

const StyledTappableCard = styled(TappableCard)`
  align-self: flex-start;
  padding: 1rem 3rem;
  margin-top: 0.625rem;
`;

const TappableText = styled(Text)`
  font-size: 1.4rem;
  text-align: center;
  color: #4f34d2;
`;

const TappableAdviceText = styled(TappableText)`
  font-size: 2.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.2rem 1rem;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 1.4rem 1.8rem;
`;

const StyledButton = styled(PrimaryButton)`
  margin: 0 0.7rem;
`;

const AddedTestsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  /* box-shadow: 0 -0.3rem 0.625rem 0 rgba(0, 0, 0, 0.09); */
  padding: 0.625rem 1rem;
  margin-bottom: 0.5rem;
  background: white;
  flex-shrink: 0;
`;

const AddedTestsInnerContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1.75rem;
`;

const AddedTestContainer = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 0.5rem;
  background: #f4f4f4;
  box-shadow: 0 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
`;

const AdviceComponent = styled(StyledTappable)`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 0.5rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  /* background: #f4f4f4; */
  /* box-shadow: 0 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1); */
  /* border-radius: 2rem; */
  padding: 0.5rem 1rem;
`;

const RemoveIcon = styled(FaTimes)`
  margin-left: 1.125rem;
  align-self: center;
`;

const LabText = styled(RegularText)`
  font-size: 1.6rem;
`;

const AdviceText = styled(RegularText)`
  font-size: 1.6rem;
  align-self: flex-start;
`;

const AddAdviceContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: stretch;
`;

const GeneralHealthAdviceContainer = styled.div`
  flex-direction: columm;
`;

const SelectElement = styled.div`
  /* border: 0.1rem solid #4F34D2; */
  align-self: auto;
  width: 15rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 16,
    textAlign: 'left',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 12,
    textAlign: 'left',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  selectContainer: (provided, state) => ({
    ...provided,
    margin: 10,
  }),
};

const invalidOptions = [
  {
    label: 'Invalid Date',
    value: 1,
  },
  {
    label: 'Invalid Doctor’s Name',
    value: 2,
  },
  {
    label: 'Invalid Patient’s Name',
    value: 3,
  },
  {
    label: 'Blurred Picture',
    value: 4,
  },
  {
    label: 'Prescription not on a valid letter head',
    value: 5,
  },
  {
    label: 'Doctor’s Signature/Stamp Missing',
    value: 6,
  },
  {
    label: 'Inappropriate Picture',
    value: 7,
  },
  { label: 'Missing date', value: 8 },
  { label: 'Missing Patient name', value: 9 },
  { label: 'Missing Doctor name', value: 10 },
  { label: 'Cropped image', value: 11 },
  { label: 'File uploaded is not a prescription', value: 12 },
];

class PrescriptionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      probableDiagnosis: props.probableDiagnosis,
      secondaryProbableDiagnoses: props.secondaryProbableDiagnoses,
      icdCode: props.icdCode,
      secondaryIcdCodes: props.secondaryIcdCodes,
      advice: '',
      medicinesModalVisible: false,
      templateModalVisible: false,
      labTestModalVisible: false,
      selectedMedicineToEdit: {},
      showSendPrescriptionSuccess: false,
      successModalMessage: 'Prescription Sent Successfully!',
      invalidOption: null,
      templateName: '',
      templateValue: null,
      templateOptions: [],
      templateData: [],
      template: [],
      diagnosisValue: null,
      secondaryDiagnosesValue: [],
      addDiagnosisModal: false,
      addSecondaryDiagnosisModal: false,
      selectedOption: null,
      showModal: false,
      modalText: 'Something went wrong',
      selectTemplateInput: '',
      symptoms: [],
      additionalNotes: props.additionalNotes ? props.additionalNotes : '',
      additionalNotesSecondary: props.additionalNotesSecondary ? props.additionalNotesSecondary : '',
      weight: this.props.consultUserInfo.weight,
      height: this.props.consultUserInfo.height
    };
    this.diseaseSelectRef = React.createRef();
    this.secondaryDiseaseSelectRef = React.createRef();
    console.log({ props, state: this.state }, 'prescription container');
  }

  hideModal = () => {
    this.setState({
      medicinesModalVisible: false,
      templateModalVisible: false,
      labTestModalVisible: false,
      addDiagnosisModal: false,
      addSecondaryDiagnosisModal: false,
      showModal: false,
    });
  };

  hidePrescriptionSentSuccessModal = () => {
    this.setState({
      showSendPrescriptionSuccess: false,
    });
  };

  openAddMedicineDialog = () => {
    console.log('openAddMedicineDialog called');
    this.setState({
      medicinesModalVisible: true,
    });
  };
  openAddTemplatesDialog = () => {
    console.log('openAddTemplatesDialog called');
    this.setState({
      templateModalVisible: true,
    });
  };
  openAddLabTestDialog = () => {
    console.log('openAddLabTestDialog called');
    this.setState({
      labTestModalVisible: true,
    });
  };

  addMedicine = (medicine, closeModal) => {
    console.log({ medicine });
    const { dispatch } = this.props;

    const body = {
      user: this.props.activeConsult.user,
      consultationId: this.props.activeConsult.consultationId,
      prescriptionId: this.props.prescriptionId,
      doctorType: this.props.activeConsult.doctorType,
      name: medicine.medicineName,
      genericName: medicine.genericName,
      dosage: medicine.dosage,
      prescribedQuantity:
        medicine.dosage === 'Other' ? medicine.prescribedQuantity : null,
      duration: medicine.durationValue,
      durationType: medicine.duration,
      meal: medicine.mealPref,
      notes:
        medicine.dosage === 'Other' ?
        medicine.otherDosage :
        medicine.notes,
      dimDrugId: medicine.dimDrugId,
      coveredUnderOpd: medicine.coveredUnderOpd,
    };

    return addMedicine(this.props.authToken, body)
      .then((result) => {
        console.log({ result }, 'result from addMedicine');
        if (result.prescriptionId) {
          body.prescriptionId = result.prescriptionId;
          dispatch(setPrescriptionId(result.prescriptionId));
        }

        body.medicineId = result.medicineId;
        dispatch(addMedicines([body]));

        // check if close modal then close
        if (closeModal) {
          this.setState({ medicinesModalVisible: false });
        }
      })
      .catch((err) => {
        console.log({ err }, 'error in add medicine');
      });
  };
  addTemplate = (templateName, closeModal) => {
    if (this.state.probableDiagnosis && this.state.probableDiagnosis.length) {
      const { medicines, labTests, generalHealthAdvice } = this.props;
      const generalAdvice = generalHealthAdvice.map((element) => {
        return { advice: element.advice };
      });
      const medicinesFormat = medicines.map((element) => {
        return {
          dimDrugId: element.dimDrugId,
          dosage: element.dosage,
          duration: element.duration,
          durationType: element.durationType,
          notes: element.notes,
        };
      });
      const labsTests = labTests.map((element) => {
        return {
          id: element.labTestId,
        };
      });
      const body = {
        title: templateName,
        diagnosis: this.state.probableDiagnosis,
        secondaryDiagnoses: this.state.secondaryProbableDiagnoses ? this.state.secondaryProbableDiagnoses.split('$') : null,
        generalAdvice,
        medicines: medicinesFormat,
        labsTests,
        icdCode: this.state.icdCode,
        secondaryIcdCodes:this.state.secondaryIcdCodes.split('$'),
        additionalNotes: this.state.additionalNotes,
        additionalNotesSecondary: this.state.additionalNotesSecondary,
      };
      return addTemplate(this.props.authToken, body)
        .then((result) => {
          console.log({ result }, 'result from addTemplate');
          if (closeModal) {
            this.setState({ templateModalVisible: false });
          }
          this.getTemplate();
        })
        .catch((err) => {
          console.log({ err }, 'error in addTemplate');
        });
    } else {
      console.log('error in addTemplate');
      this.setState({
        showModal: true,
        templateModalVisible: false,
        modalText: 'Probable diagnosis is required',
      });
    }
  };
  updateTemplate = (templateId) => {
    if (this.state.probableDiagnosis && this.state.probableDiagnosis.length) {
      const { medicines, labTests, generalHealthAdvice } = this.props;
      const generalAdvice = generalHealthAdvice.map((element) => {
        return { advice: element.advice };
      });
      const medicinesFormat = medicines.map((element) => {
        return {
          dimDrugId: element.dimDrugId,
          dosage: element.dosage,
          duration: element.duration,
          durationType: element.durationType,
          notes: element.notes,
        };
      });
      const labsTests = labTests.map((element) => {
        return {
          id: element.labTestId,
        };
      });
      const body = {
        templateId,
        diagnosis: this.state.probableDiagnosis,
        icdCode: this.state.icdCode,
        secondaryDiagnoses: this.state.secondaryProbableDiagnoses.split('$'),
        secondaryIcdCodes: this.state.secondaryIcdCodes.split('$'),
        generalAdvice,
        medicines: medicinesFormat,
        labsTests,
        additionalNotes: this.state.additionalNotes,
        additionalNotesSecondary: this.state.additionalNotesSecondary,
      };
      return updateTemplate(this.props.authToken, body)
        .then((result) => {
          console.log({ result }, 'result from deleteTemplate');
          this.getTemplate();
        })
        .catch((err) => {
          console.log({ err }, 'error in deleteTemplate');
        });
    } else {
      this.setState({
        showModal: true,
        templateModalVisible: false,
        modalText: 'Probable diagnosis is required',
      });
    }
  };
  deleteTemplate = (templateId) => {
    console.log({ templateId });
    return deleteTemplate(this.props.authToken, templateId)
      .then((result) => {
        console.log({ result }, 'result from deleteTemplate');
        this.setState({ selectedOption: null });
        this.getTemplate();
      })
      .catch((err) => {
        console.log({ err }, 'error in deleteTemplate');
      });
  };

  addLabTest = (lab) => {
    console.log({ lab });
    const { dispatch } = this.props;

    const body = {
      // user: this.props.activeConsult.user,
      consultationId: this.props.activeConsult.consultationId,
      prescriptionId: this.props.prescriptionId,
      doctorType: this.props.activeConsult.doctorType,
      labId: lab.id,
      labTestId: lab.id,
      labName: lab.value,
      code: lab.code,
    };

    return addLabTest(this.props.authToken, body)
      .then((result) => {
        console.log({ result }, 'result from addLabTest');
        if (result.prescriptionId) {
          body.prescriptionId = result.prescriptionId;
          dispatch(setPrescriptionId(result.prescriptionId));
        }

        if (result.labId) {
          body.labId = result.labId;
        }
        dispatch(addLabTests([body]));
      })
      .catch((err) => {
        console.log({ err }, 'error in add lab test');
      });
  };

  removeLabTest = (lab) => {
    return deleteLabTest(this.props.authToken, lab.labId, lab.prescriptionId)
      .then((result) => {
        this.props.dispatch(removeLabTest(lab));
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  addAdvice = (adviceDetails) => {
    const { dispatch } = this.props;

    if (this.state.advice.length === 0 && !adviceDetails) {
      return;
    }

    const body = {
      // user: this.props.activeConsult.user,
      consultationId: this.props.activeConsult.consultationId,
      prescriptionId: this.props.prescriptionId,
      doctorType: this.props.activeConsult.doctorType,
      advice: this.state.advice || adviceDetails.advice,
    };

    return addAdvicePrescription(this.props.authToken, body)
      .then((result) => {
        console.log({ result }, 'result from addAdvicePrescription');
        if (result.prescriptionId) {
          body.prescriptionId = result.prescriptionId;
          dispatch(setPrescriptionId(result.prescriptionId));
        }

        body.adviceId = result.adviceId;
        dispatch(addAdvices([body]));
        this.setState({ advice: '' });
      })
      .catch((err) => {
        console.log({ err }, 'error in add advice');
      });
  };

  removeAdvice = (advice) => {
    return removeAdvicePrescription(
      this.props.authToken,
      advice.adviceId,
      advice.prescriptionId
    )
      .then((result) => {
        this.props.dispatch(removeAdvice(advice));
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  componentDidMount() {
    const { dispatch, savedPrescription } = this.props;
    if (savedPrescription) {
      dispatch(restorePrescription(savedPrescription));
    }
    this.getTemplate();
  }
  componentDidUpdate(prevProps, prevState) {
    const { dispatch, savedPrescription, activeConsult, prescription } =
      this.props;
    if (
      prevProps.activeConsult.consultationId !==
      this.props.activeConsult.consultationId
    ) {
      this.setState({
        selectedOption: null,
        templateName: '',
        probableDiagnosis: '',
        secondaryProbableDiagnoses:'',
        icdCode: '',
        secondaryIcdCodes: '',
        templateValue: null,
      });
      dispatch(savePrescription(activeConsult.consultationId, prescription));
      if (savedPrescription) {
        dispatch(restorePrescription(savedPrescription));
      }
    }
   
    if ((this.state.secondaryProbableDiagnoses === '' || !this.state.secondaryProbableDiagnoses) && this.state.additionalNotesSecondary !== '') {
      this.setState({additionalNotesSecondary: ''});
    }
  }

  componentWillUnmount() {
    const { dispatch, activeConsult, prescription } = this.props;
    dispatch({
      type: 'SET_PROBABLE_DIAGNOSIS',
      payload: this.state.probableDiagnosis,
    });
    dispatch(savePrescription(activeConsult.consultationId, prescription));
    // dispatch({ type: 'SET_GENERAL_HEALTH_ADVICE', payload: this.state.generalHealthAdvice });
  }

  deleteMedicine = (medicine, index) => {
    return deleteMedicine(
      this.props.authToken,
      medicine.medicineId,
      medicine.prescriptionId
    )
      .then((result) => {
        this.props.dispatch(removeMedicine(medicine));
      })
      .catch((err) => {
        console.log({ err });
      });
  };
  renderMedicine = (medicine, index) => {
    return (
      <MedicineCard
        medicine={medicine}
        deleteMedicine={() => this.deleteMedicine(medicine, index)}
        key={`${index}_${medicine.name}`}
      />
    );
  };

  renderAdvices = (ele, index) => {
    return (
      <AdviceComponent key={ele.adviceId} onTap={() => this.removeAdvice(ele)}>
        <AdviceText>
          {index + 1}. {ele.advice}
        </AdviceText>
        <RemoveIcon />
      </AdviceComponent>
    );
  };

  renderLabTest = (ele, idx) => (
    <AddedTestContainer key={ele.labName} onTap={() => this.removeLabTest(ele)}>
      <LabText>{ele.labName}</LabText>
      <RemoveIcon />
    </AddedTestContainer>
  );

  previewPrescription = () => {
    debugger;
    previewPrescription(
      this.props.authToken,
      this.props.prescriptionId,
      this.state.probableDiagnosis,
      this.state.icdCode,
      this.state.secondaryProbableDiagnoses,
      this.state.secondaryIcdCodes,
      this.props.activeConsult.consultationId,
      this.props.activeConsult.doctorType,
      this.state.additionalNotes,
      this.state.additionalNotesSecondary,
      this.state.weight,
      this.state.height,
    )
      .then((result) => {
        this.props.dispatch(setTreatmentPlan(result));
        this.props.dispatch(setPrescriptionId(result.prescriptionId));
        window.open(result.treatmentPlanPdfLink);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  handleSymptomsCovid = (symptomId) => {
    this.setState({symptoms: [...symptomId]});
  }

  sendPrescription = () => {
    console.log('send prescription called');
    const transcriberDoctorId = this.props.transcriberAccountActive
      ? this.props.user.doctorId
      : null;
    let filesArray = null;
    if (this.props.files) {
      filesArray = [];
      this.props.files.forEach((file) => {
        filesArray.push(file.url);
      });
    }
    this.setState({ loading: true });
    console.log(this.state, 'state in send prescription');
    sendPrescription(
      this.props.authToken,
      this.props.prescriptionId,
      this.state.probableDiagnosis,
      this.state.icdCode,
      this.state.secondaryProbableDiagnoses,
      this.state.secondaryIcdCodes,
      this.props.activeConsult.consultationId,
      this.props.activeConsult.doctorType,
      this.props.transcriberAccountActive,
      transcriberDoctorId,
      filesArray,
      this.state.additionalNotes,
      this.state.additionalNotesSecondary,
      this.state.weight,
      this.state.height,
      this.props.consultUserInfo.id,
      this.props.consultUserInfo.sponsorId
    )
      .then((result) => {
        console.log('prescription sent success');

        if(this.state.symptoms[0].symptomId === config.covidSymptomId) {
          sendSymptoms(this.props.authToken, this.props.activeConsult.consultationId)
          .then((covidRes) => {
            this.setState({
              showSendPrescriptionSuccess: true,
              successModalMessage: 'Prescription Sent Successfully',
              probableDiagnosis: '',
              icdCode: '',
              advice: '',
              loading: false,
              additionalNotes: '',
              additionalNotesSecondary: ''
            });
            this.props.dispatch(resetPrescription());
            if (this.props.transcriberAccountActive) {
              this.props.dispatch(
                fetchConsults({
                  authToken: this.props.authToken,
                  filters: this.props.filters,
                  selectedSponsorId: this.props.selectedSponsorId,
                })
              );
            }
          })
          .catch((err) => {
            console.log({ err });
          });

          return;
        }

        this.setState({
          showSendPrescriptionSuccess: true,
          successModalMessage: 'Prescription Sent Successfully',
          probableDiagnosis: '',
          icdCode: '',
          advice: '',
          loading: false,
          additionalNotes: '',
          additionalNotesSecondary: '',
        });
        this.props.dispatch(resetPrescription());
        if (this.props.transcriberAccountActive) {
          this.props.dispatch(
            fetchConsults({
              authToken: this.props.authToken,
              filters: this.props.filters,
              selectedSponsorId: this.props.selectedSponsorId,
            })
          );
        }
        // window.open(result.treatmentPlanPdfLink);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  markPrescriptionInvalid = () => {
    markInvalidPrescription(
      this.props.authToken,
      this.props.activeConsult.consultationId,
      this.state.invalidOption
    )
      .then((result) => {
        console.log({ result });
        this.setState({
          showSendPrescriptionSuccess: true,
          successModalMessage: 'Prescription marked Invalid!',
        });
        this.props.dispatch(
          fetchConsults({
            authToken: this.props.authToken,
            filters: this.props.filters,
            selectedSponsorId: this.props.selectedSponsorId,
          })
        );
      })
      .catch((err) => {
        console.log({ err });
      });
  };
  getTemplate = (selectTemplateInput) => {
    const params = {};
    if (selectTemplateInput) {
      params.search = selectTemplateInput;
    }
    return getTemplate(this.props.authToken, params)
      .then((result) => {
        console.log({ result }, 'getTemplate result');
        const templateOptions = [
          { label: 'No Template', value: 'no-template', isPublic: true },
        ];
        result.results.map((template) => {
          templateOptions.push({
            label: template.title,
            value: template.id,
            isPublic: template.isPublic ? true : false,
          });
        });
        this.setState({
          templateOptions,
          selectTemplateInput: '',
          template: result.results,
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };
  removeTemplateDetails = () => {
    const { medicines, labTests, generalHealthAdvice } = this.props;
    if (medicines.length) {
      medicines.map((med) => {
        return this.deleteMedicine(med, null);
      });
    }
    if (labTests.length) {
      labTests.map((lab) => {
        return this.removeLabTest(lab);
      });
    }
    if (generalHealthAdvice.length) {
      generalHealthAdvice.map((advice) => {
        return this.removeAdvice(advice);
      });
    }
  };

  onDiseaseSelect = (option) => {
    console.log('Disease=>', { option, ref: this.diseaseSelectRef.current });
    if (option) {
      if (option.name === 'other') {
        this.setState({ addDiagnosisModal: true });
        return;
      }
      this.setState({
        probableDiagnosis: option.name,
        icdCode: option.code,
      });
      this.props.dispatch({
        type: 'SET_PROBABLE_DIAGNOSIS',
        payload: option.name,
      });
      this.props.dispatch({
        type: 'SET_ICD_CODE',
        payload: option.code,
      });
      this.setState({
        diagnosisValue: {
          label: option.name,
          value: option.name,
          code: option.code,
        },
      });
    } else {
      this.setState({
        diagnosisValue: null,
      });
      this.setState({
        probableDiagnosis: '',
        icdCode: null,
      });
    }
    // console.log(this.state)
  };
  onSecondaryDiseaseSelect = (option) => {
    console.log('Disease=>', {
      option,
      ref: this.secondaryDiseaseSelectRef.current,
    });
    let storeDiagnosis = [];
    if (option) {
      if (option.find((e) => e.name === 'other')) {
        this.setState({ addSecondaryDiagnosisModal: true });
        return;
      }
      option.map((data) => {
        return storeDiagnosis.push({
          label: `${data.label}`,
          value: data.value,
          code: data.code,
        });
      });
      this.setState({
        secondaryDiagnosesValue: storeDiagnosis
      }, () => {
        console.log('secondaryDiagnosesValue', this.state.secondaryDiagnosesValue);
      });

      this.setState({
        secondaryProbableDiagnoses: storeDiagnosis && storeDiagnosis
          .map(e => e.label ).join('$'),
        secondaryIcdCodes: storeDiagnosis && storeDiagnosis
          .map(e => e.code ).join('$'),
      }, () => {

        this.props.dispatch({
          type: 'SET_SECONDARY_PROBABLE_DIAGNOSIS',
          payload: this.state.probableDiagnosis
        });
        this.props.dispatch({
          type: 'SET_SECONDARY_ICD_CODE',
          payload: option.map(d => d.code).join('$'),
        });
      })
    } else {
      this.secondaryDiseaseSelectRef.current.state.defaultOptions = null;
    }
    // console.log(this.state)
  };

  getDisease = (input) => {
    return getDiseases(this.props.authToken, input)
      .then((result) => {
        console.log({ result }, 'getDisease result');
        result.forEach((dis) => {
          dis.label = `${dis.name} - ${dis.code}`;
          dis.value = dis.code;
        });
        console.log(
          this.state.diagnosisValue,
          this.state.secondaryDiagnosesValue
        );
        result = result.filter((dis) => {
          return !((this.state.diagnosisValue && this.state.diagnosisValue.code === dis.code) ||
                  this.state.secondaryDiagnosesValue.indexOf(
                    (sdv) => sdv.code === dis.code
                  ) !== -1);
        });
        // result.push({ label: 'other', value: 'OTHER', name: 'other' });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  promiseDiseaseOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getDisease(input.toLowerCase()));
      }, 500);
    });
  };

  onTemplateSelect = (option) => {
    this.setState({
      selectedOption: option,
    });
    let medicinesArray = [];
    let labsTests = [];
    let generalAdvice = [];
    let secondaryDiagnoses = [];
    let secondaryIcdCodes = [];
    if (option.value === 'no-template') {
      this.removeTemplateDetails();
      this.setState({
        templateName: '',
        probableDiagnosis: '',
        icdCode: '',
        secondaryProbableDiagnoses: '',
        secondaryIcdCode:'',
        templateValue: null,
      });
    } else {
      let templateData;
      return getTemplatesFromId(this.props.authToken, option.value)
        .then((result) => {
          console.log({ result }, 'getTemplate result');
          templateData = result.results;
          if (this.state.templateValue) {
            return this.removeTemplateDetails();
          }
        })
        .then(() => {
          console.log({ templateData }, '[onTemplateSelect]');
          if(templateData[0] && templateData[0].diagnosis)
          {
            this.setState({
              diagnosisValue: {
                label: templateData[0].diagnosis,
                value: templateData[0].diagnosis,
                code: templateData[0].icdCode || '',
              },
            });  
            this.setState({
              probableDiagnosis: templateData[0].diagnosis,
              icdCode: templateData[0].icdCode || '',
            });  
          }
          this.setState({
            secondaryDiagnosesValue: templateData[0].secondaryDiagnoses.map((sdv, index) => {
              if(sdv.length > 0){
                return {
                  label: sdv,
                  value: sdv,
                  code: templateData[0].secondaryIcdCodes[index]
                }  
              }
            }),
            secondaryIcdCodes: templateData[0].secondaryIcdCodes.join('$'),
            secondaryProbableDiagnoses: templateData[0].secondaryDiagnoses.join('$')
          })
          this.setState({
            additionalNotes: templateData[0].additionalNotes ? templateData[0].additionalNotes : '',
            additionalNotesSecondary: templateData[0].additionalNotesSecondary ? templateData[0].additionalNotesSecondary : '',
          })
          console.log(this.state, '[onTemplateSelect]');
          // Add medicines
          medicinesArray = templateData[0].medicines;
          if (medicinesArray.length) {
            const firstMedicine = medicinesArray.shift();
            const medicine = {
              medicineName: firstMedicine.name,
              genericName: firstMedicine.genericName,
              dosage: firstMedicine.dosage,
              durationValue: firstMedicine.duration,
              duration: firstMedicine.durationType,
              mealPref: firstMedicine.meal,
              notes: firstMedicine.notes,
              dimDrugId: firstMedicine.dimDrugId,
            };
            return this.addMedicine(medicine, false);
          }
        })
        .then(() => {
          if (medicinesArray.length) {
            medicinesArray.map((med) => {
              const medicine = {
                medicineName: med.name,
                genericName: med.genericName,
                dosage: med.dosage,
                durationValue: med.duration,
                duration: med.durationType,
                mealPref: med.meal,
                notes: med.notes,
                dimDrugId: med.dimDrugId,
              };
              this.addMedicine(medicine, false);
            });
          }
        })
        .then(() => {
          labsTests = templateData[0].labsTests;
          if (labsTests.length) {
            const firstLabTest = labsTests.shift();
            return this.addLabTest(firstLabTest);
          }
        })
        .then(() => {
          // Add Labs
          if (labsTests.length) {
            labsTests.map((lab) => {
              this.addLabTest(lab);
            });
          }
        })
        .then(() => {
          generalAdvice = templateData[0].generalAdvice;
          if (generalAdvice.length) {
            const firstgeneralAdvice = generalAdvice.shift();
            return this.addAdvice(firstgeneralAdvice);
          }
        })
        .then(() => {
          // Add advices
          if (generalAdvice.length) {
            generalAdvice.map((advice) => {
              this.addAdvice(advice);
            });
          }
          this.setState({
            templateName: option.label ? option.label : '',
            templateValue: option.value ? option.value : '',
          });
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  };
  handleSelectTemplateInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === 'input-change' && inputValue.length > 0) {
      const selectTemplateInput = inputValue;
      this.getTemplate(selectTemplateInput);
    }
    if (actionMeta.action === 'menu-close') {
      this.getTemplate();
    }
  };
  render() {
    console.log(
      { props: this.props, state: this.state, info: this.props.consultUserInfo },
      'log in render [PrescriptionContainer]'
    );
    const patientInfo = this.props.consultPatientInfo;
    const probableDiagnosisLink = Link.state(
      this,
      'probableDiagnosis'
    ).onChange((e) => {
      console.log({ e }, 'probableDiagnosisLink');
      this.props.dispatch({
        type: 'SET_PROBABLE_DIAGNOSIS',
        payload: e,
      });
    });
    const generalHealthAdviceLink = Link.state(this, 'advice');
    const additionalNotesLink = Link.state(this, 'additionalNotes');
    const additionalNotesSecondaryLink = Link.state(this, 'additionalNotesSecondary');
    const weightLink = Link.state(this, 'weight');
    const heightLink = Link.state(this, 'height');

    return (
      <OuterContainer>
        <BodyContainer>
          <SectionContainer>
            <SectionTitle>Template</SectionTitle>
            <Select
              options={this.state.templateOptions}
              styles={customStyles}
              value={this.state.selectedOption}
              onInputChange={this.handleSelectTemplateInputChange}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary50: '#d5d5d5',
                  primary25: '#f5f5f5',
                  primary: '#4F34D2',
                },
              })}
              placeholder="Select Templates"
              onChange={(option) => this.onTemplateSelect(option)}
            />
            {this.state.selectedOption ? (
              <TemplateCard
                template={this.state.selectedOption}
                deleteTemplate={(templateId, closeModal) =>
                  this.deleteTemplate(templateId, closeModal)
                }
                updateTemplate={(templateId, closeModal) =>
                  this.updateTemplate(templateId, closeModal)
                }
                key={`${this.state.selectedOption.id}`}
              />
            ) : null}
          </SectionContainer>
         { !(patientInfo.relativeRelation && patientInfo.relativeRelation.trim() == 'Pet') &&
        <SectionContainer>
            <SymptomsContainer
              showUnderPrescriptionsTab={true}
              symptomsCovid={this.handleSymptomsCovid || null}
            ></SymptomsContainer>
            <SectionContainer>
            <SectionTitle>Weight {this.props.info.weightMandatory && '*'}</SectionTitle>
            {/* <StyledInputBox
              valueLink={probableDiagnosisLink}
              className="visit-input"
              placeholder="eg. alopecia alcarta"
            /> */}
            <AddAdviceContainer>
              <StyledInputBox
                valueLink={weightLink}
                className="visit-input"
                placeholder="Enter Weight in kg"
                type = 'number'
              />
            </AddAdviceContainer>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>Height {this.props.info.heightMandatory && '*'}</SectionTitle>
            {/* <StyledInputBox
              valueLink={probableDiagnosisLink}
              className="visit-input"
              placeholder="eg. alopecia alcarta"
            /> */}
             <AddAdviceContainer>
              <StyledInputBox
                valueLink={heightLink}
                className="visit-input"
                placeholder="Enter Height in cm"
                type = 'number'
              />
            </AddAdviceContainer>

          </SectionContainer>

         </SectionContainer> }
         {!(patientInfo.relativeRelation && patientInfo.relativeRelation.trim() == 'Pet') &&  
        (<>
         <SectionContainer>
            <SectionTitle>Probable Diagnosis(Primary)*</SectionTitle>
            {/* <StyledInputBox
              valueLink={probableDiagnosisLink}
              className="visit-input"
              placeholder="eg. alopecia alcarta"
            /> */}
            <AsyncSelect
              ref={this.diseaseSelectRef}
              placeholder="Search Disease"
              cacheOptions
              isClearable={true}
              value={
                this.state.templateName != '' ||
                this.state.probableDiagnosis != ''
                  ? this.state.diagnosisValue
                  : ''
              }
              defaultOptions={[]}
              loadOptions={this.promiseDiseaseOptions}
              onChange={(option, cb) => this.onDiseaseSelect(option, cb)}
            />
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>Additional Notes</SectionTitle>
            <AddAdviceContainer>
              <StyledInputBox
                valueLink={additionalNotesLink}
                className="visit-input"
                placeholder="additional notes"
              />
            </AddAdviceContainer>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>Probable Diagnosis(Secondary)</SectionTitle>
            <AsyncSelect
              ref={this.secondaryDiseaseSelectRef}
              isMulti
              placeholder="Search Disease"
              cacheOptions
              isClearable={true}
              value={
                this.state.templateName != '' ||
                this.state.secondaryProbableDiagnoses != ''
                  ? this.state.secondaryDiagnosesValue
                  : ''
              }
              defaultOptions={[]}
              loadOptions={this.promiseDiseaseOptions}
              onChange={(option, cb) =>
                this.onSecondaryDiseaseSelect(option, cb)
              }
              isOptionDisabled={(option) =>
                this.state.secondaryDiagnosesValue.length > 3
              }
            />
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>Additional Notes</SectionTitle>
            <AddAdviceContainer>
              <StyledInputBox
                valueLink={additionalNotesSecondaryLink}
                className="visit-input"
                placeholder="additional notes"
              />
            </AddAdviceContainer>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>Medicines</SectionTitle>
            {this.props.medicines.map(this.renderMedicine)}
            <StyledTappableCard onTap={() => this.openAddMedicineDialog()}>
              <TappableText>+ Add Medicines</TappableText>
            </StyledTappableCard>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>Lab tests</SectionTitle>
            {this.props.labTests.length > 0 ? (
              <AddedTestsContainer>
                <AddedTestsInnerContainer>
                  {this.props.labTests.map(this.renderLabTest)}
                </AddedTestsInnerContainer>
              </AddedTestsContainer>
            ) : null}
            <StyledTappableCard onTap={() => this.openAddLabTestDialog()}>
              <TappableText>+ Add Lab Tests</TappableText>
            </StyledTappableCard>
          </SectionContainer></>)}
          <SectionContainer>
            <SectionTitle>General Health Advice</SectionTitle>
            {this.props.generalHealthAdvice.map(this.renderAdvices)}
            <AddAdviceContainer>
              <StyledInputBox
                valueLink={generalHealthAdviceLink}
                className="visit-input"
                placeholder="add an advice for patient"
              />
              <StyledTappable
                onTap={() => this.addAdvice()}
                disabled={this.state.advice.length > 0 ? false : true}
              >
                <TappableAdviceText>
                  + <TappableText>Add</TappableText>
                </TappableAdviceText>
              </StyledTappable>
            </AddAdviceContainer>
          </SectionContainer>
          <SectionContainer>
            <StyledTappableCard onTap={() => this.openAddTemplatesDialog()}>
              <TappableText>+ Add Template</TappableText>
            </StyledTappableCard>
          </SectionContainer>
          {!this.props.transcriberAccountActive ? (
            <FooterContainer>
              <StyledButton
                onTap={() => this.previewPrescription()}
                disabled={
                  !this.state.loading && ((patientInfo.relativeRelation && patientInfo.relativeRelation.trim() == 'Pet') || (this.state.probableDiagnosis.length > 0  && ((parseInt(this.state.weight) > 0 || !(this.props.info && this.props.info.weightMandatory)) && (parseInt(this.state.height) > 0) || !(this.props.info && this.props.info.heightMandatory))))
                  ? false : true
                }
              >
                <ButtonText>Preview</ButtonText>
              </StyledButton>
              <StyledButton
                onTap={() => this.sendPrescription()}
                disabled={
                  !this.state.loading && ((patientInfo.relativeRelation && patientInfo.relativeRelation.trim() == 'Pet') || ( this.state.probableDiagnosis.length > 0 && ((parseInt(this.state.weight) > 0 || !(this.props.info && this.props.info.weightMandatory)) && (parseInt(this.state.height) > 0) || !(this.props.info && this.props.info.heightMandatory))))
                    ? false
                    : true
                }
              >
                <ButtonText>Send</ButtonText>
              </StyledButton>
              <StyledButton
                onTap={() => this.props.renderChats()}
              >
                <ButtonText>Back</ButtonText>
              </StyledButton>
            </FooterContainer>
          ) : null}
          {/* {this.props.transcriberAccountActive && (
            <FooterContainer>
              <SelectElement>
                <Select
                  options={invalidOptions}
                  styles={customStyles}
                  theme={(theme) => ({
                    ...theme,
                    // borderRadius: '0.25rem',
                    colors: {
                      ...theme.colors,
                      primary50: '#d5d5d5',
                      primary25: '#f5f5f5',
                      primary: '#4F34D2',
                    },
                  })}
                  // defaultValue={this.props.selectedSponsorId}
                  onChange={(option) =>
                    this.setState({ invalidOption: option.value })
                  }
                />
              </SelectElement>
              <StyledButton
                disabled={!this.state.invalidOption}
                onTap={() => this.markPrescriptionInvalid()}
              >
                <ButtonText>Mark Pres. Invalid</ButtonText>
              </StyledButton>
            </FooterContainer>
          )} */}
        </BodyContainer>
        {this.state.medicinesModalVisible && (
          <AddMedicinesModal
            addMedicine={(body, closeModal) =>
              this.addMedicine(body, closeModal)
            }
            hideModal={() => this.hideModal()}
          />
        )}
        {this.state.addDiagnosisModal && (
          <AddDiagnosisModal
            hideModal={() => this.hideModal()}
            addDiagnosis={(value) => {
              this.onDiseaseSelect({
                name: value,
                code: 'OTHERS',
              });
              this.hideModal();
            }}
          ></AddDiagnosisModal>
        )}
        {this.state.addSecondaryDiagnosisModal && (
          <AddDiagnosisModal
            hideModal={() => this.hideModal()}
            addDiagnosis={(value) => {
              this.onSecondaryDiseaseSelect([...this.state.secondaryDiagnosesValue,{
                label: value,
                value,
                name: value,
                code: 'OTHERS',
              }]);
              this.hideModal();
            }}
          ></AddDiagnosisModal>
        )}
        {this.state.labTestModalVisible && (
          <AddLabTestModal
            addLabTest={(body) => this.addLabTest(body)}
            removeLabTest={(body) => this.removeLabTest(body)}
            hideModal={() => this.hideModal()}
          />
        )}
        {this.state.templateModalVisible && (
          <AddTemplateModal
            addTemplate={this.addTemplate}
            // addTemplate={(template, closeModal) =>
            //   this.addTemplate(template, closeModal)
            // }
            hideModal={() => this.hideModal()}
          />
        )}
        {this.state.showSendPrescriptionSuccess && (
          <PrescriptionSentSuccessModal
            hideModal={() => this.hidePrescriptionSentSuccessModal()}
            message={this.state.successModalMessage}
          />
        )}
        {this.state.showModal && (
          <CommonModal
            closeModal={() => this.hideModal()}
            modalText={this.state.modalText}
          />
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const consultationId = state.consults.activeConsult.consultationId;
  const digitisationRequestId =
    state.consults.activeConsult.digitisationRequestId;
  let savedPrescription = state.savedPrescription[consultationId];
  const transcriberAccountActive = state.app.transcriberAccountActive;
  // let offlineDoctorAuthToken = null;
  if (transcriberAccountActive) {
    savedPrescription = state.savedPrescription[digitisationRequestId];
    // offlineDoctorAuthToken = state.consults.activeConsult.offlineInfo.authToken;
  }

  return {
    authToken: state.user.authToken,
    activeConsult: state.consults.activeConsult,
    prescriptionId: state.prescription.prescriptionId,
    template: state.prescription.template,
    medicines: state.prescription.medicines,
    labTests: state.prescription.labTests,
    probableDiagnosis: state.prescription.probableDiagnosis,
    icdCode: state.prescription.icdCode,
    secondaryProbableDiagnoses: state.prescription.secondaryProbableDiagnoses,
    secondaryIcdCodes: state.prescription.secondaryDiagnosisIcdCode,
    generalHealthAdvice: state.prescription.generalHealthAdvice,
    user: state.user,
    info: state.info,
    prescription: state.prescription,
    savedPrescription,
    transcriberAccountActive,
    files: state.consults.activeConsult.files,
    filters: state.filters.filters,
    selectedSponsorId: state.selectedSponsorId,
    additionalNotes: state.prescription.additionalNotes,
    additionalNotesSecondary: state.prescription.additionalNotesSecondary,
    consultUserInfo: state.consults.currentUserInfo,
    consultPatientInfo: state.consults.consultPatientInfo
  };
};

export default connect(mapStateToProps)(PrescriptionContainer);
