import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';
import { FaSearch, FaCircleNotch } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import QcCases from './admin/QcCases';

import {
  fetchConsults,
  fetchConsultsOnPagination,
  selectConsult,
  showFilterPane,
  setFetchingConsults,
  setDoctorLastReadTimetokenForPrevConsult,
} from '../actions/index';
import StyledTappable from './common/StyledTappable';
import ConsultationItem from './ConsultationItem';

import filterIcon from '../images/filter-icon.svg';
import { RegularText, Text } from './common/Text';
import noResultsIcon from '../images/consultations/nosearch.svg';
import moment from 'moment';

const OuterContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: stretch;
  position: relative;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: stretch;
`;

const FilterIcon = styled.img`
  margin: 1.4rem;
`;

const StyledInputBox = styled(Input)`
  flex: 1 0;
  font-size: 1.6rem;
  padding: 1.4rem 0;
`;

const SearchIcon = styled(FaSearch)`
  color: #4f34d2;
  font-size: 2.2rem;
  margin: 1.4rem;
`;

const ConsultationsContainer = styled.div`
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
`;

const TappableConsult = styled(StyledTappable)`
  flex-direction: column;
  flex: 1 0;
  align-items: stretch;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;
  flex: 1;
  flex-shrink: 0;
`;

const EmptyIcon = styled.img`
  width: 10rem;
  margin-bottom: 1.25rem;
`;

const EmptyText = styled(RegularText)`
  font-size: 1.25rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  flex-shrink: 0;
  padding: 0.625rem;
  align-items: center;
`;

const LoadingText = styled(Text)`
  font-size: 1.4rem;
`;

const LoadingIcon = styled(FaCircleNotch)`
  margin-right: 0.5rem;
  transform: translate(2);
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #4F34D2;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 2s linear infinite;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background: #ffffff88;
`;

class ConsultationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      isSearchLoading: false,
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress = (event) => {
    console.log({ event, focus: document.activeElement.name });
    if (
      event.code === 'Enter' &&
      document.activeElement &&
      document.activeElement.name === 'search-input'
    ) {
      this.searchConsults();
    }
  };
  

  componentDidMount = () => {
    const urlParams = new URLSearchParams(this.props.location.search);
    if(urlParams.get('digitisationId')){
      const { dispatch, authToken, filters } = this.props;
      dispatch(fetchConsults({ authToken, searchText:urlParams.get('digitisationId') , filters }));
    }else{
      this.fetchConsults();
    }
    
    window.addEventListener('keydown', this.handleKeyPress, false);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.consults !== this.props.consults) {
      this.setState({ isSearchLoading: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress, false);
  }

  fetchConsults() {
    const {
      dispatch,
      authToken,
      location,
      filters,
      isPartnerDoctor,
      partnerSponsorId,
    } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const consultationIdKey = urlParams.get('consultationId');
    console.log({ urlParams, location });
    if (isPartnerDoctor) {
      const selectedSponsorId = partnerSponsorId;
      dispatch(fetchConsults({ authToken, filters, selectedSponsorId }));
    } else {
      dispatch(fetchConsults({ authToken, consultationIdKey, filters }));
    }
  }

  searchConsults() {
    const { dispatch, authToken, filters } = this.props;
    const { searchText } = this.state;
    this.setState({ isSearchLoading: true });
    dispatch(fetchConsults({ authToken, searchText, filters }));
  }

  fetchConsultsOnPagination = (paginateToken) => {
    const { dispatch, authToken, fetchConsultsUrlForPagination, filters } =
      this.props;
    dispatch(
      fetchConsultsOnPagination({
        authToken,
        paginateToken,
        fetchConsultsUrlForPagination,
        filters,
      })
    );
  };

  onListScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const {
      fetchConsultsUrlForPagination,
      canPaginate,
      paginateToken,
      fetchingConsults,
      dispatch,
    } = this.props;
    // console.log('onListScroll called', { scrollHeight, scrollTop, clientHeight, fetchConsultsUrlForPagination, canPaginate, paginateToken });
    if (scrollHeight - (scrollTop + clientHeight) <= 50) {
      console.log('onListScroll called', {
        scrollHeight,
        scrollTop,
        clientHeight,
        fetchConsultsUrlForPagination,
        canPaginate,
        paginateToken,
      });
      if (canPaginate && fetchConsultsUrlForPagination && !fetchingConsults) {
        dispatch(setFetchingConsults());
        this.fetchConsultsOnPagination(paginateToken);
      }
    }
  };

  setActiveConsult(consult) {
    const { dispatch, activeConsult } = this.props;
    consult.unreadMessageCount = 0;
    dispatch(selectConsult(consult));
    // dispatch action here to make api call to update doctor_last_read_timetoken
    dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
  }

  showFilter() {
    const { dispatch } = this.props;
    dispatch(showFilterPane());
  }

  getTimeText = (doctorTimetoken, userTimetoken) => {
    doctorTimetoken = doctorTimetoken / 10000000;
    userTimetoken = userTimetoken / 10000000;

    // const docMin = doctorTimetoken / 60;
    const userMin = userTimetoken / 60;

    if (userTimetoken > doctorTimetoken) {
      const currentMin = moment().valueOf() / 60000;
      const min = parseInt(currentMin - userMin);
      if (min < 60) {
        return `${min}min`;
      } else if (min < 3600) {
        return `${parseInt(min / 60)}h`;
      } else {
        return `${parseInt(min / (60 * 24))}days`;
      }
    }

    return null;
  };

  getDateText = (createdAt) => {
    // converting in moment format
    // createdAt = createdAt * 1000;

    if (!createdAt) {
      return null;
    }

    const latestTime = moment(createdAt);

    const currentTime = moment();
    if (latestTime.format('DD') !== currentTime.format('DD')) {
      return latestTime.format('D/MMM/YYYY');
    } else {
      return latestTime.format('h:mm A');
    }
  };

  render() {
    const { consults, activeConsult, transcriberAccountActive } = this.props;

    const searchTextLink = Link.state(this, 'searchText');
    const renderConsult = (ele, index) => (
      <TappableConsult
        key={`${ele.createdAt}_${index}`}
        onTap={() => this.setActiveConsult(ele)}
      >
        <ConsultationItem
          userName={
            ele.userName
              ? ele.userName
              : ele.user.relativeName
                ? ele.user.relativeName
                : ele.user.userName
          }
          issues={ele.issues}
          labels={ele.labels}
          active={
            transcriberAccountActive
              ? ele.digitisationRequestId ===
              activeConsult.digitisationRequestId
              : ele.consultationId === activeConsult.consultationId
          }
          timeText={this.getTimeText(ele.doctorTimetoken, ele.userTimetoken)}
          dateText={this.getDateText(ele.timeStamp)}
          unreadMessageCount={
            transcriberAccountActive
              ? 0
              : ele.consultationId ===
                activeConsult.consultationId ? 0
                : ele.unreadMessageCount
          }
          isSuspicious={ele.isSuspicious}
          isVipUser={ele.isVipUser}
          isReviewCase={ele.isReviewCase}
        />
      </TappableConsult>
    );
    return (
      <OuterContainer>
        {this.state.isSearchLoading ? <LoaderWrapper>
          <Loader />
        </LoaderWrapper> : <></>}
        <SearchContainer onSubmit={this.searchConsults}>
          <StyledTappable onTap={() => this.showFilter()}>
            <FilterIcon src={filterIcon} />
          </StyledTappable>
          <StyledInputBox
            valueLink={searchTextLink}
            name="search-input"
            placeholder="Search for patients"
            className="visit-input"
          />
          <StyledTappable 
          onTap={() => this.searchConsults()}
          >
            <SearchIcon />
          </StyledTappable>
        </SearchContainer>
        <ConsultationsContainer onScroll={this.onListScroll} ref={this.listRef}>
          {consults.length === 0 ? (
            <EmptyContainer>
              <EmptyIcon src={noResultsIcon} />
              <EmptyText>
                Sorry, we couldn’t find any result for that search, try a
                different search.
              </EmptyText>
            </EmptyContainer>
          ) : (
            consults.map(renderConsult)
          )}
          {this.props.canPaginate && consults.length >= 20 && (
            <ButtonsContainer>
              <LoadingIcon className="icon-spin" />
              <LoadingText>Loading...</LoadingText>
            </ButtonsContainer>
          )}
        </ConsultationsContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  consults: state.consults.consults,
  filters: state.filters.filters,
  fetchingConsults: state.consults.fetchingConsults,
  activeConsult: state.consults.activeConsult,
  authToken: state.user.authToken,
  canPaginate: state.info.canPaginate,
  paginateToken: state.info.paginateToken,
  fetchConsultsUrlForPagination: state.info.fetchConsultsUrlForPagination,
  transcriberAccountActive: state.app.transcriberAccountActive,
  isPartnerDoctor: state.user.isPartnerDoctor,
  partnerSponsorId: state.user.partnerSponsorId,
});

export default withRouter(connect(mapStateToProps)(ConsultationList));
