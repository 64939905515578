import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import lungs from '../../images/lungs.svg';
import tablets from '../../images/tablets.svg';
import food from '../../images/food.svg';
import { fetchOptions } from '../../services';

const OuterContainer = styled.div`
position: absolute;
width: 100%;
height: 100%;
background: rgba(15, 11, 40, 0.73);
z-index: 9999999;
top: 0;
left: 0;
`

const InnerContainer = styled.div`
position: absolute;
width: 375px;
height: 270px;
top: 50%;
left: 50%;
border-radius: 14.799px;
background: #FFF;
box-shadow: 0px 0px 12.332610130310059px 0px rgba(0, 0, 0, 0.05);
padding: 20px;
transform: translate(-50%, -50%);
`

const HeadContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`

const Head = styled.p`
color: #302E6B;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 140%;
`

const Line = styled.div`
width: 336px;
height: 1px;
background: #F4F6F9;
margin-top: 20px;
`

const AllergyContainer = styled.div`
display: flex;
justify-content: space-between;
margin-top: 24px;
`

const DrugContainer = styled.div`
display: flex;
flex-direction: column;
width: 101px;
height: 106px;
align-items: center;
justify-content: center;
border-radius: 12px;
border: 1px solid #E4E4E4;
background: #FFF;
cursor: pointer;
`

const Img = styled.img`
width: 32px;
height: 32px;
`

const FoodContainer = styled.div`
display: flex;
flex-direction: column;
width: 101px;
height: 106px;
align-items: center;
justify-content: center;
border-radius: 12px;
border: 1px solid #E4E4E4;
background: #FFF;
cursor: pointer;
`

const OtherContainer = styled.div`
display: flex;
flex-direction: column;
width: 101px;
height: 106px;
align-items: center;
justify-content: center;
border-radius: 12px;
border: 1px solid #E4E4E4;
background: #FFF;
cursor: pointer;
`

const Text = styled.p`
color: var(--primary-text-new, #0F0B28);
text-align: center;
font-size: 14px;
font-family: Inter;
font-style: normal;
font-weight: 600;
line-height: 120%;
letter-spacing: 0.25px;
overflow-wrap: break-word;
word-break: break-word;
hyphens: auto;
`

const Recommend = styled.button`
display: flex;
width: 335px;
padding: 12px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
background: #714FFF;
margin-top: 20px;
color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 130%;
`

const AddAllergiesModal = ({ authToken, openDrugAllergy, openFoodAllergy, openOtherAllergy, close }) => {
  return (
    <OuterContainer>
      <InnerContainer>
        <HeadContainer>
          <Head>Add Past Allergies</Head>
          <FaTimes style={{height: '16px', width: '16px', cursor: 'pointer'}} onClick={close}/>
        </HeadContainer>
        <Line />
        <AllergyContainer>
          <DrugContainer onClick={openDrugAllergy}>
            <Img src={tablets}/>
            <Text>Drug Allergy</Text>
          </DrugContainer>
          <FoodContainer onClick={openFoodAllergy}>
            <Img src={food}/>
            <Text>Food Allergy</Text>
          </FoodContainer>
          <OtherContainer onClick={openOtherAllergy}>
            <Img src={lungs}/>
            <Text>Any Other Allergy</Text>
          </OtherContainer>
        </AllergyContainer>
        <Recommend>Add</Recommend>
      </InnerContainer>
    </OuterContainer>
  )
}

export default AddAllergiesModal