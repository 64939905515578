import React,{useEffect,useState} from 'react'
import Header from '../../components/common/AdminHeader';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { fetchQcCases,markAcknowledgeById, downloadQcCsv } from '../../services';
import { useSelector } from 'react-redux';
import "react-table/react-table.css";  
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import Select from 'react-select';
import { BoldText } from '../common/Text';
import { PrimaryButton } from '../common/Buttons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ColumnFilter from '../common/ColumnFilter';
const Button = styled.button`
  margin-left: 0.625rem;
   width: 16rem; 
   background-color: ${(props) => props.backgroundColor || '#198754'};
   &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export default function QcCases() {
const [qcCases,setQcCases] = useState([]);
const [digitizationId,setDigitizationId] = useState('');
const [status,setStatus] = useState('');
const [pageSize, setPageSize] = useState(20);
const [page, setPage] = useState(0);
const [totalCount,setTotalCount] = useState(0)
let [pages, setCount] = useState(-1);
const authToken = useSelector((state) => state.user.authToken);
const [tableLoading,setTableLoading] = useState(true);
const styleObj = { 
    "font-size": "17px",
    overflow: 'scroll',
    height: '100vh',
   'font-weight': '500',
   }
   const fetchQcData  = ()=>{
   fetchQcCases(authToken,digitizationId,status,page,pageSize).then((result)=>{
    setQcCases(result.result)
    setCount(result && Math.ceil(result.count / pageSize))
    setTotalCount(result && result.count)
    setTableLoading(false)
   }).catch((err)=>{
       console.log({err})
   })
};
const downloadCsvFile = async()=>{
  const params = {
    digitisationId:digitizationId,
    status,
    page : 1 ,
    pageSize:totalCount

  }
  await downloadQcCsv(params,authToken)
}

    const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const markAcknowledge = async(requestId)=>{
    return  await  markAcknowledgeById(authToken,requestId).then((res)=>{
        toast.success('Marked Acknowledge')
        fetchQcData()
    })
    .cath((err)=>{
        console.log({err} );
    })
  }
  
    const renderDynamicValue = (value)=>(<h3>{value}</h3>)
const qcColumn =  [
    {  
        Header: 'Request Id',  
        accessor: 'id' ,
        filterable: true,
        Filter: ColumnFilter,
        style: { textAlign: 'center', fontWeight: '400',fontFamily:'Montserrat' },
        Cell:(row)=>{
            return(
                <Link
              to={`/home?digitisationId=${row.original.id}`}
              target="_blank"
            >
                <h3 style={{color:'#0B0080'}}>{row.original.id}</h3>
            </Link>
            )
        }
        }, 
        {
            Header:'Rejection Reason',
            accessor:'rejectionReason',
            style: { textAlign: 'center', fontWeight: '400',fontFamily:'Montserrat' },
            Cell:(row)=>(renderDynamicValue(row.original.rejectionReason||'N.A'))
            
        },
        {
            Header:'Remark',
            accessor:'remark',
            style: { textAlign: 'center', fontWeight: '400',fontFamily:'Montserrat' },
            Cell:(row)=>(renderDynamicValue(row.original.remark||'N.A'))
        },
        {
            Header:'Completed By',
            accessor:'doctorName',
            style: { textAlign: 'center', fontWeight: '400',fontFamily:'Montserrat' },
            Cell:(row)=>(renderDynamicValue(row.original.doctorName||'N.A'))
        },
        {
            Header:'Completed Time ',
            accessor:'completedAt',
            style: { textAlign: 'center', fontWeight: '400',fontFamily:'Montserrat' },
            Cell:(row)=>(renderDynamicValue(row.original.completedAt||'N.A'))
        },
        {
            Header:'Status',
            accessor:'status',
            filterable: true,
            style: { textAlign: 'center', fontWeight: '500',fontFamily:'Montserrat' },
            Filter: ({ filter, onChange }) => {
              return (
                <select
                  onChange={(event) => onChange(event.target.value)}
                  value={filter ? filter.value : ''}
                  // style={{ width: '100%' }}
                >
                  <option value="">Show All</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                  <option value="pending">Pending</option>
                </select>
              );
            },
            Cell:(row)=>{
                switch(row.original.status.toUpperCase()){
                case 'APPROVED':
                    return(
                        <div>
                            <span className="badge badge-success text-[#fff] " style={{
                                'border':'1px solid #198754',
                                'border-radius':'5px',
                                'padding' :'4px',
                                'background-color':'#198754'}}>{capitalizeFirst(row.original.status)}</span>
                        </div>
                       ) 
                       case 'REJECTED':
                        return(
                            <div>
                                <span className="badge badge-success text-[#fff] " style={{
                                    'border':'1px solid #DC3545',
                                    'border-radius':'5px',
                                    'padding' :'4px',
                                    'background-color':'#DC3545'}}>{capitalizeFirst(row.original.status)}</span>
                            </div>
                           ) 
                       default:
                        return(
                            <div>
                                <span className="badge badge-secondary text-[#fff] " style={{
                                    'border':'1px solid #ffcc00',
                                    'border-radius':'5px',
                                    'padding' :'4px',
                                    'background-color':'#ffcc00'}}>{capitalizeFirst(row.original.status)}</span>
                            </div>
                           ) 
                }  
            }

        },
        {
            Header:'Qc Alloted Time ',
            accessor:'sentForQc',
            style: { textAlign: 'center', fontWeight: '400',fontFamily:'Montserrat' },
            Cell:(row)=>(renderDynamicValue(row.original.sentForQc||'N.A'))
        },
        {
            Header:'Actions',
            Cell:(row)=>{
                return(
                <div className='flex flex-row '>
                <Button style={{borderRadius:'5px',color:'#fff',fontFamily:'Montserrat'}}
                onClick={()=>markAcknowledge(row.original.id)}
                disabled={row.original.acknowledgeBy}
                >
                    Mark Acknowledge
                </Button>

            </div>
                )
            }
        }
]


useEffect(() => {
    fetchQcData(); 
  }, [ page,pageSize,status]);

  const filterData = (state)=>{
    setPage(state.page + 1);
    setPageSize(state.pageSize);
    const { filtered } = state;
    const idFilter =
    filtered && filtered.find((f) => f.id === 'id');
    const statusFilter =
    filtered && filtered.find((f) => f.id === 'status');
    if(idFilter){
      setDigitizationId(idFilter.value)
    }
    if(statusFilter){
      setStatus(statusFilter.value)
    }
    if(!statusFilter){
      setStatus('')
    }
    if (!idFilter) {
      setDigitizationId('')
    }
  }

  return (
    <div className="container-fluid  overflow-auto w-full ">
    <Header> 
    </Header>
    <div className='flex flex-row'>
    <h2 className='mt-4 m-4' style={{color:'#704EFE',fontFamily:'Montserrat',fontWeight:600,fontSize:'25px'}}>QC Cases</h2>
  
    <Button className='mt-6 m-4' style={{borderRadius:'5px',color:'#fff',fontFamily:'Montserrat',height:'3rem',fontSize:'15px',backgroundColor:'#704EFE'}}
               onClick={()=>downloadCsvFile()}
                >
                     DownloadCsv
                </Button>
    </div>
  
    <div className='mt-4'>
        <ReactTable
          columns={qcColumn} 
          data={qcCases}
          pages={pages}
          pageSizeOptions={[5, 10, 20, 25, 50, 100, 150]}
          style={styleObj}
          loading={tableLoading}
          manual
          onFetchData={(state, instance) => {
            filterData(state);
          }}
        >  
        </ReactTable>
    </div>
   <ToastContainer /> 
    </div>
  );
}
