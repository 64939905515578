import React from 'react';
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ filter, onChange }) => {
  return (
    <input
      style={{
        border: '1px solid rgba(113, 79, 255, 0.3)',
        borderRadius: '10px',
      }}
      placeholder="Search..."
      onChange={(event) => onChange(event.target.value)}
    />
  );
};
