import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';
import styled from 'styled-components';

import { FaTimes } from 'react-icons/fa';
import StyledTappable from '../components/common/StyledTappable';
import TappableCard from '../components/common/TappableCard';
import { PrimaryButton } from '../components/common/Buttons';
import {
  BoldText,
  Text,
  ButtonText,
  RegularText,
} from '../components/common/Text';
import AddSymptomsModal from '../components/symptoms/AddSymptomsModal';
import PrescriptionSentSuccessModal from '../components/prescription/PrescriptionSentSuccessModal';
import {
  sendPrescription,
  getSymptoms,
  saveSymptom,
  deleteSymptom,
  sendSymptoms,
  fetchSymptoms,
  actionOnSymptom,
} from '../services';
import {
  getSymptoms as getSymptomsAction,
  addSymptoms as addSymptomsAction,
  changeAnswerSymptoms,
  changeEditable,
} from '../actions';
import prescriptionIcon from '../images/consultations/pdf-icon.png';
import symptomImage from '../images/common/symptom.svg';
import greyTick from '../images/common/greyTick.svg';
import purpleTick from '../images/common/purpleTick.svg';
import greenTick from '../images/common/tick.png';
import config from '../config';
import AddSymptomsModalNew from '../components/symptoms/AddSymptomsModalNew';
import { showError } from '../utils';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  padding: 1.6rem 2rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const SectionContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
`;

const SectionTitle = styled(RegularText)`
  font-size: 1.8rem;
  margin-bottom: 0.6rem;
`;

const SectionTitle1 = styled(BoldText)`
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
`;

const StyledInputBox = styled(Input)`
  padding: 0.9rem 0;
  flex: 1 0;
  border-bottom: 1px solid #cecece !important;
`;

const StyledTappableCard = styled(StyledTappable)`
  align-self: flex-start;
  /* padding: 1rem 3rem; */
`;
const StyledTappableCard1 = styled(TappableCard)`
  align-self: flex-start;
  padding: 1rem 3rem;
  margin-top: 0.625rem;
`;

const TappableText = styled(Text)`
  font-size: 1.4rem;
  text-align: center;
  color: #4f34d2;
`;


const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 1.4rem 1.8rem;
`;

const StyledButton = styled(PrimaryButton)`
  margin: 0 0.7rem;
`;

const SymptomContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  align-items: stretch;
  margin-bottom: 1.25rem;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.25rem;
`;

const HeaderRowInline = styled(HeaderRow)`
  justify-content: flex-start;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  margin-right: 1.25rem;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1);
`;

const SymptomImage = styled.img`
  height: 2rem;
  width: 2rem;
  margin-left: 0.4rem;
  margin-top: 0.4rem;
`;

const SymptomTitle = styled(BoldText)`
  font-size: 1.8rem;
`;

const QuestionTitle = styled(RegularText)`
  font-size: 1.6rem;
`;

const MandatoryText = styled(BoldText)`
  font-size: 1.8rem;
  color: red;
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.625rem;
`;

const OptionsBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  align-items: stretch;
`;

const OptionsGroups = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;
`;

const OptionContainer = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 1.25rem;
  margin-bottom: 0.5rem;
  background: #f4f4f4;
  box-shadow: 0 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  padding: 0.625rem 1rem;
  border: ${(props) => (props.answer ? '1px solid #4F34D2;' : '')};
`;

const OptionImage = styled.img`
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.625rem;
`;

const OptionText = styled(RegularText)`
  color: ${(props) => (props.answer ? '#4F34D2' : '#4c4c4c')};
  flex: 1;
`;

const SaveButton = styled(StyledTappable)`
  margin-right: 1.25rem;
  border: 1px solid #4f34d2;
  border-radius: 0.625rem;
  padding: 0.25rem 0.825rem;
`;

const DeleteButton = styled(StyledTappable)`
  margin-right: 1.25rem;
`;

const DeleteText = styled(ButtonText)`
  color: #b2b2b2;
`;

const SaveText = styled(ButtonText)`
  color: #4f34d2;
`;

const OptionTextContainer = styled.input`
  padding: 0.9rem 0.5rem;
  flex: 1 0;
  font-size: 1.4rem;
  border: none;
  border-bottom: 1px solid #cecece !important;
`;

class SymptomsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addSymptomModalVisible: false,
      selectedMedicineToEdit: {},
      showSendPrescriptionSuccess: false,
      symptoms: [],
      questions: [],
      editSymptom: false,
      editSymptomData: {}
    };

    console.log({ props, state: this.state });
  }

  componentDidMount() {
    this.getSymptomsNew();
  }

  getSymptoms = () => {
    const { authToken, activeConsult, dispatch } = this.props;
    getSymptoms(authToken, activeConsult.consultationId).then((res) => {
      dispatch(getSymptomsAction(res.data));
    });
  };

  getSymptomsNew = async () => {
    const { questions } = this.state;
    const { authToken, activeConsult, dispatch } = this.props;
    try {
      const result = await fetchSymptoms({authToken, consultationId: activeConsult.consultationId});
      // const resultQuestions = await fetchSymptoms({authToken});
      // console.log(resultQuestions.questions);
      if (result.symptoms && result.symptoms.length > 0) {
        const questionsArr = result.symptoms[0].questions;
        const questions = questionsArr.map((question) => {
          const { response, symptomId, ...rest } = question;
          return rest;
        });
        this.setState({
          questions: questions,
          symptoms: result.symptoms
        });
      } else if (result.questions && result.questions.length === 0) {
        this.setState({
          questions: result.questions,
        });
      } else {
        const resultQuestions = await fetchSymptoms({authToken});
        this.setState({
          questions: resultQuestions.questions,
          symptoms: [],
        });
      }
    } catch (err) {
      showError(err);
    }
  };

  addSymptoms = (symptoms) => {
    this.props.dispatch(addSymptomsAction(symptoms));
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeConsult.consultationId !==
      this.props.activeConsult.consultationId
    ) {
      this.getSymptomsNew();
    }
  }

  // componentDidUpdate(prevProps) {
  //   if(prevProps.symptoms !== this.props.symptoms && this.props.symptomsCovid) {
  //     this.props.symptomsCovid(this.props.symptoms);
  //   }
  // }

  sendPrescription = () => {
    console.log('send prescription called');
    sendSymptoms(this.props.authToken, this.props.activeConsult.consultationId)
      .then((result) => {
        console.log('prescription sent success');

        this.setState({
          showSendPrescriptionSuccess: true,
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  hideModal = () => {
    this.setState({
      addSymptomModalVisible: false,
      showSendPrescriptionSuccess: false,
    });
    this.getSymptomsNew();
  };

  openSymptomModal = () => {
    console.log('openSymptomModal called');
    this.setState({
      addSymptomModalVisible: true,
      editSymptom: false
    });
  };

  onOptionSelect = (option, optionGroup, question, symptom, event) => {
    const symptomId = symptom.symptomId;
    const questionId = question.questionId;
    const optionGroupType = optionGroup.type;
    const optionId = option.optionId;
    let answer = option.answer ? false : true;
    if (event) {
      answer = event.target.value;
    }
    this.props.dispatch(
      changeAnswerSymptoms(
        symptomId,
        questionId,
        optionGroupType,
        optionId,
        answer
      )
    );
  };

  setEditable = (symptom) => {
    // this.props.dispatch(changeEditable(symptom.symptomId, true));
    this.setState({ 
      editSymptom: true,
      editSymptomData: symptom,
      addSymptomModalVisible: true
    });
    // this.openSymptomModal();
  };

  saveSymptom = (symptom) => {
    const { activeConsult, authToken } = this.props;
    symptom.consultationId = activeConsult.consultationId;
    symptom.isAddedFromPrescription = this.props.showUnderPrescriptionsTab && symptom.symptomId !== config.covidSymptomId;
    saveSymptom(authToken, symptom).then(() => {
      this.getSymptoms();
    });
  };
  
  saveSymptomNew = async ({symptomId, duration, note, typeValue}) => {
    const { questions: questionsArr } = this.state;
    const { activeConsult, authToken } = this.props;
    let questions = [...questionsArr];
    questions.forEach((question) => {
      question.symptomId = symptomId;
    });
    questions[0].response = `${duration} ${typeValue}`;
    if (note) {
      questions[1].response = note;
    }
    console.log({ symptomId, questions }, 'log in saveSymptomNew [SymptomsContainer]');
    try {
      await actionOnSymptom({authToken, consultationId: activeConsult.consultationId, symptomId, questions, actionType: 'add'});
      this.getSymptomsNew();
    } catch (err) {
      showError(err);
    } finally {
      this.setState({
        addSymptomModalVisible: false
      });
    }
  }

  editSymptomNew = async ({duration, note, typeValue}) => {
    const { questions: questionsArr, editSymptomData } = this.state;
    const { activeConsult, authToken } = this.props;
    let questions = [...questionsArr];
    questions.forEach((question) => {
      question.symptomId = editSymptomData.symptomId;
    });
    questions[0].response = `${duration} ${typeValue}`;
    if (note) {
      questions[1].response = note;
    } 
    console.log(questions);
    console.log({ symptomId: editSymptomData.symptomId, questions }, 'log in editSymptomNew [SymptomsContainer]');
    try {
      await actionOnSymptom({authToken, consultationId: activeConsult.consultationId, symptomId: editSymptomData.symptomId, questions, actionType: 'update'});
      this.getSymptomsNew();
    } catch (err) {
      showError(err);
    } finally {
      this.setState({
        addSymptomModalVisible: false
      });
    }
  }

  deleteSymptomNew = async ({symptomId}) => {
    const { questions } = this.state;
    const { activeConsult, authToken } = this.props;
    try {
      await actionOnSymptom({authToken, consultationId: activeConsult.consultationId, symptomId, questions, actionType: 'delete'});
      this.getSymptomsNew();
    } catch (err) {
      showError(err);
    }
  }

  deleteSymptom = (symptom) => {
    const { activeConsult, authToken } = this.props;
    symptom.consultationId = activeConsult.consultationId;
    deleteSymptom(authToken, symptom).then(() => {
      this.getSymptoms();
    });
  };

  render() {
    console.log(
      { props: this.props, state: this.state },
      'log in render [SymptomsContainer]'
    );
    const { symptoms } = this.state;
    // const probableDiagnosisLink = Link.state(this, 'probableDiagnosis');
    // const generalHealthAdviceLink = Link.state(this, 'advice');

    const renderSymptom = (symptom, idx) => {
      console.log({ symptom, idx });

      // const renderQuestion = (question) => {
      //   console.log({ question });

      //   const renderOptionGroups = (optionGroup) => {
      //     switch (optionGroup.type) {
      //       case 'single': {
      //         return (
      //           <OptionsGroups key={optionGroup.type}>
      //             {optionGroup.options.map((option) => (
      //               <OptionContainer
      //                 key={option.optionId}
      //                 answer={option.answer}
      //                 onTap={() =>
      //                   this.onOptionSelect(
      //                     option,
      //                     optionGroup,
      //                     question,
      //                     symptom
      //                   )
      //                 }
      //               >
      //                 <OptionText answer={option.answer}>
      //                   {option.optionText}
      //                 </OptionText>
      //               </OptionContainer>
      //             ))}
      //           </OptionsGroups>
      //         );
      //       }
      //       case 'multiple': {
      //         return (
      //           <OptionsGroups key={optionGroup.type}>
      //             {optionGroup.options.map((option) => (
      //               <OptionContainer
      //                 key={option.optionId}
      //                 answer={option.answer}
      //                 onTap={() =>
      //                   this.onOptionSelect(
      //                     option,
      //                     optionGroup,
      //                     question,
      //                     symptom
      //                   )
      //                 }
      //               >
      //                 <OptionImage
      //                   src={option.answer ? purpleTick : greyTick}
      //                 />
      //                 <OptionText answer={option.answer}>
      //                   {option.optionText}
      //                 </OptionText>
      //               </OptionContainer>
      //             ))}
      //           </OptionsGroups>
      //         );
      //       }
      //       case 'text': {
      //         // donot use valuelink use normal text input
      //         console.log('text came', { optionGroup });
      //         return (
      //           <OptionsGroups key={optionGroup.type}>
      //             {/* <StyledInputBox
      //               valueLink={probableDiagnosisLink}
      //               className="visit-input"
      //               placeholder="Add more details here"
      //             /> */}
      //             {optionGroup.options.map((option) => (
      //               <OptionTextContainer
      //                 key={option.optionId}
      //                 type="text"
      //                 placeholder="Enter details here"
      //                 value={option.answer}
      //                 onChange={(event) =>
      //                   this.onOptionSelect(
      //                     option,
      //                     optionGroup,
      //                     question,
      //                     symptom,
      //                     event
      //                   )
      //                 }
      //               />
      //               // <OptionContainer
      //               //   key={option.optionId}
      //               //   answer={option.answer}
      //               //   onTap={() =>
      //               //     this.onOptionSelect(
      //               //       option,
      //               //       optionGroup,
      //               //       question,
      //               //       symptom
      //               //     )
      //               //   }>
      //               //   <OptionText answer={option.answer}>
      //               //     {option.optionText}
      //               //   </OptionText>
      //               // </OptionContainer>
      //             ))}
      //           </OptionsGroups>
      //         );
      //       }
      //       default: {
      //         return;
      //       }
      //     }
      //   };

      //   return (
      //     <QuestionContainer key={question.questionId}>
      //       <HeaderRowInline>
      //         <QuestionTitle>{question.questionText}</QuestionTitle>
      //         {!question.optional && <MandatoryText>*</MandatoryText>}
      //       </HeaderRowInline>
      //       <OptionsBody>
      //         {question.optionGroups &&
      //           question.optionGroups.map(renderOptionGroups)}
      //       </OptionsBody>
      //     </QuestionContainer>
      //   );
      // };

      return (
        <SymptomContainer key={symptom.symptomId}>
          <HeaderRow>
            <TitleContainer>
              <ImageContainer>
                <SymptomImage src={symptomImage} />
              </ImageContainer>
              <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                <SymptomTitle>{symptom.symptom}</SymptomTitle>
                <p style={{fontSize: '12px', fontWeight: 'normal'}}>Since last {symptom.questions[0].response}</p>
              </div>
            </TitleContainer>
            <ButtonsGroup>
              {/* {symptom.consultationId ? <OptionImage src={greenTick} /> : null} */}
              {/* {symptom.isEditable ? (
                <SaveButton onTap={() => this.saveSymptom(symptom)}>
                  <SaveText>Save</SaveText>
                </SaveButton>
              ) : this.props.showUnderPrescriptionsTab ? (
                ''
              ) : (
                <SaveButton onTap={() => this.setEditable(symptom)}>
                  <SaveText>Edit</SaveText>
                </SaveButton>
              )} */}
              <SaveButton onTap={() => this.setEditable(symptom)}>
                <SaveText>Edit</SaveText>
              </SaveButton>
              <DeleteButton onTap={() => this.deleteSymptomNew({symptomId: symptom.symptomId})}>
                <DeleteText>Delete</DeleteText>
              </DeleteButton>
            </ButtonsGroup>
          </HeaderRow>
          {/* {symptom.isEditable &&
            symptom.questions &&
            (symptom.symptomId === config.covidSymptomId || !this.props.showUnderPrescriptionsTab)&&
            symptom.questions.map(renderQuestion)} */}
        </SymptomContainer>
      );
    };

    // let isSendEnabled = symptoms.length === 0 ? false : true;
    // symptoms.forEach((symptom) => {
    //   if (symptom.isEditable) {
    //     isSendEnabled = false;
    //   }
    // });

    return (
      <OuterContainer>
        {!this.props.showUnderPrescriptionsTab ?
          <BodyContainer>
            <SectionContainer>
                <SectionTitle>
                  Please add further details to the following symptoms as
                  provided by the user
                </SectionTitle>
            </SectionContainer>
            <SectionContainer>
                <StyledTappableCard onTap={() => this.openSymptomModal()}>
                  <TappableText>+ Add Another Symptom</TappableText>
                </StyledTappableCard>
            </SectionContainer>

            <SectionContainer>{symptoms.map(renderSymptom)}</SectionContainer>

            <FooterContainer>
              <StyledButton
                onTap={() => this.sendPrescription()}
                // disabled={!isSendEnabled}
              >
                <ButtonText>Send</ButtonText>
              </StyledButton>
            </FooterContainer>
          </BodyContainer>
         :
        <React.Fragment>
          <SectionContainer>
            <SectionTitle1>Symptoms</SectionTitle1>
        </SectionContainer>
        <SectionContainer>
            <StyledTappableCard1 onTap={() => this.openSymptomModal()}>
              <TappableText>+ Add Another Symptom</TappableText>
            </StyledTappableCard1>
        </SectionContainer>
        <SectionContainer>{symptoms.map(renderSymptom)}</SectionContainer>
        {/* <FooterContainer>
          <StyledButton
            onTap={() => this.sendPrescription()}
            disabled={!isSendEnabled}
          >
            <ButtonText>Send</ButtonText>
          </StyledButton>
        </FooterContainer> */}
        </React.Fragment>
        }
        {this.state.addSymptomModalVisible && (
          <AddSymptomsModalNew 
            authToken = {this.props.authToken}
            addSymptom = {this.saveSymptomNew}
            editSymptom = {this.state.editSymptom}
            editData = {this.state.editSymptomData}
            editSymptomHandler = {this.editSymptomNew}
            closeModal = {this.hideModal}
          />
          // <AddSymptomsModal
          //   addSymptoms={(symptoms) => this.addSymptoms(symptoms)}
          //   hideModal={() => this.hideModal()}
          // />
        )}
        {this.state.showSendPrescriptionSuccess && (
          <PrescriptionSentSuccessModal
            hideModal={() => this.hideModal()}
            message="Symptoms sent successfully!"
          />
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const transcriberAccountActive = state.app.transcriberAccountActive;
  let offlineDoctorAuthToken = state.user.authToken;
  // if (transcriberAccountActive) {
  //   offlineDoctorAuthToken = state.consults.activeConsult.offlineInfo.authToken;
  // }
  return {
    authToken: offlineDoctorAuthToken
      ? offlineDoctorAuthToken
      : state.user.authToken,
    activeConsult: state.consults.activeConsult,
    user: state.user,
    // symptoms: state.symptoms.symptoms,
  };
};

export default connect(mapStateToProps)(SymptomsContainer);
