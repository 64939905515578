import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TiLocationOutline } from 'react-icons/ti';
import { FaChevronRight } from 'react-icons/fa';

import TappableCard from '../common/TappableCard';
import { PrimaryButton, InvertedSubscriptionButton } from '../common/Buttons';
import { BoldText, RegularText, Text } from '../common/Text';

import experienceIcon from '../../images/consultations/experience-icon.svg';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem;
`;

const DoctorInfoContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-bottom: 0.75rem;
`;

const DoctorPictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.75rem;
`;

const DoctorPicture = styled.img`
  width: 4rem;
`;

const DoctorDetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
`;

const DoctorName = styled(BoldText)`
  font-size: 1.125rem;
`;

const DoctorQualifications = styled(Text)`
  font-size: 0.875rem;
  color: #4d4d4d;
`;

const DoctorExperienceContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
`;

const DoctorExperienceIcon = styled.img`
  width: 0.5rem;
  margin-right: 0.375rem;
`;

const DoctorExperience = styled(Text)`
  font-size: 0.8125rem;
  color: #7f7f7f;
`;

const FeesContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0.875rem;
`;

const FeesText = styled(Text)`
  font-size: 0.875rem;
  color: #7f7f7f;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
`;

const OldPriceText = styled(BoldText)`
  font-size: 1rem;
  color: #b2b2b2;
  text-decoration: line-through;
  margin-right: 0.5rem;
`;

const PriceText = styled(BoldText)`
  font-size: 1rem;
  margin-right: 0.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
`;

const StyledButton = styled(PrimaryButton)`
  margin-right: 0.375rem;
`;

const ButtonText = styled(BoldText)`
  font-size: 1rem;
  color: white;
  text-align: center;
  white-space: nowrap;
`;

const SubscriptionButtonText = styled(ButtonText)`
  background-image: linear-gradient(to left, #ffa14f, #db4c6d);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  text-align: center;
  white-space: nowrap;
`;

const DoctorLocationContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: 0.625rem 0;
  border: 1px solid #979797;
  border-left: none;
  border-right: none;
  margin: 0.875rem 0;
`;

const DoctorOrganizationImage = styled.img`
  width: 4rem;
  margin-right: 1.25rem;
`;

const DoctorOrganizationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DoctorLocationTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DoctorLocationIcon = styled(TiLocationOutline)`
  font-size: 0.875rem;
  margin-right: 0.5em;
`;

const DoctorLocationText = styled(BoldText)`
  font-size: 0.875rem;
  color: #343434;
`;

const SpecialistSelectCard = ({
  className,
  onTap,
  doctorPicture,
  doctorName,
  doctorQualifications,
  doctorExperience,
  doctorOrganizationImage,
  doctorOrganizationText,
  doctorLocation,
  basePrice,
  doctorPrice,
  feeLabel,
  opdLabel,
  bookingLabel,
}) => (
  <TappableCard className={className} onTap={onTap}>
    <OuterContainer>
      <DoctorInfoContainer>
        <DoctorPictureContainer>
          <DoctorPicture src={doctorPicture} />
        </DoctorPictureContainer>
        <DoctorDetailsContainer>
          <DoctorName>{doctorName}</DoctorName>
          <DoctorQualifications>{doctorQualifications}</DoctorQualifications>
          <DoctorExperienceContainer>
            <DoctorExperienceIcon src={experienceIcon} />
            <DoctorExperience>{doctorExperience}</DoctorExperience>
          </DoctorExperienceContainer>
        </DoctorDetailsContainer>
        <FaChevronRight />
      </DoctorInfoContainer>
      <DoctorLocationContainer>
        <DoctorOrganizationImage src={doctorOrganizationImage} />
        <DoctorOrganizationTextContainer>
          <DoctorOrganizationTextContainer>
            {doctorOrganizationText}
          </DoctorOrganizationTextContainer>
          <DoctorLocationTextContainer>
            <DoctorLocationIcon />
            <DoctorLocationText>{doctorLocation}</DoctorLocationText>
          </DoctorLocationTextContainer>
        </DoctorOrganizationTextContainer>
      </DoctorLocationContainer>
      {doctorPrice !== 0 && (
        <FeesContainer>
          <FeesText>{feeLabel}</FeesText>
          <PriceContainer>
            <PriceText>₹</PriceText>
            {basePrice !== doctorPrice && (
              <OldPriceText>{basePrice}</OldPriceText>
            )}
            <PriceText>{doctorPrice}</PriceText>
          </PriceContainer>
        </FeesContainer>
      )}
      <ButtonContainer>
        {bookingLabel !== '' && (
          <StyledButton>
            <ButtonText>{bookingLabel}</ButtonText>
          </StyledButton>
        )}
        {doctorPrice !== 0 && opdLabel !== '' && (
          <InvertedSubscriptionButton>
            <SubscriptionButtonText>{opdLabel}</SubscriptionButtonText>
          </InvertedSubscriptionButton>
        )}
      </ButtonContainer>
    </OuterContainer>
  </TappableCard>
);

SpecialistSelectCard.propTypes = {
  onTap: PropTypes.func,
  doctorPicture: PropTypes.string.isRequired,
  doctorName: PropTypes.string.isRequired,
  doctorQualifications: PropTypes.string.isRequired,
  doctorExperience: PropTypes.string.isRequired,
  basePrice: PropTypes.string,
  doctorPrice: PropTypes.string,
  doctorOrganizationImage: PropTypes.string.isRequired,
  doctorOrganizationText: PropTypes.string.isRequired,
  doctorLocation: PropTypes.string.isRequired,
  feeLabel: PropTypes.string.isRequired,
  opdLabel: PropTypes.string,
  bookingLabel: PropTypes.string,
};

SpecialistSelectCard.defaultProps = {
  doctorPrice: '0',
  bookingLabel: '',
  opdLabel: '',
};

export default SpecialistSelectCard;
