import React, { useState } from 'react'
import styled from 'styled-components'
import UserProfile from '../components/user-profile/UserProfile';
import PastTreatments from '../components/user-profile/PastTreatments';


const OuterContainer = styled.div`
 display: flex;
 flex: 2.15 0;
 /* width: 464px; */
  height: auto;
  flex-direction: column;
  align-items: stretch;
  /* border: 1px solid #d8d8d8; */
  background: #F2F1F6;
  overflow: hidden;
  padding-bottom: 24px;
  padding-right: 20px;
`

const InnerContainer = styled.div`
    display: flex;
    flex: 1 0 1;
    flex-direction: column;
    align-items: stretch;
    /* width: 400px; */
    height: auto;
    border-radius: 0px 0px 20px 20px;
    background: #FFF;
    /* box-shadow: 2px 2px 8px 0px rgba(113, 79, 255, 0.21); */
    /* margin-top: 50px; */
    padding-bottom: 24px;
    margin-left: 20px;
    overflow-y: auto;
`

const HeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1;
    /* width: 348px; */
    max-height: 45px;
    border-radius: 8px;
    background: #F0F3F6;
    /* margin-left: 24px;
    margin-right: 24px;
    margin-top: 24px; */
    padding: 4px;
    border: none;
`

const UserDetailContainer = styled.div`
display: flex;
flex: 1;
/* width: 170px; */
padding: 8px 27px;
justify-content: center;
align-items: center;
border-radius: ${(props) => props.userDetails ? '8px': ''};
background: ${(props) => props.userDetails ? 'var(--white, #FFF)':''};
box-shadow: ${(props) => props.userDetails ? '0px 0px 5px 0px rgba(0, 0, 0, 0.10)': ''};
cursor: pointer;
`

const UserDetailText = styled.p`
color: var(--primary-text-new, #0F0B28);
text-align: center;
font-size: 12px;
font-weight: 600;
line-height: 150%;
letter-spacing: 0.25px;
`

const PastTreatmentContainer = styled.div`
display: flex;
flex: 1;
/* width: 170px; */
padding: 8px 27px;
justify-content: center;
align-items: center;
border-radius: ${(props) => props.pastTreatments ? '8px': ''};
background: ${(props) => props.pastTreatments ? 'var(--white, #FFF)':''};
box-shadow: ${(props) => props.pastTreatments ? '0px 0px 5px 0px rgba(0, 0, 0, 0.10)': ''};
cursor: pointer;
`

const PastTreatmentText = styled.p`
color: var(--primary-text-new, #0F0B28);
text-align: center;
font-size: 12px;
font-weight: 600;
line-height: 150%;
letter-spacing: 0.25px;
`

const HeadParent = styled.div`
display: flex;
justify-content: center;
align-items: center;    
flex: 1 0 1;
background: #FFF;
margin-top: 50px;
margin-left: 20px;
padding: 24px;
padding-bottom: 0px;
border-radius: 20px 20px 0px 0px;
`

const UserProfileMain = ({ authToken, activeConsult }) => {

  const [userDetails, setUserDetails] = useState(true);
  const [pastTreatments, setPastTreatments] = useState(false);

  const handleSwitch = (id) => {
    if(id === 1) {
        setUserDetails(true);
        setPastTreatments(false);
    } else {
        setPastTreatments(true);
        setUserDetails(false);
    }
  };

  return (
    <OuterContainer>
        <HeadParent>
            <HeadContainer>
                <UserDetailContainer userDetails = {userDetails} onClick={() => handleSwitch(1)}>
                    <UserDetailText>Patient Details</UserDetailText>
                </UserDetailContainer>
                <PastTreatmentContainer pastTreatments = {pastTreatments} onClick={() => handleSwitch(2)}>
                    <PastTreatmentText>Case Files</PastTreatmentText>
                </PastTreatmentContainer>
            </HeadContainer>
        </HeadParent>
        <InnerContainer>
            {
                userDetails && (<UserProfile authToken = {authToken} activeConsult = {activeConsult}/>)
            }
            {
                pastTreatments && (<PastTreatments authToken = {authToken} activeConsult = {activeConsult}/>)
            }
        </InnerContainer>
    </OuterContainer>
  )
}

export default UserProfileMain