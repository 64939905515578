import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';

import { loginUser } from '../actions/index';
import visitLogo from '../images/common/visit-logo.svg';
import { PrimaryButton } from '../components/common/Buttons';
import { BoldText, ButtonText } from '../components/common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding: 2.4rem;
`;

const Icon = styled.img`
  max-width: 16rem;
  margin-bottom: 3.2rem;
`;

const TitleText = styled(BoldText)`
  font-size: 2rem;
  margin-bottom: 3.2rem;
`;

const StyledInput = styled(Input)`
  font-size: 1.6rem;
  padding: 0.8rem;
  margin: 1rem 0;
  border-bottom: 1px solid #eee !important;
`;

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      loading: false,
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }


  handleKeyPress = event => {
    // console.log({ event, focus: document.activeElement.id });
    if (event.code === 'Enter') {
      this.login();
    }
  };

  componentDidMount = () => {
    window.addEventListener('keydown', this.handleKeyPress, false);
  };

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress, false);
  }

  componentDidUpdate() {
    const { loginError } = this.props;
    if (this.state.loading && loginError?.reset) {
      this.setState({ loading: false });
      this.props.dispatch({ type: 'LOGIN_ERROR', payload: { reset: false } });
    }
  }

  login = () => {
    const { email, password } = this.state;
    const { dispatch, history } = this.props;
    this.setState({ loading: true });
    dispatch(loginUser(email, password, history, dispatch));
  };

  render() {
    const { loading } = this.state;
    const emailLink = Link.state(this, 'email');
    const passwordLink = Link.state(this, 'password');
    return (
      <OuterContainer>
        <Icon src={visitLogo} />
        <TitleText>Welcome Back!</TitleText>
        <StyledInput
          className="visit-input"
          type="email"
          valueLink={emailLink}
          placeholder="Email"
        />
        <StyledInput
          className="visit-input"
          type="password"
          valueLink={passwordLink}
          placeholder="Password"
        />
        <PrimaryButton loading={loading} onTap={this.login}>
          <ButtonText>Login</ButtonText>
        </PrimaryButton>
      </OuterContainer>
    );
  }
}

const matchStateToProps = (state) => ({
  loginError: state.loginError,
});

export default connect(matchStateToProps)(LoginPage);
