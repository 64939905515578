import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

import { RegularText } from '../../common/Text';
import StyledTappable from '../../common/StyledTappable';
import moment from 'moment';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0.4rem 0;
  margin-left: 12px;
`;

const BubbleContainer = styled(StyledTappable)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0.5rem;
  /* border-radius: 1.4rem; */
  border-radius: 12px 12px 12px 2px;
  border: 1px solid #f5f5f5;
  background: #714FFF;
`;

const Image = styled.div`
  background-size: cover;
  width: 19rem;
  height: 19rem;
  border-radius: 1.2rem;
  background-image: ${props => `url(${props.url})`};
  background-repeat: no-repeat;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1.6rem;
  margin-top: 0.5rem;
`;

const TickIcon = styled(FaCheck)`
  color: #000;
  opacity: 0.5;
  font-size: 1rem;
  margin-right: 0.4rem;
`;

const TimestampText = styled(RegularText)`
  /* color: #000;
  opacity: 0.7;
  font-size: 1rem; */
  color: #A5A6BB;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 156%; /* 15.6px */
  letter-spacing: -0.165px;
`;

const DoctorImageMessage = ({ filePreviewUrl, url, timestamp }) => (
  <OuterContainer>
    <BubbleContainer onTap={() => {
      // document.querySelector('#image-anchor').click();
      window.open(url);
    }}>
      <Image url={filePreviewUrl} />
    </BubbleContainer>
    {timestamp && (
        <TimestampContainer>
          <TimestampText>
            {moment(timestamp).format('HH:mm A')}
          </TimestampText>
        </TimestampContainer>
      )}
    <a
      href={url}
      target="_blank"
      id="image-anchor"
      style={{ display: 'none' }}
    />
  </OuterContainer>
);

DoctorImageMessage.propTypes = {
  filePreviewUrl: PropTypes.string,
  url: PropTypes.string,
  timestamp: PropTypes.number,
};

export default DoctorImageMessage;
