import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ConsultationList from '../components/ConsultationList';
import FilterPane from '../components/FilterPane';
import DashboardHeader from '../components/DashboardHeader';
import ConsultationContainer from './ConsultationContainer';
import ProfileContainer from './ProfileContainer';
import { BoldText } from '../components/common/Text';
import { initializeChatClient } from '../services/chatClient';
import { setUnrepliedChannel } from '../actions';
import Appointments from '../components/Appointments';
import UserProfileMain from './UserProfileMain';
import PrescriptionContainer from './PrescriptionContainer';
import ConsultationChatContainer from './ConsultationChatContainer';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  overflow: hidden;
`;

const OfflineBar = styled.div`
  background: #ec5d57;
  padding: 6px 0;
  color: white;
  text-align: center;
`;

const ReconnectedBar = styled(OfflineBar)`
  background: #56c02c;
`;

const OfflineText = styled(BoldText)`
  font-size: 1.8rem;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1 0;
  align-items: stretch;
  overflow: hidden;
`;

class DashboardHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showReconnectedBar: false,
      renderChats: true,
      renderPrescription: false,
    };
  }

  componentDidMount() {
    if (!this.props.user.authToken) {
      this.props.history.push('/');
      return;
    } else {
      this.chatClient = initializeChatClient();
      // this.channel = this.props.unrepliedChannel ? this.props.unrepliedChannel : `visit-inbound-doctor-${this.props.user.doctorId}`;
      this.channel = this.props.pubnubChannelGroup;

      if (!this.props.unrepliedChannel) {
        this.props.dispatch(setUnrepliedChannel(this.channel));
      }
      console.log({ props: this.props });
      this.chatClient.subscribe(this.channel);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { appState } = this.props;
    const prevAppState = prevProps.appState;
    if (prevAppState === 'OFFLINE' && appState === 'ONLINE') {
      this.setState(
        {
          showReconnectedBar: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              showReconnectedBar: false,
            });
          }, 3000);
        }
      );
    }
  }

  handleRenderPrescription = () => {
    this.setState({renderChats: false, renderPrescription: true});
  }

  handleRenderChats = () => {
    this.setState({renderChats: true, renderPrescription: false});
  }

  render() {
    console.log(this.props, 'ggggg');
    const { appState, transcriberAccountActive } = this.props;
    const { showReconnectedBar, renderChats, renderPrescription } = this.state;
    const loadFilterPane = () => {
      let filterPane = null;
      if (this.props.showFilterPane) {
        filterPane = <FilterPane />;
      }
      return filterPane;
    };

    return (
      <OuterContainer>
        {appState === 'OFFLINE' && (
          <OfflineBar>
            <OfflineText>You are offline</OfflineText>
          </OfflineBar>
        )}
        {showReconnectedBar && (
          <ReconnectedBar>
            <OfflineText>Reconnecting...</OfflineText>
          </ReconnectedBar>
        )}
        <DashboardHeader history={this.props.history} />
        <BodyContainer>
          {
            this.props.transcriberAccountActive ? loadFilterPane() : ''
          }
          {
            this.props.transcriberAccountActive && (<ConsultationList />)
          }
          {!this.props.transcriberAccountActive && (<Appointments information = {this.props} renderChats = {this.handleRenderChats}/>)}
          {
            transcriberAccountActive && (<ConsultationContainer />)
          }
          {
            !transcriberAccountActive && renderChats && (<ConsultationChatContainer renderPrescription = {this.handleRenderPrescription}/>)
          }
          {
            !transcriberAccountActive && renderPrescription && (<PrescriptionContainer renderChats = {this.handleRenderChats}/>)
          }
          {/* <ProfileContainer /> */}
          {this.props.transcriberAccountActive ? (<ProfileContainer />) : (<UserProfileMain authToken = {this.props.user.authToken} activeConsult = {this.props.activeConsult}/>)}
        </BodyContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  activeConsult: state.consults.activeConsult,
  unrepliedChannel: state.consults.unrepliedChannel,
  showFilterPane: state.app.filterPane,
  user: state.user,
  pubnubChannelGroup: state.info.pubnubChannelGroup,
  appState: state.app.appState,
  transcriberAccountActive: state.app.transcriberAccountActive,
});

export default connect(mapStateToProps)(DashboardHome);
