import React, { useCallback, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { connect } from 'react-redux';
import { addVisionProcedure, approveVisionProcedure, fetchAvailableProcedures, fetchRequestedProcedures, fetchVisionCardDetails, getEyeDetails, rejectVisionProcedure, submitVisionCardDetails } from './service';
import { toast, ToastContainer } from 'react-toastify';
import rejectImage from '../../images/reject.svg';
import approveImage from '../../images/approve.svg';
import ProcedureModal from './ProcedureModal';
import moment from 'moment';
import { markDigitised } from '../../services';
import { fetchConsults, resetPrescription } from '../../actions';
import RejectModal from './RejectModal';
import randomString from '../../utils/random-string';
import TipButton from './TipButton';

const INVALID_REASONS = [
    { label: 'Invoice not clear', value: 'Invoice not clear' },
    { label: 'Vision card not clear', value: 'Vision card not clear' },
    { label: 'Invoice does not belong to user', value: 'Invoice does not belong to user' },
]

const DEFAULT_REJECTION_DATA = { drRemarks: '', procedureId: '', procedureFee: '', isEntering: false };
const DEFAULT_VISION_CARD_DETAILS = {
    left: {
        spherical: '',
        cylindrical: '',
        axis: '',
        vA: '',
    },
    right: {
        spherical: '',
        cylindrical: '',
        axis: '',
        vA: '',
    }
}

const Heading = styled.div`
    font-size: 13.5px;
    color: #333;
    font-weight: 600;
    margin-bottom: 5px;
`;

const Column = styled.div`
    margin-bottom: 25px;
`;

const StyledInput = styled.input`
    border: none;
    border-bottom: 1px solid #aaa;
    border-radius: 0;
    outline: none;
    font-size: 12.5px;
    width: 100%;
    padding-top: 5px;
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const BoxInput = styled.input`
    font-size: 12px;
    width: 100%;
    border: 1px solid #714FFF;
    border-radius: 2.5px;
    padding: 5px;
    outline: none;
`;

const PrimaryButton = styled.button`
    color: #fff;
    width: ${(props) => (props.full ? '100%' : 'fit-content')};
    padding: 10px;
    border-radius: 2.5px;
    font-weight: 500;
    font-size: 14px;
    border: ${(props) => (props.disabled ? '1px solid #b6c4f8' : '1px solid #714FFF')};
    background: ${(props) => (props.disabled ? '#b6c4f8' : '#714FFF')};
    font-family: BrandonText-Bold, Lato, sans-serif;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const SecondaryButton = styled.button`
    border: 1px solid #714FFF;
    color: #714FFF;
    width: 100%;
    background: transparent;
    padding: 10px 0;
    border-radius: 2.5px;
    font-weight: 600;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const StickyFlex = styled(Flex)`
    position: sticky;
    bottom: 0;
    width: 100%;
`;

const ItemContainer = styled.table`  
    height: fit-content;
    width: 100%;
    border-collapse: separate;
    border-spacing: 5px 0;
`;

const AmountInput = styled.input`
    border: 1px solid #D8D8D8;
    width: 95%;
    margin-left: 5%;
    border-radius: 2.5px;
    padding: 5px 0;
    text-align: right;
    outline: none;
    padding-right: 5px;
`;

const EyeInput = props => <BoxInput {...props} style={{ flex: 1 }} />

const DigitizeVisionPrescription = (props) => {

    const [canCallApis, setCanCallApis] = useState(true);
    const [allProcedures, setAllProcedures] = useState([]);
    const [visionCardDetails, setVisionCardDetails] = useState(DEFAULT_VISION_CARD_DETAILS);
    const [canSubmitVisionCard, setCanSubmitVisionCard] = useState(true);
    const [requestedProcedures, setRequestedProcedures] = useState({});
    const [isSubmittingVisionCard, setIsSubmittingVisionCard] = useState(false);
    const [isAddingProcedure, setIsAddingProcedure] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedInvalidReason, setSelectedInvalidReason] = useState('');
    const [rejectionData, setRejectionData] = useState(DEFAULT_REJECTION_DATA);

    useLayoutEffect(() => {
        if (canCallApis && props?.digitisationDetails?.sourceId && props?.authToken) {
            const requestId = props.digitisationDetails.sourceId;
            const authToken = props.authToken;

            Promise.allSettled([
                fetchVisionCardDetails(`get-vision-card-details/${requestId}`, authToken),
                fetchRequestedProcedures(`get-all-vision-procedures-requests/${requestId}`, authToken),
                fetchAvailableProcedures('get-all-cashless-vision-procedures', authToken),
            ]).then(([ cardResponse, procedureResponse, allProceduresResponse ]) => {
                if (Array.isArray(cardResponse.value) && cardResponse.value.length) {
                    setVisionCardDetails({
                        left: getEyeDetails(cardResponse.value, 'left'),
                        right: getEyeDetails(cardResponse.value, 'right'),
                    });
                    if (cardResponse?.value?.[0]?.sphericalDetails) {
                        setCanSubmitVisionCard(false);
                    }
                }
                if (Array.isArray(procedureResponse.value.data)) {
                    setRequestedProcedures(procedureResponse.value);
                }
                if (Array.isArray(allProceduresResponse.value)) {
                    setAllProcedures(allProceduresResponse.value);
                }
            });

            setCanCallApis(false);
        }
    }, [ props, canCallApis ]);

    const updateVisionCard = useCallback((side, key, value) => {
        setVisionCardDetails(prev => ({ ...prev, [side]: { ...prev[side], [key]: value } }))
    }, []);

    const submitVisionCard = useCallback(() => {
        setIsSubmittingVisionCard(true);
        const { sourceId } = props.digitisationDetails;
        const authToken = props.authToken;

        submitVisionCardDetails(`add-vision-card-details/${sourceId}`, visionCardDetails, authToken)
            .then((res) => {
                if (res) {
                    toast.success('Vision card details added successfully');
                }
            })
            .finally(() => setIsSubmittingVisionCard(false));
    }, [ visionCardDetails, props ]);

    const updatePatient = useCallback((patient) => {
        props.dispatch({
            type: 'SET_RELATIVE_ID',
            payload: patient.value,
        });
        setSelectedPatient(patient);
    }, [ props ]);

    const digitizeRequest = useCallback((invalidReason) => {
        console.log({ myDataIsHere: { 
            digitisationDetails: props.digitisationDetails,
            prescription: props.prescription, digitisationRequestId: props.digitisationRequestId, 
            consultation: props.consultation, filters: props.filters, selectedSponsorId: props.selectedSponsorId
        } });
        
        let digitizationParams = [];
        
        if (invalidReason) {
            digitizationParams = [
                props.authToken, null, null, props.digitisationRequestId,
                null, null, null, null, invalidReason
            ];
        } else {
            digitizationParams = [
                props.authToken,
                props.prescription.probableDiagnosis,
                props.prescription.icdCode,
                props.digitisationRequestId,
                props?.consultation?.appointmentDate ? moment(props.consultation.appointmentDate).format('YYYY-MM-DD') : '',
                props.prescription.prescriptionValidityDate,
                props.prescription.doctorName,
                props.prescription.doctorId,
                null,
                props?.consultation?.centreName || '',
                props?.consultation?.centreAddress || '',
                props?.consultation?.centerId || null,
                selectedPatient?.value || props?.consultation?.relativeId || null,
                (props?.prescription?.digitisationConsultation || {}).consultationId,
                (props?.prescription?.digitisationConsultation || {}).prescriptionId,
                props.prescription.isCritical,
                props.prescription.secondaryDiagnosis,
                props.prescription.secondaryDiagnosisIcdCode
            ];
        }

        markDigitised(...digitizationParams)
            .then(() => {
                toast.success('Digitisation Marked Complete');
                props.dispatch(resetPrescription());
                props.dispatch(
                    fetchConsults({
                        authToken: props.authToken,
                        filters: props.filters,
                        selectedSponsorId: props.selectedSponsorId,
                    })
                );
            });
    }, [ props, selectedPatient ]);

    const rejectProcedure = useCallback(() => {
        if (!rejectionData.procedureId) {
            return;
        }
        if (!rejectionData.isEntering) {
            return setRejectionData(prev => ({ ...prev, isEntering: true }));
        } else {
            setRejectionData(prev => ({ ...prev, isEntering: false }));
        }
        const { sourceId } = props.digitisationDetails;
        const authToken = props.authToken;

        rejectVisionProcedure(`reject-vision-procedure-request/${sourceId}`, rejectionData, authToken)
            .then((res) => {
                if (res) {
                    setCanCallApis(true);
                    toast.success('Vision procedure rejected successfully');
                }
            })
            .finally(() => setRejectionData(DEFAULT_REJECTION_DATA));
    }, [ props, rejectionData ]);

    const approveProcedure = useCallback((procedureId, cost, inputId) => {
        const procedureFee = document.getElementById(inputId)?.value;
        if (!parseInt(procedureFee) || cost < parseInt(procedureFee)) {
            return window.alert('Please enter a valid procedure amount to approve');
        }
        const { sourceId } = props.digitisationDetails;
        const authToken = props.authToken;
        const data = { procedureId, approvedAmount: procedureFee };

        approveVisionProcedure(`approve-vision-procedure-request/${sourceId}`, data, authToken)
            .then((res) => {
                if (res) {
                    setCanCallApis(true);
                    toast.success('Vision procedure approved successfully');
                }
            });
    }, [ props ]);

    const addProcedure = useCallback((procedure) => {
        const { sourceId } = props.digitisationDetails;
        const authToken = props.authToken;
        const data = {
            itemName: procedure.itemName,
            itemCost: procedure.cost,
            visionProcedureId: procedure.procedureId,
            remark: ''
        };

        addVisionProcedure(`add-vision-procedure-request/${sourceId}`, data, authToken)
            .then(res => {
                if (res) {
                    setCanCallApis(true);
                    toast.success('Vision procedure added successfully');
                }
            })

    }, [ props ]);

    return <div>
        <div className='p-5'>
            
            {/* <Column>
                <Heading>
                    Consultations{' '}
                    <small>(Check for existing consultations)</small>
                </Heading>
                <Select
                    placeholder='Search Consultations'
                    options={props.pastAppointments.map((c) => {
                        c.value = c.prescriptionId;
                        c.label = `${c.prescriptionId}. ${c.doctorName} - ${c.date} - Patient: ${c.patientName} [${c.type}]`;
                        return c;
                    })}
                    isClearable
                    onChange={option => props.dispatch({
                        type: 'SET_DIGITISATION_CONSULTATION',
                        payload: option,
                    })}
                />
            </Column> */}

            <Column>
                <Heading>Centre Name *</Heading>
                <StyledInput disabled value={props?.consultation?.centreName || ''} placeholder='Centre Name' />
            </Column>

            <Column>
                <Heading>Centre Address *</Heading>
                <StyledInput disabled value={props?.consultation?.centreAddress || ''} placeholder='Centre Address' />
            </Column>

            <Column>
                <Heading>Appointment Date *</Heading>
                <StyledInput
                    disabled placeholder='Appointment Date'
                    value={props?.consultation?.appointmentDate ? moment(props.consultation.appointmentDate).format('YYYY-MM-DD') : ''}
                />
            </Column>

            {/* <Column>
                <Heading>Patient *</Heading>
                <Select
                    placeholder='Search Patient' value={selectedPatient} onChange={updatePatient}
                    options={props?.relatives?.map((r) => ({ value: r.relativeId, label: r.name })) || []}
                />
            </Column> */}

            <div className='p-3 border rounded' style={{ borderColor: '#aaa' }}>
                <Heading>Add Vision Card Details</Heading>
                <Flex className='flex-col' style={{ rowGap: '10px' }}>
                    <Flex className='flex-row w-100' style={{ columnGap: '5px' }}>
                        <div style={{ flex: 2 }}></div>
                        <div style={{ flex: 1 }}>Spherical</div>
                        <div style={{ flex: 1 }}>Cylindrical</div>
                        <div style={{ flex: 1 }}>Axis</div>
                        <div style={{ flex: 1 }}>V/A</div>
                    </Flex>
                    <Flex className='flex-row w-100' style={{ columnGap: '5px' }}>
                        <div style={{ flex: 2 }}>Right Eye Details -</div>
                        <EyeInput
                            placeholder='Spherical' value={visionCardDetails.right.spherical} 
                            onChange={e => updateVisionCard('right', 'spherical', e.target.value)}
                        />
                        <EyeInput
                            placeholder='Cylindrical' value={visionCardDetails.right.cylindrical}
                            onChange={e => updateVisionCard('right', 'cylindrical', e.target.value)}
                        />
                        <EyeInput
                            placeholder='Axis' value={visionCardDetails.right.axis}
                            onChange={e => updateVisionCard('right', 'axis', e.target.value)}
                        />
                        <EyeInput
                            placeholder='V/A' value={visionCardDetails.right.vA}
                            onChange={e => updateVisionCard('right', 'vA', e.target.value)}
                        />
                    </Flex>
                    <Flex className='flex-row w-100' style={{ columnGap: '5px' }}>
                        <div style={{ flex: 2 }}>Left Eye Details -</div>
                        <EyeInput
                            placeholder='Spherical' value={visionCardDetails.left.spherical} 
                            onChange={e => updateVisionCard('left', 'spherical', e.target.value)}
                        />
                        <EyeInput
                            placeholder='Cylindrical' value={visionCardDetails.left.cylindrical}
                            onChange={e => updateVisionCard('left', 'cylindrical', e.target.value)}
                        />
                        <EyeInput
                            placeholder='Axis' value={visionCardDetails.left.axis}
                            onChange={e => updateVisionCard('left', 'axis', e.target.value)}
                        />
                        <EyeInput
                            placeholder='V/A' value={visionCardDetails.left.vA}
                            onChange={e => updateVisionCard('left', 'vA', e.target.value)}
                        />
                    </Flex>
                </Flex>
                <SecondaryButton onClick={submitVisionCard} disabled={isSubmittingVisionCard || !canSubmitVisionCard} className='mt-5'>
                    {canSubmitVisionCard ? 'Submit' : 'Already Submitted'}
                </SecondaryButton>
            </div>

            <PrimaryButton onClick={() => setIsAddingProcedure(true)} className='my-4'>
                + Add Procedure Details
            </PrimaryButton>

            <ProcedureModal
                isOpen={isAddingProcedure}
                hideModal={() => setIsAddingProcedure(false)}
                addData={addProcedure}
                allProcedures={allProcedures}
            />

            <RejectModal
                isOpen={rejectionData.isEntering}
                onClose={() => setRejectionData(DEFAULT_REJECTION_DATA)}
                onReject={rejectProcedure}
                remark={rejectionData.drRemarks}
                onRemarkChange={drRemarks => setRejectionData(prev => ({ ...prev, drRemarks }))}
            />

            <div className='border rounded' style={{ borderColor: '#714FFF' }}>
                <Flex className='flex-row bg-danger p-3 ' style={{
                    justifyContent: 'space-between', borderBottom: '1px solid #aaa'
                }}>
                    <Heading>Total wallet balance</Heading>
                    <Heading>₹{requestedProcedures.walletAmountBeforeProcedure || 0}</Heading>
                </Flex>

                <div className='p-3'>
                    <ItemContainer style={{ fontSize: '12.5px' }} className='table'>
                        <thead>
                            <tr>
                                <th className='text-start'>Details</th>
                                <th className='text-end' style={{ width: '80px' }}>Cost</th>
                                <th className='text-end' style={{ width: '120px' }}>Approved Amount</th>
                                <th className='text-end' style={{ width: '60px' }}>Final Amount</th>
                                <th className='text-end' style={{ width: '100px' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {(requestedProcedures.data || []).map((procedure, idx) => {
                                const inputId = `${procedure.procedureId}_${randomString()}`;
                                return <tr key={`${procedure.procedureId}_${idx}`}>
                                    <td className='text-start'>{procedure.itemName}</td>
                                    <td className='text-end' style={{ width: '80px' }}>₹{procedure.cost}</td>
                                    <td className='text-end' style={{ width: '120px' }}>
                                        {procedure.status !== 'pending' ? `₹${(procedure.approvedAmount || 0)}` : <AmountInput id={inputId} placeholder='Enter Amount' />}

                                    </td>
                                    <td className='text-end' style={{ width: '60px' }}>{procedure.walletDeductionAmount || 0}</td>
                                    <td className='text-end' style={{ width: '100px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        
                                        <TipButton tipText='Mark approved' disabled={procedure.status !== 'pending'} style={{
                                            filter: procedure.status !== 'pending' ? 'grayscale(100%)' : '',
                                            cursor: procedure.status !== 'pending' ? 'not-allowed' : 'pointer',
                                        }} onClick={() => approveProcedure(procedure.procedureId, procedure.cost, inputId)}>
                                            <img alt='Approve' src={approveImage} />
                                        </TipButton>
                                        <TipButton tipText='Mark not covered' style={{
                                            filter: procedure.status !== 'pending' ? 'grayscale(100%)' : '',
                                            cursor: procedure.status !== 'pending' ? 'not-allowed' : 'pointer',
                                        }} disabled={procedure.status !== 'pending'} onClick={() => setRejectionData({ procedureId: procedure.procedureId, procedureFee: procedure.cost, drRemarks: '', isEntering: true })}>
                                            <img alt='Reject' src={rejectImage} />
                                        </TipButton>
                                    </td>
                                </tr>
                            })}

                        </tbody>
                    </ItemContainer>
                </div>

                {/* <Flex className='flex-row bg-danger p-3 ' style={{
                    justifyContent: 'space-between', borderTop: '1px solid #aaa'
                }}>
                    <Heading style={{ color: '#714FFF' }}>Current Wallet Balance</Heading>
                    <Heading style={{ color: '#714FFF' }}>₹{requestedProcedures.currentWalletBalance || 0}</Heading>
                </Flex> */}

                {/* <Flex className='flex-row bg-danger p-3 ' style={{
                    justifyContent: 'space-between', borderTop: '1px solid #aaa'
                }}>
                    <Heading style={{ color: '#FF754C' }}>Co-pay Amount</Heading>
                    <Heading style={{ color: '#FF754C' }}>₹{requestedProcedures.copay || 0}</Heading>
                </Flex> */}

            </div>

        </div>

        <StickyFlex className='flex-col w-100' style={{ background: 'white' }}>
            
            <Flex className='w-100 mb-1' style={{ columnGap: '5px', background: '#fff' }}>
                <Select 
                    options={INVALID_REASONS} placeholder='Select invalid reason' className='invalid-select' 
                    menuPlacement='top' onChange={(option) => setSelectedInvalidReason(option.value)}
                />
                <PrimaryButton onClick={() => digitizeRequest(selectedInvalidReason)} disabled={!selectedInvalidReason}>Mark Request Invalid</PrimaryButton>
            </Flex>
            
            <PrimaryButton 
                disabled={props?.digitisationDetails?.status !== 'pending'} full 
                onClick={() => digitizeRequest()} className='py-6' 
                style={{ flex: 1, borderRadius: 0, width: '100% !important' }}
            >
                Mark Digitized
            </PrimaryButton>
            
        </StickyFlex>

        <ToastContainer />
    </div>;
};

const matchStateToProps = (state) => ({
    digitisationDetails: state.digitisation,
    pastAppointments: state.prescription.pastAppointments,
    authToken: state.user.authToken,
    consultation: state.consultation,
    relatives: state.prescription.relatives,
    digitisationRequestId: state.consults.activeConsult.digitisationRequestId,
    prescription: state.prescription,
    filters: state.filters.filters,
    selectedSponsorId: state.filters.selectedSponsorId,
});

export default connect(matchStateToProps)(DigitizeVisionPrescription);
