import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

import { RegularText } from '../../common/Text';
import moment from 'moment';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: flex-end;
  margin: 0.4rem 0;
  margin-right: 12px;
`;

const BubbleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 1rem 1.2rem;
  /* background: #4f34d2; */
  /* opacity: ${(props) => (props.timestamp ? 1 : 0.5)}; */
  /* border-radius: 1.4rem; */
  border-radius: 12px 12px 2px 12px;
  border: 1px solid #f5f5f5;
  /* background: #F0F3F6; */
  background: #F0F3F6;
  word-break: break-word;
`;

const MessageText = styled(RegularText)`
  /* font-size: 1.6rem; */
  color: #0F0B28;
  font-size: 15px;
  text-align: left;
  line-height: 156%; /* 21.84px */
  letter-spacing: -0.165px;
`;

const TimestampContainer = styled.div`
  display: flex;
  min-width: 45px;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1.2rem;
  width: 90px;
`;


const TimestampText = styled(RegularText)`
  /* opacity: 0.5; */
  color: #A5A6BB;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 156%; /* 15.6px */
  letter-spacing: -0.165px;
`;

const UserMessage = ({ message, timestamp }) => (
  <OuterContainer>
     {timestamp && (
        <TimestampContainer>
          <TimestampText>
            {moment(timestamp).format('HH:mm A')}
          </TimestampText>
        </TimestampContainer>
      )}
    <BubbleContainer timestamp={timestamp}>
      <MessageText>{message}</MessageText>
    </BubbleContainer>
  </OuterContainer>
);

UserMessage.propTypes = {
  message: PropTypes.string,
  timestamp: PropTypes.number,
};

export default UserMessage;
