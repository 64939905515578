import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import Modal from '../../../containers/common/Modal';
import { fetchReasons, postReasonsAndCall } from '../../../services';
import LoadingComponent from '../../common/LoadingComponent';
import DetailsCard from './DetailsCard';

const LoadingContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const LoadingBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 534px;
  width: 480px;
  background: #ffffff;
  box-shadow: 0px 0px 12.3326px rgba(0, 0, 0, 0.05);
  border-radius: 14.7991px;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  box-sizing: border-box;
  width: 480px;
  height: 650px;
  background: #ffffff;
  box-shadow: 0px 0px 12.3326px rgba(0, 0, 0, 0.05);
  border-radius: 14.7991px;
`;

const HeadingContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #f0f3f6;
  transform: matrix(1, 0, 0, 1, 0, 0);
`;

const Heading = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  margin-top: 16px;
  margin-left: 20px;
  color: #302e6b;
  padding-bottom: 20px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 440px;
  height: 107px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  margin-top: 25px;
  margin-left: 20px;
`;

const InfoRelationContainer = styled.div`
  display: flex;
`;

const InfoBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-top: 16px;
`;

const NameContainer = styled.div`
  display: flex;
`;
const NameIcon = styled.img`
  margin-right: 10px;
`;

const NameText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
`;

const NameHolder = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #585969;
  margin-left: 24px;
`;

const RelationBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 150px;
  margin-top: 16px;
`;

const RelationContainer = styled.div`
  display: flex;
`;

const RelationIcon = styled.img`
  margin-right: 10px;
`;

const RelationText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
`;

const RelationHolder = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #585969;
  margin-left: 26px;
`;

const LineBody = styled.div`
  width: 440px;
  height: 0px;
  opacity: 0.6;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 40px;
  margin-left: 20px;
`;

const ReasonsContainer = styled.div`
  box-sizing: border-box;
  width: 440px;
  height: 220px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  margin-left: 20px;
  margin-top: 25px;
  overflow-y: scroll;
`;

const ConsentContainer = styled.div`
  width: 416px;
  height: 60px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  color: #0f0b28;
  margin-top: 16px;
  margin-left: 20px;
  display: flex;
`;

const Consent = styled.input`
  margin-bottom: 40px;
`;

const ConsentLabel = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #0f0b28;
  margin-left: 8px;
`;

const CallButtonY = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 125px;
  gap: 10px;
  cursor: pointer;
  width: 440px;
  height: 48px;
  background: #714fff;
  border-radius: 12px;
  margin-top: 30px;
  margin-left: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const ReasonsHead = styled.div`
  width: 400px;
  height: 42px;
  margin-top: 20px;
  margin-left: 20px;
`;

const Head = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #0f0b28;
`;

const CallButtonN = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 125px;
  gap: 10px;
  width: 440px;
  height: 48px;
  background: #f2f2f2;
  border-radius: 12px;
  margin-top: 30px;
  margin-left: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #c2c2c2;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const InfoTextContainer = styled.div`
  display: flex;
  width: 440px;
  height: 40px;
  background: rgba(207, 200, 255, 0.32);
  border-radius: 12px 12px 0px 0px;
`;

const InfoText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #0f0b28;
  margin-left: 12px;
  margin-top: 13px;
`;

const InfoImg = styled.img`
  margin-top: 13.33px;
  margin-left: 16.9px;
  margin-bottom: 12.67px;
  height: 16px;
  width: 16px;
`;

const ReportNowY = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 125px;
  gap: 10px;
  cursor: pointer;
  width: 440px;
  height: 48px;
  background: #714fff;
  border-radius: 12px;
  margin-left: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const ReportNowN = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 125px;
  gap: 10px;
  width: 440px;
  height: 48px;
  cursor: default;
  background: #f2f2f2;
  border-radius: 12px;
  margin-left: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #c2c2c2;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const NullContainer = styled.div`
  margin-top: 3.5px;
  height: 13px;
  width: 44px;
  border: 1px solid;
  border-radius: 2px;
  opacity: 0.5;
`;

const ContactInfo = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [reasons, setReasons] = useState([]);
  const [otherReason, setOtherReason] = useState(false);
  const [otherReasonData, setOtherReasonData] = useState('');
  const [reasonsData, setReasonsData] = useState([]);
  const [docReasons, setDocReasons] = useState([]);
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    const getAllInfo = async () => {
      const reasonsData = await fetchReasons(props.information);
      if (reasonsData.message === 'success') {
        setReasonsData(reasonsData.reasons);
      }
      setIsLoading(false);
    };
    getAllInfo();
  }, []);

  const handleReasons = (reason, id) => {
    reasons.indexOf(reason) === -1
      ? setReasons((prev) => [...prev, reason])
      : setReasons((prev) => prev.filter((item) => item !== reason));
  };

  const handleOtherReason = () => {
    setOtherReason((prev) => !prev);
    setOtherReasonData('');
  };

  const handleConsent = () => {
    setConsent((prev) => !prev);
  };

  const handleOtherReasonChange = (e) => {
    if (otherReason) {
      setOtherReasonData(e.target.value);
    } else {
      setOtherReasonData('');
    }
    console.log(otherReasonData);
  };

  const initiateCall = async (reasons, data) => {
    if (otherReasonData.length) {
      reasons.push(otherReasonData);
    }
    await postReasonsAndCall(reasons, data);
    props.information.hideModal();
  };

  useEffect(() => {
    if (!reasons.length && !otherReasonData.length) setConsent(false);
  }, [reasons.length, otherReason, otherReasonData.length]);

  return !isLoading ? (
    <Modal>
      <ModalContainer>
        <ModalBody style={reasonsData.length ? (reasonsData.length <= 2 ? {height: '450px'} : {height: '534px'}) : {height: '650px'}}>
          <HeadingContainer>
            <Heading>Report Emergency</Heading>
            <FaTimes
              style={{
                width: '14px',
                height: '14px',
                marginTop: '21px',
                marginRight: '8px',
                marginLeft: '280px',
                cursor: 'pointer',
              }}
              onClick={props.information.hideModal}
            />
          </HeadingContainer>
          <DetailsCard contactInfo={props.contactInfo} />
          {reasonsData && reasonsData.length > 0 ? (
            <ReasonsContainer style={reasonsData.length <= 2 ? {height: '140px'} : {height: '220px'}}>
              <ReasonsHead>
                <Head>
                  Last time you reported emergency for the following reason.{' '}
                </Head>
              </ReasonsHead>
              {reasonsData.map((reason) => {
                return (
                  <div
                    style={{
                      marginTop: '12px',
                      marginLeft: '20px',
                      display: 'flex',
                    }}
                  >
                    <ul>
                      <li style={{ fontSize: '11px', listStyleType: 'initial' }}>{reason}</li>
                    </ul>
                  </div>
                );
              })}
            </ReasonsContainer>
          ) : (
            <ReasonsContainer>
              <ReasonsHead>
                <Head>
                  Can you tell us why you want to connect with emergency contact
                  for this user?
                </Head>
              </ReasonsHead>
              {props.contactInfo.doctorReasonsArray.map((reason, idx) => {
                return (
                  <div
                    style={{
                      marginTop: '12px',
                      marginLeft: '20px',
                      display: 'flex',
                    }}
                  >
                    <input
                      type="checkbox"
                      id={idx}
                      value={reason}
                      onClick={() => handleReasons(reason, idx)}
                    />
                    <label
                      style={{
                        marginLeft: '8px',
                        color: '#585969',
                        fontStyle: 'normal',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '150%',
                        flex: 'none',
                        order: '1',
                        flexGrow: '0',
                      }}
                    >
                      {reason}
                    </label>
                  </div>
                );
              })}
              <div
                style={{
                  marginTop: '12px',
                  marginLeft: '20px',
                  display: 'flex',
                }}
              >
                <input type="checkbox" onClick={() => handleOtherReason()} />
                <label
                  style={{
                    marginLeft: '8px',
                    color: '#585969',
                    fontStyle: 'normal',
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '150%',
                    flex: 'none',
                    order: '1',
                    flexGrow: '0',
                  }}
                >
                  Other
                </label>
              </div>
              {otherReason && (
                <>
                  <textarea
                    value={otherReasonData}
                    onChange={(e) => handleOtherReasonChange(e)}
                    style={{
                      height: '52px',
                      width: '400px',
                      fontSize: '11px',
                      background: '#FFFFFF',
                      border: '1px solid #E4E4E4',
                      borderRadius: '8px',
                      resize: 'none',
                      marginLeft: '20px',
                      padding: '5px',
                    }}
                  />
                </>
              )}
            </ReasonsContainer>
          )}
          {reasonsData && reasonsData.length > 0 ? (
            ''
          ) : (
            <>
              <LineBody />
              <ConsentContainer>
                {reasons.length || otherReasonData.length ? (
                  <Consent type="checkbox" onClick={() => handleConsent()} />
                ) : (
                  <NullContainer />
                )}
                <ConsentLabel>
                  Patient’s personal information will only be shared with the
                  emergency contact number or necessary medical personnel as
                  needed for medical care or as required by law. Visit will not
                  be involved in this procedure as we believe that it is
                  critical to safeguard the patient’s privacy by all means
                  necessary.
                </ConsentLabel>
              </ConsentContainer>
            </>
          )}
          {reasonsData && reasonsData.length > 0 && (
            <CallButtonY
            style={{marginTop: '20px'}}
              onClick={() => initiateCall(reasons, props.information)}
            >
              Call Now
            </CallButtonY>
          )}
          {(!reasonsData || !reasonsData.length) &&
            (consent ? (
              <CallButtonY style={{marginTop: '40px'}}
                onClick={() => initiateCall(reasons, props.information)}
              >
                Call Now
              </CallButtonY>
            ) : (
              <CallButtonN style={{marginTop: '40px'}}>Call Now</CallButtonN>
            ))}
        </ModalBody>
      </ModalContainer>
    </Modal>
  ) : (
    <Modal>
      <LoadingContainer>
        <LoadingBody>
          <LoadingComponent />
        </LoadingBody>
      </LoadingContainer>
    </Modal>
  );
};

export default ContactInfo;
