import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const OuterContainer = styled.div`
display: inline-flex;
max-width: 310px;
border-radius: 8px;
background: #F0F3F6;
align-items: center;
padding: 4px 4px 4px 8px;
margin-top: 8px;
gap: 2px;
cursor: default;
word-break: break-word;
justify-content: space-between;
`

const Text = styled.p`
color: #714FFF;
font-size: 12px;
font-weight: 600;
line-height: 120%;
`

const Card = ({ item, deleteAllergy, deleteChronicAilment, deletePastSurgery, deleteFamilyHistory, type }) => {
  console.log(item, 'item');

  const handleDelete = () => {
    if(type === 'allergy') {
      if(item.type === 'Drug')
      deleteAllergy(1, item.name);
      else if(item.type === 'Food') {
        deleteAllergy(2, item.name);
      } else if(item.type === 'Others') {
        deleteAllergy(3, item.name);
      }
    } else if(type === 'chronic-ailment') {
      deleteChronicAilment(item.name);
    } else if(type === 'past-surgery') {
      deletePastSurgery(item.name);
    } else if(type === 'family-history') {
      deleteFamilyHistory(item.name);
    }
  }

  return (
    <>
    {
      item && item.name && (
        <OuterContainer>
          <div style={{display: 'flex', wordBreak: 'break-word'}}>
          <Text>{item.name}</Text>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <FaTimes style={{color: '#714FFF', height: '12px', width: '12px', cursor: 'pointer'}} onClick = {() => handleDelete()}/>
          </div>
        </OuterContainer>
      )
    }
    </>
  )
}

export default Card