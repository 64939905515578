import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../containers/common/Modal';
import { FaTimes } from 'react-icons/fa';
import StyledTappable from '../../components/common/StyledTappable';
import Select from 'react-select';
import { Text } from '../../components/common/Text';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(Text)`
  font-size: 17.5px;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  flex-direction: column;
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const PrimaryButton = styled.button`
    color: #fff;
    width: fit-content;
    padding: 10px;
    border-radius: 2.5px;
    font-weight: 600;
    border: ${(props) => (props.disabled ? '1px solid #aaa' : '1px solid #714FFF')};
    background: ${(props) => (props.disabled ? '#aaa' : '#714FFF')};
`;

const SecondaryButton = styled.button`
    border: 1px solid #714FFF;
    color: #714FFF;
    width: 100%;
    background: transparent;
    padding: 10px 0;
    border-radius: 2.5px;
    font-weight: 600;
`;

const StyledInput = styled.input`
    border: none;
    border: 1px solid #ccc;
    border-radius: 0;
    outline: none;
    font-size: 12px;
    width: 100%;
    border-radius: 5px;
    padding: 9px;
`;

const DEFAULT_PROCEDURE = { itemName: '', cost: '', procedureId: '' };
const ProcedureModal = (props) => {

    const [procedureData, setProcedureData] = useState(DEFAULT_PROCEDURE);

    const closeModal = useCallback(() => {
        setProcedureData(DEFAULT_PROCEDURE);
        props.hideModal();
    }, [ props ]);

    const addData = useCallback(() => {
        props.addData(procedureData);
        closeModal();
    }, [ props, procedureData, closeModal ]);

    const handleSelecting = useCallback((option) => {
        setProcedureData(prev => {
            if (typeof option === 'object') {
                return { itemName: option.label, procedureId: option.value, cost: prev.cost };
            } else {
                return { ...prev, cost: option }
            }
        });
    }, []);
  
    if (!props.isOpen) {
        return <></>;
    }

    return <Modal>
        <ModalContainer>
            <ModalBody>
                <ModalHeader>
                    <HeaderText>Add New Procedure</HeaderText>
                    <ButtonsComponent>
                        <StyledTappable onTap={closeModal}>
                        <CrossIcon />
                        </StyledTappable>
                    </ButtonsComponent>
                </ModalHeader>

                <Body>
                    <Flex style={{ columnGap: '7.5px', alignItems: 'flex-end' }}>
                        <Flex style={{ flexDirection: 'column', flex: 2, alignItems: 'flex-start' }}>
                            <label className='mb-2'>Procedure Details</label>
                            <Select 
                                options={props.allProcedures.map(item => ({ label: item.displayName, value: item.procedureId }))}
                                className='w-100' placeholder='Add Procedure' onChange={handleSelecting}
                            />
                        </Flex>
                        <Flex style={{ flexDirection: 'column', flex: 1.6, alignItems: 'flex-start' }}>
                            <label className='mb-2'>Procedure Amount</label>
                            <StyledInput
                                placeholder='Enter Amount' value={procedureData.cost}
                                onChange={e => handleSelecting(e.target.value)}
                            />
                        </Flex>
                        <PrimaryButton
                            onClick={addData} style={{ flex: 1 }}
                            disabled={!procedureData.cost || !Number(procedureData.procedureId)}
                        >
                            Add
                        </PrimaryButton>
                        <SecondaryButton onClick={closeModal} style={{ flex: 1 }}>Cancel</SecondaryButton>
                    </Flex>
                </Body>
            </ModalBody>
        </ModalContainer>
    </Modal>
}

export default ProcedureModal;
