import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../containers/common/Modal';
import StyledTappable from './common/StyledTappable';
import { FaTimes } from 'react-icons/fa';
import { RegularText } from './common/Text';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 40%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: center;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const SimpleText = styled(RegularText)`
  margin: 0.5rem 0.625rem;
  font-size: 1.6rem;
  /* z-index: 999; */
`;
const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
`;

const HeaderText = styled(RegularText)`
  font-size: 2rem;
  color: ${(props) => (props.color ? props.color : 'black')};
`;
const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
class CommonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText color={this.props.headerColor}>
                <SimpleText>{this.props.modalText}</SimpleText>
              </HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.closeModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

export default CommonModal;
