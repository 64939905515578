import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactTable from 'react-table';
import selectTableHoc from 'react-table/lib/hoc/selectTable';
import "react-table/react-table.css";  
import styled from 'styled-components';
import { connect } from 'react-redux';
import {getAllDigitisationDetail} from '../../services/consults';
import  {getAllDoctorDigitiser} from  '../../services/consults';
import {fetchAllSponsor} from '../../services/consults';
import {bulkAssign,downloadDigitisationCsvFile} from '../../services/consults';
import Modal from '../admin/AdminModal';
import Header from '../../components/common/AdminHeader';
import { Async as AsyncSelect } from 'react-select';
import Select from 'react-select';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { PrimaryButton } from '../../components/common/Buttons';
import StyledTappable from '../../components/common/StyledTappable'
import { FaFileUpload, FaPowerOff, FaSignature, FaTimes } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';



const SelectTable = selectTableHoc(ReactTable);
const source = [
  { value: 'uploaded-labs', label: 'uploaded-labs' },
  { value: 'uploaded-meds', label: 'uploaded-meds' },
  { value: 'offline-consultations', label: 'offline-consultations' },
  { value: 'reimbursements', label: 'reimbursements' },
  {value: 'cashless-dental', label: 'cashless-dental'},
  {value: 'cashless-vision', label: 'cashless-vision'},

]

const status = [
  { value: 'pending', label: 'Pending' },
  { value: 'complete', label: 'Complete' },
  { value: '1', label: 'Invalid' }
]
const styleobj = { 
  "font-size": "17px",
 "overflow": 'hidden',
 "background-color":"#F5EDDC",
 }

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;
const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;
const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;
const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;
const HeaderText = styled.text`
  font-size: 2.4rem;
  margin: 14px 0;
`;
const Text = styled.p`
  margin: 0;
  font-family: Inter, Lato, sans-serif;
  color: ${(props) => props.color || 'black'};
  font-size: ${(props) => props.fontSize || '1.6rem'};
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;
const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;
const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;
const Input = styled.input`
padding:10px;
outline-color: #B3B3B3;
outline-offset: -2px;
outline-style: auto;
outline-width: 5px;

`
const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;
let page ,pageSize;

class Admin extends React.Component {  

  constructor(props){
      super(props);
      this.state={
        selectedOptionStatus: '',
        selectedOptionDoctorId:'',
        DoctorSelection:'',
        remarksCases :[],
        options:[],
        selection: [],
        selectAll: false,
        transferModalShown: false,
        docttorArray:[],
        transferLoading: false,
        remarksCasesLoading: false,
      remarkCasesSelected: new Set(),
      selectedRemark: null,
      allCases: [],
      selectedTransferDoctorId: -1,
      source:'',
      sourceValue:'',
      sponsorId:'',
      pages:-1,
      loading:false,
      totalCount:0,
      searchString:'',
      startDate: null,
      endDate: null,
      focusedInput: null,
      }
     this.getAllDoctors()


  }

getData(page,pageSize){
  const {searchString,startDate,endDate}=this.state;
  this.setState({
    loading: true,
  });
 return getAllDigitisationDetail(
  this.state.selectedOptionStatus.value,this.state.selectedOptionDoctorId.value,this.state.source,this.state.sponsorId,
  searchString,
  startDate && moment(startDate).unix(),
  endDate && moment(endDate).unix(),
  pageSize,
  page,
  this.props.authToken 
 ).then((res)=>{
      const remarksCases =res.result;
       this.setState({
        remarksCases,
        pages:parseInt(res.count /pageSize),
        loading: false,
        totalCount:res.count

      })

  })
  .catch((err) => {
    toast.error('Error')
    console.log(err);
  })
}
 getAllDoctors(){
  return getAllDoctorDigitiser(this.props.authToken).then((res)=>{
    const docttorArray = res.result.map((doctors) => {
      return {
        value:doctors.doctorId,
        label:doctors.name
      }
    });
    this.setState({
      docttorArray

    });

   })
   .catch((err) => {
    toast.error('Error')
    console.log(err);
  })

 }

 toggleRemarksCase = (ele, blah, obj) => {
  const { remarkCasesSelected } = this.state;
  const updatedRemarkCasesSelected = new Set(remarkCasesSelected);
  if (this.isRemarksCaseSelected(obj.digitisationId)) {
    updatedRemarkCasesSelected.delete(obj.digitisationId);
    this.setState({
      remarkCasesSelected: updatedRemarkCasesSelected,
    });
  } else {
    updatedRemarkCasesSelected.add(obj.digitisationId);
    this.setState({
      remarkCasesSelected: updatedRemarkCasesSelected,
    });
  }
};

isRemarksCaseSelected = (ele) => {
  const { remarkCasesSelected } = this.state;
  return remarkCasesSelected.has(ele);
};

toggleAllRemarksCases = () => {
  const { remarkCasesSelected, remarksCases } = this.state;
  if (remarkCasesSelected.size === remarksCases.length) {
    this.setState({
      remarkCasesSelected: new Set(),
    });
  } else {
    this.setState({
      remarkCasesSelected: new Set(
        remarksCases.map((ele) => ele.digitisationId),
      ),
    },
    () =>
    console.log(`Option selected:`, remarksCases)  
  
    );
  }
};

hideModal = () => {
  this.setState({
    transferModalShown: false,
  });
};

showBulkTransferModal = () => {
  this.setState({
    transferModalShown: true,
  });
};

 handleChangeDoctorValue = (selectedOptionDoctorId) => {
 
 this.setState({selectedOptionDoctorId},
  () =>
    console.log(`Option selected:`, this.state.selectedOptionDoctorId.value)  
  );
};

handleChangeStatus = (selectedOptionStatus) => {
  this.setState({ selectedOptionStatus }, () =>
    console.log(`Option selected:`, this.state.selectedOptionStatus.value)  
  );
};
handleChangeSource = (e) => {
  this.setState({ source: e.value}, () =>
    console.log(`Option selected:`, this.state.source)  
  );
};

filterData=(e)=>{
this.getData(page,pageSize)
  
}
onDoctorSelected=(e)=>{
this.setState({
  selectedTransferDoctorId:e.value,
  DoctorSelection:e,
}, console.log(`Option selected:`, this.state.selectedTransferDoctorId)  )
}

  bulkTransferCases=()=>{

    const {
    selectedTransferDoctorId,
    remarkCasesSelected,
    remarksCases,
    } = this.state;
    const digitisationIds = [];
    remarkCasesSelected.forEach((ele) => {
    const digitisationData = remarksCases.find((cons) => ele === cons.digitisationId);
    if (digitisationData) {
      digitisationIds.push(digitisationData.digitisationId);
    }
    });
   bulkAssign(digitisationIds,selectedTransferDoctorId,this.props.authToken)
    .then(()=>{
    this.getData(page ,pageSize).then(()=>{
      toast.success('Transfered Successfully.')
        this.setState({
          selectedTransferDoctorId: -1,
          remarkCasesSelected: new Set(),
          transferModalShown: false,
         });
      })
    .catch((err)=>{
      toast.error('Erorr')
      console.log(err)
       })
    })
    .cath((err)=>{
    toast.error('Erorr')
    console.log(err);
    })
    .finally(() => {
      this.setState({
      transferModalShown: false,
      });
    });
  }
 
  getAllSponsorList=(input)=>{
    return fetchAllSponsor(input.toLowerCase(),this.props.authToken)

    .then((result) => {
        console.log({ result }, 'getsponsor result');
        result.forEach((spon) => {
          spon.label = spon.name;
          spon.value = spon.sponsorId;
        });
    
        return result;
      })
    .catch((err) => {
        console.log({ err });
        return [];

      });
}
  promiseOptions = (input) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(this.getAllSponsorList(input));
    }, 500);
    });
  };
  onSponsorselect =(option) =>{
    this.setState({
        sponsorId:option.sponsorId
      });
}
phoneOnchange =(e)=>{
this.setState({
  searchString:e.target.value
})
}
onDatesChanged = ({ startDate, endDate }) => {
  this.setState({ startDate, endDate });
};

downloadCsvFile = ()=>{
  const {searchString,startDate,endDate}=this.state;
  const params = {
   status: this.state.selectedOptionStatus.value?this.state.selectedOptionStatus.value:'',
   doctorId: this.state.selectedOptionDoctorId.value?this.state.selectedOptionDoctorId.value:'',
    source:this.state.source?this.state.source:'',
    sponsor:this.state.sponsorId?this.state.sponsorId:'',
    searchString:searchString?searchString:'',
   startDate: startDate && moment(startDate).unix()?startDate && moment(startDate).unix():'',
   endDate: endDate && moment(endDate).unix()?endDate && moment(endDate).unix():'',
   pageSize: 20000,
    page:1,

    
  }
  downloadDigitisationCsvFile(params)
}
 getTrProps = (state, rowInfo, instance) => {
  if (rowInfo) {
    return {
      style: {
        background: rowInfo.original.duplicateRequest ? '#d9534f' : '#F5EDDC',
        color: 'black'
      }
    }
  }
  return {};
}

  render() {  

    const {transferModalShown,
      remarkCasesSelected,selectedTransferDoctorId,
      selectedOptionStatus,selectedOptionDoctorId,
      remarksCases,DoctorSelection,
      startDate,
      endDate, focusedInput
    } = this.state;
     
      const columns = [
        {  
          Header: 'DigitisationId',  
          accessor: 'digitisationId' ,
          }, 
        {  
        Header: 'User Name',  
        accessor: 'name' ,
        }, 

        {  
          Header: 'Critical Illness',  
          accessor: 'isCritical' ,
          Cell : (row)=>{
            return(
              <h1>
      {row.original.isCritical ?<h4 style={{color:'red'}} className='badge badge-danger'>YES</h4>:<h4 style={{color:'black'}}>NO
        </h4>}
              </h1>
            
              )
          }
          }, 

        {
        Header:'Status',
        accessor: 'status'
        },
        {
          Header:'Sponsor',
          accessor: 'sponsorName'
          },
             {
                  Header:'Doctor Name',
                  accessor: 'assignedDoctorName'
                  },
                  {
                      Header:'Source',
                      accessor: 'sourceType'
                      },
              {
                  Header:'Uploaded Date',
                  accessor: 'uploadedDate'
                  }
      ]  
     return (  
      
      <div className="container-fluid  overflow-auto ... w-full ... px-2">
   <Header> 
  
  </Header>
  <div className="flex flex-row pt-3">
    <div className='className="basis-1/3 pr-10'>
    
    <Input placeholder='Enter Name/Phone/Email'
    onChange={this.phoneOnchange}
    >
    </Input>

    </div>
        <div className="basis-1/3 pr-12">
        <Select
        value={selectedOptionDoctorId}
         options={this.state.docttorArray}
        placeholder='Select Doctor'
        onChange={this.handleChangeDoctorValue}
        
      />
      </div>
    <div className="basis-1/3 pr-20">
    <Select
        value={selectedOptionStatus}
        placeholder='Select Status'
        onChange={this.handleChangeStatus}
        options={status}
      />

    </div>
    <div className="basis-1/3 pr-20">
    <AsyncSelect
        placeholder='Select Sponsor'
        isClearable={true}
        loadOptions={this.promiseOptions}
        onChange={(option, cb) => this.onSponsorselect(option, cb)}
      />  

    </div>
    <div className="basis-1/3 pr-20">
    <Select
       // value={sourceValue}
        placeholder='Select Source'
        onChange={this.handleChangeSource}
        options={source}
      />

    </div>
    <div className="basis-1/3 pr-20">
    <DateRangePicker
              displayFormat={() => 'DD/MM/YYYY'}
              startDateId="startDate"
              endDateId="endDate"
              startDate={startDate}
              endDate={endDate}
              isOutsideRange={() => false}
              minimumNights={0}
              onDatesChange={this.onDatesChanged}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => this.setState({ focusedInput })}
            />

    </div>
    <div className="basis-1/3 pr-12">
      
    <PrimaryButton
                type="button"
                className="mt-2 float-left text-center align-self-end mr-5 bg-purple-500"
                onTap={this.filterData}
    >
   <Text fontSize="16px" color="white">
                  Filter Cases
                </Text>
  
</PrimaryButton>
    </div>
    <div className="basis-1/3">
      
    <PrimaryButton
                type="button"
                className="mt-2 float-left text-center align-self-end mr-5 bg-purple-500"
      onTap={this.showBulkTransferModal}
      disabled={remarkCasesSelected.size === 0}
      >
     <Text fontSize="16px" color="white">
                  Transfer Selected Cases
                </Text>
    
                </PrimaryButton>
      </div>
      <div>
  
      

</div>
<br>
  </br>
</div>
<div className="flex flex-row pt-3">
    <div className='basis-1/5'>
        <Text fontSize="16px" color="Black">
                 Total Count - {this.state.totalCount}
                </Text>
    </div>
    <div className='basis-1/7'> 
    <PrimaryButton onTap={this.downloadCsvFile}>
       <Text color="White">
       Download Csv
       </Text>
      </PrimaryButton>
    </div> 
                </div>
                
  <br></br>
        <SelectTable  
                   data={remarksCases}  
                   keyField="digitisationId" 
                   pages={this.state.pages}
                   columns={columns} 
                   rowSelection={true}
                   selectType="checkbox"
                   showPageJump={false}
                   manual
                   style={styleobj}
                   loading={this.state.loading}
                   showPaginationTop={true}
                  onFetchData={(state, instance) => {
                     page= state.page;
                     pageSize=state.pageSize;
                    this.setState({loading: true})
                    this.getData(page,pageSize)
                  }}
                  showPaginationBottom={false}
                   isSelected={this.isRemarksCaseSelected}
                toggleSelection={this.toggleRemarksCase}
                selectAll={remarkCasesSelected.size === remarksCases.length}
                toggleAll={this.toggleAllRemarksCases}
                getTrProps={this.getTrProps}
                   /> 
{transferModalShown && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>
                    {`Please select a doctor to transfer the Digitisation to`}
                  </HeaderText>
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body>
                <Select
        value={DoctorSelection}
         options={this.state.docttorArray}
        placeholder='Select Doctor'
        onChange={this.onDoctorSelected}
        
      />  
                  <ButtonComponent>
                    <Button
                      onTap={this.bulkTransferCases}
                      disabled={selectedTransferDoctorId === -1}
                      loading={this.state.transferLoading}
                    >
                      <Text fontSize="16px" color="white">
                        Transfer
                      </Text>
                    </Button>
                  </ButtonComponent>
                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
            <ToastContainer />      
      </div>

     ) 
  }  
 }   
 const mapStateToProps = (state) => ({
  user: state.user,
  authToken: state.user.authToken,
});

export default  connect(mapStateToProps)(Admin);