import React, { useState } from 'react';
import { BsCart3 } from 'react-icons/bs';
import { FaTimes, FaPlus } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';

const providerImageMapping = {
    '7': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/pharmeasy_logo.png',
    '1': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/apollo-logo.png',
    '5': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/1mg-logo.png',
    '9': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/wellness_logo.png'
}

const medicineCardImgStyles = {
    width: '40px',
    height: '40px',
    left: '124px',
    top: '425px',
    borderRadius: '50%',
    border: '1px solid lightgray'
};


const medicineListStyles = {
    maxWidth: '95%',

    gap: '1rem',
};


const medicineCardStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    padding: '0.5rem',
    borderRadius: '12px',
    margin: '1px',
    alignItems: 'center',
    border: '1px solid lightgray',

};


const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #4F34D2;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 2s linear infinite;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;



const MedicineList = (props) => {

    const medicines = props.medicines || [];
    const availableDrugs = props.availableDrugs || [];
    const [removedDrugIds, setRemovedDrugIds] = useState([]);
    const handleAddToCart = (medicine) => {
        props.handleClickOnAddToCart(medicine, removedDrugIds);
        setRemovedDrugIds([]);
    }
    return (
        <div style={medicineListStyles}>
            {props.loader && medicines.length<1 &&
                <LoaderWrapper>
                    <Loader className='' />
                </LoaderWrapper>
            }
            {(!props.loader && medicines.length < 1 ? <p>
                {props.searchedDrugName ? 'No matching medicines found.' : 'Please type to search medicines.'}
            </p> : <></>)}
            {medicines.map((medicine, index) => {
                return (
                    <div style={medicineCardStyles}>
                        <h2 style={{
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '130%',
                            textAlign: 'left',
                            letterSpacing: '0.25px',
                            textTransform: 'capitalize',
                        }}>{medicine.name}</h2>
                        <div style={{ background: 'white', width: '100%' }}>
                            {medicine.providers.map((provider, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        gap: '1rem',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                        borderBottom: '0.5px solid lightgray',
                                        background: 'white',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginRight: 0,
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            padding: '8px 16px 8px 12px',
                                            gap: '10px',
                                            color: 'purple',
                                            borderRadius: '5px'
                                        }}
                                    >
                                        <img src={providerImageMapping[provider.partnerId]} alt={provider.name} style={medicineCardImgStyles} />
                                        <div>
                                            <div style={{
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '12px',
                                                lineHeight: '130%',
                                                /* identical to box height, or 21px */
                                                textAlign: 'left',
                                                letterSpacing: '0.25px',
                                                textTransform: 'capitalize',
                                                color: removedDrugIds.includes(provider.drugId) ? '#767787' : '#0F0B28'
                                            }}>{provider.name.toUpperCase()}</div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                color: '#767787',
                                                alignItems: 'center',
                                                fontWeight: '600',
                                                lineHeight: '130%',

                                                letterSpacing: '0.25px'

                                            }}>{provider.packSize}{" "}{provider.packUnit.toUpperCase()}</div>
                                            {!availableDrugs.includes(provider.drugId) && <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                color: 'orange',
                                                alignItems: 'center',
                                                fontWeight: '600',
                                                lineHeight: '130%',
                                                letterSpacing: '0.25px'
                                            }}>{'Not Available'}</div>
                                            }
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <button
                                            style={{ fontWeight: 'bolder', fontSize: "12px" }}
                                            onClick={() => {
                                                setRemovedDrugIds(prevIds => removedDrugIds.includes(provider.drugId) ? prevIds.filter((id) => id != provider.drugId) : [...prevIds, provider.drugId]);
                                            }}
                                        >{
                                            removedDrugIds.includes(provider.drugId) ?
                                            <FaPlus style={{ color: removedDrugIds.includes(provider.drugId) ? '#ff754c' : '#767787' }} size={10}></FaPlus> :
                                            <FaTimes style={{ color: removedDrugIds.includes(provider.drugId) ? '#ff754c' : '#767787' }} size={10}></FaTimes>
                                        }
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '2px'
                                }}>
                                <button
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '8px 16px 8px 12px',
                                        gap: '10px',
                                        color: '#714FFF',
                                        borderRadius: '12px',
                                        border: '1px solid #714FFF',
                                        background: 'white',

                                    }}
                                    onClick={() => handleAddToCart(medicine)}
                                >
                                    <BsCart3></BsCart3> Add To Cart
                                </button>
                               
                            </div>
                        </div>

                    </div>
                );
            })}
        </div >
    );
};


export default MedicineList;
