import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Async as AsyncSelect } from 'react-select';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { RegularText, Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';
import TappableCard from '../common/TappableCard';
import greyTick from '../../images/common/greyTick.svg';
import purpleTick from '../../images/common/purpleTick.svg';
import { getConditionsSearch as getConditionsService } from '../../services';
import {
  showError,
  addConditions,
  changeEditableConditions,
  changeAnswerConditions,
  removeCondition,
} from '../../actions';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  height: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
  overflow: hidden;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 1;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const AddedTestsContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding: 0.625rem 1rem;
  margin-bottom: 1.25rem;
  background: white;
  /* border: 1px solid #000; */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const ConditionContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* flex: 1 0; */
  align-items: stretch;
  margin-bottom: 1.25rem;
`;

const HeaderRow = styled(StyledTappable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.825rem;
  padding-left: 1.25rem;
  border-radius: 0.825rem;
  box-shadow: ${(props) =>
    !props.isEditable ? '0 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1)' : 'none'};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.25rem;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  margin-right: 1.25rem;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1);
`;

const conditionImage = styled.img`
  height: 2rem;
  width: 2rem;
  margin-left: 0.4rem;
  margin-top: 0.4rem;
`;

const ConditionTitle = styled(BoldText)`
  font-size: 1.8rem;
`;

const QuestionTitle = styled(RegularText)`
  font-size: 1.6rem;
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.625rem;
  padding-left: 3.5rem;
`;

const OptionsBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  align-items: stretch;
`;

const OptionsGroups = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;
`;

const OptionContainer = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 1.25rem;
  background: #f4f4f4;
  box-shadow: 0 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  padding: 0.625rem 1rem;
  border: ${(props) => (props.answer ? '1px solid #4F34D2;' : '')};
`;

const OptionImage = styled.img`
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.625rem;
`;

const OptionText = styled(RegularText)`
  color: ${(props) => (props.answer ? '#4F34D2' : '#4c4c4c')};
  flex: 1;
`;

const SaveButton = styled(StyledTappable)`
  margin-right: 1.25rem;
  border: 1px solid #4f34d2;
  border-radius: 0.625rem;
  padding: 0.25rem 0.825rem;
`;

const DeleteButton = styled(StyledTappable)`
  margin-right: 1.25rem;
`;

const DeleteText = styled(ButtonText)`
  color: #b2b2b2;
`;

const SaveText = styled(ButtonText)`
  color: #4f34d2;
`;

const OptionTextContainer = styled.input`
  padding: 0.9rem 0;
  flex: 1 0;
  font-size: 1.4rem;
  border: none;
  border-bottom: 1px solid #cecece !important;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 16,
    textAlign: 'left',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 12,
    textAlign: 'left',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
};

class AddMedicalConditionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conditions: [],
      selectedConditions: [],
    };

    this.conditionsSelectRef = React.createRef();
  }

  getConditions = (input) => {
    return getConditionsService(
      this.props.authToken,
      input,
      this.props.transcriberAccountActive
    )
      .then((res) => {
        console.log({ res }, 'getConditions result');
        const result = res.data;
        result.forEach((condition) => {
          console.log({ condition });
          condition.label = condition.conditionName;
          condition.value = condition.conditionName;
        });
        this.setState({ conditions: result });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  promiseOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getConditions(input.toLowerCase()));
      }, 500);
    });
  };

  onConditionSelect = (option, action) => {
    console.log({
      option,
      action,
    });
    if (
      option &&
      !this.props.savedConditions.find(
        (condition) => condition.conditionId === option.conditionId
      ) &&
      !this.props.conditions.find(
        (condition) => condition.conditionId === option.conditionId
      )
    ) {
      option.isEditable = true;
      option.consultationId = this.props.consultationId;
      // this.setState({
      //   selectedConditions: [option, ...this.state.selectedConditions]
      // });
      this.props.dispatch(addConditions([option]));
    } else {
      this.conditionsSelectRef.current.state.defaultOptions = null;
      this.props.dispatch(
        showError(
          'Medical Condition Already Added',
          'Condition that you are trying to add is already added.'
        )
      );
      this.props.hideModal();
    }
  };

  finish = () => {
    console.log(
      { state: this.state, conditions: this.props.conditions },
      'finish'
    );
    this.props.onSubmit(this.props.conditions);
  };

  onOptionSelect = (option, optionGroup, question, condition, event) => {
    const conditionId = condition.conditionId;
    const questionId = question.questionId;
    const optionGroupType = optionGroup.type;
    const optionId = option.optionId;
    let answer = option.answer ? false : true;
    if (event) {
      answer = event.target.value;
    }
    this.props.dispatch(
      changeAnswerConditions(
        conditionId,
        questionId,
        optionGroupType,
        optionId,
        answer
      )
    );
  };

  setEditable = (condition) => {
    let isEditable = true;
    if (condition.isEditable) {
      isEditable = false;
    }
    console.log({ condition, isEditable }, 'changeEditableConditions');
    this.props.dispatch(
      changeEditableConditions(condition.conditionId, isEditable)
    );
  };

  deleteCondition = (condition) => {
    // this.setState({
    //   selectedConditions: this.state.selectedConditions.filter(
    //     condition => condition.conditionId !== condition.conditionId
    //   )
    // });
    this.props.dispatch(removeCondition(condition));
  };

  render() {
    const rendercondition = (condition, idx) => {
      console.log({ condition, idx });

      const renderQuestion = (question) => {
        console.log({ question });

        const renderOptionGroups = (optionGroup) => {
          switch (optionGroup.type) {
            case 'single': {
              return (
                <OptionsGroups key={optionGroup.type}>
                  {optionGroup.options.map((option) => (
                    <OptionContainer
                      key={option.optionId}
                      answer={option.answer}
                      onTap={() =>
                        this.onOptionSelect(
                          option,
                          optionGroup,
                          question,
                          condition
                        )
                      }
                    >
                      <OptionText answer={option.answer}>
                        {option.optionText}
                      </OptionText>
                    </OptionContainer>
                  ))}
                </OptionsGroups>
              );
            }
            case 'multiple': {
              return (
                <OptionsGroups key={optionGroup.type}>
                  {optionGroup.options.map((option) => (
                    <OptionContainer
                      key={option.optionId}
                      answer={option.answer}
                      onTap={() =>
                        this.onOptionSelect(
                          option,
                          optionGroup,
                          question,
                          condition
                        )
                      }
                    >
                      <OptionImage
                        src={option.answer ? purpleTick : greyTick}
                      />
                      <OptionText answer={option.answer}>
                        {option.optionText}
                      </OptionText>
                    </OptionContainer>
                  ))}
                </OptionsGroups>
              );
            }
            case 'text': {
              // donot use valuelink use normal text input
              console.log('text came', { optionGroup });
              return (
                <OptionsGroups key={optionGroup.type}>
                  {optionGroup.options.map((option) => (
                    <OptionTextContainer
                      key={option.optionId}
                      type="text"
                      placeholder="Enter details here"
                      value={option.answer}
                      onChange={(event) =>
                        this.onOptionSelect(
                          option,
                          optionGroup,
                          question,
                          condition,
                          event
                        )
                      }
                    />
                  ))}
                </OptionsGroups>
              );
            }
            default: {
              return;
            }
          }
        };

        return (
          <QuestionContainer key={question.questionId}>
            <TitleContainer>
              <QuestionTitle>{question.questionText}</QuestionTitle>
            </TitleContainer>
            <OptionsBody>
              {question.optionGroups &&
                question.optionGroups.map(renderOptionGroups)}
            </OptionsBody>
          </QuestionContainer>
        );
      };

      return (
        <ConditionContainer key={condition.conditionId}>
          <HeaderRow
            onTap={() => this.setEditable(condition)}
            isEditable={condition.isEditable}
          >
            <TitleContainer>
              <ConditionTitle>{condition.conditionName}</ConditionTitle>
            </TitleContainer>
            <ButtonsGroup>
              {/* {condition.isEditable ? (
                <SaveButton onTap={() => this.savecondition(condition)}>
                  <SaveText>Save</SaveText>
                </SaveButton>
              ) : (
                <SaveButton onTap={() => this.setEditable(condition)}>
                  <SaveText>Edit</SaveText>
                </SaveButton>
              )} */}
              <DeleteButton onTap={() => this.deleteCondition(condition)}>
                <DeleteText>Delete</DeleteText>
              </DeleteButton>
            </ButtonsGroup>
          </HeaderRow>
          {condition.isEditable &&
            condition.questions &&
            condition.questions.map(renderQuestion)}
        </ConditionContainer>
      );
    };

    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Add Medical Conditions</HeaderText>
              <ButtonsComponent>
                <ButtonComponent>
                  <Button onTap={() => this.finish()}>
                    <ButtonText>Save</ButtonText>
                  </Button>
                </ButtonComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <InputContainer>
                <AgeContainer>
                  <AsyncSelect
                    ref={this.conditionsSelectRef}
                    styles={customStyles}
                    // isMulti
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary50: '#d5d5d5',
                        primary25: '#f5f5f5',
                        primary: '#4F34D2',
                      },
                    })}
                    placeholder="Search Medical Conditions"
                    isClearable={true}
                    defaultOptions={this.state.conditions}
                    loadOptions={this.promiseOptions}
                    onChange={(option, action) =>
                      this.onConditionSelect(option, action)
                    }
                  />
                </AgeContainer>
              </InputContainer>
              <AddedTestsContainer>
                {this.props.conditions.map(rendercondition)}
              </AddedTestsContainer>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  conditions: state.conditions.conditions,
  savedConditions: state.conditions.savedConditions,
  consultationId: state.consults.activeConsult.consultationId,
  transcriberAccountActive: state.app.transcriberAccountActive,
});

export default connect(mapStateToProps)(AddMedicalConditionModal);
