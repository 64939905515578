import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MedicalHistory from '../../components/medical-history/MedicalHistory';
import sponsorIcon from '../../images/Building.svg';
import med from '../../images/Med.svg';
import editPen from '../../images/Edit-pen.svg';
import BmiModal from './BmiModal';
import { fetchUserBmi, getConsultationInfo, getDigitiseRequest } from '../../services';
import { setConsultationPatientInfo, setConsultationUserInfo } from '../../actions';
import { store } from '../../store';


const BmiContainer = styled.div`
  display: flex;
  flex: 1;
  /* width: 348px; */
  flex-direction: column;
  max-height: 156px;
  min-height: 156px;
  border-radius: 12px;
  background: rgba(240, 243, 246, 0.75);
  margin-top: 28px;
  margin-left: 24px;
  margin-right: 24px;
`;

const BmiHead = styled.p`
  color: #585969;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.36px;
  margin-top: 16px;
  margin-left: 16px;
`;

const BmiInfoContainer = styled.div`
  margin-left: 16px;
  margin-top: 9px;
  display: flex;
  gap: 5px;
`;

const SponsorContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: auto;
  gap: 2px;
  border-right: 1px solid #a5a6bb4d;
  opacity: 0.8001999855041504;
`;

const AgeContainer = styled.div`
  max-width: auto;
`;

const SponsorImg = styled.img``;

const SponsorText = styled.p`
  color: #767787;
  font-size: 9px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.21px;
  margin-right: 5px;
  overflow-wrap: break-word;
  word-break: break-word;
`;

const AgeText = styled.p`
  color: #767787;
  font-size: 9px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.21px;
`;

const BmiVarContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

const HeightContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  border-right: 1px solid #a5a6bb4d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const WeightContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BmiValueContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  border-left: 1px solid #a5a6bb4d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BmiHeadText = styled.p`
  color: #a5a6bb;
  font-size: 12px;
  font-weight: 500;
`;

const BmiText = styled.p`
  color: #a5a6bb;
  font-size: 16px;
  font-weight: 700;
`;

const MedicalHistoryIcon = styled.img``;

const MedicalHistoryText = styled.p`
  color: #585969;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
`;

const EditIcon = styled.img`
  height: 14px;
  width: 14px;
`;

const UserProfile = ({ authToken, activeConsult, transcriberAccountActive }) => {

  const [bmiModalOpen, setBmiModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [updateBmi, setUpdateBmi] = useState(false);

  const { user } = activeConsult;
  let userData;

  useEffect(() => {
    fetchUserData();
    getConsultation();
  },[ activeConsult, updateBmi ]);


  const handleBmiModal = () => {
    setBmiModalOpen(true);
  }

  const closeBmiModal = () => {
    setBmiModalOpen(false);
  }

  const handleUpdateBmi = () => {
    setUpdateBmi((prev) => !prev);
  }

  const fetchUserData = async () => {
    if (activeConsult.consultationId) {
      userData = await fetchUserBmi(authToken, user.userId, user.relativeId);
      console.log(userData, 'userBmi');
      setUserInfo(userData);
    }
  }

  const getConsultation = () => {
    if (activeConsult.consultationId) {
      getConsultationInfo(
        authToken,
        activeConsult.consultationId,
        user.doctorId
      )
        .then((res) => {
          console.log({ res, userData }, 'getCOnsultationInfo');
          store.dispatch(setConsultationUserInfo({...res.userInfo, weight: userData.weight || '', height : userData.height || ''}));
          store.dispatch(setConsultationPatientInfo(res.consultationInfo));
        })
        .catch((err) => {
          console.log({ err }, 'error in calling getCOnsultationInfo');
        });
    }
  };

  return (
    <>
      <BmiContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <BmiHead>{userInfo.name}</BmiHead>
          <div
            style={{
              height: '27px',
              width: '27px',
              background: '#FFF',
              marginTop: '15px',
              marginRight: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              borderRadius: '8px',
            }}
          >
            <EditIcon src={editPen} alt="edit-icon" onClick={() => handleBmiModal()}/>
          </div>
        </div>
        { bmiModalOpen && <BmiModal authToken = {authToken} activeConsult = {activeConsult} close = {() => closeBmiModal()} updateBmi = {handleUpdateBmi}/> }
        <BmiInfoContainer>
          <SponsorContainer>
            <SponsorImg src={sponsorIcon} alt="sponsor-icon" />
            <SponsorText>{userInfo.sponsorName ? userInfo.sponsorName : 'B2C'}</SponsorText>
          </SponsorContainer>
          <AgeContainer>
            <AgeText>{`${userInfo.gender}, ${userInfo.age}yr`}</AgeText>
          </AgeContainer>
        </BmiInfoContainer>
        <BmiVarContainer>
          <HeightContainer>
            <BmiHeadText>Height</BmiHeadText>
            <BmiText>{userInfo.height ? `${userInfo.height} cm` : '- -'}</BmiText>
          </HeightContainer>
          <WeightContainer>
            <BmiHeadText>Weight</BmiHeadText>
            <BmiText>{userInfo.weight ? `${userInfo.weight} kg` : '- -'}</BmiText>
          </WeightContainer>
          <BmiValueContainer>
            <BmiHeadText>BMI</BmiHeadText>
            <BmiText style={{ color: '#14AB3E' }}>{userInfo.bmi ? userInfo.bmi : '- -'}</BmiText>
          </BmiValueContainer>
        </BmiVarContainer>
      </BmiContainer>
      <div
        style={{
          display: 'flex',
          marginTop: '24px',
          marginLeft: '24px',
          gap: '4px',
        }}
      >
        <MedicalHistoryIcon src={med} alt="medical-history-icon" />
        <MedicalHistoryText>Medical History</MedicalHistoryText>
      </div>
      <MedicalHistory authToken = {authToken} activeConsult = {activeConsult}/>
    </>
  );
};

export default UserProfile;
