import React, { useEffect, useState } from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import CreatableSelect from 'react-select/lib/Creatable';
import add from '../../images/create.svg';
import { fetchOptions, saveAllergies, saveOptions } from '../../services';
import { showError } from '../../utils';

const OuterContainer = styled.div`
position: absolute;
width: 100%;
height: 100%;
background: rgba(15, 11, 40, 0.73);
z-index: 9999999;
top: 0;
left: 0;
`

const InnerContainer = styled.div`
position: absolute;
top: 50%;
left: 50%;
width: 335px;
height: auto;
border-radius: 14.799px;
background: #FFF;
box-shadow: 0px 0px 12.332610130310059px 0px rgba(0, 0, 0, 0.05);
padding: 20px;
transform: translate(-50%, -50%);
`

const HeadContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`

const Head = styled.p`
color: #302E6B;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 140%;
`

const Line = styled.div`
width: 295px;
height: 1px;
background: #F4F6F9;
margin-top: 20px;
`

const Label = styled.p`
color: #0F0B28;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 150%;
margin-top: 25px;
`

const SelectContainer = styled.div`
margin-top: 8px;

.css-bg1rzq-control {
    border-radius: 8px !important;
    background: #F0F3F6 !important;
}

.css-151xaom-placeholder {
    color: #A5A6BB !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 130% !important;
}

.css-bgvzuu-indicatorSeparator {
    display: none !important;
}

.css-1szy77t-control {
    border-radius: 8px !important;
    background: #F0F3F6 !important;
}

.css-1hwfws3 {
    color: #0F0B28 !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 130% !important;
}

.css-19bqh2r {
    color: #0F0B28 !important;
    height: 15px !important;
}

.css-11unzgr {
    max-height: 145px !important;
}
`

const AddButton = styled.div`
display: flex;
width: 295px;
padding: 12px;
justify-content: center;
align-items: center;
gap: 10px;
margin-top: 40px;
border-radius: 8px;
background: ${props => props.value ? '#714FFF' : '#E4E4E4'};
cursor: ${props => props.value ? 'pointer' : 'not-allowed'};
`

const AddText = styled.p`
color: ${props => props.value ? '#FFF' : '#A5A6BB'};
font-size: 13.8px;
font-style: normal;
font-weight: 600;
line-height: 150%;
`

const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
});

// const defaultOptions = [
//     createOption('One'),
//     createOption('Two'),
//     createOption('Three'),
//     createOption('Four'),
// ];

const CustomOption = (props) => {
    const { children, innerProps, label, data } = props;

    const pattern = /^Create ".*"$/;

    const createRenderer = () => {
        if (!pattern.test(label)) {
            return  <span>{label}</span>
        } else {
            return (
                <div style={{display: 'flex', gap: '8px'}}>
                    <span style={{color: '#714FFF'}}>{label.split('"')[1].trim()}</span>
                    <img src={add} alt='add-icon'/>
                </div>
            )
        }
    }
   
    return (
        <div
        {...innerProps}
        style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '150%',
        color: '#0F0B28',
        cursor: 'pointer',
      }}
       >
        {createRenderer()}
       </div>
    );
  };

const DrugAllergyModal = ({ authToken, close, activeConsult, saved }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [allergyOptions, setAllergyOptions] = useState({});
    const [optionAdded, setOptionAdded] = useState(false);

    const { user, consultationId } = activeConsult;

    useEffect(() => {
        try {
            if(allergyOptions.drugAllergies) {
                const drugAllergyOptions = allergyOptions.drugAllergies.map((allergy) => {
                    return createOption(allergy.name);
                })
        
                console.log(drugAllergyOptions, 'drug allergy options');
                setOptions(drugAllergyOptions);
            }
        }
        catch(err) {
            showError(err);
        };
    }, [inputValue, allergyOptions]);

    useEffect(() => {
        getAllergyOptions();
    }, [inputValue, optionAdded]);

    const handleCreate = async (inputValue) => {
        try {
            setIsLoading(true);
            const allergyObject = {
                allergy: inputValue,
                allergyType: 'drug'
            }
            await saveOptions(authToken, allergyObject);
            setOptionAdded((pre) => !pre);
            setTimeout(() => {
                const newOption = createOption(inputValue);
                setIsLoading(false);
                setOptions((prev) => [...prev, newOption]);
                setValue(newOption);
            }, 1000);
        }
        catch(err) {
            showError(err);
        }
    };

    const handleSubmit = async () => {
        if(value) {
            try {
                console.log(value)
                const allergyObject = {
                    allergy: value.label,
                    typeOfAllergy: 'drug'
                }
                await saveAllergies(authToken, user.userId, user.relativeId, allergyObject, consultationId);
                console.log('drug allergy saved');
                saved();
                close();
            }
            catch(err) {
                close();
                showError(err);
            }
        }
    }

    const getAllergyOptions = async () => {
        try {
            const optionsData = await fetchOptions(authToken, 'allergies', inputValue, 'drugAllergy');
            console.log(optionsData, 'allergyOptions');
            if (optionsData.message === 'success') {
                setAllergyOptions(optionsData);
            }
        }
        catch (err) {
            showError(err);
        }
    }

    console.log(allergyOptions, 'options drug allergy');
  

  return (
    <OuterContainer>
        <InnerContainer>
            <HeadContainer>
                <Head>Add Drug Allergy</Head>
                <FaTimes style={{height: '16px', width: '16px', cursor: 'pointer'}} onClick={close}/>
            </HeadContainer>
            <Line />
            <Label>Drug Allergy</Label>
            <SelectContainer>
                <CreatableSelect placeholder = 'Search drug allergy' isLoading={isLoading} isDisabled={isLoading} menuIsOpen = {menuIsOpen} onMenuOpen = {() => setMenuIsOpen(true)} onMenuClose = {() => setMenuIsOpen(false)} components={{Option: CustomOption}} onChange={(newValue) => setValue(newValue)} onCreateOption={handleCreate} options={options} value={value} inputValue={inputValue} onInputChange={(input) => setInputValue(input)}/>
            </SelectContainer>
            <AddButton menuIsOpen = {menuIsOpen} value = {value} onClick = {() => handleSubmit()}>
                <FaPlus style={ value ? {height: '14px', width: '16px', color: '#FFF'}: {height: '14px', width: '16px', color: '#A5A6BB'}}/>
                <AddText value = {value}>Add Drug Allergy</AddText>
            </AddButton>
        </InnerContainer>
    </OuterContainer>
  )
}

export default DrugAllergyModal