import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import responseIcon from '../../images/message-circle.svg';
import { FaChevronRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { fetchConsults, fetchPendingCallbacks } from '../../services';

const ResponseOuter = styled.div`
  width: 400px;
  height: 72px;
  background: #ffffff;
  box-shadow: 2px 2px 8px rgba(113, 79, 255, 0.21);
  border-radius: 20px;
  margin-top: 16px;
  margin-left: 20px;
  cursor: pointer;
`;

const InfoContainer = styled.div`
  position: relative;
  display: flex;
`;

const Img = styled.img`
  margin-top: 26px;
  margin-left: 26px;
`;

const Text = styled.p`
  margin-top: 26px;
  margin-left: 18px;
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #0f0b28;
`;

const Notify = styled.div`
position: absolute;
border-radius: 100%;
/* background-color: #FF0000; */
background-color: #F84F31;
box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.7);
left: 380px;
top: -5px;
width: 20px;
height: 20px;
`

const ResponseCard = (props) => {

  const [pendingCallbacksData, setPendingCallbacksData] = useState([]);
  const [consultationData, setConsultationData] = useState([]);
  const [selectedSponsorId, setSelectedSponsorId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [consultationIdKey, setConsultationIdKey] = useState(null);
  const [page, setPage] = useState(1);

  const filters = [{isSelected: true, name: 'Unreplied', verticalId: props.verticalId}];

  const activeConsult = useSelector((state) => state.consults.activeConsult);

  const updatedConsults = useSelector((state) => state.consults.consults);

  const getPendingCallbacks = async () => {
    try {
      const obj = {
        authToken: props.authToken,
        verticalId: props.verticalId,
      }
      const callBacksData = await fetchPendingCallbacks(obj);
      if(!callBacksData.results || callBacksData.results.length === 0) {
        return;
      } 
      setPendingCallbacksData(callBacksData.results);
    }
    catch (err) {
      console.log(err);
    }
  }

  const getAllConsults = async () => {
    try {
      const consultsData = await fetchConsults(props.authToken, filters, selectedSponsorId, searchText, consultationIdKey, page);
      if(!consultsData.responseBody.consultations) {
        return;
      }
      setConsultationData(consultsData.responseBody.consultations);
    }
    catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getPendingCallbacks();
    getAllConsults();
  },[updatedConsults]);

  return (
    <ResponseOuter onClick={props.switch}>
      <InfoContainer>
        <Img src={responseIcon} />
        <Text>Response Awaited</Text>
        <FaChevronRight
          style={{
            height: '12px',
            width: '12px',
            marginTop: '28px',
            marginLeft: '150px',
          }}
        />
        {((pendingCallbacksData && pendingCallbacksData.length > 0) || (consultationData && consultationData.length > 0)) && <Notify />}
      </InfoContainer>
    </ResponseOuter>
  );
};

export default ResponseCard;
