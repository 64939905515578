import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCheck, FaRegFilePdf } from 'react-icons/fa';

import { Text, RegularText } from '../../common/Text';
import downloadPdf from '../../../images/consultations/downloadPdf.svg';
import moment from 'moment';
import documentPdf from '../../../images/chats/documentPdf.svg';
import PrescriptionModal from '../PdfViewer';
import StyledTappable from '../../common/StyledTappable';
import { FlyInTransition } from '../../transitions/transitions';
import Modal from '../../../containers/common/Modal';

const OuterContainer = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: flex-end;
  margin: 0.4rem 0;
  margin-right: 12px;
`;

const BubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1rem 1.2rem;
  background: #4f34d2;
  border-radius: 12px 12px 12px 2px;
  border: 1px solid #f5f5f5;
  background: #f0f3f6;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #efedff;
  padding: 8px 12px;
  gap: 4px;
  word-break: break-word;
`;

const FileIcon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 0.8rem;
  color: #fff;
`;

const FileName = styled(Text)`
  flex: 1;
  font-size: 1.6rem;
  color: #714fff;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1.6rem;
`;

const TickIcon = styled(FaCheck)`
  color: #fff;
  opacity: 0.5;
  font-size: 1rem;
  margin-right: 0.4rem;
`;

const TimestampText = styled(RegularText)`
  /* color: #fff;
  opacity: 0.5;
  font-size: 1.2rem; */
  color: #a5a6bb;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 156%; /* 15.6px */
  letter-spacing: -0.165px;
`;

const ModalContainer = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;
const UserDocumentMessage = ({ fileName, url, timestamp }) => {
  const [prescriptionModal, setPrescriptionModal] = useState(false);
  const isPrescription = fileName.split('.')[0] === 'Prescription';
  return (
    <OuterContainer
      // onTap={() => document.querySelector('#document-anchor').click()}
      onTap={() => {
        if (isPrescription) setPrescriptionModal(true);
        else window.open(url);
      }}
    >
      {timestamp && (
        <TimestampContainer>
          <TimestampText>{moment(timestamp).format('HH:mm A')}</TimestampText>
        </TimestampContainer>
      )}
      <BubbleContainer>
        <MessageContainer>
          <FileIcon src={documentPdf} />
          <FileName>{fileName.split('.')[0]}</FileName>
        </MessageContainer>
      </BubbleContainer>
      <a
        href={url}
        target="_blank"
        id="document-anchor"
        style={{ display: 'none' }}
      />
      {isPrescription && prescriptionModal && (
        <Modal>
          <ModalContainer>
            <FlyInTransition
              in={prescriptionModal}
              mountOnEnter
              unmountOnExit
              appear
            >
              <PrescriptionModal
                url={url}
                close={() => {
                  setPrescriptionModal(false);
                }}
              />
            </FlyInTransition>
          </ModalContainer>
        </Modal>
      )}
    </OuterContainer>
  );
};

UserDocumentMessage.propTypes = {
  fileName: PropTypes.string,
  url: PropTypes.string,
  timestamp: PropTypes.number,
};

export default UserDocumentMessage;
