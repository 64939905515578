import React, { useState } from 'react';
import styled from 'styled-components';
import { FaPlus } from 'react-icons/fa';
import Card from './Card';
import infoIcon from '../../images/info.svg';
import { showError } from '../../utils';
import { deleteAllergies } from '../../services';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 16px;
  margin-top: 16px;
`;

const Head = styled.p`
  color: #0f0b28;
  font-size: 10.7px;
  font-weight: 700;
  line-height: 120%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InnerHead = styled.p`
  color: #0f0b28;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
`;

const InfoIcon = styled.img`
  margin-right: 25px;
  cursor: pointer;
`;

const Line = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 316px; */
  height: 1px;
  flex-shrink: 0;
  background: #E4E4E47F;
  margin-right: 16px;
  margin-top: 14px;
`;

const AddContainer = styled.div`
  display: flex;
  flex: 1;
  /* width: 316px; */
  max-height: 44px;
  padding: 12px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: rgba(207, 200, 255, 0.4);
  margin-top: 16px;
  margin-right: 16px;
  cursor: pointer;
`;

const AddText = styled.p`
  color: #714fff;
  font-size: 12.8px;
  font-weight: 600;
  line-height: 150%;
`;

const InfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  left: 84%;
`;

const BubbleContainer = styled.div`
  bottom: calc(100% + 10px);
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 2;
  margin-bottom: 10px;
  left: 100%;
`;

const Bubble = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f3f6;
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  max-width: 220px;
  width: 220px;
  pointer-events: auto;
  word-wrap: break-word;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 88%;
    transform: translateX(-50%);
    border-width: 8px 8px 0 8px;
    border-style: solid;
    border-color: #f0f3f6 transparent transparent transparent;
  }
`;

const BubbleText = styled.p`
  color: #0f0b28;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;

const PastAllergy = ({
  authToken,
  drugAllergy,
  foodAllergy,
  otherAllergy,
  open,
  activeConsult,
  deleted,
  dlogs,
  flogs,
  ologs,
}) => {
  console.log(drugAllergy, foodAllergy, otherAllergy, 'allergies');

  const [showDrugLogs, setShowDrugLogs] = useState(false);
  const [showFoodLogs, setShowFoodLogs] = useState(false);
  const [showOtherLogs, setShowOtherLogs] = useState(false);

  const { user, consultationId } = activeConsult;

  const handleDelete = async (id, allergy) => {
    if (id === 1) {
      try {
        await deleteAllergies(
          authToken,
          user.userId,
          user.relativeId,
          'drug',
          allergy,
          consultationId
        );
        console.log('drug allergy deleted');
        deleted();
      } catch (err) {
        showError(err);
      }
    } else if (id === 2) {
      try {
        await deleteAllergies(
          authToken,
          user.userId,
          user.relativeId,
          'food',
          allergy,
          consultationId
        );
        console.log('food allergy deleted');
        deleted();
      } catch (err) {
        showError(err);
      }
    } else if (id === 3) {
      try {
        await deleteAllergies(
          authToken,
          user.userId,
          user.relativeId,
          'others',
          allergy,
          consultationId
        );
        console.log('other allergy deleted');
        deleted();
      } catch (err) {
        showError(err);
      }
    }
  };

  const handleShowDrugLogs = () => {
    if(dlogs) {
      setShowDrugLogs((prev) => !prev);
    }
  };

  const handleShowFoodLogs = () => {
    if(flogs) {
      setShowFoodLogs((prev) => !prev);
    }
  };

  const handleShowOtherLogs = () => {
    if(ologs) {
      setShowOtherLogs((prev) => !prev);
    }
  };

  return (
    <Container>
      <Head>Past Allergies</Head>
      {drugAllergy && drugAllergy.length > 0 && (
        <InnerContainer>
          {showDrugLogs && (
            <InfoContainer>
              <BubbleContainer>
                <Bubble>
                  <BubbleText>{dlogs}</BubbleText>
                </Bubble>
              </BubbleContainer>
            </InfoContainer>
          )}
          <HeadContainer>
            <InnerHead>Drug Allergy:</InnerHead>
            <InfoIcon
              src={infoIcon}
              alt="info-icon"
              onClick={() => handleShowDrugLogs()}
            />
          </HeadContainer>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
            {drugAllergy.map((item, index) => {
              return (
                <Card
                  key={index}
                  item={item}
                  deleteAllergy={handleDelete}
                  type="allergy"
                />
              );
            })}
          </div>
          <Line />
        </InnerContainer>
      )}
      {foodAllergy && foodAllergy.length > 0 && (
        <InnerContainer>
          {showFoodLogs && (
            <InfoContainer>
              <BubbleContainer>
                <Bubble>
                  <BubbleText>{flogs}</BubbleText>
                </Bubble>
              </BubbleContainer>
            </InfoContainer>
          )}
          <HeadContainer>
            <InnerHead>Food Allergy:</InnerHead>
            <InfoIcon
              src={infoIcon}
              alt="info-icon"
              onClick={() => handleShowFoodLogs()}
            />
          </HeadContainer>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
            {foodAllergy.map((item, index) => {
              return (
                <Card
                  key={index}
                  item={item}
                  deleteAllergy={handleDelete}
                  type="allergy"
                />
              );
            })}
          </div>
          <Line />
        </InnerContainer>
      )}
      {otherAllergy && otherAllergy.length > 0 && (
        <InnerContainer>
          {showOtherLogs && (
            <InfoContainer>
              <BubbleContainer>
                <Bubble>
                  <BubbleText>{ologs}</BubbleText>
                </Bubble>
              </BubbleContainer>
            </InfoContainer>
          )}
          <HeadContainer>
            <InnerHead>Other Allergy:</InnerHead>
            <InfoIcon
              src={infoIcon}
              alt="info-icon"
              onClick={() => handleShowOtherLogs()}
            />
          </HeadContainer>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
            {otherAllergy.map((item, index) => {
              return (
                <Card
                  key={index}
                  item={item}
                  deleteAllergy={handleDelete}
                  type="allergy"
                />
              );
            })}
          </div>
          <Line />
        </InnerContainer>
      )}
      <AddContainer onClick={open}>
        <FaPlus style={{ color: '#714FFF', width: '12px', height: '12px' }} />
        <AddText>Add Allergies</AddText>
      </AddContainer>
      <Line />
    </Container>
  );
};

export default PastAllergy;
