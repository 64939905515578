import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/lib/Async';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { RegularText, Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';
import TappableCard from '../common/TappableCard';
import { fetchMedicines, fetchPincodeForDigitisation } from '../../services/';
import { store } from '../../store';
import { debounce } from '../../utils/debounce';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: center;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const RemoveIcon = styled(FaTimes)`
  font-size: 1.5rem;
  margin-top: 1.725rem;
  margin-right: 0.625rem;
`;

const ModalText = styled(RegularText)`
  font-size: 2rem;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
  border-bottom: 1px solid #979797 !important;
  padding: 0.33rem 0;
  margin: 0rem 0.625rem;
  /* width: ${(props) => (props.width ? props.width : 'auto')}; */
`;

const StyledInputBoxSmall = styled(Input)`
  /* flex: 1; */
  border-bottom: 1px solid #979797 !important;
  padding: 0.33rem 0;
  margin: 0rem 0.625rem;
  width: 8rem;
`;

const StyledTappableCard = styled(TappableCard)`
  align-self: flex-start;
  padding: 1rem 3rem;
  margin: 0rem 0.625rem;
  border: ${(props) => (props.isSelected ? '0.1rem solid #4F34D2' : 'null')};
`;

const TappableText = styled(Text)`
  font-size: 1.4rem;
  text-align: center;
  color: ${(props) => (props.isSelected ? '#4F34D2' : 'black')};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 1;
`;

const LabelText = styled(Text)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const SelectElement = styled(Select)`
  /* flex: 1; */
  width: 25rem;
  /* border: 0.1rem solid #4F34D2; */
`;

const DosageContainer = styled.div`
  display: flex;
  /* align-self: stretch; */
  flex-direction: row;
`;

// const dosageOptions = [
//   { value: '1-0-0', label: '1-0-0' },
//   { value: '0-1-0', label: '0-1-0' },
//   { value: '0-0-1', label: '0-0-1' },
//   { value: '1-0-1', label: '1-0-1' },
//   { value: '1-1-1', label: '1-1-1' },
//   { value: '1-1-1-1', label: '1-1-1-1' },
//   { value: '1-1-1-1-1', label: '1-1-1-1-1' },
//   { value: 'SOS', label: 'SOS' }
// ];

// const durationOptions = [
//   { value: 'Days', label: 'Days' },
//   { value: 'Weeks', label: 'Weeks' },
//   { value: 'Months', label: 'Months' }
// ];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 16,
    textAlign: 'left',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 12,
    textAlign: 'left',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   width: 200,
  // }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // }
};

class AddMedicinesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      medicineName: '',
      lastMedicines: [],
      genericName: '',
      notes: '',
      mealPref: '',
      durationValue: '',
      dosage: '',
      duration: props.prescriptions.durations[0],
      dimDrugId: '',
      coveredUnderOpd: true,
      otherDosage: '',
      prescribedQuantity: '',
      isSubstitute:false,
      addSubstitute:false,
      prescribedDrugId:'',
      prescribedMedNameInRx:'',
    };

    this.medicineSelectRef = React.createRef();
    this.dosageSelectRef = React.createRef();
    this.durationSelectRef = React.createRef();
    this.debounceSearch = debounce(
      (input, callback) => this.promiseOptions(input, callback),
      500
    );

    this.dosageOptions = [];
    this.durationOptions = [];
    const { dosages, durations } = props.prescriptions;
    dosages.map((dosage) => {
      this.dosageOptions.push({
        label: dosage,
        value: dosage,
      });
    });
    durations.map((duration) => {
      this.durationOptions.push({
        label: duration,
        value: duration,
      });
    });
  }

  clearFields = () => {
    this.setState({
      medicineName: '',
      genericName: '',
      notes: '',
      mealPref: '',
      durationValue: '',
      dosage: '',
      duration: this.props.prescriptions.durations[0],
      dimDrugId: '',
      isSubstitute:false,
      addSubstitute:false,
      prescribedDrugId:'',
      prescribedMedNameInRx:'',

    });
    console.log(this.dosageSelectRef.current, 'reference');
    // this.medicineSelectRef.current.state.defaultOptions = [{ label: 'dfd', value: 'fdfd' }];
    // setting the value of react-select component to null
    this.medicineSelectRef.current.select.state.value = null;
    this.dosageSelectRef.current.state.value = null;
    this.durationSelectRef.current.state.value = this.durationOptions[0];
  };

  removeMedicine = () => {
    this.setState({
      medicineName: '',
      genericName: '',
      dimDrugId: '',
    });
    console.log(this.medicineSelectRef.current, 'reference');
    // setting the value of react-select component to null
    this.medicineSelectRef.current.select.state.value = null;
    this.medicineSelectRef.current.state.defaultOptions = null;
  };

  selectMealPref = (mealPref) => {
    if (this.state.mealPref === mealPref) {
      this.setState({ mealPref: '' });
    } else {
      this.setState({ mealPref });
    }
  };

  onDosageSelect = (option) => {
    this.setState({
      dosage: option.value,
    });
  };

  onMedicineSelect = (option) => {
    if (option) {
      this.setState({
        medicineName: option.value ? option.value : '',
        genericName: option.name ? option.generic_name : '',
        dimDrugId: option.drugId,
      });
    } else {
      this.setState({
        medicineName: '',
        genericName: '',
        dimDrugId: '',
      });
      this.medicineSelectRef.current.state.defaultOptions = null;
    }
  };
  
  OnSubstituteMedSelect = (option)=>{
    if(option){
      this.setState({
        prescribedMedNameInRx:option.value ? option.value : '',
        prescribedDrugId: option.drugId,

      })
    }
    else{
      this.setState({
        prescribedMedNameInRxLink:'',
        prescribedDrugId:'',

      })
    }
  }

  onDurationSelect = (option) => {
    this.setState({
      duration: option.value,
    });
  };

  medicineLabel = (medicine)=>{
    const checkMedAvailability =  medicine.hasOwnProperty("isAvailable")
    let availabilityMsg = '';
    if(checkMedAvailability){
      availabilityMsg =  medicine.isAvailable ?'':'Not available please add substitute'
    }
    return(
      <div>  
            {medicine.name} <h3 className='float-right' style={{color:medicine.isAvailable?'green':'red'
            }}>{availabilityMsg}</h3> <br></br>{
              medicine.composition !== null ? medicine.composition + ', ' : '' 
            } {medicine.packSize} {medicine.packUnit}
            </div>
    )
  }

  getMedicines = (input, callback) => {
    const transcriberAccountActive =
      store.getState().app.transcriberAccountActive;
      const searchforSubstitute= this.state.isSubstitute?this.state.isSubstitute:false;
    const sourceType = store.getState().digitisation.sourceType;
    fetchMedicines(this.props.authToken, input.toLowerCase(),searchforSubstitute)
      .then((result) => {
        console.log({ result }, 'getMedicine result');
          result.forEach((medicine) => {
            medicine.label = this.medicineLabel(medicine)
            medicine.value = medicine.name;
          });
        // result.unshift({
        //   label: `Add Medicine: '${input}'`,
        //   value: input,
        // });
        this.setState({ lastMedicines: result });
        return callback(result);
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  promiseOptions = (input, callback) => this.getMedicines(input, callback);

  opdCheckBoxChange = (ev) => {
    this.setState({
      coveredUnderOpd: ev.currentTarget.checked,
    });
  };
  substituteChackBoxChange = (ev)=>{
    this.setState({isSubstitute:ev.currentTarget.checked,searchforSubstitute:true
    })
  }
  
  render() {
     const medicineNameLink = Link.state(this, "medicineName");
    const genericNameLink = Link.state(this, 'genericName');
    const notesLink = Link.state(this, 'notes');
    const durationValue = Link.state(this, 'durationValue');
    const prescribedQuantity = Link.state(this, 'prescribedQuantity');
    const otherDosage = Link.state(this, 'otherDosage');
    const prescribedMedNameInRxLink = Link.state(this,'prescribedMedNameInRx');
  

    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Add Medicine</HeaderText>
              <ButtonsComponent>
                <ButtonComponent>
                  <Button
                    onTap={() => {
                      this.props.addMedicine(this.state, true);
                      this.clearFields();
                    }}
                    disabled={((this.state.medicineName.length<=0 || this.state.dosage.length<=0 || (this.state.durationValue<=0 && !this.state.prescribedQuantity)))}
                    // loading={this.state.loading}
                  >
                    <ButtonText>Save</ButtonText>
                  </Button>
                </ButtonComponent>
                <ButtonComponent>
                  <Button
                    onTap={() => {
                      this.props.addMedicine(this.state);
                      this.clearFields();
                    }}
                    disabled={((this.state.medicineName.length<=0 || this.state.dosage.length<=0 || (this.state.durationValue<=0 && !this.state.prescribedQuantity)))}
                    // loading={this.state.loading}
                  
                  >
                    <ButtonText>Save & Add New</ButtonText>
                  </Button>
                </ButtonComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>
            <Body>
              <InputContainer>
                <AgeContainer>
                  <LabelText>Add Medicine</LabelText>
                  {this.props.digitisationDetails.sourceType!=='reimbursements'?
                  <div>
                  <DosageContainer> 
                <input
                  type="checkbox"
                  onChange={this.substituteChackBoxChange}
                  defaultChecked={this.state.isSubstitute}
                  value={''}
                  id="consentCheck"
                />
                <LabelText>Add Substitute Medicine</LabelText>
              </DosageContainer>
                  <AsyncCreatableSelect
                    // options={dosageOptions}
                    ref={this.medicineSelectRef}
                    styles={customStyles}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary50: '#d5d5d5',
                        primary25: '#f5f5f5',
                        primary: '#4F34D2',
                      },
                    })}
                    placeholder="Search Medicine"
                    // cacheOptions
                    isClearable={true}
                    defaultOptions={this.state.lastMedicines}
                    classNamePrefix="lp-copy-sel"
                    loadOptions={this.debounceSearch}
                    onChange={(option) => this.onMedicineSelect(option)}
                    // formatCreateLabel={(input) => `Add Medicine: '${input}'`}
                    formatCreateLabel={() => null}
                    promptTextCreator={() => false}
                   isDisabled={this.state.isSubstitute}
                   isOptionDisabled ={(option)=>option.isAvailable ===false? true:false}
                  />
                  </div> :(
                    <InputContainer>
                    <React.Fragment>
                      <AgeContainer>
                <StyledInputBox
                          type="text"
                          className="visit-input"
                           valueLink={medicineNameLink}
                          placeholder="Enter Prescribed Medicine Name"
                        />
                      </AgeContainer>
                    </React.Fragment>
    
                </InputContainer>
                  )
                  }
                </AgeContainer>
               
                {/* <CloseContainer onTap={() => this.removeMedicine()}>
                  <RemoveIcon />
                </CloseContainer> */}
              </InputContainer>
              {this.state.isSubstitute?
              ( <div className=''>
              <InputContainer>
              <AgeContainer>
                <LabelText>Prescribed Medicine(Medicine prescribed in the uploaded Rx)</LabelText>
                <AsyncCreatableSelect
                  // options={dosageOptions}
                  ref={this.medicineSelectRef}
                  styles={customStyles}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary50: '#d5d5d5',
                      primary25: '#f5f5f5',
                      primary: '#4F34D2',
                    },
                  })}
                  placeholder="Search Substitute Medicine"
                  // cacheOptions
                  isClearable={true}
                  defaultOptions={this.state.lastMedicines}
                  classNamePrefix="lp-copy-sel"
                  loadOptions={this.debounceSearch}
                  onChange={(option) => this.OnSubstituteMedSelect(option)}
                  // formatCreateLabel={(input) => `Add Medicine: '${input}'`}
                  formatCreateLabel={() => null}
                  promptTextCreator={() => false}
                />
              </AgeContainer>
              {/* <CloseContainer onTap={() => this.removeMedicine()}>
                <RemoveIcon />
              </CloseContainer> */}
              </InputContainer>
              <div className='flex'>
              <h4 className='text-left ml-4'>
                Can't find medicine in the list?
              </h4>
              <button className=''style={{color:'blue'}}
              onClick={()=>this.setState({addSubstitute:true})}
              >
                Add Prescribed Medicine
              </button>
              </div>
            </div>
            ) :('')
            }
            {this.state.addSubstitute?(

                <InputContainer>
                <React.Fragment>
                  <AgeContainer>
            <StyledInputBox
                      type="text"
                      className="visit-input"
                       valueLink={prescribedMedNameInRxLink}
                      placeholder="Enter Prescribed Medicine Name"
                    />
                  </AgeContainer>
                </React.Fragment>

            </InputContainer>

              ):('')}

              <InputContainer>
                <StyledInputBox
                  type="text"
                  className="visit-input"
                  valueLink={genericNameLink}
                  placeholder="Generic Name"
                />

                <StyledTappableCard
                  onTap={() => this.selectMealPref('before')}
                  isSelected={this.state.mealPref === 'before' ? true : false}
                >
                  <TappableText
                    isSelected={this.state.mealPref === 'before' ? true : false}
                  >
                    Before food
                  </TappableText>
                </StyledTappableCard>
                <StyledTappableCard
                  onTap={() => this.selectMealPref('after')}
                  isSelected={this.state.mealPref === 'after' ? true : false}
                >
                  <TappableText
                    isSelected={this.state.mealPref === 'after' ? true : false}
                  >
                    After food
                  </TappableText>
                </StyledTappableCard>
              </InputContainer>
              <InputContainer>
                <AgeContainer>
                  <LabelText>Dosage</LabelText>
                  <SelectElement
                    options={this.dosageOptions}
                    ref={this.dosageSelectRef}
                    styles={customStyles}
                    theme={(theme) => ({
                      ...theme,
                      // borderRadius: '0.25rem',
                      colors: {
                        ...theme.colors,
                        primary50: '#d5d5d5',
                        primary25: '#f5f5f5',
                        primary: '#4F34D2',
                      },
                    })}
                    placeholder="Select Dosage"
                    onChange={(option) => this.onDosageSelect(option)}
                  />
                </AgeContainer>
                <AgeContainer>
                  <LabelText>Duration</LabelText>
                  <DosageContainer>
                    <StyledInputBoxSmall
                      type="text"
                      className="visit-input"
                      valueLink={durationValue}
                      placeholder="0"
                    />
                    <SelectElement
                      options={this.durationOptions}
                      ref={this.durationSelectRef}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        // borderRadius: '0.25rem',
                        colors: {
                          ...theme.colors,
                          primary50: '#d5d5d5',
                          primary25: '#f5f5f5',
                          primary: '#4F34D2',
                        },
                      })}
                      defaultValue={this.durationOptions[0]}
                      placeholder="Days, Weeks, Months..."
                      onChange={(option) => this.onDurationSelect(option)}
                    />
                  </DosageContainer>
                </AgeContainer>
              </InputContainer>
              <InputContainer>
                {this.state.dosage === 'Other' ? (
                  <React.Fragment>
                    <AgeContainer>
                      <StyledInputBox
                        type="text"
                        className="visit-input"
                        valueLink={otherDosage}
                        placeholder="Dosage Description"
                      />
                    </AgeContainer>
                    <AgeContainer>
                      <StyledInputBox
                        type="number"
                        className="visit-input"
                        valueLink={prescribedQuantity}
                        placeholder="Prescribed Quantity"
                      />
                    </AgeContainer>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </InputContainer>
              <DosageContainer>
                <input
                  type="checkbox"
                  onChange={this.opdCheckBoxChange}
                  defaultChecked={this.state.coveredUnderOpd}
                  value={''}
                  id="consentCheck"
                />
                <LabelText>Covered Under OPD</LabelText>
              </DosageContainer>
              { !this.state.dosage || this.state.dosage !== 'Other' ?
                <InputContainer>
                <StyledInputBox
                  type="text"
                  className="visit-input"
                  valueLink={notesLink}
                  placeholder="Notes (optional)"
                />
              </InputContainer>
              :
              null}
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }

}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  prescriptions: state.info.prescriptions,
  digitisationDetails: state.digitisation,
});

export default connect(mapStateToProps)(AddMedicinesModal);
