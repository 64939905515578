// chatclient

import PubNub from 'pubnub';
import randomString from '../utils/random-string';
import { store } from '../store/index';
import {
  addChatMessage,
  setChatMessages,
  setDoctorTyping,
  updateConsults,
  setAppState,
  selectConsult,
  setPastChatMessages,
} from '../actions/index';
import config from '../config';

class ChatClient {
  constructor(pubnub) {
    this.pubnub = pubnub;
  }

  subscribe(channelId) {
    this.pubnub.subscribe({
      // channels: [channelId],
      channelGroups: [channelId],
      withPresence: true,
    });
    console.log('subscribed to channel', { channelId });
    this.messageListener = this.addMessageListener((payload) => {
      store.dispatch(addChatMessage(payload));
    });
    this.presenceListener = this.addPresenceListener(
      (action, channelName, occupany, state) => {
        if (action === 'state-change') {
          if (state.status === 'USER_TYPING') {
            store.dispatch(setDoctorTyping(true, state.patientId));
          } else if (state.status === 'USER_STOP_TYPING') {
            store.dispatch(setDoctorTyping(false, null));
          }
        }
      }
    );
    this.statusListener = this.addStatusListener((category, operation) => {
      const { consults } = store.getState();
      switch (category) {
        case 'PNNetworkDownCategory':
          // Dispatch an action to update the UI
          store.dispatch(setAppState('OFFLINE'));
          this.unsubscribe(consults.unrepliedChannel);
          break;
        case 'PNNetworkUpCategory':
          this.subscribe(consults.unrepliedChannel);
          store.dispatch(setAppState('ONLINE'));
          store.dispatch(updateConsults());
          store.dispatch(selectConsult(consults.activeConsult));
          break;
        default:
          break;
      }
    });
  }

  unsubscribe(channelId) {
    console.log(
      this.pubnub.unsubscribe({
        channels: [channelId],
      })
    );
    this.removeListener(this.messageListener);
    this.removeListener(this.presenceListener);
  }

  addMessageListener(fnc) {
    const listener = {
      message: (m) => {
        console.log({ m }, 'new message');
        const { user, chat, consults } = store.getState();
        const channelName = m.channel; // The channel for which the message belongs
        // The channel group or wildcard subscription match (if exists)
        const channelGroup = m.subscription;
        const pubTT = m.timetoken; // Publish timetoken
        const payload = m.message; // The Payload
        // Pass the payload to handler in case it is not from user, or it is an image or document payload
        const shouldParseMessage =
          payload.senderId.toString() !== user.doctorId.toString() ||
          payload.cardType === 'chatImage' ||
          payload.cardType === 'documentCard' ||
          payload.cardType === 'prescriptionCard' ||
          payload.cardType === 'treatmentPlanCard' ||
          payload.cardType === 'sp-suggest' ||
          payload.cardType === 'reschedule-card';
        console.log({ m, shouldParseMessage }, 'new message');

        if (shouldParseMessage) {
          if (window.newMsgAudioBuffer && window.audioContext) {
            const soundSource = window.audioContext.createBufferSource();
            soundSource.buffer = window.newMsgAudioBuffer;
            soundSource.connect(window.audioContext.destination);
            soundSource.start();
          }
          if (channelName === chat.chatChannel) {
            // Add message to redux store
            payload.timetoken = Number.parseFloat(m.timetoken) / 10000;
            fnc(payload, channelName, channelGroup, pubTT);
          } else {
            // new message arrived from different consultation, update consultation list
            store.dispatch(updateConsults());
          }
        }
      },
    };
    this.pubnub.addListener(listener);
    return listener;
  }

  addStatusListener(fnc) {
    // handle status
    this.pubnub.addListener({
      status: (s) => {
        const {
          category,
          operation,
          affectedChannels,
          subscribedChannels,
          affectedChannelGroups,
          lastTimetoken,
          currentTimetoken,
        } = s;

        fnc(
          category,
          operation,
          affectedChannels,
          subscribedChannels,
          affectedChannelGroups,
          lastTimetoken,
          currentTimetoken
        );
      },
    });
  }

  addPresenceListener(fnc) {
    this.pubnub.addListener({
      presence: (p) => {
        // handle presence
        const {
          action,
          channelName,
          occupancy,
          state,
          channelGroup,
          presenceEventTime,
          uuid,
        } = p;

        fnc(
          action,
          channelName,
          occupancy,
          state,
          channelGroup,
          presenceEventTime,
          uuid
        );
      },
    });
  }

  removeListener(fnc) {
    this.pubnub.removeListener(fnc);
  }

  setState(status, doctorId) {
    console.log({ status, doctorId }, 'setState method called');
    const { chat } = store.getState();
    const channel = chat.chatChannel;
    this.pubnub.setState(
      {
        channels: [channel],
        state: {
          doctorId,
          status,
        },
      },
      (status, response) => {
        if (status.error) {
          // Handle error
          console.log(status);
          return;
        }
        console.log({ response });
      }
    );
  }

  publish(message) {
    const { chat, consults } = store.getState();
    console.log(message);
    const channel = chat.chatChannel;
    const unrepliedChannel = consults.unrepliedChannel;
    const chatMessage = this.createChatMessage(message);
    store.dispatch(addChatMessage(chatMessage));

    console.log('Publishing message', { chatMessage });

    this.pubnub.publish(
      {
        message: chatMessage,
        channel,
      },
      (status, response) => {
        if (status.error) {
          // Handle error
          console.log(status);
          return;
        }
        const { chat } = store.getState();
        chat.chatMessages.forEach((ele) => {
          // console.log(ele, chatMessage);
          if (ele.messageId === chatMessage.messageId) {
            chatMessage.timetoken =
              Number.parseFloat(response.timetoken) / 10000;
          }
        });
        store.dispatch(setChatMessages(Array.from(chat.chatMessages)));
      }
    );
    this.pubnub.publish(
      {
        message: chatMessage,
        channel: unrepliedChannel,
      },
      (status, response) => {
        if (status.error) {
          // Handle error
          console.log(status);
          return;
        }
        console.log(
          { response, unrepliedChannel },
          'publishing on unreplied channel'
        );
      }
    );
    // Publish to doctor inbound channel
    // this.pubnub.publish({
    //   message: chatMessage,
    //   channel: `visit-inbound-doctor-${doctorId}`,
    // }, (status, response) => {
    //   if (status.error) {
    //     console.log('Doctor channel', status);
    //   }
    //   console.log('Doctor channel', response);
    // });

    return chatMessage;
  }

  createChatMessage(message) {
    const { user, consults } = store.getState();
    const chatMessage = {
      senderId: user.doctorId,
      consultationId: parseInt(consults.activeConsult.consultationId),
      name: `${user.doctorFirstName}`,
      messageId: randomString(20),
      platform: 'web-sdk',
      text: message.text || '',
      userType: 'doctor',
      notificationType: 'chat',
      type: 'chat',
      cardType: message.cardType || 'text',
      messageType: message.messageType,
      ...message,
    };

    // Add notification payload
    const basePayloadCopy = Object.assign({}, chatMessage);
    chatMessage.pn_apns = {
      aps: {
        ...basePayloadCopy,
        sound: 'default',
        alert: {
          title: basePayloadCopy.name,
          body: basePayloadCopy.text || 'New Message',
        },
        topic: 'com.getvisitapp.visit',
      },
    };

    chatMessage.pn_gcm = {
      data: basePayloadCopy,
    };

    return chatMessage;
  }

  // [1,2,3,4,5]

  // check if historical messages length < batch size then increase the channel index

  history(options) {
    const { chat } = store.getState();
    return new Promise((resolve, reject) => {
      this.pubnub.history(options, (status, res) => {
        if (!status.error) {
          const { chatMessages } = chat;
          const historicalMessages = res.messages;
          const formattedMessages = [];
          historicalMessages.forEach((message) => {
            formattedMessages.push({
              timetoken: message.timetoken / 10000,
              ...message.entry,
            });
          });
          console.log(formattedMessages);
          store.dispatch(
            setChatMessages(formattedMessages.concat(chatMessages))
          );
          resolve();
        } else {
          console.log(status);
          reject(status);
        }
      });
    });
  }

  getNextHistoryBatch(currentChannelId, oldestTimeToken) {
    const { chat } = store.getState();
    const options = {
      channel: currentChannelId,
      count: 30,
      start: oldestTimeToken,
    };
    console.log(options);
    return new Promise((resolve, reject) => {
      this.pubnub.history(options, (status, res) => {
        if (!status.error) {
          const { pastChatMessages } = chat;
          const historicalMessages = res.messages;
          const formattedMessages = [];
          historicalMessages.forEach((message) => {
            formattedMessages.push({
              timetoken: message.timetoken / 10000,
              ...message.entry,
            });
          });
          console.log(formattedMessages);
          store.dispatch(
            setPastChatMessages(formattedMessages.concat(pastChatMessages))
          );
          console.log(formattedMessages);
          // Resolve with the formatted messages
          resolve(formattedMessages);
        } else {
          console.log(status);
          reject(status);
        }
      });
    });
  }
}

let client;

const initializeChatClient = () => {
  if (client) {
    return client;
  } else {
    const { user } = store.getState();
    const pubnubParams = {
      subscribeKey: config.pubnubSubscribeKey,
      publishKey: config.pubnubPublishKey,
      authKey: user.doctorUuid,
      uuid: `browser::${user.doctorUuid}`,
      restore: true,
    };

    const pubnub = new PubNub(pubnubParams);

    client = new ChatClient(pubnub);
    return client;
  }
};

export { initializeChatClient };
