import app from './app';
import chat from './chat';
import user from './user';
import consults from './consults';
import info from './info';
import prescription from './prescription';
import savedPrescription from './savedPrescription';
import symptoms from './symptoms';
import conditions from './conditions';
import filters from './filters';
import videoFeedback from './videoFeedback';
import digitisation from './digitisation';
import digitisationPincode from './digitisationPincode';
import videoSession from './videoSession';
import consultation from './consultation';
import loginError from './loginError';

export default {
  app,
  chat,
  consults,
  user,
  info,
  prescription,
  savedPrescription,
  symptoms,
  conditions,
  filters,
  videoFeedback,
  digitisation,
  digitisationPincode,
  videoSession,
  consultation,
  loginError,
};
