import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import {
  fetchConsults,
  showFilterPane,
  toggleFilter,
  setSelectedSponsorId,
  setSelectedDoctorId,
  hideFilterPane,
  setSelectedSource,
  setFromDate,
  setToDate,
} from '../actions/index';
import StyledTappable from './common/StyledTappable';
import { FadeInTransition } from './transitions/transitions';
import { PrimaryButton } from './common/Buttons';

import selectedIcon from '../images/common/purpleTick.svg';
import unSelectedIcon from '../images/common/greyTick.svg';
import { BoldText, RegularText } from './common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex: 1.7 0;
  flex-direction: column;
  align-items: stretch;
  border-right: 1px solid #dfdfdf !important;

  &:focus {
    outline: none;
    border: none;
  }
  overflow-y: scroll;
`;

const HeaderBoldText = styled(BoldText)`
  margin: 2rem 0.625rem;
  font-size: 1.6rem;
`;

const FilterText = styled(RegularText)`
  margin: 2rem 0.625rem;
  font-size: 1.6rem;
`;

const SelectedFilterText = styled(RegularText)`
  margin: 2rem 0.625rem;
  font-size: 1.6rem;
  color: #4f34d2;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8rem;
  align-items: stretch;
  overflow-y: scroll;
`;

const Filters = styled(StyledTappable)`
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 1.25rem;
  /* align-items: flex-start; */
`;

const Icon = styled.img`
  width: 2rem;
  margin-right: 0.625rem;
`;

const SelectElement = styled.div`
  /* border: 0.1rem solid #4F34D2; */
  align-self: auto;
  max-width: 20rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  width: 6rem;
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 0rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 16,
    textAlign: 'left',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 12,
    textAlign: 'left',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
};

const SOURCE_FILTERS = [
  { label: 'uploaded-labs', value: 'uploaded-labs' },
  { label: 'offline-consultations', value: 'offline-consultations' },
  { label: 'reimbursements', value: 'reimbursements' },
  { label: 'uploaded-meds', value: 'uploaded-meds' },
  { label: 'cashless-vision', value: 'cashless-vision' },
];

const PARTNER_DOCTOR_SOURCE_FILTER = [
  { label: 'uploaded-labs', value: 'uploaded-labs' },
  { label: 'offline-consultations', value: 'offline-consultations' },
  { label: 'uploaded-meds', value: 'uploaded-meds' },
  { label: 'cashless-vision', value: 'cashless-vision' },
];

class FilterPane extends Component {
  // componentDidMount() {
  //   console.log('search doctor data ', this.props.Docfilter)
  // }

  constructor(props) {
    super(props);

    this.state = {
      // filters: [
      //   { name: 'All', isSelected: false },
      //   { name: 'Unattented', isSelected: false },
      //   { name: 'Ongoing', isSelected: false },
      //   { name: 'Followups', isSelected: false },
      //   { name: 'Completed', isSelected: false },
      // ],
      // selectedSponsorId: null,
      selectedSource: {
        label: props.selectedSource,
        value: props.selectedSource,
      },
    };
    this.containerRef = React.createRef();
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   if (this.props.selectedSource !== prevProps.selectedSource) {
  //     this.setState({
  //       selectedSource: {
  //         label: this.props.selectedSource,
  //         value: this.props.selectedSource,
  //       },
  //     });
  //   }
  // };

  componentDidMount = () => {
    this.props.sponsors.map((sponsor) => {
      sponsor.label = sponsor.sponsorName;
      sponsor.value = sponsor.sponsorId;
      return sponsor;
    });
    this.props.Docfilter.map((doc) => {
      doc.label = doc.doctorName;
      doc.value = doc.doctorId;
      return doc;
    });
    this.containerRef.current && this.containerRef.current.focus();
    console.log('search doctor data ', this.props.Docfilter);
  };

  setActiveFilter = (ele, index) => {
    const { dispatch } = this.props;
    dispatch(toggleFilter(index));
    // const filters = [...this.state.filters];
    // filters[index].isSelected = !filters[index].isSelected;
    // this.setState({
    //   filters,
    // })
  };

  hideFilters = () => {
    const { dispatch } = this.props;
    dispatch(hideFilterPane());
  };

  // setSelectedSource;
  onOptionSelectSource = (option) => {
    console.log({ option });
    const { dispatch } = this.props;
    this.setState({
      selectedSource: option,
    });
    dispatch(setSelectedSource(option.value));
    // this.setState({
    //   selectedSponsorId: option.sponsorId,
    // });
  };
  fromDateSelector = (e) => {
    const date = e.target.value;
    console.log({ date }, 'from date selector');
    const { dispatch } = this.props;
    dispatch(setFromDate(date));
  };
  toDateSelector = (e) => {
    const date = e.target.value;
    console.log({ date }, 'to date selector');
    const { dispatch } = this.props;
    dispatch(setToDate(date));
  };

  onOptionSelect = (option) => {
    console.log({ option });
    const { dispatch } = this.props;
    dispatch(setSelectedSponsorId(option.sponsorId));
    // this.setState({
    //   selectedSponsorId: option.sponsorId,
    // });
  };

  onOptionSelectDoctor = (option) => {
    console.log('selected doctor', { option });
    console.log(this.props.Docfilter, 'search doctor filter ');
    const { dispatch } = this.props;
    dispatch(setSelectedDoctorId(option.doctorId));
  };

  submitFilters = () => {
    const {
      dispatch,
      authToken,
      filters,
      selectedSponsorId,
      selectedDoctorId,
      fromDateSelector,
      toDateSelector,
    } = this.props;
    console.log(fromDateSelector, 'fromDateSelector');
    console.log(toDateSelector, 'toDateSelector');
    console.log(selectedSponsorId, 'sponsorID');
    // dispatch(showFilterPane());
    dispatch(
      fetchConsults({
        authToken,
        filters,
        selectedSponsorId,
        selectedDoctorId,
        fromDateSelector,
        toDateSelector,
      })
    );
  };

  render() {
    const renderFilter = (ele, index) => {
      let element = null;

      if (
        this.props.transcriberAccountActive &&
        ele.scope.indexOf('transcribers') === -1
      ) {
        return null;
      } else if (
        !this.props.transcriberAccountActive &&
        ele.scope.indexOf('doctors') === -1
      ) {
        return null;
      }

      if (ele.isSelected) {
        element = (
          <Filters
            key={ele.name}
            onTap={() => this.setActiveFilter(ele, index)}
          >
            <Icon src={selectedIcon} />
            <SelectedFilterText>{ele.name}</SelectedFilterText>
          </Filters>
        );
      } else {
        element = (
          <Filters
            key={ele.name}
            onTap={() => this.setActiveFilter(ele, index)}
          >
            <Icon src={unSelectedIcon} />
            <FilterText>{ele.name}</FilterText>
          </Filters>
        );
      }

      return element;
    };

    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer
          ref={this.containerRef}
          tabIndex="1"
          // onBlur={this.hideFilters}
        >
          <HeaderBoldText>Filter users</HeaderBoldText>
          <FiltersContainer>
            {this.props.filters.map(renderFilter)}
          </FiltersContainer>

          {this.props.transcriberAccountActive ? (
            <>
              <span
                style={{
                  fontSize: '1.6rem',
                  marginLeft: '0.625rem',
                  marginTop: '1rem',
                }}
              >
                Date Filter
              </span>
              <FilterText>From</FilterText>{' '}
              <input
                type="date"
                data-date-format="YYYY-MM-DD "
                onChange={(e) => {
                  this.fromDateSelector(e);
                }}
                style={{
                  alignSelf: 'auto',
                  maxWidth: '20rem',
                  marginLeft: '0.625rem',
                  marginRight: '0.625rem',
                  border: '0.1rem solid ',
                  padding: '15px',
                  borderRadius: '4px',
                }}
              />{' '}
              <FilterText>To</FilterText>
              <input
                type="date"
                data-date-format="YYYY-MM-DD "
                onChange={(e) => {
                  this.toDateSelector(e);
                }}
                style={{
                  alignSelf: 'auto',
                  maxWidth: '20rem',
                  marginLeft: '0.625rem',
                  marginRight: '0.625rem',
                  border: '0.1rem solid ',
                  padding: '15px',
                  borderRadius: '4px',
                }}
              />
              <FilterText>Source Filter</FilterText>
              <SelectElement>
                <Select
                  options={
                    this.props.isPartnerDoctor
                      ? PARTNER_DOCTOR_SOURCE_FILTER
                      : SOURCE_FILTERS
                  }
                  styles={customStyles}
                  theme={(theme) => ({
                    ...theme,
                    // borderRadius: '0.25rem',
                    colors: {
                      ...theme.colors,
                      primary50: '#d5d5d5',
                      primary25: '#f5f5f5',
                      primary: '#4F34D2',
                    },
                  })}
                  value={this.state.selectedSource}
                  onChange={(option) => this.onOptionSelectSource(option)}
                />
              </SelectElement>
            </>
          ) : null}

          {!this.props.isPartnerDoctor && (
            <>
              <FilterText>Sponsored Filters</FilterText>
              <SelectElement>
                <Select
                  options={this.props.sponsors}
                  styles={customStyles}
                  theme={(theme) => ({
                    ...theme,
                    // borderRadius: '0.25rem',
                    colors: {
                      ...theme.colors,
                      primary50: '#d5d5d5',
                      primary25: '#f5f5f5',
                      primary: '#4F34D2',
                    },
                  })}
                  // defaultValue={this.props.selectedSponsorId}
                  onChange={(option) => this.onOptionSelect(option)}
                />
              </SelectElement>{' '}
            </>
          )}

          {this.props.transcriberAccountActive &&
          !this.props.isPartnerDoctor ? (
            <>
              <FilterText>Doctor Filter</FilterText>
              <SelectElement>
                <Select
                  options={this.props.Docfilter}
                  styles={customStyles}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary50: '#d5d5d5',
                      primary25: '#f5f5f5',
                      primary: '#4F34D2',
                    },
                  })}
                  onChange={(option) => this.onOptionSelectDoctor(option)}
                />
              </SelectElement>
            </>
          ) : null}

          <ButtonComponent>
            <Button
              onTap={() => this.submitFilters()}
              loading={this.state.loading}
            >
              <ButtonText>Apply</ButtonText>
            </Button>
          </ButtonComponent>
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  sponsors: state.info.sponsors,
  Docfilter: state.info.filters,
  filters: state.filters.filters,
  selectedSponsorId: state.filters.selectedSponsorId,
  selectedDoctorId: state.filters.selectedDoctorId,
  transcriberAccountActive: state.app.transcriberAccountActive,
  selectedSource: state.filters.selectedSource,
  fromDateSelector: state.filters.fromDate,
  toDateSelector: state.filters.toDate,
  isPartnerDoctor: state.user.isPartnerDoctor,
  partnerSponsorId: state.user.partnerSponsorId,
});

export default connect(mapStateToProps)(FilterPane);
