import React from 'react';
import styled from 'styled-components';
import caller2 from '../images/chats/caller1.svg';
import caller1 from '../images/chats/caller2.svg';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2.5;
  position: relative;
  overflow: hidden;
  padding: 30px 24px 25px;
`;

const InnerContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    border-radius: 20px;
    background: linear-gradient(180deg, #F4F2FF 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 2px 2px 8px 0px rgba(113, 79, 255, 0.21);
    padding: 0px 0px 20px;
`;

const ImageContainer = styled.div`
display: flex;
`;

const ImageContainer1 = styled.div`
display: flex;
flex: 1;
align-items: center;
`;

const ImageContainer2 = styled.div`
display: flex;
flex: 1;
align-items: center;
`;

const Image1 = styled.img`
margin-bottom: 20px;
`;

const Image2 = styled.img`
margin-top: 20px;
`;

const TextContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-top: 82px;
`;

const Text1 = styled.div`
color: #714FFF;
text-align: right;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 156%; /* 31.2px */
letter-spacing: -0.165px;
`;

const Text2 = styled.div`
color: #A5A6BB;
text-align: right;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 156%; /* 24.96px */
letter-spacing: -0.165px;
`;

const NoActiveChat = () => {
  return (
    <OuterContainer>
        <InnerContainer>
            <ImageContainer>
                <ImageContainer1>
                    <Image1 src={caller1}/>
                </ImageContainer1>
                <ImageContainer2>
                    <Image2 src={caller2}/>
                </ImageContainer2>
            </ImageContainer>
            <TextContainer>
                <Text1>No Chat Open</Text1>
                <Text2>Select a patient to attend to</Text2>
            </TextContainer>
        </InnerContainer>
    </OuterContainer>
  )
}

export default NoActiveChat