import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import infoIcon from '../../images/info.svg';
import { deleteChronicAilments } from '../../services';
import { showError } from '../../utils';
import Card from './Card';

const Container = styled.div`
position: relative;
display: flex;
flex: 1;
flex-direction: column;
margin-left: 16px;
margin-top: 16px;
`

const Head = styled.p`
color: #0F0B28;
font-size: 10.7px;
font-weight: 700;
line-height: 120%;
`

const HeadContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`

const InnerContainer = styled.div`
display: flex;
flex-direction: column;
margin-top: 2px;
`

const InfoIcon = styled.img`
position: relative;
margin-right: 25px;
cursor: pointer;
width: 16px;
height: 16px;
`

const Line = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 316px; */
  height: 1px;
  flex-shrink: 0;
  background: #E4E4E47F;
  margin-top: 14px;
  margin-right: 16px;
`

const AddContainer = styled.div`
display: flex;
flex: 1;
/* width: 316px; */
max-height: 44px;
padding: 12px 6px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 12px;
background: rgba(207, 200, 255, 0.40);
margin-top: 16px;
margin-right: 16px;
cursor: pointer;
`

const AddText = styled.p`
color: #714FFF;
font-size: 12.8px;
font-weight: 600;
line-height: 150%;
`

const InfoContainer = styled.div`
position: relative;
display: flex;
flex-direction: column;
left: 84%;
`

const BubbleContainer = styled.div`
  bottom: calc(100% + 10px);
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 2;
  margin-bottom: 10px;
  left: 100%;
`

const Bubble = styled.div`
position: relative;
display: flex;
justify-content: center;
align-items: center;
  background-color: #F0F3F6;
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  max-width: 220px;
  width: 220px;
  pointer-events: auto;
  word-wrap: break-word;

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 88%;
    transform: translateX(-50%);
    border-width: 8px 8px 0 8px;
    border-style: solid;
    border-color: #F0F3F6 transparent transparent transparent;
  }
`

const BubbleText = styled.p`
color: #0F0B28;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 120%;
`

const ChronicAilment = ({authToken, chronicAilment, open, activeConsult, deleted, logs }) => {

  const [showLogs, setShowLogs] = useState(false);

  const { user, consultationId } = activeConsult;

  console.log(logs, 'chronic ailment logs');

  const handleDelete = async (ailment) => {
    try {
        await deleteChronicAilments(authToken, user.userId, user.relativeId, ailment, consultationId);
        console.log('chronic ailment deleted');
        deleted();
    }
    catch(err) {
        showError(err);
    }
  }

  const handleLogs = () => {
    if(logs) {
      setShowLogs((prev) => !prev);
    }
  }

  console.log(chronicAilment, 'chronicAilment');
  return (
    <Container>
        {
            showLogs && (
            <InfoContainer>
                <BubbleContainer>
                    <Bubble>
                        <BubbleText>{logs}</BubbleText>
                    </Bubble>
                </BubbleContainer>
            </InfoContainer>
            )
        }
        <HeadContainer>
            <Head>Chronic Ailment</Head>
            <InfoIcon src={infoIcon} alt="info icon" onClick={() => handleLogs()}/>
        </HeadContainer>
        <InnerContainer>
            <div style={{display: 'flex', flexWrap: 'wrap', gap: '12px'}}>
                {
                    chronicAilment.map((ailment, index) => {
                        return (
                            <Card key={index} item={ailment} deleteChronicAilment = {handleDelete} type = 'chronic-ailment'/>
                        )
                    })
                }
            </div>
        </InnerContainer>
        <AddContainer onClick={open}>
            <FaPlus style={{color: '#714FFF', height: '12px', width: '12px'}} />
            <AddText>Add Chronic Ailment</AddText>
        </AddContainer>
        <Line />
    </Container>
  )
}

export default ChronicAilment