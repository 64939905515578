import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fetchPastTreatments } from '../../services';
import { showError } from '../../utils';
import PastTreatmentsCard from './PastTreatmentsCard';
import noCases from '../../images/pana.svg';
import PrescriptionViewer from './PrescriptionViewer';

const OuterContainer = styled.div`
display: flex;
flex: 1;
/* width: 400px; */
height: auto;
flex-direction: column;
gap: 22px;
margin-top: 20px;
margin-left: 24px;
margin-right: 24px;
`

const NoCaseFoundContainer = styled.div`
display: flex;
height: auto;
background: #FFF;
justify-content: center;
align-items: center;
flex-direction: column;
`

const NoCases = styled.img`
margin-top: 100px;
`

const NoCasesText = styled.p`
color: #0F0B28;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 120%; 
margin-top: 30px;
margin-bottom: 100px;
`

const PastTreatments = ({ authToken, activeConsult }) => {

  const [pastTreatments, setPastTreatments] = useState([]);
  const [timeOut, setTimeOut] = useState(true);
  const [prescriptionViewer, setPrescriptionViewer] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState([]);

  const { consultationId } = activeConsult;

  useEffect(() => {
    getPastTreatments();
  }, [ consultationId ])

  const openPrescriptionViewer = () => {
    setPrescriptionViewer(true);
  }

  const closePrescriptionViewer = () => {
    setPrescriptionViewer(false);
  }

  const handlePrescriptionData = (data) => {
    setPrescriptionData(data);
  }

  const getPastTreatments = async () => {
    try {
        setTimeOut(true);
        const pastTreatmentsData = await fetchPastTreatments(authToken, consultationId);
        console.log(pastTreatmentsData, 'pastTreatmentsData');
        setPastTreatments(pastTreatmentsData.pastTreatments);
        setTimeOut(false);
    }
    catch (err) {
        showError(err);
    }
  }

  return (
    <OuterContainer>
        {
            pastTreatments && pastTreatments.length > 0 && (
                pastTreatments.map((treatment, index) => {
                    return (
                      <PastTreatmentsCard key={index} treatment={treatment} open = {() => openPrescriptionViewer()} prescriptionData = {handlePrescriptionData}/>
                    )
                })
            )
        }
        {
          !timeOut && (!pastTreatments || (pastTreatments && pastTreatments.length === 0)) && (
            <NoCaseFoundContainer>
              <NoCases src={noCases}/>
              <NoCasesText>No Case Found</NoCasesText>
            </NoCaseFoundContainer>
          )
        }
        {
          prescriptionViewer && (<PrescriptionViewer close = {() => closePrescriptionViewer()} data = {prescriptionData}/>)
        }
    </OuterContainer>
  )
}

export default PastTreatments