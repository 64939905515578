import React from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import freemium from '../../images/Freemium.svg';
import { markCallbackComplete } from '../../services';
import { showError } from '../../utils';

const ModalContainer = styled.div`
position: absolute;
width: 100%;
height: 100vh;
right: 0px;
top: 0;
  flex: 1;
  display: flex;
  background: rgba(12, 11, 56, 0.73);
  backdrop-filter: blur(2px);
  z-index: 100;
  cursor: default;
`

const ModalBody = styled.div`
position: absolute;
top: 300px;
left: 565px;
width: 300px;
height: 248px;
background: #FFFFFF;
box-shadow: 2px 2px 12px rgba(58, 44, 160, 0.12);
border-radius: 12px;
`

const Img = styled.img`
width: 70px;
height: 70px;
margin-top: 26px;
margin-left: 110px;
`

const Text = styled.p`
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 140%;
color: #302E6B;
`
const Button = styled.button`
box-sizing: border-box;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: auto;
gap: 10px;
width: 260px;
height: 40px;
background: #714FFF;
border-radius: 12px;
margin-top: 30px;
color: #FFFFFF;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 140%;
letter-spacing: 0.25px;
margin-left: 20px;
`

const CallbacksConfirmation = (props) => {

  const handleMarkCallback = async () => {
    // API call
    try {
        await markCallbackComplete(props.authToken, props.callBacksData.connectionRequestId);
        props.closeModal();
    }
    catch (err) {
        showError(err);
    }
  }  
  console.log(props, 'mark call backs');

  return (
    <ModalContainer>
        <ModalBody>
            <div style={{display: 'flex'}}>
            <Img src={freemium} alt='check'/>
            <FaTimes onClick={() => props.closeModal()} style={{height: '18px', width: '18px', marginTop: '22px', marginLeft: '75px', cursor: 'pointer'}}/>
            </div>
            <div style={{width: '230px', display: 'flex', textAlign: 'center',marginLeft: '32px', marginTop: '18px'}}>
            <Text>Do you want to mark this callback request as completed?</Text>
            </div>
            <Button onClick={() => handleMarkCallback()}>Mark as Completed</Button>
        </ModalBody>
    </ModalContainer>
  )
}

export default CallbacksConfirmation