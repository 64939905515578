import React from 'react';
import styled from 'styled-components';
import checked from '../../../images/Freemium.svg';
import Modal from '../../../containers/common/Modal';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  box-sizing: border-box;
  width: 270px;
  height: 235px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 12.3326px rgba(0, 0, 0, 0.05);
  border-radius: 14.7991px;
`;

const ModalImg = styled.img`
  height: 70px;
  width: 70px;
  margin-top: 26.37px;
`;

const ModalTextContainer = styled.div`
  height: 40px;
  width: 238px;
  margin-top: 18.37px;
`;

const ModalText = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 140%;
  text-align: center;
  color: #302e6b;
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 10px;
  width: 230px;
  height: 41px;
  background: #714fff;
  border-radius: 8px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 25px;
`;

const ReportedConfirmation = (props) => {
  console.log(props, 'yooo');
  return (
    <Modal>
      <ModalContainer>
        <ModalBody>
          <ModalImg src={checked} alt="checked" />
          <ModalTextContainer>
            <ModalText>
              Reported Emergency Successfully for this Case!
            </ModalText>
          </ModalTextContainer>
          <Button onClick={() => props.hideModal()}>Okay!</Button>
        </ModalBody>
      </ModalContainer>
    </Modal>
  );
};

export default ReportedConfirmation;
