import axios from 'axios';
import config from '../config';
import { showError } from '../utils';
import { store } from '../store';

export function fetchMedicines(authToken, input, isSubstitute) {
  const headers = {
    authorization: authToken,
  };

  const transcriberAccountActive = store.getState().app.transcriberAccountActive;
  let pincode = transcriberAccountActive ? store.getState().digitisation.pincode : null;
  let partnerCode = transcriberAccountActive ? store.getState().digitisation.partnerAssignedId : null;
  const digitisationId = transcriberAccountActive ? store.getState().digitisation.digitisationRequestId : null;


  const urlWithPartnerId = `${config.pharmaServer}/medicines/search?s=${input}${pincode !== null ? `&pc=${pincode}` : ''}${partnerCode !== null ? `&partner=${partnerCode}&digitisationId=${digitisationId} ` : ''}`;

  const urlWithoutPartnerId = `${config.pharmaServer}/medicines/search?s=${input}${pincode !== null ? `&pc=${pincode}` : ''}&isSubstitute=${isSubstitute}`
  const finalUrl = isSubstitute?urlWithoutPartnerId:urlWithPartnerId;
  console.log({ finalUrl }, 'url for fetchMedicines');

  return axios
    .get(finalUrl, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchMedicinesFromDimDrugs(authToken, input, partnerId) {
  const headers = {
    authorization: authToken,
  };
  const transcriberAccountActive = store.getState().app.transcriberAccountActive;
  const pincode = transcriberAccountActive ? store.getState().digitisation.pincode : null;
  const digitisationId = transcriberAccountActive ? store.getState().digitisation.digitisationRequestId : null;
  const urlWithPartnerId = `${config.pharmaServer}/medicines/search/v1/by/partnerId?s=${input}&pincode=${pincode}&digitisationId=${digitisationId}${partnerId !== null ? `&partnerId=${partnerId} ` : ''}`;

  console.log({ urlWithPartnerId }, 'url for fetchMedicines');

  return axios
    .get(urlWithPartnerId, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchMedicinesFromElasticSearch(authToken, input) {
  const transcriberAccountActive = store.getState().app.transcriberAccountActive;
  const digitisationId = transcriberAccountActive ? store.getState().digitisation.digitisationRequestId : null;
  const headers = {
    authorization: authToken,
  };

  const ELASTIC_SEARCH_DRUG_URL = `${config.pharmaServer}/medicines/search/v1?s=${input}&digitisationId=${digitisationId}`;
  const finalUrl = ELASTIC_SEARCH_DRUG_URL;
  console.log({ finalUrl }, 'url for fetchMedicines');

  return axios
    .get(finalUrl, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
export function addMedicine(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  let url = `${config.apiBaseUrl}/prescriptions/medicines/add`;

  const state = store.getState();
  if (state.app.transcriberAccountActive) {
    url = `${config.apiBaseUrl}/digitisations/requests/medicines/add`;
  } else {
    body.transcriber = false;
  }

  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteMedicine(
  authToken,
  medicineId,
  prescriptionId,
  digitisationId
) {
  const headers = {
    authorization: authToken,
  };

  const body = { medicineId };

  let url = `${config.apiBaseUrl}/prescriptions/${prescriptionId}/medicines/remove`;

  const state = store.getState();
  if (state.app.transcriberAccountActive) {
    body.digitisationId = digitisationId;
    url = `${config.apiBaseUrl}/digitisations/requests/medicines/remove`;
  } else {
    body.transcriber = false;
  }

  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

//diseases
export function getDiseases(authToken, searchQuery) {

  return axios
    .get(`${config.apiBaseUrl}/network/practice/search-disease`, {
      params: {
        q: searchQuery,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

//doctors
export function getDoctors(authToken, searchQuery) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/network/practice/doctors-doc`, {
      headers,
      params: {
        q: searchQuery,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

//Centers
export function getCenters(authToken, searchQuery) {
  const headers = {
    authorization: authToken,
  };
  const isDigitization = true

  return axios
    .get(`${config.apiBaseUrl}/network/practice/center-doc`, {
      headers,
      params: {
        q: searchQuery,
        isDigitization
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

// lab tests
export function getLabTests(authToken, searchQuery) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/all`, {
      headers,
      params: {
        s: searchQuery,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody.labs;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addLabTest(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  let url = `${config.apiBaseUrl}/prescriptions/labs/add`;

  const state = store.getState();
  if (state.app.transcriberAccountActive) {
    url = `${config.apiBaseUrl}/digitisations/requests/labs/add`;
  } else {
    body.transcriber = false;
  }

  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteLabTest(
  authToken,
  labId,
  prescriptionId,
  digitisationId
) {
  const headers = {
    authorization: authToken,
  };

  const body = { labId };

  let url = `${config.apiBaseUrl}/prescriptions/${prescriptionId}/labs/remove`;

  const state = store.getState();
  if (state.app.transcriberAccountActive) {
    url = `${config.apiBaseUrl}/digitisations/requests/labs/remove`;
    body.digitisationId = digitisationId;
  } else {
    body.transcriber = false;
  }

  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function previewPrescription(
  authToken,
  prescriptionId,
  diagnosis,
  icdCode,
  secondaryDiagnoses,
  secondaryIcdCodes,
  consultationId,
  doctorType,
  additionalNotes, 
  additionalNotesSecondary,
  weight,
  height
) {
  const headers = {
    authorization: authToken,
  };

  const url = `${config.apiBaseUrl}/prescriptions/preview`;

  return axios
    .post(
      url,
      { prescriptionId, diagnosis, secondaryDiagnoses, secondaryIcdCodes, consultationId, doctorType, icdCode, additionalNotes, additionalNotesSecondary, weight, height },
      { headers }
    )
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function sendPrescription(
  authToken,
  prescriptionId,
  diagnosis,
  icdCode,
  secondaryDiagnoses,
  secondaryIcdCodes,
  consultationId,
  doctorType,
  isTranscriberAccount,
  transcriberDoctorId,
  filesArray, 
  additionalNotes,
  additionalNotesSecondary,
  weight,
  height,
  userId,
  sponsorId
) {
  const headers = {
    authorization: authToken,
  };

  let url = `${config.apiBaseUrl}/prescriptions/send`;
  if (isTranscriberAccount) {
    url = `${config.apiBaseUrl}/prescriptions/send-digitized-rx`;
  }

  const body = {
    prescriptionId, diagnosis, consultationId, doctorType, icdCode, secondaryDiagnoses,
    secondaryIcdCodes, additionalNotes, additionalNotesSecondary, weight, height, userId, sponsorId
  };

  if (transcriberDoctorId) {
    body.transcriberDoctorId = transcriberDoctorId;
  }
  if (filesArray) {
    body.urls = filesArray;
  }

  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addAdvicePrescription(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  let url = `${config.apiBaseUrl}/prescriptions/advices/add`;

  const state = store.getState();
  if (state.app.transcriberAccountActive) {
    url = `${config.apiBaseUrl}/digitisations/requests/advices/add`;
  } else {
    body.transcriber = false;
  }

  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeAdvicePrescription(
  authToken,
  adviceId,
  prescriptionId,
  digitisationId
) {
  const headers = {
    authorization: authToken,
  };

  const body = {
    prescriptionId,
    adviceId,
  };

  let url = `${config.apiBaseUrl}/prescriptions/advices/remove`;

  const state = store.getState();
  if (state.app.transcriberAccountActive) {
    body.digitisationId = digitisationId;
    url = `${config.apiBaseUrl}/digitisations/requests/advices/remove`;
  } else {
    body.transcriber = false;
  }

  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

let labsAbortController = null;
export function getDigitisedLabs(authToken, digitisationId) {
  if (labsAbortController) {
    labsAbortController.abort();
  }
  labsAbortController = new AbortController();
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/requests/labs/get/${digitisationId}`,
      {
        headers,
        // signal: labsAbortController.signal
      }
    )
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      if (!axios.isCancel(err)) {
        showError(err);
        throw err;
      }
    });
}

let medicineAbortController = null;
export function getDigitisedMeds(authToken, digitisationId) {
  if (medicineAbortController) {
    medicineAbortController.abort();
  }
  medicineAbortController = new AbortController();
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/requests/medicines/get/${digitisationId}`,
      {
        headers,
        // signal: medicineAbortController.signal
      }
    )
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      if (!axios.isCancel(err)) {
        showError(err);
        throw err;
      }
    });
}

let adviceAbortController = null;
export function getDigitisedAdvices(authToken, digitisationId) {
  if (adviceAbortController) {
    adviceAbortController.abort();
  }
  adviceAbortController = new AbortController();
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/requests/advices/get/${digitisationId}`,
      {
        headers,
        // signal: adviceAbortController.signal
      }
    )
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      if (!axios.isCancel(err)) {
        showError(err);
        throw err;
      }
    });
}

let relativeAbortController = null;
export function getRelativesForDigitisation(authToken, digitisationId) {
  if (relativeAbortController) {
    relativeAbortController.abort();
  }
  relativeAbortController = new AbortController();
  
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/requests/relatives/${digitisationId}`,
      {
        headers,
        // signal: relativeAbortController.signal
      }
    )
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      if (!axios.isCancel(err)) {
        showError(err);
        throw err;
      }
    });
}

let pastConsultationAbortController = null;
export function getPastAppointments(authToken, digitisationId) {
  if (pastConsultationAbortController) {
    pastConsultationAbortController.abort();
  }
  pastConsultationAbortController = new AbortController();
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/past-consultations/${digitisationId}`,
      {
        headers,
        // signal: pastConsultationAbortController.signal
      }
    )
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      if (!axios.isCancel(err)) {
        showError(err);
        throw err;
      }
    });
}

export function markDigitised(
  authToken,
  diagnosis,
  icdCode,
  digitisationId,
  appointmentDate,
  prescriptionValidityDate,
  doctorName,
  doctorId,
  invalidReason,
  centerName,
  centerAddress,
  centerId,
  relativeId,
  consultationId,
  prescriptionId,
  isCritical,
  secondaryDiagnosis,
  secondaryDiagnosisIcdCode
) {
  const headers = {
    authorization: authToken,
  };

  const body = {
    diagnosis,
    digitisationId,
    appointmentDate,
    prescriptionValidityDate,
    doctorName,
    centerName,
    centerAddress,
    relativeId,
    consultationId,
    prescriptionId,
    isCritical,
    doctorId,
    centerId,
    icdCode,
    secondaryDiagnosis,
    secondaryDiagnosisIcdCode
  };

  if (invalidReason) {
    body.invalidReason = invalidReason;
  }

  return axios
    .post(`${config.apiBaseUrl}/digitisations/requests/mark-digitised`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getTemplate(authToken, params) {
  const headers = {
    authorization: authToken,
  };
  console.log({ params }, 'getTemplate');
  const url = `${config.apiBaseUrl}/prescriptions/prescription_templates`;
  return axios
    .get(url, { headers, params })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getTemplatesFromId(authToken, templateId) {
  const headers = {
    authorization: authToken,
  };
  const url = `${config.apiBaseUrl}/prescriptions/prescription_templates/id/${templateId}`;
  return axios
    .get(url, { headers })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addTemplate(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/prescriptions/prescription_templates/create`,
      body,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateTemplate(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .put(`${config.apiBaseUrl}/prescriptions/prescription_templates`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteTemplate(authToken, templateId) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .delete(
      `${config.apiBaseUrl}/prescriptions/prescription_templates/${templateId}`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export async function saveEditedMedicine(authToken, body) {
  const headers = {
    authorization: authToken,
  };
  const url = `${config.pharmaServer}/medicines/save/editedMedicine`;
  const response = await axios.put(url, body, { headers })
  return response.data;

}

export async function removeMedicine(authToken, body) {
  const headers = {
    authorization: authToken,
  };
  const url = `${config.pharmaServer}/medicines/remove`;
  const response = await axios.put(url, body, { headers })
  return response.data;
}

export async function removeMedicineGroupByMasterItemCode(authToken, body) {
  const headers = {
    authorization: authToken,
  };
  const url = `${config.pharmaServer}/medicines/remove/masterDrugGroup`;
  const response = await axios.put(url, body, { headers })
  return response.data;
}

export async function saveMedicineGroup(authToken, body) {

  const headers = {
    authorization: authToken,
  };
  const url = `${config.pharmaServer}/medicines/save/cartItems`;
  const response = await axios.put(url, body, { headers });
  return response.data;

}

export async function getMedicineCartItemsByDigitisationId(authToken, digitisationId) {

  const headers = {
    authorization: authToken,
  };

  
  const url = `${config.pharmaServer}/medicines/cartItems?digitisationId=${digitisationId}`;
  const response = await axios.get(url, { headers });
  return response.data;

}


export async function getServicablePartners(authToken) {
  const transcriberAccountActive = store.getState().app.transcriberAccountActive;
  const digitisationId = transcriberAccountActive ? store.getState().digitisation.digitisationRequestId : null;
  const headers = {
    authorization: authToken,
  };

  const url = `${config.pharmaServer}/medicines/visitDrug/serviceable/partners?digitisationId=${digitisationId}`;
  const response = await axios.get(url, { headers });
  return response.data;

}

export async function updateCriticalIllness(authToken, digitisationId , isCritical) {
  
  const headers = {
    authorization: authToken,
  };
  const body = {
    digitisationId , isCritical
  }

  const url = `${config.apiBaseUrl}/digitisations/update-critical-illness`;
  const response = await axios.put(url, body ,{ headers });
  return response && response.data;

}
