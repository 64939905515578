
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactTable from 'react-table';
import Select from 'react-select';
import selectTableHoc from 'react-table/lib/hoc/selectTable';
import "react-table/react-table.css";  
import styled from 'styled-components';
import Header from '../../components/common/AdminHeader';
import  {removeTranscriberAccount} from  '../../services/consults';
import {addTranscriberAccount} from '../../services/consults';
import {editTrnascriberDocAccount} from  '../../services/consults';
import {fetchAllDoctorForTranscriber} from   '../../services/consults';
import {fetchAllTrascriberDoctorList}  from '../../services/consults';
import { PrimaryButton } from '../../components/common/Buttons';
import { Async as AsyncSelect } from 'react-select';
import Modal from '../admin/AdminModal';
import { FaFileUpload, FaPowerOff, FaSignature, FaTimes,FaEdit,FaCheck, } from 'react-icons/fa';
import {ImCross} from 'react-icons/im';
import StyledTappable from '../../components/common/StyledTappable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
   width: 16rem; 
`;
const AddButton = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;
const source = [
    { value: 1, label: 'uploaded-labs' },
    { value: 2, label: 'uploaded-meds' },
    { value: 3,label: 'offline-consultations' },
    { value: 4, label: 'reimbursements' },
  ]
const styleobj = { 
    "font-size": "17px",
   "overflow": 'hidden',
   "background-color":"#F5EDDC",
   "textAlign":"center"
   }
   const Text = styled.p`
   margin: 0;
   font-family: Inter, Lato, sans-serif;
   color: ${(props) => props.color || 'black'};
   font-size: ${(props) => props.fontSize || '1.6rem'};
 `;

 const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;
const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;
const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;
const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;
const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;
let doctorName;
const EditIcon= styled(FaEdit)`
font-size: 2rem;
  font-weight: normal;`;
  const CheckIcon = styled(FaCheck)`
  color:green;
  `
  const Cross = styled(ImCross)`
  color:red;`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;
const HeaderText = styled.text`
  font-size: 2.4rem;
  margin: 14px 0;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;
class Digitiser extends Component {  

    constructor(props){
        super(props);
        this.state={
            transcriberDoctor:[],
            doctorArray:[],
            addDoctorTranscriberModal :false,
            openEditModal:false,
            doctorValue: null,
            doctorId:-1,
            uploadsLabs:0,
            uploadsMeds:0,
            offlineConsultations:0,
            reimbursements:0,
            EditButtonEnable : -1 
        }
        this.getData()

    }
     removeTranscriberDoctor(doctorId){

        return removeTranscriberAccount(doctorId,this.props.authToken).then((res)=>{
            this.getData().then(()=>{
                if(res.message === 'success'){
                    toast.success('Doctor is Removed.')
                }
                else{
                    toast.error('Error.')
                }
            })
            .catch((err) => {
                console.log({ err });
                return [];
    
              });

        })
        .catch((err) => {
            console.log({ err });
            return [];

          });
    }
    getAllDoctorList=(input)=>{
        return fetchAllDoctorForTranscriber(input.toLowerCase(),this.props.authToken)

        .then((result) => {
            console.log({ result }, 'getDoctors result');
            result.forEach((doc) => {
              doc.label = doc.name;
              doc.value = doc.doctorId;
            });
        
            return result;
          })
        .catch((err) => {
            console.log({ err });
            return [];

          });
    }
    removeDoctor = (doctorId) => () => {
        this.removeTranscriberDoctor(doctorId)
      };

    getData(){
        return fetchAllTrascriberDoctorList(this.props.authToken).then((res)=>{
            const transcriberDoctor =res.result;
            if(res.message ==='success'){
            this.setState({
                transcriberDoctor,
           })

            }
            else{
            toast.error('Error')
            }
        })
        .catch((err)=>{
            console.log({ err });
        })
    }

    hideModal = () => {
        this.setState({
            addDoctorTranscriberModal: false,
        });
    };
    hideEditModal = ()=> {
      this.setState({
        openEditModal: false,
    });
    }
    showAddDoctorAdmin = () => {
        this.setState({
            addDoctorTranscriberModal: true,
        });
        
    };
    showEditModal = (e) => () => {
      doctorName=e.name;
      this.setState({
        openEditModal: true,
        doctorId:e.doctorId
      });
      
  };

    onDocSelect =(option) =>{
        this.setState({
            doctorValue: {
              label: option.doctorName,
              value: option.doctorId,
            },
            doctorId:option.doctorId
          });
    }
    addTranscriberDoctor = () =>{
    return addTranscriberAccount(this.state.doctorId,
        this.state.uploadsLabs,
        this.state.uploadsMeds,
        this.state.offlineConsultations,
        this.state.reimbursements,
        this.props.authToken).then((result)=>{
        this.getData().then(()=>{
            if(result.message === 'success'){
             toast.success('Doctor Added.')
            this.setState({
                addDoctorTranscriberModal:false,
            })
            
        }
        else{
            toast.error('Error')
        }
        })
        .catch((err)=>{
            console.log({ err });
        })
        
    })
    .catch((err)=>{
        console.log({ err });
    })
 }
 EditTranscriberDoctor = () =>{
  return editTrnascriberDocAccount(this.state.doctorId,
      this.state.uploadsLabs,
      this.state.uploadsMeds,
      this.state.offlineConsultations,
      this.state.reimbursements,
      this.props.authToken).then((result)=>{
      this.getData().then(()=>{
          if(result.message === 'success'){
           toast.success('Details Updated.')
          this.setState({
            openEditModal: false,
          })
          
      }
      else{
          toast.error('Error')
      }
      })
      .catch((err)=>{
          console.log({ err });
      })
      
  })
  .catch((err)=>{
      console.log({ err });
  })
}

 surceValueChange=(e)=>{
    this.setState({EditButtonEnable:1})

    e.forEach((e) => {
        if(e.label==='uploaded-labs'){
            this.setState({
                uploadsLabs:1,
                
            })
        }
        if(e.label==='uploaded-meds'){
            this.setState({
                uploadsMeds:1,
               
            })
        } if(e.label==='offline-consultations'){
            this.setState({
                offlineConsultations:1,
               
            })
        } if(e.label==='reimbursements'){
            this.setState({
                reimbursements:1,
            })
        }
})

 }
    render(){
        const {transcriberDoctor,addDoctorTranscriberModal,doctorArray,doctorId,openEditModal} = this.state;

        const columns = [
            {  
              Header: 'DoctorId',  
              accessor: 'doctorId' ,
              }, 
            
            {
            Header:'DoctorName',
            accessor: 'name'
            },
            {
              Header:'uploaded-labs',
              Cell:(row) =>{
              return (
                row.original.uploadedLabs?<CheckIcon color='green'></CheckIcon>:<Cross></Cross>
              )
              
               },
            },
            {
                Header:'uploaded-meds',
                Cell:(row) =>{
                  return (
                    row.original.uploadedMeds?<CheckIcon></CheckIcon>:<Cross></Cross>
                  ) 
                },
                },
                {
                  Header:'offline-consultations',
                  Cell:(row) =>{
                    return (
                      row.original.offlineConsultation?<CheckIcon></CheckIcon>:<Cross></Cross>
                    )
                  },
                  },
                  {
                    Header:'reimbursements',
                    Cell:(row) =>{
                      return (
                        row.original.reimbursements?<CheckIcon></CheckIcon>:<Cross></Cross>
                      )
                    },
                    },
                    {
                      Header:'Action',
          
                      Cell: ( row ) => (
                        <ButtonComponent>
                         <Button onTap={this.removeDoctor(row.original.doctorId)}>
                         <Text fontSize="16px" color="white">
                        Remove Doctor
                      </Text>
                         </Button>
                         <CloseContainer onTap={this.showEditModal(row.original)} 
                         >
                        <EditIcon> </EditIcon>
                         </CloseContainer>
                         </ButtonComponent>
      
                        )
                  },
          ]  

        return(
      <div className="container-fluid  overflow-auto ... w-full ... px-2">
      <Header> 
  
      </Header>
      <div className="flex flex-row pt-3">
      <div className="basis-1/6">
      <PrimaryButton
                  type="button"
                  className="mt-2 mb-2 float-left text-center align-self-end "
        onTap={this.showAddDoctorAdmin}
        >
       <Text fontSize="16px" color="white">
                    Add Doctor
                  </Text>
      
                  </PrimaryButton>
        </div>
        </div>
      <ReactTable
     data={transcriberDoctor} 
     columns={columns} 
     style={styleobj}
     showPaginationTop={true}
     showPaginationBottom={false}
      
      />
{addDoctorTranscriberModal && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>
                    {`Please select a doctor`}
                  </HeaderText>
                  <HeaderText>
                    {`Please select a source`}
                  </HeaderText>
                  <ButtonsComponent>   
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body>
                <div className="flex flex-row pt-3">
                <div className="basis-1/2 pr-20">
                <AsyncSelect
         //defaultOptions={doctorArray}
        placeholder='Select Doctor'
        isClearable={true}
        loadOptions={this.getAllDoctorList}
        onChange={(option, cb) => this.onDocSelect(option, cb)}
      />  
                  <ButtonComponent>
                    <AddButton
                       onTap={this.addTranscriberDoctor}
                       disabled={this.state.doctorId === -1}
                    >
                      <Text fontSize="16px" color="white">
                        Add Doctor
                      </Text>
                    </AddButton>
                  </ButtonComponent>
                  </div>
                  <div className="basis-1/2">
                  <Select
    closeMenuOnSelect={false}
      isMulti
      className="basic-multi-select"
      classNamePrefix="select"
      options={source}
      onChange={this.surceValueChange}
    />
    </div>
                  </div>

                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
      
        {openEditModal && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>
                    {`Doctor Name : ${doctorName}`
                    
                    }
                  </HeaderText>
                  <ButtonsComponent>   
                    <CloseContainer onTap={this.hideEditModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body>
                {/* <div className="flex flex-row pt-3">
                <div className="basis-1/2 pr-20"> */}
                <Select
    closeMenuOnSelect={false}
      isMulti
      className="basic-multi-select"
      classNamePrefix="select Source"
      options={source}
      onChange={this.surceValueChange}
    />
                  <ButtonComponent>
                    <AddButton
                       onTap={this.EditTranscriberDoctor}
                       disabled={this.state.EditButtonEnable === -1}
                    >
                      <Text fontSize="16px" color="white">
                        Edit Doctor
                      </Text>
                    </AddButton>
                  </ButtonComponent>
                  {/* </div>
                  </div> */}

                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
          <ToastContainer /> 
      </div>
            
        )
    }
}
const mapStateToProps = (state) => ({
  user: state.user,
  authToken: state.user.authToken,
});
export default  connect(mapStateToProps)(Digitiser);