import React, { useState, useEffect, useRef } from 'react';
import Video from 'twilio-video';
import styled from 'styled-components';
import StyledTappable from '../common/StyledTappable';
import { Text } from '../common/Text';
import { docEndCall } from '../../services/consults';
import { useDispatch, useSelector } from 'react-redux';
import { resetVideoData, selectConsult, setChatInfo } from '../../actions';
import { FaTimes } from 'react-icons/fa';
import Draggable from 'react-draggable';
import waitingRoom from '../../images/VideoCall/wait.png';
import mute from '../../images/VideoCall/mute.svg';
import videoOff from '../../images/VideoCall/videoOff.svg';
import videoOn from '../../images/VideoCall/videoOn.svg';
import muteActive from '../../images/VideoCall/muteActive.svg';
import minimize from '../../images/VideoCall/minimize.svg';
import bar1 from '../../images/VideoCall/network1.svg';
import bar2 from '../../images/VideoCall/network2.svg';
import bar3 from '../../images/VideoCall/network3.svg';
import bar4 from '../../images/VideoCall/network4.svg';
import bar5 from '../../images/VideoCall/network5.svg';
import ConsultContainerMinimized from '../../containers/ConsultContainerMinimized';
import connectGif from '../../images/VideoCall/conn.gif';
import reconnectGif from '../../images/VideoCall/reconn.gif';


const OuterContainer = styled.div`
  display: flex;
  flex: 2.5 0;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  border: 1px solid #d8d8d8;
`

const ConnectionContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width: 100%;
background: rgba(47, 47, 47, 0.40);
backdrop-filter: blur(24.464536666870117px);
z-index: 10;
`

const ConnectingContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 271px;
height: 266px;
border-radius: 16px;
background: rgba(31, 31, 31, 0.70);
backdrop-filter: blur(24.464536666870117px);
padding: 24px;
`

const ConnectingGif = styled.img`
width: 120px;
height: 115px;
margin-top: 0px;
`

const ConnectingText = styled.p`
color: #FFF;
text-align: center;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 28px;
letter-spacing: 0.274px;
`

const BadnetworkContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 50px;
width: 50px;
border-radius: 50%;
background: #EA4335;
`

const TryButton = styled.button`
display: flex;
height: 40px;
width: 210px;
/* padding: 14px 90px; */
flex-direction: column;
align-items: center;
justify-content: center;
gap: 10px;
border-radius: 12px;
background: #FFF;
color: #404041;
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px;
margin-top: 20px;
`

const ControlsContainer = styled.div`
position: absolute;
bottom: 0;
width: 100%;
height: 120px;
display: flex;
align-items: center;
justify-content: center;
gap: 60px;
border-radius: 17.365px 17.365px 0px 0px;
background: rgba(55, 55, 55, 0.30);
backdrop-filter: blur(26.55217742919922px);
z-index: 10001;
`

const Controls = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 55px;
height: 55px;
border-radius: 50%;
/* background: rgba(255, 255, 255, 0.20); */
background: ${props => props.speaker || props.mute ? 'rgba(255, 255, 255, 0.90)' : 'rgba(255, 255, 255, 0.20)'};
background: ${props => props.endCall && '#F83D39'};
cursor: pointer;
`

const ControlsImg = styled.img`
width: 30px;
height: 30px;
`

const Minimize = styled.img`
position: absolute;
top: 25px;
right: 25px;
height: 55px;
width: 55px;
cursor: pointer;
`

const Maximize = styled.img`
position: absolute;
top: 15px;
right: 15px;
height: 35px;
width: 35px;
cursor: pointer;
z-index: 10000;
`

const NetworkBarContainer = styled.div`
position: absolute;
top: 100px;
right: 65px;
display: flex;
flex-direction: column;
gap: 2px;
`

const NetworkBar = styled.img`
width: 30px;
height: 30px;
`

const NetworkBarText = styled.p`
color: #FFF;
text-align: center;
font-size: 9px;
font-style: normal;
font-weight: 600;
line-height: 18px;
letter-spacing: 0.32px;
`

const RemoteParticipants = styled.video`
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transform: scaleX(-1);
`;

const LocalParticipants = styled.video`
height: 100%;
width: 100%;
border-radius: 8px;
object-fit: cover;
transform: scaleX(-1);
`;

const RemoteParticipantsImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

const DurationTimerContainer = styled.div`
position: absolute;
top: 50px;
left: 20px;
display: flex;
flex-direction: column;
width: 290px;
z-index: 10005;
`

const PatientName = styled.p`
color: #FFF;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 28px; 
letter-spacing: 0.274px;
`

const DurationTimer = styled.p`
color: #FFF;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.32px;
`

const VideoDisabledContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
min-width: 100%;
min-height: 100%;
background: rgba(31, 31, 31, 1);
border-radius: 8px;
`

const PatientInitials = styled.p`
color: #FFF;
font-size: 64px;
font-style: normal;
font-weight: 500;
line-height: 78.581px; 
letter-spacing: 0.77px;
`

const PoorConnectionContainer = styled.div`
position: absolute;
bottom: 140px;
left: 50%;
width: 380px;
height: 60px;
display: flex;
align-items: center;
justify-content: space-between;
border-radius: 12px;
background: rgba(55, 55, 55, 0.70);
backdrop-filter: blur(24.464536666870117px);
z-index: 10001;
padding: 20px;
transform: translate(-50%, 0);
`

const PoorConnectionTextContainer = styled.div`
display: flex;
flex-direction: column;
gap: 4px;
`

const PoorConnectionText = styled.p`
color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.274px;
margin-right: 50px;
`

const PipReconContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
min-width: 100%;
min-height: 100%;
background: rgba(31, 31, 31, 1);
border-radius: 8px;
`

const AudioMuted = styled.img`
position: absolute;
left: 50%;
bottom: 20%;
bottom: ${props => props.poorConn && !props.minimized ? '30%' : '20%'};
transform: translate(-50%, 0);
z-index: 1000;
`

const VideoRoom = ({
  roomName,
  token,
  userName,
  profileImg,
  doctorName,
  sessionId,
  authToken,
  history,
  handleFeedback,
  minimizeVideo,
}) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState(null);
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [videoRemoteTracks, setVideoRemoteTracks] = useState([]);
  const [audioRemoteTracks, setAudioRemoteTracks] = useState([]);
  const [muteVideo, setMuteVideo] = useState(true);
  const [muteAudio, setMuteAudio] = useState(true);
  const [userConnected, setUserConnected] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [networkQualityLocal, setNetworkQualityLocal] = useState(0);
  const [networkQualityRemote, setNetworkQualityRemote] = useState(0);
  const [connecting, setConnecting] = useState(true);
  const [badNetwork, setBadNetwork] = useState(false);
  const [reconnectingLocal, setReconnectingLocal] = useState(false);
  const [reconnectingRemote, setReconnectingRemote] = useState(false);
  const [localUserConnected, setLocalUserConnected] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [speaker, setSpeaker] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [paused, setPaused] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [localVideoEnabled, setLocalVideoEnabled] = useState(true);
  const [remoteVideoEnabled, setRemoteVideoEnabled] = useState(true);
  const [poorConnectionModal, setPoorConnectionModal] = useState(false);
  const [tryCount, setTryCount] = useState(0);
  const [remoteAudioEnabled, setRemoteAudioEnabled] = useState(true);
  let mins = min;
  let secs = sec;

  const videoRef = useRef();
  const audioRef = useRef();
  const videoRemoteRef = useRef();
  const audioRemoteRef = useRef();

  const activeConsult = useSelector((state) => state.consults.activeConsult);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  console.log(roomName, token, userName, profileImg);

  useEffect(() => {
    setTimeout(() => {
      setConnecting(false);
    },7000);
  },[]);

  useEffect(() => {
    if(networkQualityLocal > 1) {
      setLocalUserConnected(true);
      setBadNetwork(false);
    }

    if(!localUserConnected && networkQualityLocal <= 1) {
      setBadNetwork(true);
    }

    if(localUserConnected && networkQualityLocal <= 2) {
      setPoorConnectionModal(true);
    }

    if(localUserConnected && networkQualityLocal > 2) {
      setPoorConnectionModal(false);
    }

    if(userConnected && networkQualityRemote <= 0) {
      setPaused(true);
      setReconnectingRemote(true);
    }

    if(userConnected && networkQualityRemote > 0) {
      setPaused(false);
      setReconnectingRemote(false);
    }
  }, [networkQualityLocal, localUserConnected, userConnected, networkQualityRemote]);

  useEffect(() => {
    let timerInterval;

    if(!connecting && userConnected && localUserConnected) {
      if (!paused) {
        timerInterval = setInterval(() => {
          setSeconds((prevSeconds) => {
            if (prevSeconds === 59) {
              setMinutes((prevMinutes) => prevMinutes + 1);
              return 0;
            } else {
              return prevSeconds + 1;
            }
          });
        }, 1000);
      }
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [connecting, userConnected, paused, localUserConnected]);

  useEffect(() => {
    // disconnect call after 1 min (reconnecting) 
    let timer;
    if(reconnectingLocal) {
      timer = setTimeout(() => {
        dispatch(resetVideoData());
        dispatch(setChatInfo({
          consultationId: activeConsult.consultationId,
          chatChannel: activeConsult.channel,
          receiverId: user.doctorId,
        }));
      }, 42000);
    }
    return () => clearTimeout(timer);  
  }, [reconnectingLocal]);

  useEffect(() => {
    // disconnect call after 1 min (reconnecting) 
    let timer; 
    if(reconnectingRemote) {
      timer = setTimeout(() => {
        dispatch(resetVideoData());
        dispatch(setChatInfo({
          consultationId: activeConsult.consultationId,
          chatChannel: activeConsult.channel,
          receiverId: user.doctorId,
        }));
      }, 42000);
    }
    return () => clearTimeout(timer);
  }, [reconnectingRemote]);

  // Helper function to format time as "00:00"
  const formatTime = (value) => (value < 10 ? `0${value}` : value);

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);
  // const countUp = () => {
  //   console.log({ mins, secs });
  //   if (!mins && !secs) {
  //     setInterval(() => {
  //       if (secs >= 60) {
  //         mins = mins + 1;
  //         secs = 0;
  //         setMin(mins);
  //         setSec(0);
  //         console.log({ mins });
  //       } else {
  //         secs = secs + 1;
  //         setMin(mins);
  //         setSec(secs);
  //         console.log({ secs });
  //       }
  //     }, 1000);
  //   }
  // };

  // Connect Video call
  useEffect(() => {
    const monitorNetworkRemote = (networkQualityLevel) => {
      console.log(networkQualityLevel);
      setNetworkQualityRemote((prevNetworkQuality) => {
        return networkQualityLevel;
      });
    };

    const participantConnected = (participant) => {
      console.log(participant.sid);
      setParticipants(participant);
      roomRemoteParticipants(participant);
      setUserConnected(1);
      // countUp();
      console.log('hit');
      participant.on('networkQualityLevelChanged', monitorNetworkRemote);
    };

    const participantDisconnected = () => {
      console.log(1, minimized);
      setParticipants(null);
      setUserConnected(0);
      setMinimized(false);
    };

    const monitorNetworkLocal = (networkQualityLevel) => {
      console.log(networkQualityLevel);
      setNetworkQualityLocal((prevNetworkQuality) => {
        return networkQualityLevel;
      });
    };

    const reconnectingHandler = () => {
      console.log('hit');
      setPaused(true);
      setReconnectingLocal(true);
    };

    const reconnectedHandler = () => {
      console.log('hit');
      setPaused(false);
      setReconnectingLocal(false);
    };

    console.log(participants);

    Video.connect(token, {
      name: roomName,
      networkQuality: {
        local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
        remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
      }
    }).then((room) => {
      setRoom(room);
      roomParticipants(room.localParticipant);
      console.log({room});
      for(let [sid, participant] of room.participants.entries()){
        console.log({sid, participant});
        if(sid !== room.localParticipant.sid) {
          participantConnected(participant)
        }
      };
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.on('reconnecting', reconnectingHandler);
      room.on('reconnected', reconnectedHandler);
      room.localParticipant.setNetworkQualityConfiguration({
        local: 2,
        remote: 1
      });
      room.localParticipant.on('networkQualityLevelChanged', monitorNetworkLocal);
    });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);
  // local participants
  const roomParticipants = (participant) => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    const trackDisabled = (track) => {
      console.log(track.kind);
      if(track.kind === 'video') {
        setLocalVideoEnabled(false);
      }
    };

    const trackEnabled = (track) => {
      console.log(track.kind);
      if(track.kind === 'video') {
        setLocalVideoEnabled(true);
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);
    participant.on('trackDisabled', trackDisabled);
    participant.on('trackEnabled', trackEnabled);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  };

  useEffect(() => {
    const videoTrack = videoTracks[0];
    console.log(videoTrack);
    console.log(connecting, localUserConnected);
    if (videoTrack && !connecting && localUserConnected) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks, connecting, localUserConnected]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  // Remote Participants
  const roomRemoteParticipants = (participant) => {
    setVideoRemoteTracks(trackpubsToTracks(participant.videoTracks));
    setAudioRemoteTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoRemoteTracks((videoRemoteTracks) => [
          ...videoRemoteTracks,
          track,
        ]);
      } else if (track.kind === 'audio') {
        setAudioRemoteTracks((audioRemoteTracks) => [
          ...audioRemoteTracks,
          track,
        ]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoRemoteTracks((videoRemoteTracks) =>
          videoRemoteTracks.filter((v) => v !== track)
        );
      } else if (track.kind === 'audio') {
        setAudioRemoteTracks((audioRemoteTracks) =>
          audioRemoteTracks.filter((a) => a !== track)
        );
      }
    };

    const trackDisabled = (track) => {
      console.log(track.kind);
      if(track.kind === 'video') {
        setRemoteVideoEnabled(false);
      }
      if(track.kind === 'audio') {
        setRemoteAudioEnabled(false);
      }
    };

    const trackEnabled = (track) => {
      console.log(track.kind);
      if(track.kind === 'video') {
        setRemoteVideoEnabled(true);
      }
      if(track.kind === 'audio') {
        setRemoteAudioEnabled(true);
      }
    };

    const reconnectingHandler = () => {
      console.log('hit');
      setPaused(true);
      setReconnectingRemote(true);
    };

    const reconnectedHandler = () => {
      console.log('hit');
      setPaused(false);
      setReconnectingRemote(false);
    }

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);
    participant.on('trackDisabled', trackDisabled);
    participant.on('trackEnabled', trackEnabled);
    participant.on('reconnecting', reconnectingHandler);
    participant.on('reconnected', reconnectedHandler);

    return () => {
      setVideoRemoteTracks([]);
      setAudioRemoteTracks([]);
      participant.removeAllListeners();
    };
  };
  useEffect(() => {
    const videoTrack = videoRemoteTracks[0];
    console.log(videoTrack);
    if (videoTrack && !connecting && userConnected) {
      videoTrack.attach(videoRemoteRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoRemoteTracks, connecting, userConnected]);

  useEffect(() => {
    const audioTrack = audioRemoteTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRemoteRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioRemoteTracks]);

  // Handle video call
  const handleMuteVideo = () => {
    if(muteVideo){
      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable();
        setMuteVideo(false);
      });
    } else {
      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable();
        setMuteVideo(true);
      });
    }
  };

  const handleMuteAudio = () => {
    if (room) {
      if(muteAudio) {
        room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable();
          setMuteAudio(false);
        });
      } else {
        room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.enable();
          setMuteAudio(true);
        });
      }
    }
  };

  const handleDisconnectRoom = () => {
    if (room) {
      room.on('disconnected', (room) => {
        room.localParticipant.tracks.forEach((publication) => {
          const attachedElements = publication.track.detach();
          attachedElements.forEach((element) => element.remove());
        });
      });
      room.disconnect();
      // history.push('/home');
      setCallEnded(true);
      dispatch(resetVideoData());
      handleFeedback();
      dispatch(setChatInfo({
        consultationId: activeConsult.consultationId,
        chatChannel: activeConsult.channel,
        receiverId: user.doctorId,
      }));
    }
    dispatch(resetVideoData());
  };

  const handleTryAgain = () => {
    if(tryCount >= 3) {
      dispatch(resetVideoData());
    }
    setConnecting(true);
    setTryCount((prev) => prev + 1);
    setTimeout(() => {
      setConnecting(false);
    }, 7000);
  };

  const handleMinimize = () => {
    minimizeVideo();
    setMinimized(true);
    dispatch(setChatInfo({
      consultationId: activeConsult.consultationId,
      chatChannel: activeConsult.channel,
      receiverId: user.doctorId,
    }));
  };

  const handleMaximize = () => {
    setMinimized(false);
  }

  const extractFirstLetter = (input) => {
    return input.replace(/^(Dr\.?|Dr|Mr\.?|Mr|Mrs\.?|Mrs)\s*/i, '').charAt(0);
  }

  // const bounds = {
  //   left: -442,
  //   top: 0,
  //   right: 780, // Adjust as needed
  //   bottom: 500, // Adjust as needed
  // };

  console.log(localUserConnected, userConnected);
  console.log(minimized);
  return (
    <OuterContainer>
      {
        connecting && (
          <ConnectionContainer>
            <ConnectingContainer>
              <ConnectingGif src={connectGif}/>
              <ConnectingText>Connecting...</ConnectingText>
              <ConnectingText style={{fontSize: '14px', marginTop: '16px', lineHeight: '18px'}}>We are checking your connection</ConnectingText>
            </ConnectingContainer>
          </ConnectionContainer>
        )
      }
      {
        !connecting && badNetwork && (
          <ConnectionContainer>
            <ConnectingContainer style={{padding: '20px'}}>
              <BadnetworkContainer>
                <FaTimes style={{color: '#FFF', height: '25px', width: '25px'}}/>
              </BadnetworkContainer>
              <ConnectingText style={{marginTop: '30px'}}>Bad Network</ConnectingText>
              <ConnectingText style={{fontSize: '14px', marginTop: '20px', lineHeight: '18px'}}>Please check your network and try again</ConnectingText>
              <TryButton onClick={handleTryAgain}>Try Again</TryButton>
            </ConnectingContainer>
          </ConnectionContainer>
        )
      }
      {
        !connecting && !minimized && !badNetwork && (reconnectingLocal || reconnectingRemote) && (
          <ConnectionContainer>
            <ConnectingContainer>
              <ConnectingGif src = {reconnectGif} style={{height: '80px', width: '80px'}}/>
              <ConnectingText style={{marginTop: '30px'}}>Reconnecting...</ConnectingText>
              {
                reconnectingLocal && (<ConnectingText style={{fontSize: '14px', marginTop: '20px', lineHeight: '18px'}}>Check your connection or try moving for a better signal...</ConnectingText>)
              }
              {
                !reconnectingLocal && reconnectingRemote && (<ConnectingText style={{fontSize: '14px', marginTop: '20px', lineHeight: '18px'}}>There’s an issue with Patient’s network please wait till they fix it...</ConnectingText>)
              }
            </ConnectingContainer>
          </ConnectionContainer>
        )
      }
      {
        <Draggable defaultPosition={{x: 340, y: 320}}>
          <div style={!connecting && room && localUserConnected && !minimized ? {position: 'absolute', zIndex: '10008', borderRadius: '8px', height: '250px', width: '200px'} : {display: 'none'}}>
            <LocalParticipants ref={videoRef} height='100%' width='100%' autoPlay={true} style={!localVideoEnabled ? {display: 'none'} : {width: '100%'}}/> 
            {
              !localVideoEnabled && !minimized && (
                <VideoDisabledContainer>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#714FFF', borderRadius: '100%', padding: '43px', width: '100px', height: '100px'}}>
                    <PatientInitials>{extractFirstLetter(doctorName).toUpperCase()}</PatientInitials>
                  </div>
                </VideoDisabledContainer>
              ) 
            }
          </div>
        </Draggable>
      }
      {
        !connecting && (userConnected ? (
          <Draggable disabled={!connecting && !minimized} position = {!minimized && {x: 0, y: 0}}>
            <div style={!connecting && !minimized ? {minHeight: '100%', minWidth: '100%', objectFit: 'cover', display: (reconnectingLocal || reconnectingRemote || badNetwork) ? 'none' : 'block'} : {position: 'absolute', zIndex: '1000', borderRadius: '8px', height: '250px', width: '200px'}}>
              <div style={{minHeight: '100%', minWidth: '100%', display: 'flex', position: 'relative'}}>
                {
                  minimized && (<Maximize src={minimize} onClick={() => handleMaximize()}/>) 
                }
                {
                  !minimized && (
                    <DurationTimerContainer>
                      <PatientName>{userName}</PatientName>
                      <DurationTimer>{formatTime(minutes)}:{formatTime(seconds)}</DurationTimer>
                    </DurationTimerContainer>
                  )
                }
                <RemoteParticipants ref={videoRemoteRef} autoPlay={false} muted={false} height='100%' width='100%' style={(!remoteVideoEnabled || reconnectingLocal || reconnectingRemote) ? {display: 'none'} : {minWidth: '100%'}}/>
                {
                  minimized && (reconnectingLocal || reconnectingRemote) && (
                    <PipReconContainer>
                      <ConnectingGif src={reconnectGif} style={{height: '50px', width: '50px'}}/>
                    </PipReconContainer>
                  )
                }
                {
                  minimized && !remoteAudioEnabled && (
                    <AudioMuted src={mute} style={{height: '30px', width: '30px'}}/>
                  )
                }
                {
                  !remoteVideoEnabled && !reconnectingLocal && !reconnectingRemote && (
                    <VideoDisabledContainer>
                      <div style={!minimized ? {display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#714FFF', borderRadius: '100%', padding: '43px', width: '150px', height: '150px'} : {display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#714FFF', borderRadius: '100%', padding: '43px', width: '100px', height: '100px'}}>
                        <PatientInitials>{extractFirstLetter(userName).toUpperCase()}</PatientInitials>
                      </div>
                    </VideoDisabledContainer>
                  )
                }
              </div>
            </div>
          </Draggable>
        ) : (<RemoteParticipantsImg src={waitingRoom} style={(reconnectingLocal || reconnectingRemote || badNetwork || minimized) ? {display: 'none'} : {width: '100%'}}/>))
      }
      {
        !connecting && !callEnded && !minimized && userConnected && (
          <Minimize src={minimize} onClick={() => handleMinimize()}/>
        )
      }
      {
        !connecting && !callEnded && !minimized && (
          <>
            <NetworkBarContainer>
              {networkQualityLocal === 1 && (<NetworkBar src={bar1}/>)}
              {networkQualityLocal === 2 && (<NetworkBar src={bar2}/>)}
              {networkQualityLocal === 3 && (<NetworkBar src={bar3}/>)}
              {networkQualityLocal === 4 && (<NetworkBar src={bar4}/>)}
              {networkQualityLocal === 5 && (<NetworkBar src={bar5}/>)}
              {networkQualityLocal >= 1 && (<NetworkBarText>Doctor</NetworkBarText>)}
            </NetworkBarContainer>
            <NetworkBarContainer style={{right: '15px'}}>
              {networkQualityRemote === 1 && (<NetworkBar src={bar1}/>)}
              {networkQualityRemote === 2 && (<NetworkBar src={bar2}/>)}
              {networkQualityRemote === 3 && (<NetworkBar src={bar3}/>)}
              {networkQualityRemote === 4 && (<NetworkBar src={bar4}/>)}
              {networkQualityRemote === 5 && (<NetworkBar src={bar5}/>)}
              {networkQualityRemote >= 1 && (<NetworkBarText>Patient</NetworkBarText>)}
            </NetworkBarContainer>
          </>
        )
      }
      {
        callEnded && (
          <ConnectionContainer>
            <ConnectingContainer style={{padding: '0px'}}>
              <ConnectingGif src='https://tinyurl.com/tc844ayc' style={{height: '210px', width: '210px', marginTop: '-15px'}}/>
              <ConnectingText style={{marginTop: '-35px'}}>Call Ended</ConnectingText>
              <ConnectingText style={{fontSize: '12px', marginTop: '15px'}}>Duration: 05:08</ConnectingText>
            </ConnectingContainer>
          </ConnectionContainer>
        )
      }
      {
        !connecting && !minimized && localUserConnected && poorConnectionModal && !reconnectingLocal && !reconnectingRemote && (
          <PoorConnectionContainer>
            <NetworkBar src={bar1}/>
            <PoorConnectionTextContainer>
              <PoorConnectionText>Poor Connection</PoorConnectionText>
              <PoorConnectionText style={{fontSize: '12px', fontWeight: '400'}}>Try moving to get better signal</PoorConnectionText>
            </PoorConnectionTextContainer>
            <FaTimes style={{height: '24px', width: '24px', color: '#FFF'}} onClick={() => setPoorConnectionModal(false)}/>
          </PoorConnectionContainer>
        )
      }
      {
        !connecting && !minimized && ( 
          <ControlsContainer>
            <Controls onClick={() => handleMuteVideo()} speaker = {!muteVideo}>
              {
                muteVideo ? (<ControlsImg src={videoOff} />) : (<ControlsImg src={videoOn} />)
              }
            </Controls>
            <Controls onClick={() => handleMuteAudio()} mute = {!muteAudio}>
              {
                muteAudio ? (<ControlsImg src={mute} />) : (<ControlsImg src={muteActive} />)
              }
            </Controls>
            <Controls endCall={true} onClick={() => handleDisconnectRoom()}>
              <FaTimes style={{height: '30px', width: '30px', color: '#FFF'}}/>
            </Controls>
          </ControlsContainer>
        )
      }
      {
        !connecting && !minimized && userConnected && !remoteAudioEnabled && !badNetwork && !reconnectingLocal && !reconnectingRemote && (
          <AudioMuted src={mute} poorConn = {poorConnectionModal} minimized = {minimized}/>
        )
      }
      {
        minimized && (<ConsultContainerMinimized />) 
      }
    </OuterContainer>
  );
};

export default VideoRoom;
