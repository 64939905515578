import React from 'react';
import styled from 'styled-components';
import { RegularText, Text } from './common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding: 1.2rem;
  background: ${(props) => (props.active ? '#B9C8FC' : 'white')};
  border-bottom: 1px solid #dfdfdf;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const TitleText = styled(Text)`
  font-size: 1.6rem;
`;
const Badge = styled.span`
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-color: ${props => props.backgroundColor || '#6c757d'};
  color: ${props => props.textColor || '#fff'};
`;
const DateText = styled(Text)`
  font-size: 1.2rem;
  opacity: 0.3;
`;

const TimeText = styled(Text)`
  padding-top: 0.25rem;
  font-size: 1.2rem;
  color: #d72323;
`;

const TagsText = styled(RegularText)`
  font-size: 1.4rem;
`;

const LabelsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => (props.color ? props.color : '#4F34D2')};
  margin: 0 0.25rem;
  margin-bottom: 0.25rem;
  border-radius: 0.4rem;
  padding: 0.4rem 0.8rem;
`;

const LabelText = styled(Text)`
  font-size: 1.2rem;
  color: white;
`;

const UnreadMessageCountBadge = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  background-color: green;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  userName,
  issues,
  labels,
  active,
  timeText,
  dateText,
  unreadMessageCount,
  isSuspicious,
  isVipUser,
  isReviewCase
}) => {
  const renderLabel = (ele, idx) => (
    <LabelContainer key={idx} color={ele.color}>
      <LabelText>{ele.text}</LabelText>
    </LabelContainer>
  );
  return (
    <OuterContainer active={active}>
      <TitleContainer>
        <TitleText>{userName}</TitleText>
        <RightContainer>
          {isReviewCase ?
            <Badge
              backgroundColor="#335fff"
            >
              {'Review Case'}
            </Badge>:('')
          }
          {isSuspicious?
        <Badge
        backgroundColor="#dc3545"
            >
              {'Suspicious user'}
            </Badge>
 :('')}
  {isVipUser?
        <Badge className='mt-1'
        backgroundColor="#198754"
            >
              {'VIP'}
            </Badge>
 :('')}
          <DateText>{dateText}</DateText>
          {timeText ? <TimeText>{timeText}</TimeText> : null}
          {unreadMessageCount > 0 ? (
            <UnreadMessageCountBadge>
              {unreadMessageCount}
            </UnreadMessageCountBadge>
          ) : null}
        </RightContainer>
      </TitleContainer>
      <TagsText>{issues}</TagsText>
      <LabelsContainer>{labels.map(renderLabel)}</LabelsContainer>
    </OuterContainer>
  );
};
