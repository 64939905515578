import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { Input } from 'valuelink/tags';
import { BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 40%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: center;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;
const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const SectionContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: stretch;
`;

const SectionTitle = styled(BoldText)`
  font-size: 1.6rem;
  margin-top: 2.7rem;
  margin-bottom: 0.6rem;
`;

const StyledInputBox = styled.input`
  padding: 0.9rem 0;
  margin: 1.5rem;
  flex: 1 0;
  border-bottom: 1px solid #cecece !important;
`;

class AddTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateName: '',
    };
  }
  handleTemplateName = (e) => {
    this.setState({ templateName: e.target.value });
  };
  clearFields = () => {
    this.setState({
      templateName: '',
    });
  };

  render() {
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Add Template</HeaderText>
              <ButtonsComponent>
                <ButtonComponent>
                  <Button
                    onTap={() => {
                      this.props.addTemplate(this.state.templateName, true);
                      this.clearFields();
                    }}
                  >
                    <ButtonText>Save</ButtonText>
                  </Button>
                </ButtonComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>
            <Body>
              <SectionContainer>
                <SectionTitle>Template Name</SectionTitle>
                <StyledInputBox
                  value={this.state.templateName}
                  className="visit-input"
                  placeholder="Template Name"
                  onChange={this.handleTemplateName}
                />
              </SectionContainer>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  prescriptions: state.info.prescriptions,
});

export default connect(mapStateToProps)(AddTemplateModal);
