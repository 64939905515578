import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Prescription from './Prescription';
import PrescriptionViewer from './PrescriptionViewer';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* width: 352px; */
  height: auto;
  border-radius: 12px;
  background: #fff;
  box-shadow: 2px 2px 8px 0px rgba(58, 44, 160, 0.2);
  padding-bottom: 16px;
`;

const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImgTextContainer = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  gap: 8px;
  margin-top: 16px;
  margin-left: 16px;
`;

const DocImg = styled.img`
  height: 40px;
  width: 40px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex-wrap: wrap;
`;

const Text = styled.p`
  color: var(--primary-text-new, #0f0b28);
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
`;

const Info = styled.p`
  color: #a5a6bb;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
`;

const TimeDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-right: 16px;
`;

const Time = styled.p`
  color: var(--text-primary, #0f0b28);
  text-align: right;
  font-size: 10px;
  font-weight: 700;
  line-height: 150%;
  opacity: 0.30000001192092896;
`;

const Date = styled.p`
  color: var(--text-primary, #0f0b28);
  text-align: right;
  font-size: 10px;
  font-weight: 700;
  line-height: 150%;
  opacity: 0.30000001192092896;
`;

const DiagnosisContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PrimaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-left: 16px;
  flex-wrap: wrap;
  width: 146px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
`;

const PrimaryText = styled.p`
  color: #767787;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.25px;
`;

const PrimaryInfo = styled.p`
  color: #585969;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.25px;
`;

const SecondaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-right: 10px;
  flex-wrap: wrap;
  width: 146px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
`;

const SecondaryText = styled.p`
  color: #767787;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.25px;
`;

const SecondaryInfo = styled.p`
  color: #585969;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.25px;
`;

const Line = styled.div`
  width: 320px;
  height: 1px;
  flex-shrink: 0;
  background: #e4e4e480;
  margin-top: 12px;
  margin-left: 16px;
`;

const ViewMore = styled.p`
color: var(--Primary, #714FFF);
font-size: 11px;
font-style: normal;
font-weight: 700;
line-height: 120%;
margin-top: 22px;
margin-left: 10px;
cursor: pointer;
`

const PastTreatmentsCard = ({ treatment, open, prescriptionData }) => {
  console.log(treatment, 'treatment');

  const [viewMore, setViewMore] = useState(false);

  const handlePrescriptionData = () => {
    open();
    prescriptionData(treatment);
  }

  return (
    <OuterContainer>
      <HeadContainer>
        <ImgTextContainer>
          <DocImg src={`${treatment.doctorProfilePic}`} />
          <TextContainer>
            <Text>{treatment.doctorName}</Text>
            <Info>{treatment.doctorInfo}</Info>
          </TextContainer>
        </ImgTextContainer>
        <TimeDateContainer>
          <Time>{treatment.consultationTime}</Time>
          <Date>{treatment.consultationDate}</Date>
        </TimeDateContainer>
      </HeadContainer>
      <DiagnosisContainer>
        <PrimaryContainer>
          <PrimaryText>Primary Diagnosis:</PrimaryText>
          <PrimaryInfo>{treatment.consultationType === 'online' ? (treatment.diagnosis.length > 0 ? (treatment.diagnosis[0].onlineDiagnosis ? treatment.diagnosis[0].onlineDiagnosis : `- -`) : `- -`) : (treatment.diagnosis.length > 0 ? (treatment.diagnosis[0].offlineDiagnosis ? treatment.diagnosis[0].offlineDiagnosis: `- -`) : `- -`)}</PrimaryInfo>
        </PrimaryContainer>
        <SecondaryContainer>
          <SecondaryText>Secondary Diagnosis:</SecondaryText>
          <SecondaryInfo>{treatment.consultationType === 'online' ? (treatment.diagnosis.length > 0 ? (treatment.diagnosis[0].onlineSecondaryDiagnosis ? treatment.diagnosis[0].onlineSecondaryDiagnosis.split('$').join(', ') : `- -`) : `- -`) : (treatment.diagnosis.length > 0 ? (treatment.diagnosis[0].offlineSecondaryDiagnosis ? treatment.diagnosis[0].offlineSecondaryDiagnosis : `- -`) : `- -`)}</SecondaryInfo>
        </SecondaryContainer>
      </DiagnosisContainer>
      {treatment.prescription && treatment.prescription.length > 0 && <Line />}
      {treatment.prescription && treatment.prescription.length > 0 && (
        <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
          {treatment.prescription.slice(0,2).map((prescription, index) => {
            return <Prescription key={index} link={prescription} />;
          })}
          {treatment.prescription.length > 2 && (<ViewMore onClick={handlePrescriptionData}>view more...</ViewMore>)}
        </div>
      )}
    </OuterContainer>
  );
};

export default PastTreatmentsCard;
