import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getPastDigitization, markDuplicateRequest } from '../services';

import {} from '../actions';
import StyledTappable from '../components/common/StyledTappable';
import CardContainer from '../components/common/CardContainer';
import { BoldText, RegularText, Text } from '../components/common/Text';
import prescriptionIcon from '../images/consultations/pdf-icon.png';
import LoadingComponent from '../components/common/LoadingComponent';
import CaseDetailsMessage from '../components/consultations/chat-bubbles/CaseDetailsMessage';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  align-items: stretch;
  padding: 1.6rem 2rem;
  background-color: #fff;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const OuterCardContainer = styled.div`
  margin-bottom: 1.25rem;
  display: flex;
  flex: 1;
  border-radius: 0.875rem;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
  /* flex-shrink: 0; */
  flex: 1 0 auto;
`;

const CardBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1.25rem;
  overflow: hidden;
  align-items: stretch;
  justify-content: stretch;
`;

const InfoRow = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const ProfileComponent = styled.div`
  display: flex;
  flex: 1;
  /* flex-wrap: wrap; */
  align-items: center;
`;

const ProfileComponentColumn = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 0;
`;

const ProfileImage = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
  margin-right: 0.625rem;
`;

const PrescriptionImage = styled.img`
  width: 1.2rem;
  height: 1.5rem;
  margin-right: 0.625rem;
`;

const TimeText = styled(RegularText)`
  ${'' /* color: #b3b3b3; */}
`;

const DoctorText = styled(Text)`
  font-size: 1.6rem;
  /* flex: 1; */
`;

const DoctorTextLight = styled(DoctorText)`
  color: #535353;
  flex: 1;
  font-size: 1.4rem;
  flex-wrap: wrap;
`;

const DoctorTextLightColored = styled(DoctorTextLight)`
  color: #4f34d2;
  text-align: center;
`;

// const PrescriptionRow = styled.div`
//   flex-direction: row;
//   flex: 1;
//   align-items: stretch;
//   padding: 0.6rem 0.2rem;
//   overflow-x: scroll;
//   -webkit-overflow-scrolling: touch;
// `;

const PrescriptionRow = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding: 0.625rem 1rem;
  margin-top: 0.625rem;
  padding-bottom: 0rem;
  background: white;
  row-gap: 10px;
  /* flex-wrap: wrap; */
  /* overflow: hidden; */
`;

const PrescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-bottom: 1.825rem;
  /* flex-wrap: wrap; */
  overflow-x: scroll;
`;

const AddedPrescriptionContainer = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 0.625rem;
  background: #f4f4f4;
  box-shadow: 0 0.375rem 0.625rem 5 rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  padding: 0.625rem 1rem;
  border: 1px solid #f4f4f4;
  border-color: ${(props) => props.borderColor || '#f4f4f4'};
`;

// const PrescriptionContainer = styled(StyledTappable)`
//   /* flex: 1 0; */
//   padding: 0.25rem;
//   background-color: #f5f5f5;
// `;

const ShowSymptomsButton = styled(StyledTappable)`
  margin: 0rem 0rem 0.625rem 0.625rem;
  display: flex;
  justify-content: flex-start;
`;

const ShowSymptomsButtonText = styled(BoldText)`
  color: #4f34d2;
  font-size: 1.4rem;
`;

class PastTreatmentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pastDigitisations: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    if (this.props.activeConsult.digitisationRequestId) {
      this.getPastDigitisations();
    }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeConsult.digitisationRequestId !==
      this.props.activeConsult.digitisationRequestId
    ) {
      this.getPastDigitisations();
    }
  }

  getPastDigitisations = () => {
    this.setState({
      loading: true,
    });
    getPastDigitization(
      this.props.authToken,
      this.props.activeConsult.digitisationRequestId
    )
      .then((res) => {
        console.log({ res }, 'getPastDigitisations');
        this.setState({
          pastDigitisations: res.pastDigitisations,
          loading: false,
        });
      })
      .catch((err) => {
        console.log({ err }, 'error in calling getPastDigitisations');
      });
  };

  showSymptoms = (treatment) => {
    treatment.showSymptoms = true;
    const pastDigitisations = [...this.state.pastDigitisations];
    this.setState({
      pastDigitisations,
    });
  };

  markDuplicate = (requestId) => {
    markDuplicateRequest(
      this.props.authToken,
      this.props.activeConsult.digitisationRequestId,
      requestId
    )
      .then((res) => {
        console.log({ res }, 'markDuplicate');

        this.getPastDigitisations();
      })
      .catch((err) => {
        console.log({ err }, 'error in calling getPastDigitisations');
      });
  };

  openPastDigitisation = (digitisationId) => {
    const baseUrl = window.location.href.split('?')[0];
    const newQueryParams = new URLSearchParams({ digitisationId }).toString();
    const newUrl = `${baseUrl}?${newQueryParams}`;
    window.open(newUrl, '_blank');
  }

  renderPastDigitisations = (treatment) => {
    return (
      <OuterCardContainer>
        <CardBody>
          <ProfileComponentColumn>
            <InfoRow>
              <DoctorText>Doctor Name: {treatment.doctorName || 'Doctor'}</DoctorText>
              <TimeText>Prescription Date:{treatment.appointmentDate || 'Time'}</TimeText>
              <TimeText>Completed Date:{treatment.completedDate || 'Time'}</TimeText>
            </InfoRow>
            <InfoRow>
              <DoctorTextLight>{`Diagnosis: ${treatment.diagnosis}`}</DoctorTextLight>
              <DoctorTextLight>{`Patient Name: ${treatment.patientName?treatment.patientName:treatment.userName||'Not Available'}`}</DoctorTextLight>
              <AddedPrescriptionContainer
                onTap={() => window.open(treatment.mergedDocumentsUrl)}
              >
                <PrescriptionImage src={prescriptionIcon} />
                <DoctorTextLight>Document</DoctorTextLight>
              </AddedPrescriptionContainer>
            </InfoRow>
            <InfoRow>
              <DoctorText>Source Type: {treatment.sourceType || 'Unknown'}</DoctorText>
            </InfoRow>
          </ProfileComponentColumn>
          <PrescriptionRow>
            <AddedPrescriptionContainer
              borderColor="#4F34D2"
              onTap={() => this.markDuplicate(treatment.requestId)}
            >
              <DoctorTextLightColored>Mark Duplicate</DoctorTextLightColored>
            </AddedPrescriptionContainer>
            <AddedPrescriptionContainer
              borderColor="#4F34D2"
              onTap={() => this.openPastDigitisation(treatment.requestId)}
            >
              <DoctorTextLightColored>Open in new tab</DoctorTextLightColored>
            </AddedPrescriptionContainer>
          </PrescriptionRow>
        </CardBody>
      </OuterCardContainer>
    );
  };

  render() {
    const { pastDigitisations } = this.state;
    return (
      <OuterContainer>
        {this.state.loading ? (
          <LoadingComponent />
        ) : (
          <BodyContainer>
            {this.state.pastDigitisations.length === 0 && (
              <DoctorText style={{ textAlign: 'center', fontSize: '1.8rem' }}>
                No past records found for this user.
              </DoctorText>
            )}
            {pastDigitisations.map(this.renderPastDigitisations)}
          </BodyContainer>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
  user: state.user,
  patientId: state.consults.activeConsult.user.userId,
});

export default connect(mapStateToProps)(PastTreatmentContainer);
