import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import CreatableSelect from 'react-select/lib/Creatable';
import add from '../../images/create.svg';
import { showError } from '../../utils';
import { fetchSymptoms, saveSymptomNew } from '../../services';
import { useSelector } from 'react-redux';

const OuterContainer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    weight: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(15, 11, 40, 0.73);
    z-index: 10000;
`;

const InnerContainer = styled.div`
    display: flex;
    background: #FFF;
    width: 400px;
    padding: 16px 20px;
    height: auto;
    border-radius: 12px;
    flex-direction: column;
`;

const HeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const HeadText = styled.p`
    color: #302E6B;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
`;

const Line = styled.div`
    height: 1px;
    width: 360px;
    margin-top: 20px;
    background: #F0F3F6;
`;

const OuterSelect = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 18px;
`;

const SelectText = styled.p`
    color: #0F0B28;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
`;

const SelectContainer = styled.div`
margin-top: 8px;

.css-bg1rzq-control {
    border-radius: 8px !important;
    background: #F0F3F6 !important;
}

.css-151xaom-placeholder {
    color: #A5A6BB !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 130% !important;
}

.css-bgvzuu-indicatorSeparator {
    display: none !important;
}

.css-1szy77t-control {
    border-radius: 8px !important;
    background: #F0F3F6 !important;
}

.css-1hwfws3 {
    color: #0F0B28 !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 130% !important;
}

.css-19bqh2r {
    color: #0F0B28 !important;
    height: 15px !important;
}

.css-11unzgr {
    max-height: 145px !important;
}
`;

const AdditionalNotes = styled.textarea`
    display: flex;
    width: 100%;
    height: 80px;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 18.2px */
    padding: 8px 12px;
    margin-top: 8px;
    resize: none;
`;

const AddSymptomN = styled.div`
    width: 100%;
    border-radius: 8px;
    background: #E4E4E4;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #A5A6BB;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 18.2px */
    margin-top: 20px;
    cursor: not-allowed;
`;

const AddSymptomY = styled.button`
    width: 100%;
    border-radius: 8px;
    background: #714FFF;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; 
    margin-top: 20px;
    cursor: pointer;
`;

const DurationInput = styled.input`
    display: flex;
    padding: 8px 12px;
    width: 60%;
    height: 38px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid hsl(0, 0%, 80%);
    background: #F0F3F6;
    color: #0F0B28;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 18.2px */
    margin-top: 8px;
`;

const createOption = (label, symptomId) => ({
    label,
    value: label.toLowerCase(),
    symptomId
});

const CustomOption = (props) => {
    const { children, innerProps, label, data } = props;

    const pattern = /^Create ".*"$/;

    const createRenderer = () => {
        if (!pattern.test(label)) {
            return  <span>{label}</span>
        } else {
            return (
                <div style={{display: 'flex', gap: '8px'}}>
                    <span style={{color: '#714FFF'}}>{label.split('"')[1].trim()}</span>
                    <img src={add} alt='add-icon'/>
                </div>
            )
        }
    }
   
    return (
        <div
        {...innerProps}
        style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '150%',
        color: '#0F0B28',
        cursor: 'pointer',
      }}
       >
        {createRenderer()}
       </div>
    );
};

const AddSymptomsModalNew = ({ authToken, addSymptom, editSymptom, editData, editSymptomHandler, closeModal }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [optionAdded, setOptionAdded] = useState(false);
    const [note, setNote] = useState('');
    const [duration, setDuration] = useState('');
    const [typeInput, setTypeInput] = useState('');
    const [typeValue, setTypeValue] = useState({value: 'days', label: 'Days'});
    const [typeMenuIsOpen, setTypeMenuIsOpen] = useState(false);
    const [typeOptions, setTypeOptions] = useState([
        {value: 'days', label: 'Days'},
        {value: 'weeks', label: 'Weeks'},
        {value: 'months', label: 'Months'},
        {value: 'years', label: 'Years'}
    ]);
    const [isSearchable, setIsSearchable] = useState(true);

    const activeConsult = useSelector((state) => state.consults.activeConsult);

    useEffect(() => {
        getSymptoms();
    }, [inputValue, optionAdded]);

    useEffect(() => {
        if (editSymptom) {
            const match = editData.questions[0].response.split(' ');
            const noteData = editData.questions.length > 1 ? editData.questions[1].response : null;
            // const regex = /^\d+/;
            // const match = editData.questions[0].response.match(regex);
            // console.log(match);
            setValue(createOption(editData.symptom));
            setDuration(Number(match[0]));
            setTypeValue(createOption(match[1]));
            if (noteData) {
                setNote(noteData);
            }
        } 
    }, [editSymptom]);

    const handleCreate = async (inputValue) => {
        try {
            setIsLoading(true);
            const addedSymptomDetails = await saveSymptomNew({authToken, symptomName: inputValue});
            setOptionAdded((pre) => !pre);
            setTimeout(() => {
                const newOption = createOption(inputValue, addedSymptomDetails.symptomId);
                setIsLoading(false);
                setOptions((prev) => [...prev, newOption]);
                setValue(newOption);
            }, 1000);
        }
        catch(err) {
            showError(err);
        }
    };

    const getSymptoms = async () => {
        try {
            if (inputValue.length > 0) {
                const symptomDetails = await fetchSymptoms({authToken, searchKey: inputValue});
                console.log(symptomDetails);

                if (symptomDetails && symptomDetails.symptoms && symptomDetails.symptoms.length > 0) {
                    const symptomsOptions = symptomDetails.symptoms.map((symptom) => {
                        return createOption(symptom.symptom, symptom.symptomId);
                    });

                    console.log(symptomsOptions);
                    setOptions(symptomsOptions);
                }
            }
        } catch (err) {
            showError(err);
        }
    };

    const handleClick = (id) => {
        if (id === 0) {
            console.log(1);
            addSymptom({symptomId: value.symptomId, duration, note, typeValue: typeValue.label});
        } else if (id === 1) {
            editSymptomHandler({ duration, note, typeValue: typeValue.label });
        }
    }

    const handleDurationChange = (e) => {
        const inputValue = e.target.value;
        const regex = /^(?!-)([1-9]\d*)?$/;
        if (inputValue === '' || regex.test(inputValue)) {
            setDuration(inputValue);
        }
    }

    return (
        <>
            <OuterContainer>
                <InnerContainer>
                    <HeadContainer>
                        <HeadText>Add Symptom</HeadText>
                        <FaTimes style={{height: '14px', width: '14px', marginTop: '5px', cursor: 'pointer'}} onClick={closeModal}/>
                    </HeadContainer>
                    <Line />
                    <OuterSelect>
                        <SelectText>Symptoms</SelectText>
                        <SelectContainer>
                            <CreatableSelect placeholder = 'Select symptom' isLoading={isLoading} isDisabled={isLoading || editSymptom} menuIsOpen = {menuIsOpen} onMenuOpen = {() => setMenuIsOpen(true)} onMenuClose = {() => setMenuIsOpen(false)} components={{Option: CustomOption}} onChange={(newValue) => setValue(newValue)} onCreateOption={handleCreate} options={options} value={value} inputValue={inputValue} onInputChange={(input) => setInputValue(input)} isClearable={true}/>
                        </SelectContainer>
                    </OuterSelect>
                    <OuterSelect>
                        <SelectText>Duration of symptoms</SelectText>
                        <div style={{display: 'flex', maxWidth: '100%', alignItems: 'center'}}>
                            <DurationInput placeholder='Duration' type='number' value={duration} onChange={(e) => handleDurationChange(e)} min={1}/>
                            {/* <SelectContainer style={{width: '60%'}}>
                                <CreatableSelect placeholder = 'Duration' isLoading={isLoading} isDisabled={isLoading} menuIsOpen = {menuIsOpen} onMenuOpen = {() => setMenuIsOpen(true)} onMenuClose = {() => setMenuIsOpen(false)} components={{Option: CustomOption}} onChange={(newValue) => setValue(newValue)} onCreateOption={handleCreate} options={options} value={value} inputValue={inputValue} onInputChange={(input) => setInputValue(input)}/>
                            </SelectContainer> */}
                            <SelectContainer style={{width: '40%', marginLeft: '8px'}}>
                                <CreatableSelect placeholder = 'Type' menuIsOpen = {typeMenuIsOpen} onMenuOpen = {() => setTypeMenuIsOpen(true)} onMenuClose = {() => setTypeMenuIsOpen(false)} components={{Option: CustomOption}} onChange={(newValue) => setTypeValue(newValue)} options={typeOptions} value={typeValue} inputValue={typeInput} onInputChange={(input) => setTypeInput(input)}/>
                            </SelectContainer>
                        </div>
                    </OuterSelect>
                    <OuterSelect>
                        <SelectText>Additional Note</SelectText>
                        <AdditionalNotes placeholder='Add an advice for patient' value={note} onChange={(e) => setNote(e.target.value)}/>
                    </OuterSelect>
                    {
                        editSymptom && (value && typeValue && duration ? (<AddSymptomY onClick={() => handleClick(1)}>Edit Symptom</AddSymptomY>) : (<AddSymptomN onClick={() => handleClick(0)}>Edit Symptom</AddSymptomN>)) 
                    }
                    {
                        !editSymptom && (value && typeValue && duration ? (<AddSymptomY onClick={() => handleClick(0)}>Add Symptom</AddSymptomY>) : (<AddSymptomN onClick={() => handleClick(0)}>Add Symptom</AddSymptomN>))
                    }
                </InnerContainer>
            </OuterContainer>
        </>
    )
}

export default AddSymptomsModalNew