import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import filterIcon from '../images/filter-icon.svg';
import { fetchConsults, fetchPendingCallbacks } from '../services';
import LiveCases from './Bifurcation/LiveCases';
import LiveCasesCard from './Bifurcation/LiveCasesCard';
import PastCases from './Bifurcation/PastCases';
import PastCasesCard from './Bifurcation/PastCasesCard';
import ResponseAwaited from './Bifurcation/ResponseAwaited';
import ResponseCard from './Bifurcation/ResponseCard';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {AiOutlineCalendar} from 'react-icons/ai';
import Select from 'react-select'
import { fetchAllSponsors } from '../services';
import InfoCard from './Bifurcation/InfoCard';
import { setActiveConsult, updateConsults } from '../actions/index';
import { ThreeDots } from 'react-loader-spinner';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { selectConsult, setDoctorLastReadTimetokenForPrevConsult } from '../actions/index';
import pana from '../images/pana.svg';
import { store } from '../store';
import { showError } from '../utils';
import CallbacksCard from './Bifurcation/CallbacksCard';
import CallbacksModal from './Bifurcation/CallbacksModal';
import CallbacksConfirmation from './Bifurcation/CallbacksConfirmation';

const OuterContainer = styled.div`
    width: 435px;
    height: 100%;
    background: #F2F1F6;
    overflow-y: scroll;
    padding-bottom: 20px;
`

const ModalContainer = styled.div`
position: absolute;
width: 442px;
height: 100%;
top: 0;
  flex: 1;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  background: rgba(12, 11, 56, 0.73);
  backdrop-filter: blur(2px);
  z-index: 10;
`

const ModalBody = styled.div` 
position: absolute;
top: 120px;
left: 20px;
  display: flex;
  width: 400px;
  height: auto;
  flex-direction: column;
  background: #FFFFFF;
box-shadow: 4px 4px 20px rgba(58, 44, 160, 0.3);
border-radius: 20px;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  margin: 0 1rem;
`

const Heading = styled.h1`
    width: 180px;
    height: 29px;
    margin-left: 20px;
    margin-top: 45px;
    color: #3A2CA0;

    /* font-family: 'Montserrat'; */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
`

const SearchOuterContainer = styled.div`
    display: flex;
`

const SearchContainer = styled.input`
    width: 335px;
    height: 42px;
    margin-left: 20px;
    margin-top: 16px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    font-style: normal;
    font-size: 12.5px;
    placeholder-color: #A5A6BB;
`

const FilterContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 42px;
    height: 42px;
    margin-left: 20px;
    margin-top: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(58, 44, 160, 0.12);
    border-radius: 8px;
    z-index: 100;
`

const FilterIcon = styled.img`
`

const LiveCasesOuter = styled.div`
    width: 400px;
    height: 531px;
    background: #FFFFFF;
    margin-top: 16px;
    margin-left: 20px;
    box-shadow: 2px 2px 8px rgba(113, 79, 255, 0.21);
    border-radius: 20px;
`

const LiveCasesLogoContainer = styled.div`
    display: flex;
`

const LiveCasesLogo = styled.img`
    margin-top: 26px;
    margin-left: 26px;
`
const LiveCasesHead = styled.h1`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #0F0B28;
    margin-top: 26px;
    margin-left: 14px;
`

const DateInputContainer = styled.div`
display: flex;
width: 355px;
height: 40px;
background: #F0F3F6;
border-radius: 8px;
margin-left: 12px;
margin-top: 8px;
`

const DateHead = styled.p`
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 140%;
letter-spacing: 0.25px;
margin-left: 14px;
margin-top: 16px;
`

const DateInput = styled.input`
padding: 16px;
gap: 10px;
width: 366px;
height: 49px;
left: 16px;
top: 84px;
background: #F0F3F6;
border-radius: 8px;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 120%;
color: #0F0B28;
`

const CalendarContainer = styled.div`
.react-calendar { 
 width: 400px;
 max-width: 100%;
 background-color: #fff;
 color: #222;
 border: none;
 font-family: Arial, Helvetica, sans-serif;
 line-height: 1.125em;
}
.react-calendar__navigation button {
 color: #714FFF;
 min-width: 44px;
 background: none;
 margin-top: 8px;
 font-style: normal;
 font-weight: 600;
 font-size: 14px;
 line-height: 20px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
 background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
 background-color: #f0f0f0;
}
abbr[title] {
 text-decoration: none;
}
.react-calendar__month-view__days__day--weekend {
 color: #222;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
 background: #f8f8fa;
 color: #6f48eb;
 border-radius: 6px;
}
.react-calendar__tile--now {
 background: #6f48eb33;
 border-radius: 6px;
 font-weight: bold;
 color: #6f48eb;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
 background: #6f48eb33;
 border-radius: 6px;
 font-weight: bold;
 color: #6f48eb;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
 background: #f8f8fa;
}
.react-calendar__tile--active {
 background: #6f48eb;
 border-radius: 6px;
 font-weight: bold;
 color: white;
}
.react-calendar__month-view__weekdays__weekday {
  font-style: normal;
font-weight: 600;
font-size: 11px;
color: #714FFF;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
 background: #6f48eb;
 color: white;
}
.react-calendar__year-view__months__month {
  font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 17px;
color: #585969;
}
.react-calendar__decade-view__years__year {
  font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 17px;
color: #585969;
}
.react-calendar__century-view__decades__decade {
  font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 17px;
color: #585969; 
}
.react-calendar__month-view__days__day {
  font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 17px;
color: #585969;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #CFC8FF;
}
.react-calendar--selectRange .react-calendar__tile--hover {
 background-color: #f8f8fa;
}
.react-calendar__tile--range {
 background: #f8f8fa;
 color: #6f48eb;
 border-radius: 0;
}
.react-calendar__tile--rangeStart {
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 border-top-left-radius: 6px;
 border-bottom-left-radius: 6px;
 background: #6f48eb;
 color: white;
}
.react-calendar__tile--rangeEnd {
 border-top-left-radius: 0;
 border-bottom-left-radius: 0;
 border-top-right-radius: 6px;
 border-bottom-right-radius: 6px;
 background: #6f48eb;
 color: white;
}
`

const FiltersContainer = styled.div`
display: flex;
`

const FilterHeadContainer = styled.div`
display: flex;
gap: 11px;
`

const FilterHead = styled.p`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 140%;
display: flex;
align-items: center;
letter-spacing: 0.25px;
color: #0F0B28;
margin-top: 12px;
`

const Line = styled.div`
width: 360px;
height: 0px;
border-bottom: 1px solid #F0F3F6;
margin-left: 12px;
margin-top: 8px;
`

const ClearFilter = styled.p`
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 140%;
display: flex;
align-items: center;
letter-spacing: 0.25px;
text-decoration-line: underline;
color: #FF754C;
cursor: pointer;
margin-top: 12px;
margin-left: 220px;
`

const DoneBtnN = styled.button`
display: flex;
align-items: center;
justify-content: center;
width: 355px;
height: 40px;
background: #E4E4E4;
border-radius: 8px;
margin-top: 15px;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 150%;
color: #A5A6BB;
margin-left: 12px;
`

const DoneBtnY = styled.button`
display: flex;
align-items: center;
justify-content: center;
width: 355px;
height: 40px;
background: #714FFF;
border-radius: 8px;
margin-top: 15px;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 150%;
color: #FFFFFF;
margin-left: 12px;
`

const DropDownContainer = styled.div`
.css-bgvzuu-indicatorSeparator {
  display: none;
}
.css-151xaom-placeholder {
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 17px;
color: #A5A6BB;
}
.css-bg1rzq-control {
  width: 355px;
height: 40px;
background: #F0F3F6;
border-radius: 8px;
margin-top: 8px;
margin-left: 12px;
}
.css-bg1rzq-control {
  width: 355px;
height: 40px;
background: #F0F3F6;
border-radius: 8px;
margin-top: 8px;
margin-left: 12px;
}
.css-1szy77t-control {
  width: 355px;
height: 40px;
background: #F0F3F6;
border-radius: 8px;
margin-top: 8px;
margin-left: 12px;
}
.css-kj6f9i-menu {
  top: -260px;
  height: 250px;
}
.css-11unzgr {
  height: 250px;
}
`

const ApplyBtnY = styled.button`
width: 355px;
height: 42px;
margin-left: 12px;
margin-top: 20px;
background: #714FFF;
border-radius: 12px;
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 140%;
color: #FFFFFF;
`

const ApplyBtnN = styled.button`
width: 355px;
height: 42px;
margin-left: 12px;
margin-top: 20px;
background: #E4E4E4;
border-radius: 12px;
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 140%;
color: #A5A6BB;
cursor: default;
`

const FilterCount = styled.div`
position: absolute;
display: flex;
top: -7px;
left: 30px;
align-items: center;
justify-content: center;
width: 19px;
height: 19px;
background: #FF754C;
border-radius: 100%;
color: #FFFFFF;
font-weight: 800;
`

const ResultHeadContainer = styled.div`
display: flex;
align-items: center;
`

const ResultHead = styled.p`
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 150%;
color: #A5A6BB;
margin-top: 20px;
margin-left: 20px;
`

const ResultClearFilters = styled.p`
margin-top: 20px;
width: 80px;
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 140%;
letter-spacing: 0.25px;
text-decoration-line: underline;
color: #FF754C; 
cursor: pointer;
`

const ResultContainer = styled.div`
height: 545px;
width: 400px;
background: #FFFFFF;
box-shadow: 2px 2px 8px rgba(113, 79, 255, 0.21);
border-radius: 20px;
margin-top: 8px;
margin-left: 20px;
padding: 8px;
overflow-y: scroll;
`

const HeadContainer = styled.div`
width: 352px;
height: 42px;
background: #F0F3F6;
border-radius: 12px;
display: flex;
align-items: center;
padding: 6px;
margin-top: 18px;
margin-left: 20px;
`

const CallBackContainer = styled.div`
position: relative;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 27px;
gap: 10px;
width: 167.5px;
height: 34px;
background: #FFFFFF;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
border-radius: 8px;
cursor: pointer;
`

const CText = styled.p`
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 150%;
text-align: center;
letter-spacing: 0.25px;
color: #0F0B28;
` 

const TextContainer = styled.div`
position: relative;
height: auto;
width: 167.5px;
padding: 8px 27px;
gap: 10px;
background: #F0F3F6;
`

const Notify = styled.div`
position: absolute;
border-radius: 100%;
/* background-color: #FF0000; */
background-color: #F84F31;
box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.7);
top: -5px;
left: 160px;
width: 12px;
height: 12px;
z-index: 1;
`
const UnrepliedContainer = styled.div`
position: relative;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 27px;
gap: 10px;
width: 167.5px;
height: 34px;
margin-left: 3px;
background: #FFFFFF;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
border-radius: 8px;
cursor: pointer;
`

const UText = styled.p`
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 150%;
text-align: center;
letter-spacing: 0.25px;
color: #0F0B28;
`

const NoCases = styled.img`
width: 232.19px;
height: 171px;
margin-left: 82px;
margin-top: 130px;
`

const NoCasesText = styled.p`
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 120%;
color: #0F0B28;
margin-left: 128px;
margin-top: 20px;
`

const Appointments = (props) => {
  const [liveCaseOpen, setLiveCaseOpen] = useState(false);
  const [responseAwaitedOpen, setResponseAwaitedOpen] = useState(false); 
  const [pastCaseOpen, setPastCaseOpen] = useState(false);
  const [consultationData, setConsultationData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [submitted, setSubmitted] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState(new Date());
  const [filters, setFilters] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [value, setValue] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateSelected, setDateSelected] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [filterCount, setFilterCount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [consultationIdKey, setConsultationIdKey] = useState(null);
  const [noData, setNoData] = useState(false);
  const [callBacks, setCallBacks] = useState(true);
  const [pendingCallbacksData, setPendingCallbacksData] = useState([]);
  const [unreplied, setUnreplied] = useState(false);
  const [callBacksData, setCallBacksData] = useState({});
  const [markCallbackModal, setMarkCallbackModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [noDataCB, setNoDataCB] = useState(false);

  const dispatch = useDispatch();

  const activeConsult = useSelector((state) => state.consults.activeConsult);

  const updatedConsults = useSelector((state) => state.consults.consults);

  const authToken = props.information.user.authToken;

  console.log(props, "propschk");

  const observer = useRef();
  const lastRef = useCallback(node => {
    if(loading) return;
    if(observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if(node) observer.current.observe(node);
    console.log(node, 'node');
  },[loading, hasMore]);

  useEffect(() => {
    console.log(activeConsult, "activeConsultDatatata");
    if(activeConsult.consultationId) dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));

    return () => {
      setConsultationData([]);
    }
  },[]);

  useEffect(() => {
    if(activeConsult.consultationId) dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
    // if(searchText.length > 0 || startDate || endDate) {
    //   return;
    // }
    // setConsultationData(updatedConsults);
    // setConsultationData((prevData) => {
    //   const uniqueData = new Map(prevData.map((item) => [item.consultationId, item]));
    //   updatedConsults.forEach((item) => {
    //     uniqueData.set(item.consultationId, item);
    //   });
    //   return Array.from(uniqueData.values());
    // });   
  },[updatedConsults]);

  const getAllConsults = async () => {
    if(!hasMore) return;
    setLoading(true);
    setNoData(false);
    try {
      const consultsData = await fetchConsults(authToken, filters, value ? value.value : null, submitted, consultationIdKey, page, startDate, endDate);
      if(!consultsData.responseBody.consultations) {
        setHasMore(false);
        setLoading(false);
        setNoData(true);
        return;
      }
      console.log(consultsData, 'consultsData', 'ResponseAwaited');
      // setConsultationData((prevData) => [...prevData, ...consultsData.responseBody.consultations]);
      setConsultationData(prevData => (prevData && Array.isArray(prevData) ? [...prevData, ...consultsData.responseBody.consultations] : [...consultsData.responseBody.consultations]));
     
      if(consultsData.responseBody.consultations.length === 0) {
        setNoData(true);
      }
      setLoading(false);
    }
    catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllConsults();
  },[page, submitted, hasMore, value, startDate, endDate]);

  const handleSwitch = (id) => {
    console.log(id, "id");
    setLiveCaseOpen(false);
    setResponseAwaitedOpen(false);
    setPastCaseOpen(false);
    if(id === 1) 
    setLiveCaseOpen(true);
    else if(id === 2)
    setResponseAwaitedOpen(true);
    else if(id === 3)
    setPastCaseOpen(true);
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHasMore(true);
    setPage(1);
    setConsultationData([]);
    setSubmitted(searchText);
    setSearchText('');
  }

  const handleDates = () => {
    setStartDate(moment(date[0]).format('YYYY-MM-DD'));
    setEndDate(moment(date[1]).format('YYYY-MM-DD'));
    setDateSelected(false);
    setHasMore(true);
    setPage(1);
    setConsultationData([]);
  }

  const handleClearFilters = () => {
    setDate(new Date());
    setStartDate(null);
    setEndDate(null);
    setValue(null);
    setOpenDate(false);
    setFilterCount([]);
    setSubmitted(null);
  }

  const handleSponsorChange = (value) => {
    setValue(value);
    setHasMore(true);
    setPage(1);
    setConsultationData([]);
  }

  const handleApplyFilters = async () => {
    // API call
    setFilterCount([]);
    if(value) {
      setFilterCount((prev) => [...prev, value]);
    } 
    if(startDate && endDate) {
      setFilterCount((prev) => [...prev, startDate]);
    }
    console.log(page, 'pageFilters');
    const dataResponse = await fetchConsults(authToken, filters, value ? value.value : null, submitted, consultationIdKey, page, startDate, endDate);
    setConsultationData(dataResponse.responseBody.consultations);
    setOpenDate(false);
  }

  const setActiveConsult = (consult) => {
    consult.unreadMessageCount = 0;
    dispatch(selectConsult(consult));
    // dispatch action here to make api call to update doctor_last_read_timetoken
    dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
    store.dispatch(updateConsults());
    props.renderChats();
  }

  const handleClose = (id) => {
    if(id === 1) setLiveCaseOpen(false);
    else if(id === 2) setResponseAwaitedOpen(false);
    else if(id === 3) setPastCaseOpen(false);
  }

  const handleSwitchHead = (id) => {
    if(id === 1) {
      setCallBacks(true);
      setUnreplied(false);
      setConsultationData([]);
      // setHasMore(true);
    } else if(id === 2) {
      setCallBacks(false);
      setUnreplied(true);
      setConsultationData([]);
      setHasMore(true);
      if(page === 1) {
        getAllConsults();
      } else 
      setPage(1);
    }
  };

  const handleMarkCallbacks = (data) => {
    setCallBacksData(data);
    setMarkCallbackModal(true);
  }

  const getPendingCallbacks = async () => {
    setLoading(true);
    setNoDataCB(false);
    try {
      const obj = {
        authToken: authToken,
        verticalId: props.information.user.verticalId,
      }
      const callBacksData = await fetchPendingCallbacks(obj, submitted, value ? value.value : null, startDate, endDate);
      if(!callBacksData.results || callBacksData.results.length === 0) {
        setNoDataCB(true);
      } 
      setPendingCallbacksData(callBacksData.results);
      setLoading(false);
    }
    catch (err) {
      showError(err);
      setLoading(false);
    }
  }

  const hideCallbacksModal = () => {
    setCallBacksData({});
    setMarkCallbackModal(false);
    getPendingCallbacks();
  }

  const handleConfirmModal = () => {
    setMarkCallbackModal(false);
    setConfirmModal(true);
  }

  const hideConfirmModal = () => {
    setCallBacksData({});
    setConfirmModal(false); 
    getPendingCallbacks();
  }
  

  // useEffect(() => {
  //   const getTextData = async () => {
  //     const dataResponse = await fetchConsults(authToken, filters, value ? value.value : null, submitted, consultationIdKey, page, startDate, endDate);
  //     setConsultationData(dataResponse.responseBody.consultations);
  //   }
  //   getTextData();
  // },[submitted]);

  useEffect(() => {
    const getAllSponsors = async () => {
      const sponsorsData = await fetchAllSponsors(authToken);
      setSponsors(sponsorsData.sponsors);
    }
    getAllSponsors();
  },[]);

  useEffect(() => {
    getPendingCallbacks();
  },[submitted, startDate, endDate, value]);

  useEffect(() => {
    if(date[0] && date[1]) {
      setDateSelected(true);
    }
  },[date]);

  console.log(sponsors, "sponsors");

  let options = [];

  for(let i = 0; i < sponsors.length; i++) {
    options.push({value: sponsors[i].sponsorId, label: sponsors[i].sponsorName});
  }

//   useEffect(() => {
//     const getAllConsultations = async () => {
//         const {information} = props;
//         const consultationsData = await fetchConsults(
//             information.user.authToken,
//             filters,
//             selectedSponsorId,
//             searchText,
//             consultationIdKey
//           );
//           setConsultationData(consultationsData.responseBody.consultations); 
//     };
//     getAllConsultations();
//   },[]);
  
  console.log(consultationData,value, "consultationData");
  console.log(startDate, endDate, "startDate, endDate");

  return (
    <OuterContainer>
        <Heading>Appointments</Heading>
        <SearchOuterContainer>
            <form onSubmit={(e) => handleSubmit(e)}>
            <SearchContainer placeholder='Search Patients or users' value={searchText} onChange={(e) => handleSearch(e)}/>
            </form>
            <FilterContainer onClick={() => setOpenDate((prev) => !prev)} style={openDate ? {position: 'absolute', top: '50px', left:'360px'} : {top: '0px'}}>
                <FilterIcon src={filterIcon}/>
                <div>
                {filterCount.length !== 0 && (<FilterCount style={openDate ? {display: 'none'} : {display: 'flex'}} className='filter-count'>{filterCount.length}</FilterCount>)}
                </div>
            </FilterContainer>
        </SearchOuterContainer>
        {
          openDate && (
            <ModalContainer>
              <ModalBody>
                <FiltersContainer>
                  <FilterHeadContainer>
                    <FilterIcon src={filterIcon} style={{height: '12px', width: '12px', marginTop: '16px', marginLeft: '16px'}}/>
                    <FilterHead>Filters</FilterHead>
                  </FilterHeadContainer>
                  <ClearFilter onClick={() => handleClearFilters()}>Clear Filters</ClearFilter>
                </FiltersContainer>
                <Line />
                <DateHead>Select Date</DateHead>
                <DateInputContainer>
                  <AiOutlineCalendar style={{height: '14px', width: '14px', marginLeft: '14px', marginTop: '14px', cursor: 'pointer'}} onClick={() => setCalendarOpen((prev) => !prev)}/>
                  {date.length > 0 ? (<DateHead>{date[0].toLocaleDateString()} - {date[1].toLocaleDateString()}</DateHead>) : ('')}
                </DateInputContainer>
                {
                  calendarOpen && (
                    <CalendarContainer>
                      <Calendar minDetail='year' onChange={setDate} value={date} selectRange = {true}/>
                    </CalendarContainer>
                  )
                }
                {
                  calendarOpen && (dateSelected ? (<DoneBtnY onClick={() => handleDates()}>Done</DoneBtnY>) : (<DoneBtnN>Done</DoneBtnN>))
                }
                <DateHead>Select Sponsor</DateHead>
                <DropDownContainer> 
                  <Select isClearable={true} isSearchable={true} options={options} defaultValue={value} onChange={handleSponsorChange} placeholder = 'None Selected' noOptionsMessage = {() => 'No sponsors found...'}/>
                </DropDownContainer>
                {
                  value || date.length === 2 ? (<ApplyBtnY onClick={() => handleApplyFilters()}>Apply Filters</ApplyBtnY>) : (<ApplyBtnN>Apply Filters</ApplyBtnN>)
                }
              </ModalBody>
            </ModalContainer>
          )
        }
        {
          (filterCount.length > 0 || submitted) && (
            <ResultHeadContainer>
              {
                value && (<div style={{width: '450px', height: 'auto'}}><ResultHead>Showing Results for {`"${value.label ? value.label : null}"`}</ResultHead></div>)
              }
              <div style={{width: '100px', height: 'auto'}}>
              <ResultClearFilters onClick={() => handleClearFilters()} style={value && value.label ? {marginRight: '0px'} : {marginLeft: '185px'}}>Clear Filters</ResultClearFilters>
              </div>
            </ResultHeadContainer>
          )
        }
        {
          (filterCount.length > 0 || submitted) && (
            <ResultContainer>
              <HeadContainer>
              {
          callBacks ? (
            <CallBackContainer>
              <CText>Callback Requests</CText>
              {pendingCallbacksData && pendingCallbacksData.length > 0 && (<Notify />)}
            </CallBackContainer>
          ) : (
            <TextContainer style={{padding: '0px'}}>
            <CText onClick={() => handleSwitchHead(1)} style={{marginLeft: '5px', cursor: 'pointer'}}>Callback Requests</CText>
            </TextContainer>
          )
        }
        {
          unreplied ? (
            <UnrepliedContainer>
              <UText>Others</UText>
              {consultationData && consultationData.length > 0 && (<Notify />)}
            </UnrepliedContainer>
          ) : (
            <TextContainer>
            <UText onClick={() => handleSwitchHead(2)} style={{cursor: 'pointer'}}>Others</UText>
            </TextContainer>
          )
        }
              </HeadContainer>
              {
        markCallbackModal && (<CallbacksModal closeModal = {hideCallbacksModal} callBacksData = {callBacksData} confirmMarked = {handleConfirmModal} authToken = {authToken} doctorId = {props.information.user.doctorId}/>)
      }
      {
        confirmModal && (<CallbacksConfirmation closeModal = {hideConfirmModal} callBacksData = {callBacksData} authToken = {authToken}/>)
      }
              {
        callBacks && (
          pendingCallbacksData && pendingCallbacksData.length !== 0 && pendingCallbacksData.map((data, idx) => {
            return (
              <div key={data.connectionRequestId} onClick = {() => handleMarkCallbacks(data)}>
                <CallbacksCard id={data.connectionRequestId} callBacksData = {data} activeConsult = {activeConsult}/>
              </div>
            )
          })
        )
      }
       {
        callBacks && (noDataCB && pendingCallbacksData && pendingCallbacksData.length === 0) && (
          <>
          <NoCases src={pana} alt='no cases'/>
          <NoCasesText>No Cases Found</NoCasesText>
          </>
        )
      }
              {
        unreplied &&(consultationData && consultationData.length !== 0 && consultationData.map((data, idx) => {
          if(consultationData.length === idx + 1) {
            return (
              <div ref={lastRef} onClick = {() => setActiveConsult(data)}>
              <InfoCard consultationData = {data} id = {0} activeConsult = {activeConsult}/>
              </div>
            )
          }
          return (
            <div onClick={() => setActiveConsult(data)}>
              <InfoCard consultationData = {data} id = {0} activeConsult = {activeConsult}/>
            </div>
          )
        }))
      }
      {
        unreplied && (noData && consultationData && consultationData.length === 0) && (
          <>
          <NoCases src={pana} alt='no cases'/>
          <NoCasesText>No Cases Found</NoCasesText>
          </>
        )
      }
      {
        loading && (
          <div style={{width: '400', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ThreeDots 
              height='60'
              width='60'
              radius='9'
              color='#714FFF'
              ariaLabel="progress-bar-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
          />
          </div>
        )
      }
            </ResultContainer>
          )
        }
        {
            (filterCount.length === 0 && !submitted) && (!liveCaseOpen ? <LiveCasesCard authToken = {authToken} verticalId = {props.information.user.verticalId} switch = {() => handleSwitch(1)} /> : <LiveCases information = {props.information} authToken = {authToken} searchText = {submitted} tab ={liveCaseOpen} close = {() => handleClose(1)} renderChats = {props.renderChats}/>)
        }
        {
            (filterCount.length === 0 && !submitted) && (!responseAwaitedOpen ? <ResponseCard authToken = {authToken} verticalId = {props.information.user.verticalId} switch = {() => handleSwitch(2)} /> : <ResponseAwaited information = {props.information} authToken = {authToken} searchText = {submitted} tab = {responseAwaitedOpen} close = {() => handleClose(2)} renderChats = {props.renderChats}/>)
        }
        {
            (filterCount.length === 0 && !submitted) && (!pastCaseOpen ? <PastCasesCard authToken = {authToken} switch = {() => handleSwitch(3)} /> : <PastCases information = {props.information} authToken = {authToken} searchText = {submitted} tab = {pastCaseOpen} close = {() => handleClose(3)} renderChats = {props.renderChats}/>)
        }
    </OuterContainer>
  )
}

export default Appointments;