
import React from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import Header from '../common/AdminHeader';
import ReactTable from 'react-table';
import "react-table/react-table.css";  
import { FaTimes } from 'react-icons/fa';
 import { fetchAllSuspiciousDigitizationReq,markUnSuspiciousByUserId,
  fetchAllSuspiciousReqByUserId } from '../../services';
  import StyledTappable from '../common/StyledTappable';
import { toast } from 'react-toastify';
import Modal from '../../containers/common/Modal';
import {
  BoldText,
} from '../common/Text';
const styleObj = {
    'font-size': '15px',
    overflow: 'hidden',
    'background-color': '#FFF',
    'font-weight': '600',
   
  };
  const styleObjTwo = {
    'background-color': '#FFF',
    'font-weight': '600',
    'font-size': '12px',
  };
  const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  flex-direction: column;
`;
const CrossIcon = styled(FaTimes)`
font-size: 2rem;
font-weight: normal;
`;
  const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;
const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;

`;
const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;
const HeaderText = styled(BoldText)`
  font-size: 2rem;
  color:#4F34D2;
`;
const CloseContainer = styled(StyledTappable)`
  align-self: center;
`;

  const Title = styled.p`
  font-size: 24px;
  line-height: 37px;
  color: #4F34D2;
  font-weight: bold;
`;

const HoverButton = styled.button`
  background-color: #4F34D2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.2s;
  &:hover {
    background-color: #4F34D2;
    transform: scale(1.05);
  }

  &:hover::before {
    opacity: 1;
    visibility: visible;
  }
`;  
 class SuspiciousUsers extends React.Component {
   constructor(props) {
    super(props)
    this.state={
       tableData :[],
       suspiciousUserReqDataSource:[],
      SuspiciousUsersReqModal:false 
    }
    this.fetchAllSuspiciousDigitization()
   }

    suspiciousColumn =
      [
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
          Request ID
        </div>
      ),
      accessor: 'reqId',
      width: 170,
      style: { textAlign: 'center', fontWeight: '500' },
    },

    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
          User Name
        </div>
      ),
      accessor: 'name',      
      width: 170,
      style: { textAlign: 'center', fontWeight: '500' },
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
          Patient Name
        </div>
      ),
      width: 170,
      style: { textAlign: 'center', fontWeight: '500' },
      Cell: (row) => (
        <span>{`${
          row.original.relativeName
            ? row.original.relativeName
            : row.original.name
        }`}</span>
      ),
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
         Status
        </div>
      ),
      accessor: 'status',
      width: 170,
      style: { textAlign: 'center', fontWeight: '500' },
    },
    {
      Header: () => (
        <div
          className="flex flex-row"
          style={{ backgroundColor: '', margin: '0px' }}
        >
         Source Type
        </div>
      ),
      accessor: 'sourceType',
      width: 170,
      style: { textAlign: 'center', fontWeight: '500' },
    },
    
   ]
  
   renderListOfAllSuspiciousReqByUserId = ()=>{
    return(
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>
              Suspicious Digitization Requests
              </HeaderText>
              <CloseContainer 
              onTap={()=>this.setState({SuspiciousUsersReqModal:false})}
              >
                <CrossIcon />
              </CloseContainer>
            </ModalHeader>
            <Body>
            <ReactTable className=''
             data={this.state.suspiciousUserReqDataSource||[]}
             columns={this.suspiciousColumn}
             style={styleObjTwo}
             showPaginationTop={false}
             showPaginationBottom={true}
           />
            </Body>
            
          
          </ModalBody>
        </ModalContainer>

        
      </Modal>
    )
   }

   fetchAllSuspiciousDigitization = () => {
     return fetchAllSuspiciousDigitizationReq(this.props.authToken)
       .then((res) => {
        const tableData = res
        this.setState({tableData})
       })
       .catch((err) => {
        toast.error('Error.')
       });
   };

   markUnSuspicious = (userId)=>{
    return markUnSuspiciousByUserId(this.props.authToken,userId)
    .then((res) => {
      this.fetchAllSuspiciousDigitization()
     })
     .catch((err) => {
      toast.error('Error.')
     });
   }
   fetchSuspiciousCaseByUserId= (userId)=>{
    return fetchAllSuspiciousReqByUserId(this.props.authToken,userId)
    .then((res) => {
      this.setState({SuspiciousUsersReqModal:true,suspiciousUserReqDataSource:res})
     })
     .catch((err) => {
      toast.error('Error.')
     });
   }
   render() {
     let column = [
       {
         Header: () => (
           <div
             className="flex flex-row"
             style={{ backgroundColor: '', margin: '0px' }}
           >
             Request ID
           </div>
         ),
         accessor: 'sourceId',
         filterable: true,
         width: 170,
         style: { textAlign: 'center', fontWeight: '500' },
       },

       {
         Header: () => (
           <div
             className="flex flex-row"
             style={{ backgroundColor: '', margin: '0px' }}
           >
             User Name
           </div>
         ),
         accessor: 'name',
         filterable: true,
         //Filter: ColumnFilter,
         width: 170,
         style: { textAlign: 'center', fontWeight: '500' },
       },
       {
         Header: () => (
           <div
             className="flex flex-row"
             style={{ backgroundColor: '', margin: '0px' }}
           >
             Patient Name
           </div>
         ),
         filterable: true,
         //Filter: ColumnFilter,
         width: 170,
         style: { textAlign: 'center', fontWeight: '500' },
         Cell: (row) => (
           <span>{`${
             row.original.relativeName
               ? row.original.relativeName
               : row.original.name
           }`}</span>
         ),
       },
       {
         Header: 'Sponsor Name ',
         accessor: 'sponsorName',
         width: 170,
         // filterable: brokerId ? false : true,
         style: { textAlign: 'center', fontWeight: '500' },
         // Filter: ({ filter, onChange }) => (
         //   <SponsorFilter onChangeValue={onChange} value={filter ? filter : ''} />
         // ),
       },

       {
         Header: 'Reason ',
         accessor: 'reason',
         width: 260,
         style: { textAlign: 'center', fontWeight: '500' },
       },
       {
         Header: () => (
           <div
             className="flex flex-row"
             style={{ backgroundColor: '', margin: '0px' }}
           >
             Total Suspicious Requests
           </div>
         ),
         accessor: 'totalReqCount',
         //Filter: ColumnFilter,
         width: 170,
         style: { textAlign: 'center', fontWeight: '500' },
         Cell: (row) => {
           return (
             <h2
                onClick={() => this.fetchSuspiciousCaseByUserId(row.original.userId)}
               style={{
                 textAlign: 'center',
                 fontWeight: '500',
                 color: '#0000EE',
                 cursor: 'pointer',
               }}
             >
               {row.original.totalReqCount}
             </h2>
           );
         },
       },
       {
         Header: () => (
           <div
             className="flex flex-row"
             style={{ backgroundColor: '', margin: '0px' }}
           >
             Marked At
           </div>
         ),
         accessor: 'createdAt',
         //Filter: ColumnFilter,
         width: 200,
         style: { textAlign: 'center', fontWeight: '500',height:'70px' },
       },
       {
         Header: 'Actions',
         width: 200,
         numeric: false,
         disablePadding: false,

         style: {
           whiteSpace: 'unset',
           position: 'absolute',
           right: 0,
           background: 'white',
           height: '1110px',
           boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
         },
         headerStyle: {
           overflow: 'visible',
           position: 'absolute',
           right: 0,
           background: '#FFFFFF',
           textAlign: 'center',
         },
         Cell: (row) => {
           return (
             <div className="">
              {!row.original.deletedAt?
             <HoverButton
             onClick={()=>this.markUnSuspicious(row.original.userId)}>Mark Unsuspicious</HoverButton>
              :('')}
             </div>
           );
         },
       },
     ];

     return (
       <div className="container-fluid  overflow-auto w-full  ">
         <Header></Header>
         <Title className='mt-5 m-3'>Suspicious Users</Title>
         <div  style={{marginTop:'40px'}}>
           <ReactTable
             data={this.state.tableData}
             columns={column}
             style={styleObj}
             showPaginationTop={false}
             showPaginationBottom={true}
           />
         </div>
         { this.state.SuspiciousUsersReqModal?
          
          this.renderListOfAllSuspiciousReqByUserId():('')}
       </div>
       
     );
   }
 }
const mapStateToProps = (state) => ({
    user: state.user,
    authToken: state.user.authToken,
  });
  export default connect(mapStateToProps)(SuspiciousUsers);

