/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import styled from 'styled-components';
import { RegularText, Text } from '../common/Text';
import StyledTappable from '../common/StyledTappable';

const OuterContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding: 1.2rem;
  background: ${props => (props.active ? 'RGBA(136, 82, 204, 0.3)' : 'white')};
  border: 1px solid #dfdfdf;
  border-radius: 1rem;
  margin-bottom: 1.25rem;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
`;

const TitleText = styled(Text)`
  font-size: 1.6rem;
`;

const DeleteText = styled(Text)`
  font-size: 1.4rem;
  color: #d23f3f;
`;

const TagsText = styled(RegularText)`
  margin-bottom: 0.25rem;
  font-size: 1.4rem;
`;

const GenericName = styled(RegularText)`
  margin-bottom: 0.25rem;
  font-size: 1.2rem;
  color: #8d8d8d;
`;

export default ({ medicine, deleteMedicine }) => {
  console.log({ medicine }, 'medicine in MedicineCard');
  return (
    <OuterContainer>
      <TitleContainer>
          
        {medicine.isSubstitute?
       <div className='flex'> <TitleText>{medicine.name} </TitleText> <span
       style={{color:'#f0ad4e'}}>substitute</span></div> : <TitleText>{medicine.name}</TitleText>
        }
        <StyledTappable onTap={deleteMedicine}>
          <DeleteText>Delete</DeleteText>
        </StyledTappable>
      </TitleContainer>
      <GenericName>{medicine.genericName}</GenericName>
      <TagsText>
        {medicine.dosage && `Dosage: ${medicine.dosage}`}
        {medicine.meal && `, Daily ${medicine.meal} food `}
        {medicine.duration &&
          `for ${medicine.duration} ${medicine.durationType}`}
      </TagsText>
      {medicine.notes ? <TagsText>{`Notes: ${medicine.notes}`}</TagsText> : null}
    </OuterContainer>
  );
};
