import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import sponsorIcon from '../../images/Building.svg';
import { fetchUserBmi, updateUserBmi } from '../../services';

const OuterContainer = styled.div`
position: absolute;
width: 100%;
height: 100%;
background: rgba(15, 11, 40, 0.73);
z-index: 9999999;
top: 0;
left: 0;
`

const InnerContainer = styled.div`
position: absolute;
width: 400px;
height: 370px;  
border-radius: 14.799px;
background: #FFF;
box-shadow: 0px 0px 12.332610130310059px 0px rgba(0, 0, 0, 0.05);
left: 50%;
top: 50%;
padding: 20px;
transform: translate(-50%, -50%);
`

const HeadContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`

const Head = styled.p`
color: #3A2CA0;
font-size: 18.017px;
font-style: normal;
font-weight: 600;
line-height: 120%;
letter-spacing: -0.36px;
`

const InfoContainer = styled.div`
display: flex;
margin-top: 9px;
gap: 5px;
`

const SponsorContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 270px;
  gap: 2px;
  border-right: 1px solid #a5a6bb4d;
`

const SponsorImg = styled.img`

`

const SponsorText = styled.p`
color: #767787;
font-size: 10.51px;
font-style: normal;
font-weight: 500;
line-height: 120%;
letter-spacing: -0.21px;
margin-right: 5px;
overflow-wrap: break-word;
word-break: break-word;
`

const InfoText = styled.p`
color: #767787;
font-size: 11.5px;
font-style: normal;
font-weight: 500;
line-height: 120%;
`

const InfoTextContainer = styled.div`
max-width: auto;
display: flex;
align-items: center;
`

const Line = styled.div`
width: 365px;
height: 1px;
background: #F4F6F9;
margin-top: 28px; 
`

const BmiValuesContainer = styled.div`
display: flex;
flex-direction: column;
margin-top: 24px;
gap: 8px;
`

const BmiText = styled.p`
color: #767787;
font-size: 12.5px;
font-style: normal;
font-weight: 500;
line-height: 150%;
`

const BmiInputContainer = styled.div`
display: flex;
align-items: center;
border-radius: 8px;
border: 1px solid #E4E4E4;
background: #FFF;
`

const BmiInput = styled.input`
width: 340px;
height: 34px;
padding: 14px;
color: #0F0B28;
font-size: 13.5px;
font-style: normal;
font-weight: 600;
border-radius: 8px 0px 0px 8px;
background: #FFF;
line-height: 130%;
`

const BmiVal = styled.div`
display: flex;
text-align: center;
align-items: center;
justify-content: center;
/* margin-right: 10px; */
color: #0F0B28;
font-size: 13.5px;
font-style: normal;
font-weight: 600;
line-height: 130%;
border-left: 1px solid #E4E4E4;
border-radius: 0px 8px 8px 0px;
background: #FFF;
height: 34px;
padding: 15px;
`

const SaveChangesBtn = styled.button`
display: flex;
width: 358px;
padding: 12px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
background: ${props => (props.height || props.weight) ? '#714FFF' : '#E4E4E4'};
color: ${props => (props.height || props.weight) ? '#FFFFFF' : '#A5A6BB'};
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 130%;
margin-top: 40px;
cursor: ${props => (props.height || props.weight) ? 'pointer' : 'not-allowed'};
`

const BmiModal = ({ close, authToken, activeConsult, updateBmi }) => {

  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    fetchUserData();
  },[ activeConsult ]);

  const { user, consultationId } = activeConsult;

  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  }

  const handleWeightChange = (e) => {
    setWeight(e.target.value);
  }

  const fetchUserData = async () => {
    const userData = await fetchUserBmi(authToken, user.userId, user.relativeId);
    console.log(userData, 'userBmi');
    setUserInfo(userData);
  }

  const handleBmi = async () => {
    const { user } = activeConsult;
    await updateUserBmi(authToken, user.userId, user.relativeId, height, weight, activeConsult.consultationId);
    updateBmi();
    close();
  }

  console.log(height, 'height');
  console.log(weight, 'weight');

  return (
    <OuterContainer>
      <InnerContainer>
        <HeadContainer>
          <Head>{userInfo.name}</Head>
          <FaTimes style={{height: '16px', width: '16px', cursor: 'pointer'}} onClick={close}/>
        </HeadContainer>
        <InfoContainer>
          <SponsorContainer>
            <SponsorImg src={sponsorIcon} />
            <SponsorText>{userInfo.sponsorName ? userInfo.sponsorName : 'B2C'}</SponsorText>
          </SponsorContainer>
          <InfoTextContainer>
            <InfoText>{`${userInfo.gender}, ${userInfo.age}yr`}</InfoText>
          </InfoTextContainer>
        </InfoContainer>
        <Line />
        <BmiValuesContainer>
          <BmiText>Height</BmiText>
          <BmiInputContainer>
            <BmiInput placeholder="Enter height" type='number' value={height} onChange={handleHeightChange}/>
            <BmiVal>cm</BmiVal>
          </BmiInputContainer>
        </BmiValuesContainer>
        <BmiValuesContainer>
          <BmiText>Weight</BmiText>
          <BmiInputContainer>
            <BmiInput placeholder="Enter weight" type='number' value={weight} onChange={handleWeightChange}/>
            <BmiVal>kg</BmiVal>
          </BmiInputContainer>
        </BmiValuesContainer>
        <SaveChangesBtn height = {height} weight = {weight} onClick = {() => handleBmi()}>Save Changes</SaveChangesBtn>
      </InnerContainer>
    </OuterContainer>
  )
}

export default BmiModal