import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import { Input } from 'valuelink/lib/tags';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { RegularText, Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';
import TappableCard from '../common/TappableCard';
import { fetchMedicinesFromDimDrugs, saveEditedMedicine } from '../../services';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: center;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const RemoveIcon = styled(FaTimes)`
  font-size: 1.5rem;
  margin-top: 1.725rem;
  margin-right: 0.625rem;
`;

const ModalText = styled(RegularText)`
  font-size: 2rem;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
  border-bottom: 1px solid #979797 !important;
  padding: 0.33rem 0;
  margin: 0rem 0.625rem;
  /* width: ${(props) => (props.width ? props.width : 'auto')}; */
`;

const StyledInputBoxSmall = styled(Input)`
  /* flex: 1; */
  border-bottom: 1px solid #979797 !important;
  padding: 0.33rem 0;
  margin: 0rem 0.625rem;
  width: 8rem;
`;

const StyledTappableCard = styled(TappableCard)`
  align-self: flex-start;
  padding: 1rem 3rem;
  margin: 0rem 0.625rem;
  border: ${(props) => (props.isSelected ? '0.1rem solid #4F34D2' : 'null')};
`;

const TappableText = styled(Text)`
  font-size: 1.4rem;
  text-align: center;
  color: ${(props) => (props.isSelected ? '#4F34D2' : 'black')};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 1;
`;

const LabelText = styled(Text)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const SelectElement = styled(Select)`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 1;
`;

const DosageContainer = styled.div`
  display: flex;
  /* align-self: stretch; */
  flex-direction: row;
`;



const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 16,
    textAlign: 'left',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: 12,
    textAlign: 'left',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    fontSize: 14,
    textAlign: 'left',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),

};

const providerImageMapping = {
  '7': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/pharmeasy_logo.png',
  '1': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/apollo-logo.png',
  '5': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/1mg-logo.png',
  '9': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/wellness_logo.png'
}

const pharmacyIdNameMapping = {
  '5': '1mg',
  '1': 'Apollo',
  '7': 'Phameasy',
  '9': 'Wellness Forever'
}

const medicineCardStyles = {
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
  width: '100%',
  padding: '0.5rem',
  borderRadius: '12px',
  margin: '1px',
  alignItems: 'center',


};



const medicineNameStyles = {
  display: 'flex', justifyContent: 'flex-start',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  letterSpacing: '0.25px',
  textAlign: 'left',
  textTransform: 'capitalize',
  color: '#0F0B28',
  overflowX: 'scroll',
  textOverflow: 'ellipsis',
  lineHeight: '130%',
  letterSpacing: '0.25px',
  textTransform: 'capitalize',
  color: '#0F0B28'

};

const medicinePackStyles = {
  display: 'flex', justifyContent: 'flex-start',
  color: '#767787',
  fontWeight: '600',
  fontSize: '9px',
  textAlign: 'left',
  letterSpacing: '0.25px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};


const providerLogoStyles = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  border: '2px solid lightgray'
};


class EditMedicineModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMedicine: this.props.editMedicine
    };

    console.log(this.props.editMedicine);


    this.dosageOptions = [];
    this.durationOptions = [];
    const { dosages, durations } = props.prescriptions;
    dosages.map((dosage) => {
      this.dosageOptions.push({
        label: dosage,
        value: dosage,
      });
    });

    durations.map((duration) => {
      this.durationOptions.push({
        label: duration,
        value: duration,
      });
    });
  }



  clearFields = () => {
    this.setState({
      editMedicine: this.props.editMedicine
    });
  };



  medicineSelectMealPref = (meal) => {

    const editMedicine = this.state.editMedicine;
    this.state.editMedicine.meal = meal
    this.setState({
      editMedicine: editMedicine
    });

  }

  onDosageSelect = (option) => {
    this.setState({
      dosage: option.value,
    });
  };

  onMedicineDosageSelect = (option) => {
    const editMedicine = this.state.editMedicine;
    this.state.editMedicine.dosageOption = option;
    this.state.editMedicine.dosage = option.value;
    this.setState({
      editMedicine: editMedicine
    });
  };

  setMedicineDurationValue = (value) => {

    const editMedicine = this.state.editMedicine;
    this.state.editMedicine.duration = value
    this.setState({
      editMedicine: editMedicine
    });

    console.log(this.state.editMedicine)
  }

  onMedicineSelect = (option) => {
    if (option) {
      this.setState({
        medicineName: option.value ? option.value : '',
        genericName: option.name ? option.generic_name : '',
        dimDrugId: option.dimDrugId,
      });
    } else {
      this.setState({
        medicineName: '',
        genericName: '',
        dimDrugId: '',
      });
      //this.medicineSelectRef.current.state.defaultOptions = null;
    }
  };



  onAddToCartSelectHandle = (option) => {
    this.setState({
      duration: option.value,
    });
  }

  onDurationSelect = (option) => {
    this.setState({
      duration: option.value,
    });
  };

  onMedicineDurationSelect = (option) => {

    const editMedicine = this.state.editMedicine;
    this.state.editMedicine.durationType = option.value;
    this.state.editMedicine.durationOption = option;
    this.setState({
      editMedicine: editMedicine
    });
  };

  setMedicineOtherDosageValue = (value) => {


    const editMedicine = this.state.editMedicine;
    editMedicine.otherDosage = value
    this.setState({
      editMedicine: editMedicine
    });

  }

  setMedicinePrescribedQuantityValue = (value) => {

    const editMedicine = this.state.editMedicine;
    editMedicine.prescribedQuantity = value
    this.setState({
      editMedicine: editMedicine
    });

    console.log(this.state.editMedicine)
  }


  loadMedicinesOptions = (input, callback) => {
    fetchMedicinesFromDimDrugs(this.props.authToken, input.toLowerCase(), this.state.editMedicine.ph_partner_id)
      .then(data => {
        console.log(data);
        const options = data.map(item => ({
          value: item.name,
          label:  this.medicineLabel(item),
          dimDrugId: item.drugId,
          packSize: item.packSize,
          packUnit: item.packUnit
        }));
        if (typeof callback === 'function') {
          callback(options);
        }
      });
  };


  medicineLabel = (medicine) => {
    const checkMedAvailability = medicine.hasOwnProperty("isAvailable");
    let availabilityMsg = '';
    if (checkMedAvailability) {
      availabilityMsg = medicine.isAvailable ? '' : 'Not available, please add substitute';
    }
    return (
      <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px', textAlign: 'left' }}>
        <span style={{ fontWeight: 'bold' }}>{medicine.name}</span>
        <span style={{ color: medicine.isAvailable ? 'green' : 'red', marginLeft: '10px' }}>
          {availabilityMsg}
        </span>
        <br />

        <span style={{ fontStyle: 'italic', fontSize: '10px' }}>{medicine.packSize} {medicine.packUnit}</span>
      </div>
    );
  };



  setSelectedMedicineOption = (option) => {
    const editMedicine = this.state.editMedicine;
    this.state.editMedicine.name = option.value;
    this.state.editMedicine.packSize = option.packSize;
    this.state.editMedicine.packUnit = option.packUnit;
    this.state.editMedicine.dimDrugId = option.dimDrugId;
    this.state.editMedicine.medicineSelectedOption = option;
    this.setState({
      editMedicine: editMedicine
    });
  }

  handleSubstituteCheckBoxChange = (ev) => {
    const editMedicine = this.state.editMedicine;
    editMedicine.is_substitute = ev.currentTarget.checked;
    this.setState({
      editMedicine: editMedicine
    });
  }

  handleCoveredCheckBoxChange = (ev) => {
    const editMedicine = this.state.editMedicine;
    editMedicine.is_covered = ev.currentTarget.checked;
    this.setState({
      editMedicine: editMedicine
    });
  };

  saveEditedMedicine = (medicine) => {
    const medicineBody = {
      "id": medicine.id,
      "name": medicine.name,
      "dosage": medicine.dosage,
      "duration": medicine.duration,
      "durationType": medicine.durationType || medicine.duration_type,
      "meal": medicine.meal,
      "dimDrugId": medicine.dimDrugId || medicine.dim_drug_id,
      "isSubstitute": medicine.is_substitute,
      "isCovered": medicine.is_covered,
    }
    const requestBody = {
      medicine: medicineBody,
    };
    saveEditedMedicine(this.props.authToken, requestBody)
      .then((result) => {
        this.props.reloadCartItems();
        this.clearFields();
        this.props.hideModal();
      })
      .catch((err) => {
        console.log({ err }, 'error in save item');
      });
  }

  render() {
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>You are editing {this.state.editMedicine.name}</HeaderText>
              <ButtonsComponent>
                <ButtonComponent>
                  <Button
                    onTap={() => {
                      this.saveEditedMedicine(this.state.editMedicine)
                      this.props.reloadCartItems();
                      this.clearFields();
                      this.props.hideModal();
                    }}
                  >
                    <ButtonText>Save</ButtonText>
                  </Button>
                </ButtonComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>
            <Body>
              <div style={{
                border: '0.5px solid lightgray',
                borderRadius: '12px'
              }}>
                <div style={{ ...medicineCardStyles, border: '0.5px solid lightgray', flexDirection: 'row' }}>
                  <img src={providerImageMapping[this.state.editMedicine.ph_partner_id]} style={{ ...providerLogoStyles, zIndex: 1 }} />
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ ...medicineNameStyles, alignSelf: 'flex-start' }}>{this.state.editMedicine.name}</div>
                    <div style={{ ...medicinePackStyles, alignSelf: 'flex-start' }}>{this.state.editMedicine.packSize} {this.state.editMedicine.packUnit}</div>
                  </div>
                </div>
                <div>
                  <AsyncCreatableSelect
                    value={this.state.editMedicine.medicineSelectedOption}
                    onChange={(option) => this.setSelectedMedicineOption(option)}
                    loadOptions={(input, callback) => this.loadMedicinesOptions(input, callback)}
                  />
                  <div style={{ display: 'flex', flexDirection: 'row', width: 'auto', margin: '0.5rem' }}>
                    <input
                      style={{ marginLeft: '1rem' }}
                      type="checkbox"
                      onChange={this.handleSubstituteCheckBoxChange}
                      defaultChecked={this.state.editMedicine.is_substitute}
                      value={''}
                    />
                    <b>Substitute</b>
                    <input
                      style={{ marginLeft: '1rem' }}
                      type="checkbox"
                      onChange={this.handleCoveredCheckBoxChange}
                      defaultChecked={this.state.editMedicine.is_covered}
                      value={''}
                      id="consentCheck"
                    />
                    <b>Covered Under OPD</b>
                  </div>
                  <InputContainer>
                    <StyledTappableCard
                      onTap={() => this.medicineSelectMealPref('before')}
                      isSelected={this.state.editMedicine.meal.toLowerCase() === 'before'}
                    >
                      <TappableText
                        isSelected={this.state.editMedicine.meal.toLowerCase() === 'before'}
                      >
                        Before food
                      </TappableText>
                    </StyledTappableCard>
                    <StyledTappableCard
                      onTap={() => this.medicineSelectMealPref('after')}
                      isSelected={this.state.editMedicine.meal.toLowerCase() === 'after'}
                    >
                      <TappableText
                        isSelected={this.state.editMedicine.meal.toLowerCase() === 'after'}
                      >
                        After food
                      </TappableText>
                    </StyledTappableCard>
                  </InputContainer>
                  <InputContainer>
                    <AgeContainer>
                      <LabelText>Dosage</LabelText>
                      <SelectElement
                        placeholder={this.dosageOptions.find(item => item.label.toLowerCase().includes(this.state.editMedicine.dosage.toLowerCase())).label}
                        options={this.dosageOptions}
                        defaultValue={this.state.editMedicine.dosageOption}
                        styles={customStyles}
                        theme={(theme) => ({
                          ...theme,
                          // borderRadius: '0.25rem',
                          colors: {
                            ...theme.colors,
                            primary50: '#d5d5d5',
                            primary25: '#f5f5f5',
                            primary: '#4F34D2',
                          },
                        })}
                        onChange={(option) => this.onMedicineDosageSelect(option)}
                      />
                    </AgeContainer>
                    <AgeContainer>
                      <LabelText>Duration</LabelText>
                      <DosageContainer>
                        <input
                          style={{
                            borderBottom: '1px solid #979797 !important',
                            padding: '0.33rem 0',
                            margin: '0rem 0.625rem;',
                            width: '8rem',
                          }}
                          type="text"
                          className="visit-input"
                          defaultValue={0}
                          value={this.state.editMedicine.duration}
                          onChange={(event) => this.setMedicineDurationValue(event.target.value)}
                          placeholder='0'
                        >
                        </input>
                        <SelectElement
                          options={this.durationOptions}
                          styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            // borderRadius: '0.25rem',
                            colors: {
                              ...theme.colors,
                              primary50: '#d5d5d5',
                              primary25: '#f5f5f5',
                              primary: '#4F34D2',
                            },
                          })}
                          defaultValue={this.state.editMedicine.duration_type}
                          placeholder={this.state.editMedicine.duration_type}
                          onChange={(option) => this.onMedicineDurationSelect(option)}
                        />
                      </DosageContainer>
                    </AgeContainer>
                  </InputContainer>
                  <InputContainer>
                    {this.state.editMedicine.dosage.toLowerCase() === 'Other'.toLocaleLowerCase() ? (
                      <React.Fragment>
                        <AgeContainer>
                          <input
                            type="text"
                            className="visit-input"
                            value={this.state.editMedicine.otherDosage}
                            onChange={(event) => this.setMedicineOtherDosageValue(event.target.value)}
                            placeholder="Dosage Description"
                          />
                        </AgeContainer>
                        <AgeContainer>
                          <input
                            type="number"
                            className="visit-input"
                            value={this.state.editMedicine.prescribedQuantity}
                            onChange={(event) => this.setMedicinePrescribedQuantityValue(event.target.value)}
                            placeholder="Prescribed Quantity"
                          />
                        </AgeContainer>
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </InputContainer>
                </div>
              </div>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  prescriptions: state.info.prescriptions,
  digitisationDetails: state.digitisation,
});

export default connect(mapStateToProps)(EditMedicineModal);
