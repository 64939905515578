import {
  all,
  apply,
  call,
  delay,
  put,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import {
  loginUser as loginUserService,
  fetchConsults as fetchConsultsService,
  fetchTranscriptionConsults,
  fetchConsultsOnPagination as fetchConsultsOnPaginationService,
  callTosaveDoctorLastReadTimeToToken,
  fetchQuickReplySuggestions,
} from '../services';

import { store } from '../store/index';
import { initializeChatClient } from '../services/chatClient';
import {
  setAuthToken,
  setUserInfo,
  setConsults,
  addConsults,
  selectConsult as selectConsultAction,
  setActiveConsult,
  resetChat,
  resetPrescription,
  setChatInfo,
  setInfo,
  setUnrepliedChannel,
  setFetchConsultsUrlForPagination,
  savePrescription,
  setQuickReplySuggestions,
  setPastChatInfo,
} from '../actions';

/**
 * Login
 */
function* loginUser({ payload }) {
  const { email, password, history, dispatch } = payload;
  try {
    const result = yield call(loginUserService, email, password, dispatch);
    const { authorization } = result.headers;
    const {
      doctor,
      pubnubChannelGroup,
      verticals,
      sponsors,
      filters,
      prescriptions,
      endReasons,
      heightMandatory,
      weightMandatory
    } = result.body;
    const reasons = endReasons
      ? endReasons.map((reason) => {
          return {
            label: reason,
            value: reason,
          };
        })
      : [];
    reasons.unshift({ label: 'None', value: 'None' });
    console.log({ result }, 'loginsaga result');
    yield put(setAuthToken(authorization));
    yield put(setUserInfo(doctor));
    yield put(setUnrepliedChannel(`visit-inbound-doctor-${doctor.doctorId}`));
    yield put(
      setInfo(pubnubChannelGroup, verticals, sponsors, filters, prescriptions, reasons, heightMandatory, weightMandatory)
    );
    yield call(history.push, '/home');
  } catch (err) {
    console.log(err);
  }
}

function* fetchConsults({ payload }) {
  const {
    authToken,
    filters,
    selectedSponsorId,
    selectedDoctorId,
    searchText,
    consultationIdKey,
    fromDateSelector,
    toDateSelector
  } = payload;
  try {
    const { app } = store.getState();
    let result;
    if (app.transcriberAccountActive) {
      result = yield call(
        fetchTranscriptionConsults,
        authToken,
        filters,
        selectedSponsorId,
        selectedDoctorId,
        searchText,
        consultationIdKey,
        fromDateSelector,
        toDateSelector
      );
    } else {
      result = yield call(
        fetchConsultsService,
        authToken,
        filters,
        selectedSponsorId,
        searchText,
        consultationIdKey
      );
    }
    yield put(setFetchConsultsUrlForPagination(result.responseURL));
    const { consultations, requests } = result.responseBody;
    if (app.transcriberAccountActive) {
      yield put(setConsults(requests));
      if (requests.length > 0) {
        yield put(selectConsultAction(requests[0]));
      }
    } else {
      yield put(setConsults(consultations));
      if (consultations.length > 0) {
        yield put(selectConsultAction(consultations[0]));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

function* fetchConsultsOnUpdate({ payload }) {
  const { user, app } = store.getState();
  const { authToken } = user;
  console.log({ user, authToken }, 'fetchConsultsOnUpdate called');
  try {
    let result;
    if (app.transcriberAccountActive) {
      result = yield call(fetchTranscriptionConsults, authToken);
    } else {
      result = yield call(fetchConsultsService, authToken);
    }
    yield put(setFetchConsultsUrlForPagination(result.responseURL));
    const { consultations, requests } = result.responseBody;
    if (app.transcriberAccountActive) {
      yield put(setConsults(requests));
    } else {
      yield put(setConsults(consultations));
    }
  } catch (err) {
    console.log(err);
  }
}

function* fetchConsultsOnPagination({ payload }) {
  const { app } = store.getState();
  const { authToken, paginateToken, fetchConsultsUrlForPagination } = payload;
  try {
    const result = yield call(
      fetchConsultsOnPaginationService,
      authToken,
      paginateToken,
      fetchConsultsUrlForPagination
    );
    const { consultations, requests } = result;
    if (app.transcriberAccountActive) {
      yield put(addConsults(requests));
    } else {
      yield put(addConsults(consultations));
    }
  } catch (err) {
    console.log(err);
  }
}

function* selectConsult({ payload }) {
  const { app } = store.getState();
  const { consult } = payload;
  const { consultationId, channel, user, channels } = consult;
  console.log(channels);
  const state = store.getState();
  const doctor = state.user;
  console.log({ payload });
  try {
    if (app.transcriberAccountActive) {
      yield put(
        savePrescription(
          state.consults.activeConsult.digitisationRequestId,
          state.prescription
        )
      );
      yield put(setActiveConsult(consult));
      yield put(resetPrescription());
    } else if (state.consults.activeConsult.consultationId !== consultationId) {
      yield put(
        savePrescription(
          state.consults.activeConsult.consultationId,
          state.prescription
        )
      );
      yield put(setActiveConsult(consult));
      yield put(resetChat());
      yield put(resetPrescription());
      yield put(
        setChatInfo({
          consultationId,
          chatChannel: channel,
          // unrepliedChannel: `visit-inbound-doctor-${doctor.doctorId}`,
          receiverId: user.userId,
        })
      );
      const chatClient = initializeChatClient();
      chatClient.history({
        channel,
        count: 30,
      });
      chatClient.getNextHistoryBatch(channel);
    }
  } catch (err) {
    console.log(err);
  }
}

function* sendMessage({ payload }) {
  const { chatMessage } = payload;
  const chatClient = initializeChatClient();
  yield apply(chatClient, 'publish', [chatMessage]);
}

function* setTypingState({ payload }) {
  const { status } = payload;
  const { user } = store.getState();
  const chatClient = initializeChatClient();
  yield apply(chatClient, 'setState', [status, user.doctorId]);
}

function* saveDoctorLastReadTimeToToken({ payload }) {
  try{
    const { user } = store.getState();
    const { authToken } = user;

    yield call(callTosaveDoctorLastReadTimeToToken(payload.consultationId,payload.lastReadTimetoken,authToken));
  } catch(err){
    console.log(err);
  }
}

function* getQuickReplySuggestions({ payload }) {
  try{
    const { user } = store.getState();
    const { authToken } = user;
    const suggestions = yield call(fetchQuickReplySuggestions, payload.searchKey, payload.verticalId, payload.sponsorId, authToken);
    yield put(setQuickReplySuggestions(suggestions));
  } catch(err){
    console.log(err);
  }
};

/**
 * User Sagas
 */
export default function* root() {
  yield all([
    takeLatest('LOGIN_USER', loginUser),
    takeLatest('FETCH_CONSULTS', fetchConsults),
    takeLatest('UPDATE_CONSULTS', fetchConsultsOnUpdate),
    takeLatest('FETCH_CONSULTS_ON_PAGINATION', fetchConsultsOnPagination),
    takeEvery('SET_DOCTOR_LAST_READ_TIME_TOKEN', saveDoctorLastReadTimeToToken),
    takeLatest('SELECT_CONSULT', selectConsult),
    takeLatest('SEND_MESSAGE', sendMessage),
    takeEvery('SET_TYPING_STATE', setTypingState),
    takeLatest('GET_QUICK_REPLY_SUGGESTIONS', getQuickReplySuggestions)
  ]);
}
