import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getPastTreatments } from '../services';

import {} from '../actions';
import StyledTappable from '../components/common/StyledTappable';
import CardContainer from '../components/common/CardContainer';
import { BoldText, RegularText, Text } from '../components/common/Text';
import prescriptionIcon from '../images/consultations/pdf-icon.png';
import LoadingComponent from '../components/common/LoadingComponent';
import CaseDetailsMessage from '../components/consultations/chat-bubbles/CaseDetailsMessage';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  align-items: stretch;
  padding: 1.6rem 2rem;
  background-color: #f7f7f7;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const OuterCardContainer = styled.div`
  margin-bottom: 1.25rem;
  display: flex;
  flex: 1;
  border-radius: 0.875rem;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
  /* flex-shrink: 0; */
  flex: 1 0 auto;
`;

const CardBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0.625rem;
  overflow: hidden;
  align-items: stretch;
  justify-content: stretch;
`;

const InfoRow = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ProfileComponent = styled.div`
  display: flex;
  flex: 1;
  /* flex-wrap: wrap; */
  align-items: center;
`;

const ProfileComponentColumn = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1 0;
`;

const ProfileImage = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
  margin-right: 0.625rem;
`;

const PrescriptionImage = styled.img`
  width: 1.2rem;
  height: 1.5rem;
  margin-right: 0.625rem;
`;

const TimeText = styled(RegularText)`
  color: #b3b3b3;
`;

const DoctorText = styled(Text)`
  font-size: 1.6rem;
  /* flex: 1; */
`;

const DoctorTextLight = styled(DoctorText)`
  color: #535353;
  flex: 1;
  font-size: 1.4rem;
  flex-wrap: wrap;
`;

// const PrescriptionRow = styled.div`
//   flex-direction: row;
//   flex: 1;
//   align-items: stretch;
//   padding: 0.6rem 0.2rem;
//   overflow-x: scroll;
//   -webkit-overflow-scrolling: touch;
// `;

const PrescriptionRow = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding: 0.625rem 1rem;
  margin-top: 0.625rem;
  padding-bottom: 0rem;
  background: white;
  /* flex-wrap: wrap; */
  /* overflow: hidden; */
`;

const PrescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-bottom: 1.825rem;
  /* flex-wrap: wrap; */
  overflow-x: scroll;
`;

const AddedPrescriptionContainer = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 0.625rem;
  background: #f4f4f4;
  box-shadow: 0 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  padding: 0.625rem 1rem;
`;

// const PrescriptionContainer = styled(StyledTappable)`
//   /* flex: 1 0; */
//   padding: 0.25rem;
//   background-color: #f5f5f5;
// `;

const ShowSymptomsButton = styled(StyledTappable)`
  margin: 0rem 0rem 0.625rem 0.625rem;
  display: flex;
  justify-content: flex-start;
`;

const ShowSymptomsButtonText = styled(BoldText)`
  color: #4f34d2;
  font-size: 1.4rem;
`;

class PastTreatmentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pastTreatments: [],
      loading: true,
      // pastTreatments: [
      //   {
      //     doctorName: 'Dr. Renu Gupta',
      //     doctorInfo: 'MD, Medicine',
      //     prescription: [
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://stackoverflow.com/questions/19837916/creating-object-with-dynamic-keys',
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams'
      //     ],
      //     doctorProfilePic: '',
      //     consultationTime: 'Dec 12, 8:30 AM'
      //   },
      //   {
      //     doctorName: 'Dr. Jasper',
      //     doctorInfo: 'MBBS, Medicine',
      //     prescription: [
      //       'https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams',
      //       'https://stackoverflow.com/questions/19837916/creating-object-with-dynamic-keys'
      //     ],
      //     doctorProfilePic: '',
      //     consultationTime: 'Dec 12, 8:30 AM'
      //   }
      // ]
    };
  }

  componentDidMount = () => {
    if (this.props.activeConsult.consultationId) {
      this.getPastTreatments();
    }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeConsult.consultationId !==
      this.props.activeConsult.consultationId
    ) {
      this.getPastTreatments();
    }
  }

  getPastTreatments = () => {
    this.setState({
      loading: true,
    });
    getPastTreatments(
      this.props.authToken,
      this.props.activeConsult.consultationId
    )
      .then((res) => {
        console.log({ res }, 'getPastTreatments');
        this.setState({ pastTreatments: res.pastTreatments, loading: false });
      })
      .catch((err) => {
        console.log({ err }, 'error in calling getPastTreatments');
      });
  };

  showSymptoms = (treatment) => {
    treatment.showSymptoms = true;
    const pastTreatments = [...this.state.pastTreatments];
    this.setState({
      pastTreatments,
    });
  };

  renderPastTreatments = (treatment, idx) => {
    const renderPrescription = (ele, idx) => (
      <AddedPrescriptionContainer onTap={() => window.open(ele)}>
        <PrescriptionImage src={prescriptionIcon} />
        <DoctorText>Prescription</DoctorText>
      </AddedPrescriptionContainer>
    );

    return (
      <OuterCardContainer>
        <CardBody>
          <ProfileComponent>
            <ProfileImage src={treatment.doctorProfilePic} />
            <ProfileComponentColumn>
              <InfoRow>
                <DoctorText>{treatment.doctorName}</DoctorText>
                <TimeText>{treatment.consultationTime}</TimeText>
              </InfoRow>
              <DoctorTextLight>{treatment.doctorInfo}</DoctorTextLight>
              {treatment.assistantName && (
                <ProfileComponent>
                  <DoctorText>Assistant Doctor:</DoctorText>{' '}
                  <DoctorTextLight>{`${treatment.assistantName}, ${treatment.assistantInfo}`}</DoctorTextLight>
                </ProfileComponent>
              )}
            </ProfileComponentColumn>
          </ProfileComponent>
          <PrescriptionRow>
            <PrescriptionContainer>
              {treatment.prescription.map(renderPrescription)}
            </PrescriptionContainer>
          </PrescriptionRow>
          {treatment.symptoms &&
            treatment.symptoms.length > 0 &&
            !treatment.showSymptoms && (
              <ShowSymptomsButton onTap={() => this.showSymptoms(treatment)}>
                <ShowSymptomsButtonText>
                  Show Case Details
                </ShowSymptomsButtonText>
              </ShowSymptomsButton>
            )}
          {treatment.symptoms &&
            treatment.symptoms.length > 0 &&
            treatment.showSymptoms && (
              <CaseDetailsMessage caseDetails={treatment.symptoms} hideShadow />
            )}
        </CardBody>
      </OuterCardContainer>
    );
  };

  render() {
    const { pastTreatments } = this.state;
    return (
      <OuterContainer>
        {this.state.loading ? (
          <LoadingComponent />
        ) : (
          <BodyContainer>
            {this.state.pastTreatments.length === 0 && (
              <DoctorText style={{ textAlign: 'center', fontSize: '1.8rem' }}>
                No past treatment records found for this user.
              </DoctorText>
            )}
            {pastTreatments.map(this.renderPastTreatments)}
          </BodyContainer>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
  user: state.user,
  patientId: state.consults.activeConsult.user.userId,
});

export default connect(mapStateToProps)(PastTreatmentContainer);
