import React from 'react';
import styled from 'styled-components';
import { removeMedicine } from '../../services/';
import { connect } from 'react-redux';


const providerImageMapping = {
    '7': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/pharmeasy_logo.png',
    '1': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/apollo-logo.png',
    '5': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/1mg-logo.png',
    '9': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/wellness_logo.png'
}

const pharmacyIdNameMapping = {
    '5': '1mg',
    '1': 'Apollo',
    '7': 'Phameasy',
    '9': 'Wellness Forever'
}

const MedicineListContainer = styled.div`
    display: flex;
    gap: 1.5rem;
    flex-direction: row;
    overflow-x: scroll; 
    width: 100%;
    max-width: 700px;
`;

const MedicineCardContainer = styled.div`
    flex-shrink: 0;
    padding: 1.7rem;
    border-radius: 12px;
    margin: 1px;
    border: 1px solid lightgray;
    width: 400px;
    box-shadow: 0 18px 5px rgba(0, 0, 0, 0.1);
`;

const MedicineCardHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const PharmacyImage = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.05);
`;

const PharmacyName = styled.h1`
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.25px;
    text-align: left;
    text-transform: capitalize;
    margin: 0 16px;
`;

const MedicineItemContainer = styled.div`
    background-color: #FFFFFF;
    display:flex;
    justify-content:space-between	;
`;

const MedicineName = styled.p`
    font-style: normal;
    fontWeight:'550';
    font-size: 12px;
    line-height: 150%;
    text-align: left;
    letter-spacing: 0.27px;
    text-transform: capitalize;
    margin: 8px 0;
`;


const PharmacyBasedMedicineCartListModel = (props) => {
    const medicines = props.result;
    const groupedData = medicines.reduce((acc, curr) => {
        if (!acc[curr.ph_partner_id]) {
            acc[curr.ph_partner_id] = [];
        }
        acc[curr.ph_partner_id].push(curr);

        return acc;
    }, {});
    const deleteMedicine = (medicine) => {

        const medicineBody = {
            dRequestId: medicine.digitisation_request_id,
            medicineId: medicine.id
        }

        removeMedicine(props.authToken, medicineBody)
            .then((result) => {
                props.reloadCartItems();
            })
            .catch((err) => {
                console.log({ err }, 'error in remove item');
            });
    }
    return (
        <MedicineListContainer>
            {Object.keys(groupedData).map((phPartnerId) => (
                <MedicineCardContainer key={phPartnerId}>
                    <MedicineCardHeader>
                        {providerImageMapping[phPartnerId] && <PharmacyImage src={providerImageMapping[phPartnerId]} alt={phPartnerId} />}
                        {pharmacyIdNameMapping[phPartnerId] && <PharmacyName>{pharmacyIdNameMapping[phPartnerId] + ''}</PharmacyName>}
                    </MedicineCardHeader>
                    {groupedData[phPartnerId].map((medicine) => (
                        <div style={{
                            width: '100%'
                        }}>
                            <hr style={{
                                marginTop: '3px',
                                borderTop: '0.5px solid rgba(0, 0, 0, 0.05)',
                                width: '100%'
                            }}></hr>
                            <MedicineItemContainer key={medicine.id}>
                                <div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                    }}>
                                        {medicine.is_substitute == 1 && <MedicineName style={{
                                            textDecorationLine: 'line-through', color: 'GrayText', gap: '1rem', paddingRight: '1rem'
                                        }}>{medicine.master_group_name}</MedicineName>}
                                        <MedicineName>{medicine.name}</MedicineName>
                                    </div>
                                    {medicine.is_available == 1 && medicine.is_covered == 1 && <p
                                        style={{
                                            color: '#585969', fontSize: '11px',
                                        }}
                                    >{`${medicine.dosage} Daily  ${medicine.meal} food for ${medicine.duration} ${medicine.duration_type}`}</p>}
                                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        {medicine.is_covered == 0 &&
                                            <div style={{ color: '#FF754C', fontSize: '11px', alignSelf: 'flex-start' }}>{'Not Covered, '}</div>
                                        }
                                        {medicine.is_available == 0 &&
                                            <div style={{ color: '#FF754C', fontSize: '11px', alignSelf: 'flex-start' }}>{' Not Available'}</div>
                                        }
                                    </div>


                                </div>
                                <div style={{ display: 'flex', gap: '10px', marginBlock: '5%', height: '30px' }}>
                                    <button
                                        disabled={props.isButtonsDisabled ||false}
                                        style={{
                                            padding: '1px 25px',
                                            backgroundColor: props.isButtonsDisabled ? '#b9c8fc': '#714FFF',
                                            border: `0.5px solid ${props.isButtonsDisabled ? '#b9c8fc': '#714FFF'}`,
                                            borderRadius: '5px',
                                            cursor: !props.isButtonsDisabled && 'pointer',
                                            fontWeight: '550',
                                            color: 'white',
                                            fontSize: '12px'
                                        }}
                                        onClick={() => {
                                            props.openEditSubstituteMedicineModal(medicine);
                                        }}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        disabled={props.isButtonsDisabled ||false}
                                        style={{
                                            padding: '1px 25px',
                                            backgroundColor: 'white',
                                            border: '0.5px solid #E4E4E4',
                                            borderRadius: '5px',
                                            fontWeight: '550',
                                            color: '#FF754C',
                                            fontSize: '12px',
                                            opacity: props.isButtonsDisabled && 0.8,
                                            cursor: !props.isButtonsDisabled && 'pointer',
                                        }}
                                        onClick={() => deleteMedicine(medicine)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </MedicineItemContainer>
                        </div>
                    ))}

                </MedicineCardContainer>
            ))}
        </MedicineListContainer>
    );
};

const mapStateToProps = (state) => ({
    authToken: state.user.authToken,
});

export default connect(mapStateToProps)(PharmacyBasedMedicineCartListModel);
