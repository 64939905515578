import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text, BoldText, ButtonText } from '../../common/Text';
import SpecialistSelectCard from '../SpecialistSelectCard';
import CardContainer from '../../common/CardContainer';
import { PrimaryButton } from '../../common/Buttons';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  background: #f7f7f7;
  padding: 0.625rem;
  margin: 0.25rem 0;
`;

const Title = styled(Text)`
  font-size: 1rem;
  opacity: 0.5;
  margin-left: 0.625rem;
`;

const BodyContainer = styled.div`
  display: flex;
  align-items: stretch;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`;

const StyledSpecialistCard = styled(SpecialistSelectCard)`
  margin: 0.625rem 0.3125rem;
  flex-shrink: 0;
`;

const FooterContainer = styled(CardContainer)`
  flex-direction: column;
  align-items: stretch;
  padding: 0.5rem;
`;

const FooterTitle = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
`;

const FooterBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.625rem;
  margin-bottom: 0.5rem;
`;

const FooterIcon = styled.img`
  width: 1.875rem;
  height: 1.875rem;
`;

const FooterBodyText = styled(BoldText)`
  font-size: 0.875rem;
`;

const SpecialistSuggestMessage = ({
  titleText,
  specialists,
  footerTitle,
  footerIcon,
  footerText,
  opdLabel,
  bookingLabel,
}) => {
  const renderSpecialist = ele => (
    <StyledSpecialistCard
      key={ele.doctorId}
      doctorPicture={ele.profileImg}
      doctorName={ele.doctorName}
      doctorQualifications={ele.vertical}
      doctorExperience={ele.experience}
      doctorPrice={ele.finalFee}
      doctorOrganizationImage={ele.orgImg}
      doctorOrganizationText={ele.orgName}
      doctorLocation={ele.distance}
      feeLabel={ele.feeLabel}
      opdLabel={opdLabel}
      bookingLabel={bookingLabel}
    />
  );
  return (
    <OuterContainer>
      <Title>{titleText}</Title>
      <BodyContainer>{specialists.map(renderSpecialist)}</BodyContainer>
      <FooterContainer>
        <FooterTitle>{footerTitle}</FooterTitle>
        <FooterBody>
          <FooterIcon src={footerIcon} />
          <FooterBodyText>{footerText}</FooterBodyText>
        </FooterBody>
        <PrimaryButton>
          <ButtonText>See All Specialists</ButtonText>
        </PrimaryButton>
      </FooterContainer>
    </OuterContainer>
  );
};

SpecialistSuggestMessage.propTypes = {
  titleText: PropTypes.string,
  specialists: PropTypes.array,
  footerIcon: PropTypes.string,
  footerTitle: PropTypes.string,
  footerText: PropTypes.string,
  opdLabel: PropTypes.string,
  bookingLabel: PropTypes.string,
};

export default SpecialistSuggestMessage;
