import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import Modal from '../../../containers/common/Modal';
import { fetchContactDetails } from '../../../services';
import LoadingComponent from '../../common/LoadingComponent';
import ContactInfo from './ContactInfo';
import ReportEmergency from './ReportEmergency';

const LoadingContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
`

const LoadingBody = styled.div`
display: flex;
justify-content: center;
align-items: center;
box-sizing: border-box;
height: 534px;
width: 480px;
background: #FFFFFF;
box-shadow: 0px 0px 12.3326px rgba(0, 0, 0, 0.05);
border-radius: 14.7991px;
`

const EmergencyContactModal = (props) => {
  
  const [contactInfo, setContactInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getAllInfo = async () => {
        const contactData = await fetchContactDetails(props);
        if(contactData.message === 'success'){
            setContactInfo(contactData);
        }
        setTimeout(() => {
            setIsLoading(false);
        },200);
    }
    getAllInfo();
  },[]);

  return (
    isLoading ? (
        <Modal>
            <LoadingContainer>
               <LoadingBody>
                <LoadingComponent />
               </LoadingBody>
            </LoadingContainer>
        </Modal>
    ) : (
        (contactInfo && contactInfo.emergencyContact ? (<ContactInfo information = {props} contactInfo = {contactInfo}/>) : (<ReportEmergency information = {props}/>))
    )
  )
}

export default EmergencyContactModal