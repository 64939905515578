import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import pastCasesIcon from '../../images/past-cases.svg';
import { fetchConsults } from '../../services';
import InfoCard from './InfoCard';
import { ThreeDots } from 'react-loader-spinner';
import { selectConsult, setDoctorLastReadTimetokenForPrevConsult, updateConsults } from '../../actions/index';
import { useSelector, useDispatch } from 'react-redux';
import pana from '../../images/pana.svg';
import { store } from '../../store';

const PastCasesOuter = styled.div`
  width: 400px;
  height: 480px;
  background: #ffffff;
  margin-top: 16px;
  margin-left: 20px;
  box-shadow: 2px 2px 8px rgba(113, 79, 255, 0.21);
  border-radius: 20px;
  overflow-y: scroll;
  padding-bottom: 8px;
`;

const PastCasesLogoContainer = styled.div`
  display: flex;
`;

const PastCasesLogo = styled.img`
  margin-top: 26px;
  margin-left: 26px;
`;
const PastCasesHead = styled.h1`
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #0f0b28;
  margin-top: 26px;
  margin-left: 14px;
`;

const HeadContainer = styled.div`
width: 352px;
height: 42px;
background: #F0F3F6;
border-radius: 12px;
display: flex;
align-items: center;
padding: 6px;
margin-top: 18px;
margin-left: 20px;
`

const OpenCasesContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 27px;
gap: 10px;
width: 167.5px;
height: 34px;
background: #FFFFFF;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
border-radius: 8px;
cursor: pointer;
`

const OText = styled.p`
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 150%;
text-align: center;
letter-spacing: 0.25px;
color: #0F0B28;
`

const PastCasesContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 27px;
gap: 10px;
width: 167.5px;
height: 34px;
margin-left: 3px;
background: #FFFFFF;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
border-radius: 8px;
cursor: pointer;
`

const PText = styled.p`
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 150%;
text-align: center;
letter-spacing: 0.25px;
color: #0F0B28;
`

const TextContainer = styled.div`
height: auto;
width: 167.5px;
padding: 8px 27px;
gap: 10px;
background: #F0F3F6;
`

const NoCases = styled.img`
width: 232.19px;
height: 171px;
margin-left: 82px;
margin-top: 140px;
`

const NoCasesText = styled.p`
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 120%;
color: #0F0B28;
margin-left: 128px;
margin-top: 30px;
`

const PastCases = (props) => {

  const [loading, setIsLoading] = useState(false);
  const [consultationData, setConsultationData] = useState([]);
  const [selectedSponsorId, setSelectedSponsorId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [consultationIdKey, setConsultationIdKey] = useState(null);
  const [page, setPage] = useState(1);
  const [openCases, setOpenCases] = useState(true);
  const [pastCases, setPastCases] = useState(false);
  const [filters, setFilters] = useState([{isSelected: true, name: 'Open Cases'}]);
  const [id, setId] = useState(3.1);
  const [hasMore, setHasMore] = useState(true);
  const [noData, setNoData] = useState(false);
  const [update, setUpdate] = useState(false);

  const dispatch = useDispatch();

  const activeConsult = useSelector((state) => state.consults.activeConsult);

  const updatedConsults = useSelector((state) => state.consults.consults);

  const scrollContainerRef = useRef(null);

  console.log(activeConsult, 'activeConsult', 'test');

  const observer = useRef();
  const lastRef = useCallback(node => {
    if(loading || !node) return;
    if(observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if(node) observer.current.observe(node);
    console.log(node, 'node');
  },[loading, hasMore]);

  const scrollToTop = () => {
    setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    },400);
  };

  useEffect(() => {
    console.log(activeConsult, "activeConsultDatatata");
    if(activeConsult.consultationId) dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
  },[]);

  useEffect(() => {
    if(activeConsult.consultationId) dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
    setConsultationData([]);
    setUpdate((prev) => !prev);
  },[updatedConsults]);


  const setActiveConsult = (consult) => {
    consult.unreadMessageCount = 0;
    if(consult.consultationId === activeConsult.consultationId) return;
    dispatch(selectConsult(consult));
    // dispatch action here to make api call to update doctor_last_read_timetoken
    dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
    store.dispatch(updateConsults());
    setConsultationData([]);
    setHasMore(true);
    setPage(1);
    props.renderChats();
    // scrollToTop();
  }

  const getAllConsults = async () => {
    if(!hasMore) return;
    setIsLoading(true);
    setNoData(false);
    try {
      const consultsData = await fetchConsults(props.authToken, filters, selectedSponsorId, searchText, consultationIdKey, page);
      if(!consultsData.responseBody.consultations) {
        setHasMore(false);
        setIsLoading(false);
        setNoData(true);
        return;
      }
      console.log(consultsData, 'consultsData', 'ResponseAwaited');
      let filterArr = consultsData.responseBody.consultations;
      console.log(filterArr);

      setConsultationData((prevData) => {
        const consultationIdMap = new Map(filterArr.map(obj => [obj.consultationId, true]));
        if(page === 1) prevData = []
        const uniqueData = prevData.filter(obj => !consultationIdMap.has(obj.consultationId));
        return [...uniqueData,...filterArr];
      }); 

      if(filterArr.length === 0) {
        setNoData(true);
      }
      setIsLoading(false);
    }
    catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllConsults();
  },[filters, page, hasMore]);

  useEffect(() => {
    if(openCases) {
      setId(3.1);
    } else if (pastCases) {
      setId(3.2);
    }
  },[openCases, pastCases]);

  useEffect(() => {
    if(page === 1) {
      setTimeout(() => {
        getAllConsults();
      }, 250);
    }
    setTimeout(() => {
      setHasMore(true);
      setPage(1);
    }, 400);
  }, [ update ]);

  const handleSwitch = (id) => {
    if(id === 1) {
      setOpenCases(true);
      setPastCases(false);
      setFilters([{isSelected: true, name: 'Open Cases'}]);
      setPage(1);
      setConsultationData([]);
      setHasMore(true);
    } else if (id === 2){
      setOpenCases(false);
      setPastCases(true);
      setFilters([{isSelected: true, name: 'Past Cases'}]);
      setPage(1);
      setConsultationData([]);
      setHasMore(true);
    }
  };

  return (
    <PastCasesOuter ref={scrollContainerRef} >
      <PastCasesLogoContainer onClick={props.close} style={{cursor: 'pointer'}}>
        <PastCasesLogo src={pastCasesIcon} />
        <PastCasesHead>All Cases</PastCasesHead>
      </PastCasesLogoContainer>
      <HeadContainer>
        {
          openCases ? (
            <OpenCasesContainer>
              <OText>Open Cases</OText>
            </OpenCasesContainer>
          ) : (
            <TextContainer>
              <OText onClick={() => handleSwitch(1)} style={{cursor: 'pointer'}}>Open Cases</OText>
            </TextContainer>
          )
        }
        {
          pastCases ? (
            <PastCasesContainer>
              <PText>Past Cases</PText>
            </PastCasesContainer>
          ) : (
            <TextContainer>
              <PText onClick={() => handleSwitch(2)} style={{cursor: 'pointer'}}>Past Cases</PText>
            </TextContainer>
          )
        }
      </HeadContainer>
      {
        consultationData && consultationData.length !== 0 && consultationData.map((data, idx) => {
          if(consultationData.length === idx + 1) {
            return (
              <div ref={lastRef} onClick = {() => setActiveConsult(data)}>
              <InfoCard consultationData = {data} id = {id} activeConsult = {activeConsult}/>
              </div>
            )
          }
          return (  <div onClick={() => setActiveConsult(data)}>
                    <InfoCard consultationData = {data} id = {id} activeConsult = {activeConsult}/>
                    </div>
              )
        })
      }
      {
        noData && consultationData && consultationData.length === 0 && (
          <>
          <NoCases src={pana} alt='no cases'/>
          <NoCasesText>No Cases Found</NoCasesText>
          </>
        )
      }
      {
        loading && (
          <div style={{width: '400', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ThreeDots 
              height='60'
              width='60'
              radius='9'
              color='#714FFF'
              ariaLabel="progress-bar-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
          />
          </div>
        )
      }
    </PastCasesOuter>
  )
}

export default PastCases