import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import happy from '../../images/Care/consults/happy.png';
import starSelected from '../../images/feedback/selectedStar.png';
import starUnselected from '../../images/feedback/starUnselected.png';
import feedbackGraphic from '../../images/Care/consults/feedbackGraphic.png';
import { submitVideoFeedback } from '../../services';
import { RegularText, Text, BoldText } from '../common/Text';
import { resetVideoFeedbackInfo } from '../../actions';
import StyledTappable from '../common/StyledTappable';
import Modal from '../../containers/common/Modal';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const ModalHeader = styled.div`
  justify-content: center;
  align-items: center;
  /* align-self: stretch; */
  flex-direction: column;
  padding-top: 24;
  /* margin: 0px -16px 0px -16px; */
  /* border-radius: 8px; */
  /* background-color: #e5e9fe;
  border-bottom-color: #e5e9fe; */
  /* border-bottom-width: 1; */
  margin-bottom: 24px;
`;

const HeaderText = styled(BoldText)`
  font-size: 18px;
  /* flex: 1; */
  text-align: center;
  margin-top: 20px;
  color: #413958;
  align-self: center;
  align-items: center;
`;

const HorizontalLine = styled.div`
  width: 204px;
  align-self: center;
  margin-bottom: 20px;
  height: 1px;
  border: 1px solid #f5f5f5;
`;

const GreetBody = styled.div`
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  justify-content: center;
  align-items: center;
  height: 280px;
`;

const HappyImage = styled.img`
  width: 64px;
  height: 64px;
`;

const HeaderImage = styled.img`
  width: 110px;
  height: 134px;
`;

const StarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const StarContainer = styled(StyledTappable)`
  display: flex;
  width: 25px;
  height: 25px;
  margin-right: ${(props) => (props.lastStar ? '0px' : '23px')};
`;

const StarImage = styled.img`
  width: 24px;
  height: 24px;
`;

const TagsContainer = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  align-items: center;
  /* margin-top: 12px; */
  padding: 0px 0px;
  /* padding-top: 0px; */
`;

const TagContainer = styled(StyledTappable)`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => (props.isSelected ? '#b9c8fc' : '#fff')};
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  padding: 8px 16px;
  /* margin-right: 16px; */
  margin-bottom: 16px;
  border: 1px solid #b9c8fc;
  /* border-width: 1px; */
  /* border-color: ${(props) => (props.isSelected ? '#4F34D2' : '#efeff0')}; */
`;

const TagText = styled(RegularText)`
  flex: 1;
  font-size: 14px;
  line-height: 16px;
`;

const CheckoutButton = styled(StyledTappable)`
  display: flex;
  margin-top: 20px;
  margin: 20px -16px -36px -16px;
  height: 52px;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? '#B9C8FC' : '#4f34d2')};
  border-bottom-left-radius: 6;
  border-bottom-right-radius: 6;
`;

const CheckoutButtonText = styled(BoldText)`
  font-size: 14px;
  text-align: center;
  color: #fff;
`;

const styles = {
  addInvertedShadow: {
    shadowColor: '#000000',
    shadowRadius: 20,
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: -1 },
  },
  addShadow: {
    shadowColor: '#0c000000',
    shadowRadius: 20,
    shadowOffset: { width: 0, height: 1 },
    borderRadius: 6,
  },
};

const stars = [
  {
    id: 1,
    label: 'Very Poor',
  },
  {
    id: 2,
    label: 'Poor',
  },
  {
    id: 3,
    label: 'Average',
  },
  {
    id: 4,
    label: 'Good',
  },
  {
    id: 5,
    label: 'Amazing',
  },
];

class VideoFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
      selectedStar: { id: 0, label: 'Rate Your Experience' },
      selectedTags: [],
      showGreetings: false,
      showTags: false,
    };
  }

  resetModal = () => {
    this.setState({
      isVisible: true,
      selectedStar: { id: 0, label: 'Rate Your Experience' },
      selectedTags: [],
      showGreetings: false,
      showTags: false,
    });
  };

  submitStars = () => {
    if (this.state.selectedStar.id <= 4) {
      this.setState({ showTags: true });
    } else {
      this.setState({ showTags: false });
    }
  };

  submitFeedback = () => {
    const { authToken } = this.props;
    const body = {
      tags:
        this.state.selectedStar.id > 4 ? '' : this.state.selectedTags.join(','),
      rating: this.state.selectedStar.id,
      sessionId: this.props.videoFeedback.sessionId,
    };
    submitVideoFeedback(authToken, body)
      .then(() => {
        this.setState({ showGreetings: true });
        this.goBackInterval = setTimeout(() => {
          this.props.back();
        }, 1000);
      })
      .catch((err) => {
        console.log({ err }, 'error in submitting feedback');
        this.props.back();
      })
      .finally(() => {
        this.props.resetVideoFeedbackInfo();
      });
  };

  renderStars = () => {
    const { selectedStar, selectedTags, showGreetings } = this.state;
    return (
      <StarsContainer>
        {stars.map((star) => (
          <StarContainer
            lastStar={star.id === stars.length}
            onTap={() => {
              this.setState(
                {
                  selectedStar: star,
                },
                () => {
                  setTimeout(() => {
                    this.submitStars();
                  }, 0);
                }
              );
            }}
          >
            <StarImage
              src={star.id <= selectedStar.id ? starSelected : starUnselected}
            />
          </StarContainer>
        ))}
      </StarsContainer>
    );
  };

  processTagSelect = (tag) => {
    const tags = [...this.state.selectedTags];
    if (this.state.selectedTags.indexOf(tag) > -1) {
      tags.splice(this.state.selectedTags.indexOf(tag), 1);
    } else {
      tags.push(tag);
    }

    this.setState({ selectedTags: tags });
  };

  renderTags = () => {
    const { videoFeedback } = this.props;
    const { selectedTags, selectedStar } = this.state;
    let tags = videoFeedback.averageTags;
    if (selectedStar.id === 4) {
      tags = videoFeedback.moreTags;
    } else if (selectedStar.id < 3) {
      tags = videoFeedback.badTags;
    }
    return (
      <TagsContainer>
        <HorizontalLine />
        <RegularText
          style={{
            textAlign: 'center',
            maxWidth: 230,
            marginBottom: 16,
            fontSize: 14,
          }}
        >
          What improvement do you think needs to be done?
        </RegularText>
        {tags.map((tag) => (
          <TagContainer
            onTap={() => this.processTagSelect(tag)}
            isSelected={selectedTags.indexOf(tag) > -1}
          >
            <TagText style={{ textAlign: 'center' }}>{tag}</TagText>
          </TagContainer>
        ))}
      </TagsContainer>
    );
  };

  render() {
    const { isVisible, back, videoFeedback } = this.props;
    const { selectedStar, selectedTags, showGreetings, showTags } = this.state;
    return (
      <Modal>
        <ModalContainer>
          <ModalBody style={styles.addInvertedShadow}>
            {!showGreetings ? (
              <ModalHeader>
                <HeaderImage src={feedbackGraphic} />
                <HeaderText style={{ maxWidth: 200 }}>
                  How was your video call experience?
                </HeaderText>
              </ModalHeader>
            ) : null}

            {!showGreetings ? this.renderStars() : this.null}

            {!showGreetings && showTags ? this.renderTags() : null}

            {!showGreetings && selectedStar.id > 0 ? (
              <CheckoutButton onTap={this.submitFeedback}>
                <CheckoutButtonText>Submit</CheckoutButtonText>
              </CheckoutButton>
            ) : null}

            {showGreetings ? (
              <GreetBody>
                <HappyImage src={happy} />
                <BoldText
                  style={{
                    textAlign: 'center',
                    marginTop: 34,
                    maxWidth: 200,
                    fontSize: 20,
                  }}
                >
                  We appreciate your feedback
                </BoldText>
              </GreetBody>
            ) : null}
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    authToken: state.user.authToken,
    videoFeedback: state.videoFeedback,
  };
}

const mapDispatchToProps = (dispatch) => ({
  resetVideoFeedbackInfo: () => {
    dispatch(resetVideoFeedbackInfo());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoFeedback);
