import React, { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import Select from 'react-select';

import {
  BoldText,
  Text,
  ButtonText,
  RegularText,
} from '../components/common/Text';
import PrescriptionSentSuccessModal from '../components/prescription/PrescriptionSentSuccessModal';
import StyledTappable from '../components/common/StyledTappable';
import ConsultationChatContainer from './ConsultationChatContainer';
import PrescriptionContainer from './PrescriptionContainer';
import SymptomsContainer from './SymptomsContainer';
import { connect } from 'react-redux';
import { PrimaryButton } from '../components/common/Buttons';
import arrowDown from '../images/common/arrowDown.png';
import DigitizePrescriptionContainer from './DigitizePrescriptionContainer';
import { fetchConsults, resetPrescription } from '../actions';
import {
  markDigitised,
  submitDiagnosisWhileClosingConsultation,
} from '../services';
import DigitizeSymptomsContainer from './DigitizeSymptomsContainer';
import CloseConsultationModal from '../components/consultations/CloseConsultationModal';
import moment from 'moment';
import { updateStatusQc } from '../services';
import { toast } from 'react-toastify';
import Modal from './common/Modal';
import VideoCallContainer from './VideoCallContainer';

const OuterContainer = styled.div`
  display: flex;
  flex: 2.5 0;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  border: 1px solid #d8d8d8;
  width: 700px; /* Adjust the width to your desired fixed value */
  overflow-x: scroll;
`;

const TabContainer = styled.div`
  display: flex;
  background: #f5f5f5;
  align-items: stretch;
  justify-content: space-between;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.23);
`;

const TabItemContainer = styled(StyledTappable)`
  padding: 1rem 4rem;
  border-bottom: ${(props) => (props.active ? '3px solid #4F34D2' : 'none')};
`;

const TabActiveItemText = styled(BoldText)`
  font-size: 1.6rem;
  color: #4f34d2;
`;
const Button = styled.button`
  margin-left: 0.625rem;
  background-color: ${(props) => props.backgroundColor || '#198754'};
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const TabItemText = styled(Text)`
  font-size: 1.6rem;
  opacity: 0.4;
`;

const SelectElement = styled.div`
  /* border: 0.1rem solid #4F34D2; */
  align-self: auto;
  width: 15rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
`;

const StyledButton = styled(StyledTappable)`
  display: flex;
  flex: 1;
  height: 5rem;
  align-items: center;
  justify-content: center;
  margin: 0rem 0rem;
  align-self: stretch;
  background-color: ${(props) => (props.disabled ? '#B9C8FC' : '#5a68e7')};
`;

const MarkContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.25rem;
  justify-content: space-between;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTappableButton = styled(StyledTappable)`
  display: flex;
  flex: 1;
  margin-right: 0.25rem;
  flex-direction: row;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  padding: 0.625rem 1.625rem;
  border-radius: 4px;
  border: 1px solid #5a68e7;
  background-color: ${(props) => props.backgroundColor || '#fff'};
  color: ${(props) => props.color};
`;

const DownImage = styled.img`
  height: 14px;
  width: 14px;
  margin-left: 1.625rem;
`;

const OptionContainer = styled(StyledTappable)`
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 1.25rem;
  /* align-items: flex-start; */
`;

const AttachmentText = styled(RegularText)`
  margin: 2rem 0.625rem;
  font-size: 1.6rem;
`;

const AttachmentContainer = styled.div`
  position: absolute;
  flex: 1 0;
  bottom: 10rem;
  left: 3rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  max-height: 300px;
  overflow-y: auto;
  &:focus {
    border: none;
    outline: none;
  }
`;
const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  flex-direction: column;
`;
const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;
const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;
const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;
const CloseContainer = styled(StyledTappable)`
  align-self: center;
`;
const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;
const ReasonButton = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const qcReasonList = [
  {
    value: 'Wrongly marked invalid',
    label: 'Wrongly marked invalid',
  },
  {
    value: 'Substitute alternate mapping was available',
    label: 'Substitute alternate mapping was available',
  },
  {
    value: 'Wrong test digitized',
    label: 'Wrong test digitized',
  },
  {
    value: 'Wrong medicine digitized',
    label: 'Wrong medicine digitized',
  },
  {
    value: 'Prescription was invalid',
    label: 'Prescription was invalid',
  },
  {
    value: 'Did not choose the consultation details from the drop-down',
    label: 'Did not choose the consultation details from the drop-down',
  },
  {
    value: 'Wrong diagnosis',
    label: 'Wrong diagnosis',
  },
];
class ConsultationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      invalidOptionSelected: null,
      showAttachmentContainer: false,
      showSendPrescriptionSuccess: false,
      showCloseConsultModal: false,
      rejectModalOfQc: false,
      rejectionReason: '',
      qcRemark: '',
      minimizeVideo: false,
    };
    this.attachmentContainerRef = React.createRef();
  }

  attachFile = () => {
    this.setState(
      {
        showAttachmentContainer: !this.state.showAttachmentContainer,
      },
      () => {
        this.attachmentContainerRef.current &&
          this.attachmentContainerRef.current.focus();
      }
    );
  };

  markApprovedOrRejected = async (dRequestId, status) => {
    const { authToken } = this.props;
    const { rejectionReason, qcRemark } = this.state;
    const body = {
      status,
      dRequestId: dRequestId,
      rejectionReason,
      qcRemark,
    };
    await updateStatusQc(authToken, body)
      .then(() => {
        this.setState({ rejectModalOfQc: false });
        toast.success('Status Updated');
      })
      .catch((err) => {
        console.log('Error', { err });
      });
  };

  hidePrescriptionSentSuccessModal = () => {
    this.setState({
      showSendPrescriptionSuccess: false,
    });
  };

  markPrescriptionInvalid = () => {
    this.setState({ loading: true });
    const { blacklistedCentreName } = this.props;
    const invalidReason = blacklistedCentreName
      ? `${this.state.invalidOptionSelected.label} - ${blacklistedCentreName}`
      : this.state.invalidOptionSelected.label;
    markDigitised(
      this.props.authToken,
      null,
      null,
      this.props.activeConsult.digitisationRequestId,
      null,
      null,
      null,
      null,
      invalidReason
    )
      .then((result) => {
        this.setState({
          showSendPrescriptionSuccess: true,
          successModalMessage: 'Marked Invalid Successfully',
        });
        this.props.dispatch(resetPrescription());
        this.props.dispatch(
          fetchConsults({
            authToken: this.props.authToken,
            filters: this.props.filters,
            selectedSponsorId: this.props.selectedSponsorId,
          })
        );
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  markDigitised = () => {
    this.setState({ loading: true });
    console.log(
      this.props.secondaryDiagnosis,
      this.props.secondaryDiagnosisIcdCode,
      '[markDigitised]',
      this.props
    );
    markDigitised(
      this.props.authToken,
      this.props.probableDiagnosis,
      this.props.icdCode,
      this.props.activeConsult.digitisationRequestId,
      this.props.appointmentDate,
      this.props.prescriptionValidityDate,
      this.props.doctorName,
      this.props.doctorId,
      null,
      this.props.centerName,
      this.props.centerAddress,
      this.props.centerId,
      this.props.relativeId,
      (this.props.digitisationConsultation || {}).consultationId,
      (this.props.digitisationConsultation || {}).prescriptionId,
      this.props.isCritical,
      this.props.secondaryDiagnosis,
      this.props.secondaryDiagnosisIcdCode
    )
      .then((result) => {
        this.setState({
          showSendPrescriptionSuccess: true,
          successModalMessage: 'Digitisation Marked Complete',
          loading: false,
        });
        // this.props.dispatch(resetPrescription());
        this.props.dispatch(
          fetchConsults({
            authToken: this.props.authToken,
            filters: this.props.filters,
            selectedSponsorId: this.props.selectedSponsorId,
          })
        );
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  submitDiagnosis = (data, userInfo) => {
    const { consultationInfo, consultUserInfo, authToken } = this.props;
    const body = {
      'Lead date': moment().format('YYYY-DD-MM'),
      'Employee Name': userInfo.userName,
      'Employee Phone number': userInfo.phone,
      Company: userInfo.sponsorName,
      'Patient Name':
        consultationInfo.relativeName || consultationInfo.userName,
      'Patient Age': consultationInfo.relativeAge || '',
      Sex: consultationInfo.relativeGender || '',
      'Relationship with employee': consultationInfo.relativeRelation || '',
      City: consultationInfo.orgAddress || '',
      Diagnosis: data.selectedDiagnosis,
    };
    console.log(body, 'The diagnosis data from doctor suggested to patient');
    submitDiagnosisWhileClosingConsultation(authToken, body)
      .then((res) => {
        console.log(res, 'submitted the surgery data');
      })
      .catch((err) => {
        console.log(err, 'error in submitting the surgery data');
      })
      .finally(() => {
        this.hideModal();
      });
  };

  hideModal = () => {
    this.setState({
      showCloseConsultModal: false,
    });
  };
  openModal = () => {
    this.setState({ rejectModalOfQc: true });
  };

  OnSelectValue = (e) => {
    this.setState({ rejectionReason: e.value });
  };

  handleMinimizeVideo = () => {
    this.setState({
      minimizeVideo: !this.state.minimizeVideo,
    });
  };

  render() {
    const { location, history, transcriberAccountActive, videoSessionInfo } =
      this.props;
    console.log(videoSessionInfo);
    const urlParams = new URLSearchParams(location.search);
    const isQcCase = urlParams.get('digitisationId');
    const consultationSearchKey = urlParams.get('c');
    const renderTab = () => {
      switch (consultationSearchKey) {
        case 'chat':
          if (!transcriberAccountActive) return <ConsultationChatContainer />;
          else return null;
        case 'prescriptions':
          if (!transcriberAccountActive) return <PrescriptionContainer />;
          else return <DigitizePrescriptionContainer />;
        case 'symptoms':
          if (!transcriberAccountActive) return <SymptomsContainer />;
          else return <DigitizeSymptomsContainer />;
        default:
          if (!transcriberAccountActive) return <ConsultationChatContainer />;
          else return <DigitizePrescriptionContainer />;
      }
    };
    const showTab = (search) => {
      console.log({ search, urlParams });
      if (consultationSearchKey) {
        if (consultationSearchKey !== search) {
          urlParams.set('c', search);
        }
      } else {
        urlParams.append('c', search);
      }
      history.replace(`${location.pathname}?${urlParams}`);
    };

    const renderRejectModal = () => (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>
                Specify a reason to mark this request rejected
              </HeaderText>
              <CloseContainer
                onTap={() => this.setState({ rejectModalOfQc: false })}
              >
                <CrossIcon />
              </CloseContainer>
            </ModalHeader>
            <Body>
              <Select
                className="mt-1"
                classNamePrefix="select"
                placeholder="Select Reason"
                options={qcReasonList}
                onChange={(e) => this.OnSelectValue(e)}
              />
              <input
                type="text"
                placeholder="Enter Remark"
                class="bg-[#fff] border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-3 dark:placeholder-gray-400 mt-4"
                style={{ border: '2px solid #015ECC' }}
                onChange={(e) => this.setState({ qcRemark: e.target.value })}
              ></input>

              <ButtonComponent>
                <ReasonButton
                  onTap={() =>
                    this.markApprovedOrRejected(isQcCase, 'rejected')
                  }
                  disabled={!this.state.rejectionReason}
                >
                  <ButtonText>Confirm</ButtonText>
                </ReasonButton>
              </ButtonComponent>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
    const renderButtons = () => (
      <Col>
        <MarkContainer>
          <StyledTappableButton
            backgroundColor="#4F34D2"
            color="#fff"
            onTap={() => this.markApprovedOrRejected(isQcCase, 'approved')}
          >
            <BoldText>{'Mark Approved'}</BoldText>
          </StyledTappableButton>

          <StyledTappableButton
            onTap={() => this.openModal()}
            backgroundColor="#4F34D2"
            color="#fff"
          >
            <BoldText>Mark Reject</BoldText>
          </StyledTappableButton>
        </MarkContainer>
      </Col>
    );

    const renderAttachmentTypes = (ele, idx) => {
      return (
        <OptionContainer
          key={ele.name}
          onTap={() =>
            this.setState({
              invalidOptionSelected: ele,
              showAttachmentContainer: false,
            })
          }
        >
          <AttachmentText>{ele.label}</AttachmentText>
        </OptionContainer>
      );
    };
    return (
      <>
        {!transcriberAccountActive && videoSessionInfo.startCall ? ( // change after development of video room
          <VideoCallContainer
            sessionData={videoSessionInfo}
            minimizeVideo={this.handleMinimizeVideo}
          />
        ) : (
          <OuterContainer>
            <TabContainer>
              {!transcriberAccountActive ? (
                <TabItemContainer
                  active={
                    consultationSearchKey === 'chat' || !consultationSearchKey
                  }
                  onTap={() => showTab('chat')}
                >
                  {consultationSearchKey === 'chat' ||
                  !consultationSearchKey ? (
                    <TabActiveItemText>Chat</TabActiveItemText>
                  ) : (
                    <TabItemText>Chat</TabItemText>
                  )}
                </TabItemContainer>
              ) : null}
              <TabItemContainer
                active={consultationSearchKey === 'symptoms'}
                onTap={() => showTab('symptoms')}
              >
                {consultationSearchKey === 'symptoms' ? (
                  <TabActiveItemText>Symptoms</TabActiveItemText>
                ) : (
                  <TabItemText>Symptoms</TabItemText>
                )}
              </TabItemContainer>
              <TabItemContainer
                active={consultationSearchKey === 'prescriptions'}
                onTap={() => showTab('prescriptions')}
              >
                {consultationSearchKey === 'prescriptions' ? (
                  <TabActiveItemText>Prescriptions</TabActiveItemText>
                ) : (
                  <TabItemText>Prescriptions</TabItemText>
                )}
              </TabItemContainer>
            </TabContainer>
            {renderTab()}
            {transcriberAccountActive ? (
              isQcCase ? (
                renderButtons()
              ) : (
                this.props.digitisationDetails.sourceType !== 'cashless-vision' ? <Col>
                  <MarkContainer>
                    <StyledTappableButton onTap={this.attachFile}>
                      <BoldText>
                        {this.state.invalidOptionSelected
                          ? this.state.invalidOptionSelected.label
                          : this.props.digitisationDetails.invalidReason ||
                            'Select invalid Reason'}
                      </BoldText>
                      <DownImage src={arrowDown} />
                    </StyledTappableButton>

                    <PrimaryButton
                      disabled={!this.state.invalidOptionSelected}
                      onTap={() => this.markPrescriptionInvalid()}
                    >
                      <ButtonText>Mark Pres. Invalid</ButtonText>
                    </PrimaryButton>
                  </MarkContainer>
                  <TabContainer>
                    {/* <StyledButton
                  disabled={
                    this.props.probableDiagnosis.length > 0 && !this.state.loading
                      ? false
                      : true
                  }
                  onTap={() => this.previewPrescription()}
                >
                  <ButtonText>Preview</ButtonText>
                </StyledButton> */}
                    <StyledButton
                      disabled={
                        ((this.props.digitisationConsultation &&
                          this.props.digitisationConsultation.consultationId) ||
                          (this.props.digitisationDetails &&
                            (this.props.digitisationDetails.sourceType ===
                              'offline-consultations' ||
                              this.props.digitisationDetails.sourceType ===
                                'cashless-dental') &&
                            this.props.probableDiagnosis &&
                            this.props.probableDiagnosis.length > 0) ||
                          (this.props.probableDiagnosis &&
                            this.props.probableDiagnosis.length > 0 &&
                            this.props.appointmentDate.length === 10 &&
                            this.props.doctorName &&
                            this.props.doctorName.length > 0 &&
                            this.props.relativeId &&
                            this.props.prescriptionValidityDate &&
                            this.props.centerName.length > 0)) &&
                        !this.props.isBlacklistedCentre &&
                        !this.state.loading
                          ? this.props.digitisationDetails.status ===
                              'complete' ||
                            this.props.digitisationDetails.invalid
                            ? true
                            : false
                          : true
                      }
                      onTap={() => {
                        // console.log('Data-----', this.props);
                        this.markDigitised();
                      }}
                    >
                      <ButtonText>Mark Digitized</ButtonText>
                    </StyledButton>
                  </TabContainer>
                  {this.state.showAttachmentContainer && (
                    <AttachmentContainer
                      ref={this.attachmentContainerRef}
                      onBlur={this.closeAttachmentContainer}
                      tabIndex="1"
                    >
                      {this.props.invalidReasons.map(renderAttachmentTypes)}
                    </AttachmentContainer>
                  )}
                </Col> : <></>
              )
            ) : null}
            {this.state.showCloseConsultModal && (
              <CloseConsultationModal
                hideModal={() => this.hideModal()}
                submitDiagnosis={(data, userInfo) => {
                  // this.submitDiagnosis(data, userInfo);
                  this.setState({ showCloseConsultModal: false });
                  this.markDigitised();
                }}
                digitisation={true}
              />
            )}
            {this.state.showSendPrescriptionSuccess && (
              <PrescriptionSentSuccessModal
                hideModal={() => this.hidePrescriptionSentSuccessModal()}
                message={this.state.successModalMessage}
              />
            )}
            {this.state.rejectModalOfQc ? renderRejectModal() : ''}
          </OuterContainer>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  transcriberAccountActive: state.app.transcriberAccountActive,
  probableDiagnosis: state.prescription.probableDiagnosis,
  icdCode: state.prescription.icdCode,
  secondaryDiagnosisIcdCode: state.prescription.secondaryDiagnosisIcdCode,
  secondaryDiagnosis: state.prescription.secondaryDiagnosis,
  doctorName: state.prescription.doctorName,
  doctorId: state.prescription.doctorId,
  isBlacklistedCentre: state.prescription.isBlacklistedCentre,
  blacklistedCentreName: state.prescription.blacklistedCentreName,
  appointmentDate: state.prescription.appointmentDate,
  prescriptionValidityDate: state.prescription.prescriptionValidityDate,
  relativeId: state.prescription.relativeId,
  digitisationConsultation: state.prescription.digitisationConsultation,
  centerName: state.prescription.centerName,
  centerId: state.prescription.centerId,
  centerAddress: state.prescription.centerAddress,
  digitisationRequestId: state.consults.activeConsult.digitisationRequestId,
  activeConsult: state.consults.activeConsult,
  digitisationDetails: state.digitisation,
  consultationInfo: state.consults.consultPatientInfo,
  consultUserInfo: state.consults.currentUserInfo,
  invalidReasons: state.consults.invalidReasons,
  isCritical: state.prescription.isCritical,
  videoSessionInfo: state.videoSession,
});

export default connect(mapStateToProps)(withRouter(ConsultationContainer));
