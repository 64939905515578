import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

import { BlackText, BoldText, RegularText } from './common/Text';

import visitLogo from '../images/common/visit-logo-white.svg';
import callIcon from '../images/call-icon.svg';
import videoIcon from '../images/video-icon.svg';
import closeIcon from '../images/close-icon.svg';
import StyledTappable from './common/StyledTappable';
import CloseConsultationModal from '../components/consultations/CloseConsultationModal';
import CancelConsultationModal from '../components/consultations/CancelConsultationModal';
import VoiceCallSuccessModal from '../components/consultations/VoiceCallSuccessModal';
import LoadingComponent from '../components/common/LoadingComponent';
import Modal from '../containers/common/Modal';

import {
  doctorInitiateVideoCall,
  closeConsultationNew,
  voiceCallUser,
  goActive,
  goInactive,
  goOfflineActive,
  goOfflineInactive,
  submitDiagnosisWhileClosingConsultation,
  initiateVoiceCallForDigitizationUser,
} from '../services';
import {
  logoutUser,
  toggleTranscriberAccount,
  fetchConsults,
  updateConsults,
  setVideoCallData,
} from '../actions';
import moment from 'moment';
import config from '../config';
import { store } from '../store';
import EmergencyContactModal from './consultations/EmergencyContacts/EmergencyContactModal';

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem;
  background: #4f34d2;
  position: relative;
`;

const LogoContainer = styled.img`
  max-width: 9.8rem;
`;

const PatientDetailText = styled(BlackText)`
  font-size: 1.9rem;
  color: white;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ActionContainer = styled(StyledTappable)`
  display: flex;
  align-items: center;
  margin: 0 2.5rem;
`;

const ActionIcon = styled.img`
  max-height: 1.5rem;
  margin-right: 1rem;
`;

const ActionText = styled(BoldText)`
  font-size: 1.4rem;
  color: white;
`;

const UserContainer = styled(StyledTappable)`
  display: flex;
  align-items: center;
`;

const UserIcon = styled.img`
  width: 3rem;
  height: 3rem;
  margin-right: 0.9rem;
`;

const UserText = styled(BoldText)`
  font-size: 1.6rem;
  color: ${(props) => (props.transcriberAccountActive ? '#F25540' : 'white')};
`;

const OptionContainer = styled(StyledTappable)`
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 1.25rem;
  z-index: 999;
  align-items: stretch;

  &:hover {
    background: rgba(125, 125, 125, 0.3);
  }
`;

const AttachmentText = styled(RegularText)`
  margin: 0.5rem 0.625rem;
  font-size: 1.6rem;
  /* z-index: 999; */
`;

const AttachmentContainer = styled.div`
  position: absolute;
  width: 20rem;
  flex: 1 0;
  top: 5rem;
  right: 2rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  z-index: 999;
  overflow: visible;
  &:focus {
    border: none;
    outline: none;
  }
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

class DashboardHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
      showCloseConsultationModal: false,
      showCancelConsultationModal: false,
      showEmergencyContactModal: false,
      showVoiceCallModal: false,
      showModal: false,
      showModalLoading: false,
      data: this.props,
      modalText: '',
      attachmentTypes: [
        { type: 'goActive', name: 'Go Active' },
        { type: 'goInactive', name: 'Go Inactive' },
        { type: 'Logout', name: 'Logout' },
      ],
    };
    this.optionsRef = React.createRef();
    console.log(this.props, 'check props in header');
  }

  componentDidMount = () => {
    const addAttachments = [];
    if (this.props.isInhouseDoctor && parseInt(this.props.isInhouseDoctor)) {
      addAttachments.push({
        type: 'goOfflineActive',
        name: 'Go Offline Active',
      });
      addAttachments.push({
        type: 'goOfflineInactive',
        name: 'Go Offline Inactive',
      });
    }
    if (this.props.isTranscriber && parseInt(this.props.isTranscriber)) {
      addAttachments.push({
        type: 'toggleTranscriberAccount',
        name: 'Toggle Transcriber Account',
      });
    }
    if (
      this.props.isDigitisationAdmin &&
      parseInt(this.props.isDigitisationAdmin)
    ) {
      addAttachments.push({
        type: 'Admin',
        name: 'Admin',
      });
    }
    this.setState({
      attachmentTypes: [...this.state.attachmentTypes, ...addAttachments],
    });
  };

  initiateVoiceCall = () => {
    if (this.props.transcriberAccountActive) {
      const { digitisationDetails, authToken } = this.props;
      initiateVoiceCallForDigitizationUser(
        authToken,
        digitisationDetails.digitisationRequestId,
        digitisationDetails.sourceType
      )
        .then((result) => {
          console.log(result, 'result');
          this.setState({ showVoiceCallModal: true });
        })
        .catch((err) => {
          console.log({ err });
        });
    } else {
      voiceCallUser(
        this.props.authToken,
        this.props.activeConsult.consultationId
      )
        .then((result) => {
          console.log({ result }, 'voice call initiated');
          this.setState({ showVoiceCallModal: true });
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  };

  initiateVideoCall = () => {
    doctorInitiateVideoCall(
      this.props.authToken,
      this.props.doctorId,
      this.props.activeConsult.consultationId
    )
      .then((result) => {
        console.log({ result }, 'result from initiateVideoCall');
        const { dispatch } = this.props;
        result.authToken = this.props.authToken;
        result.startCall = true;
        dispatch(setVideoCallData(result));
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  openAttachmentType = (ele, idx) => {
    console.log({ ele, idx });
    const { authToken } = this.props;
    switch (ele.type) {
      case 'Logout':
        this.setState({ showOptions: false });
        this.props.dispatch(logoutUser());
        this.props.history.push('/');
        break;
      case 'goActive':
        this.setState({
          showModal: true,
          showModalLoading: true,
          showOptions: false,
        });
        goActive(authToken)
          .then((res) => {
            this.setState({
              showModalLoading: false,
              modalText: 'You are now online',
            });
          })
          .catch((err) => {
            const errorMessage =
              err.response &&
              err.response.data &&
              err.response.data.errorMessage
                ? err.response.data.errorMessage
                : 'Something went wrong. Please try again later';
            this.setState({ showModalLoading: false, modalText: errorMessage });
            console.error(err);
          });
        break;
      case 'goInactive':
        this.setState({
          showModal: true,
          showModalLoading: true,
          showOptions: false,
        });
        goInactive(authToken)
          .then((res) => {
            this.setState({
              showModalLoading: false,
              modalText: 'You are now offline',
            });
          })
          .catch((err) => {
            const errorMessage =
              err.response &&
              err.response.data &&
              err.response.data.errorMessage
                ? err.response.data.errorMessage
                : 'Something went wrong. Please try again later';
            this.setState({ showModalLoading: false, modalText: errorMessage });
            console.error(err);
          });
        break;
      case 'goOfflineActive':
        this.setState({
          showModal: true,
          showModalLoading: true,
          showOptions: false,
        });
        goOfflineActive(authToken)
          .then((res) => {
            this.setState({
              showModalLoading: false,
              modalText: 'You are now offline active',
            });
          })
          .catch((err) => {
            const errorMessage =
              err.response &&
              err.response.data &&
              err.response.data.errorMessage
                ? err.response.data.errorMessage
                : 'Something went wrong. Please try again later';
            this.setState({ showModalLoading: false, modalText: errorMessage });
            console.error(err);
          });
        break;
      case 'goOfflineInactive':
        this.setState({
          showModal: true,
          showModalLoading: true,
          showOptions: false,
        });
        goOfflineInactive(authToken)
          .then((res) => {
            this.setState({
              showModalLoading: false,
              modalText: 'You are now offline inactive',
            });
          })
          .catch((err) => {
            const errorMessage =
              err.response &&
              err.response.data &&
              err.response.data.errorMessage
                ? err.response.data.errorMessage
                : 'Something went wrong. Please try again later';
            this.setState({ showModalLoading: false, modalText: errorMessage });
            console.error(err);
          });
        break;
      case 'toggleTranscriberAccount': {
        this.props.dispatch(toggleTranscriberAccount());
        this.props.dispatch(fetchConsults({ authToken }));
        this.showOptions();
        break;
      }
      case 'Admin': {
        //this.props.history.push('/admin',this.state.authToken)
        this.props.history.push('/admin', { authToken: this.props.authToken });
        this.props.dispatch(fetchConsults({ authToken }));
        break;
      }
      default:
        break;
    }
  };

  showOptions = () => {
    this.setState({ showOptions: !this.state.showOptions }, () => {
      this.optionsRef.current && this.optionsRef.current.focus();
    });
  };

  initiateCloseConsultation = () => {
    this.setState({
      showCloseConsultationModal: true,
    });
  };

  closeOptions = () => {
    this.setState({
      showOptions: false,
    });
  };

  hideModal = () => {
    this.setState({
      showCloseConsultationModal: false,
      showCancelConsultationModal: false,
      showVoiceCallModal: false,
      showEmergencyContactModal: false,
    });
  };

  closeConsult = (body) => {
    console.log({ body, activeConsult: this.props.activeConsult });

    closeConsultationNew(
      this.props.authToken,
      this.props.activeConsult.consultationId,
      // body.followDateTime,
      // body.followupNotes,
      body.endReason,
      body.closedType,
      body.consultationNote,
      body.othersRemark
    )
      .then((result) => {
        console.log({ result }, 'closeConsult');
        console.log('update called on cancel');
        store.dispatch(updateConsults());
        this.hideModal();
      })
      .catch((err) => {
        console.log({ err });
        this.hideModal();
      });
  };

  submitDiagnosis = (data, userInfo) => {
    console.log(userInfo, 'submitDiagnosis');
    const { consultationInfo, consultUserInfo, authToken } = this.props;
    const body = {
      'Lead date': moment().format('DD MMM YY'),
      'Employee Name': userInfo.userName,
      'Employee Phone number': userInfo.phone,
      'Patient Name': consultationInfo.relativeName,
      'Patient Age': consultationInfo.relativeAge,
      'Relationship with employee': consultationInfo.relativeRelation,
      'Doctor Name': `${this.props.doctorFirstName} ${this.props.doctorLastName}`,
      Sex: consultationInfo.relativeGender,
      Ecard: 'WEB',
      Company: userInfo.sponsorName,
      City: consultationInfo.orgAddress,
      Diagnosis: data.selectedDiagnosis,
    };
    console.log(body, 'The diagnosis data from doctor suggested to patient');
    if (config.apiBaseUrl === 'https://api.getvisitapp.com/v3') {
      submitDiagnosisWhileClosingConsultation(authToken, body)
        .then((res) => {
          console.log(res, 'submitted the surgery data');
        })
        .catch((err) => {
          console.log(err, 'error in submitting the surgery data');
        });
    }
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      showModalLoading: false,
      modalText: '',
    });
  };

  render() {
    const {
      activeConsult,
      doctorFirstName,
      doctorLastName,
      doctorId,
      transcriberAccountActive,
    } = this.props;
    const renderAttachmentTypes = (ele, idx) => {
      return (
        <OptionContainer
          key={ele.name}
          onTap={() => this.openAttachmentType(ele, idx)}
        >
          <AttachmentText>{ele.name}</AttachmentText>
        </OptionContainer>
      );
    };
    return (
      <OuterContainer>
        <LogoContainer src={visitLogo} />
        {activeConsult && (
          <PatientDetailText>
            {`${activeConsult.userName || activeConsult.user.userName}`}
          </PatientDetailText>
        )}
        {activeConsult && transcriberAccountActive && (
          <ActionsContainer>
            <ActionContainer
              onTap={() => this.initiateVoiceCall()}
             // disabled={transcriberAccountActive}
            >
              <ActionIcon src={callIcon} />
              <ActionText>Phone</ActionText>
            </ActionContainer>
            <ActionContainer
              onTap={() => this.initiateVideoCall()}
              disabled={transcriberAccountActive}
            >
              <ActionIcon src={videoIcon} />
              <ActionText>Video</ActionText>
            </ActionContainer>
            <ActionContainer onTap={() => this.setState({showEmergencyContactModal: true})}>
              <ActionIcon src={callIcon}/>
              <ActionText>Emergency Contact</ActionText>
            </ActionContainer>
            <ActionContainer
              onTap={() => this.initiateCloseConsultation()}
              disabled={transcriberAccountActive}
            >
              <ActionIcon src={closeIcon} />
              <ActionText>End Consultation</ActionText>
            </ActionContainer>
            <ActionContainer
              onTap={() => this.setState({ showCancelConsultationModal: true })}
              disabled={transcriberAccountActive}
            >
              <ActionIcon src={closeIcon} />
              <ActionText>Cancel Consultation</ActionText>
            </ActionContainer>
          </ActionsContainer>
        )}
        <UserContainer onTap={this.showOptions}>
          <UserIcon
            src={`https://api.getvisitapp.com/apiv2/user-images/doctor-${this.props.doctorId}.png`}
          />
          <UserText
            transcriberAccountActive={transcriberAccountActive}
          >{`${doctorFirstName} ${doctorLastName}`}</UserText>
        </UserContainer>

        {this.state.showOptions && (
          <AttachmentContainer
            tabIndex="1"
            onBlur={this.closeOptions}
            ref={this.optionsRef}
          >
            {this.state.attachmentTypes.map(renderAttachmentTypes)}
          </AttachmentContainer>
        )}
        {this.state.showCloseConsultationModal && (
          <CloseConsultationModal
            closeConsult={(body) => this.closeConsult(body)}
            hideModal={() => this.hideModal()}
            submitDiagnosis={(data, userInfo) =>
              this.submitDiagnosis(data, userInfo)
            }
          />
        )}
        {
          this.state.showEmergencyContactModal && (
            <EmergencyContactModal 
              hideModal={() => this.hideModal()}
              information = {this.props}
            />
          )
        }
        {this.state.showCancelConsultationModal && (
          <CancelConsultationModal
            closeConsult={(body) => this.closeConsult(body)}
            hideModal={() => this.hideModal()}
          />
        )}
        {this.state.showVoiceCallModal && (
          <VoiceCallSuccessModal hideModal={() => this.hideModal()} />
        )}
        {this.state.showModal && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <CloseContainer onTap={this.closeModal}>
                  <CrossIcon />
                </CloseContainer>
                {this.state.showModalLoading ? (
                  <LoadingComponent />
                ) : (
                  <AttachmentText>{this.state.modalText}</AttachmentText>
                )}
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  activeConsult: state.consults.activeConsult,
  doctorFirstName: state.user.doctorFirstName,
  doctorLastName: state.user.doctorLastName,
  isInhouseDoctor: state.user.isInhouseDoctor,
  isDigitisationAdmin: state.user.isDigitisationAdmin,
  doctorId: state.user.doctorId,
  authToken: state.user.authToken,
  isTranscriber: state.user.transcriber,
  digitisationDetails: state.digitisation,
  transcriberAccountActive: state.app.transcriberAccountActive,
  consultationInfo: state.consults.consultPatientInfo,
  consultUserInfo: state.consults.consultUserInfo,
});

export default connect(mapStateToProps)(DashboardHeader);
