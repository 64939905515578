import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';
import { FaArrowLeft } from 'react-icons/fa';
import { PrimaryButton } from '../../components/common/Buttons'
import { Text } from './Text';
import visitLogo from '../../images/common/visit-logo-white.svg'

const HeaderContainer = styled.div`
  z-index: 10;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #4F34D2;
  padding: 0.875rem;
`;
const Button = styled.button`
  margin-left: 0.625rem;
   width: 16rem; 
  
`;
const BackContainer = styled(Tappable)`
  margin-right: 1.125rem;
  padding: 0.5rem;
  flex : 2
  align-items: right;
  justify-content: space-between;
  &.Tappable-active {
    opacity: 0.2;
  }
`;
const TextData = styled.p`
  margin: 0;
  font-family: Inter, Lato, sans-serif;
  color: ${(props) => props.color || 'white'};
  font-size: ${(props) => props.fontSize || '1.6rem'};
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: 1rem;
`;

const HeaderText = styled(Text)`
  font-size: 1rem;
`;
const HeaderLogo = styled.img`
  height: 1.875rem;
`;
const LogoContainer = styled.img`
  max-width: 9.8rem;
`;

//const Header = ({ title, history, onTap, hideBackButton }) => (
  class Header extends React.Component {  

    render(){
      return(
        <HeaderContainer>
       
        <LogoContainer  src={visitLogo} alt="Visit Logo" />    
     
   
   {/* <BackContainer> */}
  
   
   <div className="flex flex-row pt-3">
        <div className="basis-1/3 pr-12">
      
        <Link to='/admin-table'>
        <Button>
        <TextData>
         Add Admin
        </TextData>
</Button>

        </Link>
      
            </div>
            <div className="basis-1/3">
            <Link to='/admin'>
        <Button>
        <TextData>
         Bulk Assign
        </TextData>
</Button>

        </Link>
        
            </div>
            <div className="basis-1/3 pr-12">
      
      <a href="https://api.getvisitapp.com/v4/digitisations/digitisation/view" target="_blank" rel="noopener noreferrer">
<Button>
<TextData>
 MIS
</TextData>
</Button>
</a>
    </div>
   
    <div className='basis-1/3'>
            <Link to='/transcriber'>
        <Button>
        <TextData>
        Add Digitizer
        </TextData>
</Button>

        </Link>

            </div>
            <div className='basis-1/3'>
            <Link to='/qc-cases'>
        <Button>
        <TextData>
        Qc Cases
          </TextData>
          </Button>
          </Link>
          </div>
   <div className='basis-1/3'>
            <Link to='/suspicious-users'>
        <Button>
        <TextData>
        Suspicious Users
        </TextData>
        </Button>
        </Link>
            </div>
            <div className='basis-1/3'>
            <Link to='/home'>
        <Button>
        <TextData>
         Go Home
        </TextData>
</Button>

        </Link>

            </div>

          
        </div>
        
   {/* </BackContainer> */}

    {/* <HeaderText>{title}</HeaderText> */}
  </HeaderContainer>
      )
    }
 
//);

// Header.propTypes = {
//   title: PropTypes.string.isRequired,
//   hideBackButton: PropTypes.bool,
//   onTap: PropTypes.func,
// };

// Header.defaultProps = {
//   hideBackButton: false,
// };
  }

export default (Header);
