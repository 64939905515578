/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';

// import CardContainer from '../../common/CardContainer';
import { fetchScheduleCardDetail, cancelScheduleCall } from '../../../services';
// import arrowRight from '../../../images/common/arrow-right-blue.svg';
import timeIcon from '../../../images/consultations/time-icon.svg';
import moment from 'moment';
import CancelConfirmModal from '../ConfirmAddModal';
import { Text, BoldText } from '../../common/Text';

const Card = styled.div`
  border-radius: 12px;
  margin: 16px 8px;
`;
const TopHeader = styled.div`
  padding: 16px 20px;
`;
const HeaderText = styled(Text)`
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

const TimeComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`;

const TimeText = styled(Text)`
  font-size: 16px;
  margin-left: 6px;
  font-weight: 600;
  line-height: 24px;
`;


const CancelComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  border-top: 1px solid #e3e3e3;
`;


export class ScheduleCardMessage extends Component {
  state = {
    isCancelled: false,
    showCancelConfirmModal: false,
    cancelledBy:""
  };
  componentDidMount() {
    this.fetchScheduleCardDetail();
  }

  cancelCall = () => {
    const {  authToken, cardDetails } = this.props;
    cancelScheduleCall(cardDetails.consultationId, authToken)
      .then((res) => {
        this.hideModal();
        this.fetchScheduleCardDetail();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchScheduleCardDetail = () => {
    fetchScheduleCardDetail(
      this.props.cardDetails.scheduleId,
      this.props.authToken
    )
      .then((res) => {
        console.log(res);
        this.setState({
          isCancelled: res.isCancelled,
          cancelledBy: res.cancelledBy
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  hideModal = () => {
    this.setState({
      showCancelConfirmModal: false,
    });
  };

  render() {
    const { cardDetails } = this.props;
    console.log(cardDetails);
    let time = '';
    if (cardDetails.rescheduleTime) {
      time += moment(cardDetails.rescheduleTime).format(
        'hh:mm a, MMMM Do YYYY '
      );
    }
    const isCancelled = this.state.isCancelled;
    let text ='You have a call scheduled at:';
    if(isCancelled){
      if(this.state.cancelledBy === 'specialist'){
        text = 'You have cancelled this call'
      }
      else{
        text = 'User have cancelled this call' 
      }
    }
    return (
      <Card
        className="rounded-12 my-16px"
        style={
          isCancelled
            ? { border: '1px solid #F25540' }
            : { border: '1px solid #E3E3E3' }
        }
      >
        <TopHeader className="px-20px py-16px">
          <HeaderText
            style={isCancelled ? { color: '#F25540' } : { color: '#0F0B28' }}
          >
            {text}
          </HeaderText>
          <TimeComponent>
            <img src={timeIcon} />
            <TimeText
              style={{ color: '#3A2CA0' }}
            >
              {time}
            </TimeText>
          </TimeComponent>
        </TopHeader>
        {!isCancelled ? (
          <CancelComponent>
            <HeaderText
              style={{ color: '#F25540' }}
              onClick={() => {
                this.setState({
                  showCancelConfirmModal: true,
                });
              }}
            >
              Cancel
            </HeaderText>
          </CancelComponent>
        ) : (
          ''
        )}
        {this.state.showCancelConfirmModal && (
          <CancelConfirmModal
            status={'Yes, cancel'}
            message={
              'Are you sure you want to cancel ?'
            }
            hideModal={this.hideModal}
            confirmRequest={() => {
              this.cancelCall();
            }}
          />
        )}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(ScheduleCardMessage);
