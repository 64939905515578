import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCheck, FaRegFilePdf } from 'react-icons/fa';

import { Text, RegularText } from '../../common/Text';
import downloadPdf from '../../../images/consultations/downloadPdf.svg';
import StyledTappable from '../../common/StyledTappable';
import moment from 'moment';
import documentPdf from '../../../images/chats/documentPdf.svg';

const OuterContainer = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0.4rem 0;
  margin-left: 12px;
`;

const BubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1rem 1.2rem;
  /* background: #f7f7f7; */
  border-radius: 12px 12px 12px 2px;
  border: 1px solid #f5f5f5;
  background: #714FFF;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #EFEDFF;
  padding: 8px 12px;
  gap: 4px;
`;

const FileIcon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 0.8rem;
  color: #fff;
`;

const FileName = styled(Text)`
  flex: 1;
  font-size: 1.6rem;
  color: #714FFF;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1.6rem;
`;

const TickIcon = styled(FaCheck)`
  opacity: 0.5;
  font-size: 1rem;
  margin-right: 0.4rem;
`;

const TimestampText = styled(RegularText)`
  /* opacity: 0.5;
  font-size: 1.2rem; */
  color: #A5A6BB;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 156%; /* 15.6px */
  letter-spacing: -0.165px;
`;

const DoctorFileMessage = ({ fileName, url, timestamp }) => (
  <OuterContainer
    onTap={() => window.open(url)}>
    <BubbleContainer>
      <MessageContainer>
        <FileIcon src={documentPdf} />
        <FileName>{fileName.split('.')[0]}</FileName>
      </MessageContainer>
    </BubbleContainer>
    {timestamp && (
          <TimestampContainer>
            <TimestampText>
              {moment(timestamp).format('HH:mm A')}
            </TimestampText>
          </TimestampContainer>
        )}
  </OuterContainer>
);

DoctorFileMessage.propTypes = {
  fileName: PropTypes.string,
  url: PropTypes.string,
  timestamp: PropTypes.number,
};

export default DoctorFileMessage;
