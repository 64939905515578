import React, { useState } from 'react';
import styled from 'styled-components';
import pdf from '../../images/doc-pdf.svg';
import PrescriptionModal from '../consultations/PdfViewer';
import { FlyInTransition } from '../transitions/transitions';
import Modal from '../../containers/common/Modal';

const OuterContainer = styled.a`
  width: 121px;
  height: 34px;
  min-width: 121px;
  min-height: 34px;
  border-radius: 8px;
  background: #efedff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  margin-top: 25px;
  margin-left: 16px;
`;

const Img = styled.img`
  width: 16px;
  height: 16px;
`;

const Text = styled.p`
  color: var(--primary, #714fff);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;
const ModalContainer = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;
const Prescription = ({ link }) => {
  console.log(link, 'prescription data');
  const [prescriptionModal, setPrescriptionModal] = useState(false);
  return (
    <OuterContainer
      // href={`${link}`}
      // target="_blank"
      onClick={() => setPrescriptionModal(true)}
    >
      <Img src={pdf} alt="prescription-icon" />
      <Text>Prescription</Text>
      {prescriptionModal && (
        <Modal>
          <ModalContainer>
            <FlyInTransition
              in={prescriptionModal}
              mountOnEnter
              unmountOnExit
              appear
            >
              <PrescriptionModal
                url={link}
                close={() => {
                  setPrescriptionModal(false);
                }}
              />
            </FlyInTransition>
          </ModalContainer>
        </Modal>
      )}
    </OuterContainer>
  );
};

export default Prescription;
