import React, { Component } from 'react';
import styled from 'styled-components';
import Link from "valuelink";
import { Input } from "valuelink/lib/tags";
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { RegularText, Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import moment from 'moment';
import { PrimaryButton } from "../common/Buttons";
import TappableCard from '../common/TappableCard';
import greyTick from '../../images/common/greyTick.svg';
import purpleTick from '../../images/common/purpleTick.svg';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const OptionContainer = styled(StyledTappable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-right: 3rem;
`;

const OptionText = styled(RegularText)`
  /* color: white; */
  font-size: 1.4rem;
  text-align: center;
  margin: 0rem;
  margin-left: 0.25rem;
  padding: 0rem;
`;

const SelectLinkText = styled(StyledTappable)`
  padding: 0.45rem;
`;

const SelectIcon = styled.img`
  width: 2rem;
  align-self: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

class GetOfflineConsultationStatusModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 0,
    };
  }

  optionClicked = (option) => {
    this.setState({ status: option });
  }

  render() {
    // const followupNotesLink = Link.state(this, "followupNotes");
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Please provide the status of this offline consultation</HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <OptionsContainer>
                <OptionContainer onTap={() => this.optionClicked(1)}>
                  <SelectLinkText>
                    {this.state.status === 1 ? <SelectIcon src={purpleTick} alt="Success" /> : <SelectIcon src={greyTick} alt="Success" />}
                  </SelectLinkText>
                  <OptionText>Offline Required</OptionText>
                </OptionContainer>
                <OptionContainer onTap={() => this.optionClicked(2)}>
                  <SelectLinkText>
                    {this.state.status === 2 ? <SelectIcon src={purpleTick} alt="Success" /> : <SelectIcon src={greyTick} alt="Success" />}
                  </SelectLinkText>
                  <OptionText>Offline Not Required</OptionText>
                </OptionContainer>
                <OptionContainer onTap={() => this.optionClicked(3)}>
                  <SelectLinkText>
                    {this.state.status === 3 ? <SelectIcon src={purpleTick} alt="Success" /> : <SelectIcon src={greyTick} alt="Success" />}
                  </SelectLinkText>
                  <OptionText>Not Sure</OptionText>
                </OptionContainer>
              </OptionsContainer>

              {/* <DobContainer>
                <StyledInputBox
                  type="text"
                  className="visit-input"
                  valueLink={followupNotesLink}
                  placeholder="Follow-up Notes"
                />
              </DobContainer> */}

              <InputContainer>
                <ButtonComponent>
                  <Button
                    onTap={() => this.props.onSubmit(this.state.status)}
                    disabled={this.state.status ? false : true}
                  // loading={this.state.loading}
                  >
                    <ButtonText>Submit</ButtonText>
                  </Button>
                </ButtonComponent>
              </InputContainer>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
};

export default GetOfflineConsultationStatusModal;
