export function loginUser(email, password, history, dispatch) {
  return {
    type: 'LOGIN_USER',
    payload: {
      email,
      password,
      history,
      dispatch,
    },
  };
}

export function setUserInfo(userInfo) {
  return {
    type: 'SET_USER_INFO',
    payload: {
      userInfo,
    },
  };
}

export function setInfo(
  pubnubChannelGroup,
  verticals,
  sponsors,
  filters,
  prescriptions,
  endReasons,
  heightMandatory,
  weightMandatory
) {
  return {
    type: 'SET_INFO',
    payload: {
      pubnubChannelGroup,
      verticals,
      sponsors,
      filters,
      prescriptions,
      endReasons,
      heightMandatory,
      weightMandatory
    },
  };
}

export function setAuthToken(authToken) {
  return {
    type: 'SET_AUTH_TOKEN',
    payload: authToken,
  };
}

export function fetchConsults({
  authToken,
  filters,
  selectedSponsorId,
  selectedDoctorId,
  searchText,
  consultationIdKey,
  fromDateSelector,
  toDateSelector
}) {
  console.log('heeelpp', selectedDoctorId);
  return {
    type: 'FETCH_CONSULTS',
    payload: {
      authToken,
      filters,
      selectedSponsorId,
      selectedDoctorId,
      searchText,
      consultationIdKey,
      fromDateSelector,
      toDateSelector
    },
  };
}

export function fetchConsultsOnPagination({
  authToken,
  paginateToken,
  fetchConsultsUrlForPagination,
}) {
  return {
    type: 'FETCH_CONSULTS_ON_PAGINATION',
    payload: {
      authToken,
      paginateToken,
      fetchConsultsUrlForPagination,
    },
  };
}

export function fetchReasons(authToken, query) {
  return {
    type: 'FETCH_REASONS',
    payload: {
      authToken,
      query,
    }
  }
};

export function setConsults(consults) {
  return {
    type: 'SET_CONSULTS',
    payload: {
      consults,
    },
  };
}

export function setFetchingConsults() {
  return {
    type: 'SET_FETCHING_CONSULTS',
  };
}

export function addConsults(consults) {
  return {
    type: 'ADD_CONSULTS',
    payload: {
      consults,
    },
  };
}

export function selectConsult(consult) {
  return {
    type: 'SELECT_CONSULT',
    payload: {
      consult,
    },
  };
}

export function setDoctorLastReadTimetokenForPrevConsult(prevConsult) {
  return {
    type: 'SET_DOCTOR_LAST_READ_TIME_TOKEN',
    payload: {
      consultationId: prevConsult.consultationId,
      // have multipled to 1e7 as pubnub requires precision timetokens
      lastReadTimetoken: ((new Date(Date.now())).getTime() / 1000)*1e7,
    },
  };
}

export function setActiveConsult(consult) {
  return {
    type: 'SET_ACTIVE_CONSULT',
    payload: {
      consult,
    },
  };
}

export function showError(errorTitle, errorDesc) {
  return {
    type: 'SHOW_ERROR',
    payload: {
      hasError: true,
      errorTitle,
      errorDesc,
    },
  };
}

export function hideError() {
  return {
    type: 'HIDE_ERROR',
    payload: null,
  };
}

export function addChatMessage(chatMessage) {
  return {
    type: 'ADD_CHAT_MESSAGE',
    payload: {
      chatMessage,
    },
  };
}

export function sendMessage(chatMessage) {
  return {
    type: 'SEND_MESSAGE',
    payload: {
      chatMessage,
    },
  };
}

export function getQuickReplySuggestions(searchKey, verticalId, sponsorId) {
  return {
    type: 'GET_QUICK_REPLY_SUGGESTIONS',
    payload: {
      searchKey,
      verticalId,
      sponsorId
    }
  }
}

export function setTypingState(status) {
  return {
    type: 'SET_TYPING_STATE',
    payload: {
      status,
    },
  };
}

export function setChatInfo(chatInfo) {
  return {
    type: 'SET_CHAT_INFO',
    payload: {
      ...chatInfo,
    },
  };
}

export function setChatMessages(chatMessages) {
  return {
    type: 'SET_CHAT_MESSAGES',
    payload: {
      chatMessages,
    },
  };
}

export function resetChat() {
  return {
    type: 'RESET_CHAT',
  };
}

export function resetPrescription() {
  return {
    type: 'RESET_PRESCRIPTION',
  };
}

export function showFilterPane() {
  return {
    type: 'SHOW_FILTER_PANE',
  };
}

export function toggleFilter(index) {
  return {
    type: 'TOGGLE_FILTER',
    payload: {
      index,
    },
  };
}

export function hideFilterPane() {
  return {
    type: 'HIDE_FILTER_PANE',
  };
}

export function setSelectedSponsorId(selectedSponsorId) {
  return {
    type: 'SET_SELECTED_SPONSOR_ID',
    payload: {
      selectedSponsorId,
    },
  };
}

export function setFromDate(fromDate) {
  return {
    type: 'SET_FROM_DATE',
    payload: {
     fromDate,
    },
  };
}
export function setToDate(toDate) {
  return {
    type: 'SET_TO_DATE',
    payload: {
     toDate,
    },
  };
}

export function setSelectedDoctorId(selectedDoctorId) {
  return {
    type: 'SET_SELECTED_DOCTOR_ID',
    payload: {
      selectedDoctorId,
    },
  };
}

export function setSelectedSource(selectedSource) {
  return {
    type: 'SET_SELECTED_SOURCE',
    payload: {
      selectedSource,
    },
  };
}

export function setFetchConsultsUrlForPagination(url) {
  return {
    type: 'SET_FETCH_CONSULTS_URL_FOR_PAGINATION',
    payload: {
      url,
    },
  };
}

export function setPrescriptionId(prescriptionId) {
  return {
    type: 'SET_PRESCRIPTION_ID',
    payload: {
      prescriptionId,
    },
  };
}

export function addMedicines(medicines) {
  return {
    type: 'ADD_MEDICINES',
    payload: {
      medicines,
    },
  };
}

export function removeMedicine(medicine) {
  return {
    type: 'REMOVE_MEDICINES',
    payload: {
      medicine,
    },
  };
}

export function addPrescriptionTemplate(template) {
  return {
    type: 'ADD_TEMPLATE',
    payload: {
      template,
    },
  };
}

export function removeTemplate(template) {
  return {
    type: 'REMOVE_TEMPLATE',
    payload: {
      template,
    },
  };
}

export function addLabTests(labTests) {
  return {
    type: 'ADD_LAB_TESTS',
    payload: {
      labTests,
    },
  };
}

export function removeLabTest(lab) {
  return {
    type: 'REMOVE_LAB_TEST',
    payload: {
      lab,
    },
  };
}

export function setTreatmentPlan(treatmentPlan) {
  return {
    type: 'SET_TREATMENT_PLAN',
    payload: {
      treatmentPlan,
    },
  };
}

export function setDoctorTyping(typing, doctorId) {
  return {
    type: 'SET_DOCTOR_TYPING',
    payload: {
      typing,
      doctorId,
    },
  };
}

export function setUnrepliedChannel(channel) {
  return {
    type: 'SET_UNREPLIED_CHANNEL',
    payload: channel,
  };
}

export function updateConsults() {
  return {
    type: 'UPDATE_CONSULTS',
  };
}

export function logoutUser() {
  return {
    type: 'LOGOUT_USER',
  };
}

export function addAdvices(advices) {
  return {
    type: 'ADD_ADVICES',
    payload: {
      advices,
    },
  };
}

export function removeAdvice(advice) {
  return {
    type: 'REMOVE_ADVICE',
    payload: {
      advice,
    },
  };
}

export function savePrescription(consultationId, prescription) {
  return {
    type: 'SAVE_PRESCRIPTION',
    payload: {
      consultationId,
      prescription,
    },
  };
}

export function deleteSavedPrescription(consultationId) {
  return {
    type: 'DELETE_SAVED_PRESCRIPTION',
    payload: {
      consultationId,
    },
  };
}

export function restorePrescription(prescription) {
  return {
    type: 'RESTORE_PRESCRIPTION',
    payload: {
      prescription,
    },
  };
}

export function setAppState(state) {
  return {
    type: 'SET_APP_STATE',
    payload: state,
  };
}

export function addSymptoms(symptoms) {
  return {
    type: 'ADD_SYMPTOM',
    payload: { symptoms },
  };
}

export function getSymptoms(symptoms) {
  return {
    type: 'GET_SYMPTOMS',
    payload: { symptoms },
  };
}

export function changeAnswerSymptoms(
  symptomId,
  questionId,
  optionGroupType,
  optionId,
  answer
) {
  return {
    type: 'CHANGE_ANSWER',
    payload: { symptomId, questionId, optionGroupType, optionId, answer },
  };
}

export function changeEditable(symptomId, isEditable) {
  return {
    type: 'CHANGE_EDITABLE',
    payload: { symptomId, isEditable },
  };
}

export function addConditions(conditions) {
  return {
    type: 'ADD_CONDITION',
    payload: { conditions },
  };
}

export function getConditions(conditions) {
  return {
    type: 'GET_CONDITIONS',
    payload: { conditions },
  };
}

export function changeAnswerConditions(
  conditionId,
  questionId,
  optionGroupType,
  optionId,
  answer
) {
  return {
    type: 'CHANGE_ANSWER_CONDITION',
    payload: { conditionId, questionId, optionGroupType, optionId, answer },
  };
}

export function changeEditableConditions(conditionId, isEditable) {
  return {
    type: 'CHANGE_EDITABLE_CONDITIONS',
    payload: { conditionId, isEditable },
  };
}

export function removeCondition(condition) {
  return {
    type: 'REMOVE_CONDITION',
    payload: { condition },
  };
}

export function resetConditions() {
  return {
    type: 'RESET_CONDITIONS',
  };
}

export function toggleTranscriberAccount() {
  return {
    type: 'TOGGLE_TRANSCRIBER_ACCOUNT',
  };
}

export function addFilesToActiveConsult(files) {
  return {
    type: 'ADD_FILES_TO_ACTIVE_CONSULT',
    payload: {
      files,
    },
  };
}

export function setVideoFeedbackInfo(info) {
  return {
    type: 'SET_VIDEO_FEEDBACK_INFO',
    payload: info,
  };
}

export function resetVideoFeedbackInfo() {
  return {
    type: 'RESET_VIDEO_FEEDBACK_INFO',
  };
}

export function setConsultationUserInfo(data) {
  return {
    type: 'SET_CONSULT_USER_INFO',
    payload: data,
  };
}

export function setConsultationPatientInfo(data) {
  return {
    type: 'SET_CONSULTATION_PATIENT_INFO',
    payload: data,
  };
}

export function setDigitisationInvalidReasons(data) {
  return {
    type:'SET_DIGITISATION_INVALID_REASONS',
    payload: data
  }
}

export function setQuickReplySuggestions(data){
  return {
    type: 'SET_QUICK_REPLY_SUGGESTIONS',
    payload: data
  };
}

export function setVideoCallData(data) {
  return {
    type: 'SET_VIDEO_DATA',
    payload: data
  };
}

export function resetVideoData() {
  return {
    type: 'RESET_VIDEO_DATA'
  };
}

export function setPastChatInfo(chatInfo) {
  return {
    type: 'SET_PAST_CHAT_INFO',
    payload: {
      ...chatInfo,
    }
  }
}

export function setPastChatMessages(pastChatMessages) {
  return {
    type: 'SET_PAST_CHAT_MESSAGES',
    payload: {
      pastChatMessages,
    }
  }
}

export function resetPastChats() {
  return {
    type: 'RESET_PAST_CHAT'
  }
}

export function setActiveConsultChannels(activeConsultChannels) {
  return {
    type: 'SET_ACTIVE_CONSULT_CHANNELS',
    payload: activeConsultChannels
  }
}
