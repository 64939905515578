import React, { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import { fetchMedicalHistory, fetchOptions } from '../../services';
import { showError } from '../../utils';
import PrescriptionViewer from '../user-profile/PrescriptionViewer';
import AddAllergiesModal from './AddAllergiesModal';
import AddChronicAilmentModal from './AddChronicAilmentModal';
import AddFamilyHistoryModal from './AddFamilyHistoryModal';
import AddPastSurgeryModal from './AddPastSurgeryModal';
import ChronicAilment from './ChronicAilment';
import DrugAllergyModal from './DrugAllergyModal';
import FamilyHistory from './FamilyHistory';
import FoodAllergyModal from './FoodAllergyModal';
import OtherAllergyModal from './OtherAllergyModal';
import PastAllergy from './PastAllergy';
import PastSurgery from './PastSurgery';

const OuterContainer = styled.div`
display: flex;
flex: 1 0 1;
/* width: 348px; */
flex-direction: column;
height: auto;
border-radius: 12px;
border: 1px solid #E4E4E4;
background: #FFF;
margin-left: 24px;
margin-right: 24px;
margin-top: 12px;
padding-bottom: 24px;
`

const AddContainer = styled.div`
display: flex;
flex: 1;
/* width: 316px; */
max-height: 44px;
padding: 12px 6px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 12px;
background: rgba(207, 200, 255, 0.40);
margin-top: 16px;
margin-left: 16px;
margin-right: 16px;
cursor: pointer;
`

const AddText = styled.p`
color: #714FFF;
font-size: 12.8px;
font-weight: 600;
line-height: 150%;
`

const Line = styled.div`
display: flex;
flex-direction: column;
/* width: 316px; */
height: 1px;
flex-shrink: 0;
background: #E4E4E47F;
margin-left: 16px;
margin-right: 16px;
margin-top: 14px;
`

const MedicalHistory = ({ authToken, activeConsult }) => {

  const [drugAllergy, setDrugAllergy] = useState([]);
  const [foodAllergy, setFoodAllergy] = useState([]);
  const [otherAllergy, setOtherAllergy] = useState([]);
  const [chronicAilment, setChronicAilment] = useState([]);
  const [pastSurgery, setPastSurgery] = useState([]);
  const [familyHistory, setFamilyHistory] = useState([]);
  const [allergyModal, setAllergyModal] = useState(false);
  const [chronicAilmentModal, setChronicAilmentModal] = useState(false);
  const [pastSurgeryModal, setPastSurgeryModal] = useState(false);
  const [familyHistoryModal, setFamilyHistoryModal] = useState(false);
  const [drugAllergyModal, setDrugAllergyModal] = useState(false);
  const [foodAllergyModal, setFoodAllergyModal] = useState(false);
  const [otherAllergyModal, setOtherAllergyModal] = useState(false);
  const [medicalHistorySaved, setMedicalHistorySaved] = useState(false);
  const [medicalHistoryUpdated, setMedicalHistoryUpdated] = useState(false);
  const [drugAllergyLogs, setDrugAllergyLogs] = useState([]);
  const [foodAllergyLogs, setFoodAllergyLogs] = useState([]);
  const [otherAllergyLogs, setOtherAllergyLogs] = useState([]);
  const [chronicAilmentLogs, setChronicAilmentLogs] = useState([]);
  const [pastSurgeryLogs, setPastSurgeryLogs] = useState([]);
  const [familyHistoryLogs, setFamilyHistoryLogs] = useState([]);

  const { user } = activeConsult;

  useEffect(() => {
    getMedicalHistory();
  }, [ activeConsult, medicalHistorySaved, medicalHistoryUpdated ]);

  const openAllergyModal = () => {
    setAllergyModal(true);
  }

  const closeAllergyModal = () => {
    setAllergyModal(false);
  }

  const openChronicAilmentModal = () => {
    setChronicAilmentModal(true);
  }

  const closeChronicAilmentModal = () => {
    setChronicAilmentModal(false);
  }

    const openPastSurgeryModal = () => {
        setPastSurgeryModal(true);
    }

    const closePastSurgeryModal = () => {
        setPastSurgeryModal(false);
    }

    const openFamilyHistoryModal = () => {
        setFamilyHistoryModal(true);
    }

    const closeFamilyHistoryModal = () => {
        setFamilyHistoryModal(false);
    }

    const openDrugAllergy = () => {
        setAllergyModal(false);
        setDrugAllergyModal(true);
    }
    
      const closeDrugAllergy = () => {
        setDrugAllergyModal(false);
    }
    
      const openFoodAllergy = () => {
        setAllergyModal(false);
        setFoodAllergyModal(true);
    }
    
      const closeFoodAllergy = () => {
        setFoodAllergyModal(false);
    }
    
      const openOtherAllergy = () => {
        setAllergyModal(false);
        setOtherAllergyModal(true);
    }
    
      const closeOtherAllergy = () => {
        setOtherAllergyModal(false);
    }

    const handleMedicalHistorySaved = () => {
        setMedicalHistorySaved((prev) => !prev);
    }

    const handleMedicalHistoryUpdated = () => {
        setMedicalHistoryUpdated((prev) => !prev);
    }

    const getMedicalHistory = async () => {
        try {
            const medicalHistory = await fetchMedicalHistory(authToken, user.userId, user.relativeId);
            console.log(medicalHistory, 'medicalHistory');
            if(medicalHistory.message === 'success') {
                setDrugAllergy(medicalHistory.allergies.drugAllergies);
                setFoodAllergy(medicalHistory.allergies.foodAllergies);
                setOtherAllergy(medicalHistory.allergies.otherAllergies);
                setChronicAilment(medicalHistory.chronicAilments);
                setPastSurgery(medicalHistory.pastSurgeries);
                setFamilyHistory(medicalHistory.familyHistory);
                setDrugAllergyLogs(medicalHistory.allergyLogs.drugAllergiesLogs);
                setFoodAllergyLogs(medicalHistory.allergyLogs.foodAllergiesLogs);
                setOtherAllergyLogs(medicalHistory.allergyLogs.otherAllergiesLogs);
                setChronicAilmentLogs(medicalHistory.chronicAilmentLogs);
                setPastSurgeryLogs(medicalHistory.pastSurgeryLogs);
                setFamilyHistoryLogs(medicalHistory.familyHistoryLogs);
            }
        }
        catch (err) {
            showError(err);
        }
    }

  return (
    <OuterContainer>
        {
            allergyModal && (<AddAllergiesModal authToken = {authToken} openDrugAllergy={openDrugAllergy} openFoodAllergy={openFoodAllergy} openOtherAllergy={openOtherAllergy} close = {() => closeAllergyModal()}/>)
        }
        {
            chronicAilmentModal && (<AddChronicAilmentModal authToken = {authToken} close = {() => closeChronicAilmentModal()} activeConsult = {activeConsult} saved = {handleMedicalHistorySaved}/>)
        }
        {
            pastSurgeryModal && (<AddPastSurgeryModal authToken = {authToken} close = {() => closePastSurgeryModal()} activeConsult = {activeConsult} saved = {handleMedicalHistorySaved}/>)
        }
        {
            familyHistoryModal && (<AddFamilyHistoryModal authToken = {authToken} close = {() => closeFamilyHistoryModal()} activeConsult = {activeConsult} saved = {handleMedicalHistorySaved}/>)
        }
        {
            drugAllergyModal && (<DrugAllergyModal authToken = {authToken} close = {() => closeDrugAllergy()} activeConsult = {activeConsult} saved = {handleMedicalHistorySaved}/>)
        }
        {
            foodAllergyModal && (<FoodAllergyModal authToken = {authToken} close = {() => closeFoodAllergy()} activeConsult = {activeConsult} saved = {handleMedicalHistorySaved}/>)
        }
        {
            otherAllergyModal && (<OtherAllergyModal authToken = {authToken} close = {() => closeOtherAllergy()} activeConsult = {activeConsult} saved = {handleMedicalHistorySaved}/>)
        }
        {
            ((drugAllergy && drugAllergy.length > 0)
            || (foodAllergy && foodAllergy.length > 0)
            || (otherAllergy && otherAllergy.length > 0))
            ? (
                <PastAllergy authToken = {authToken} dlogs = {drugAllergyLogs} flogs = {foodAllergyLogs} ologs = {otherAllergyLogs} drugAllergy={drugAllergy} foodAllergy={foodAllergy} otherAllergy={otherAllergy} open = {openAllergyModal} activeConsult = {activeConsult} deleted = {handleMedicalHistoryUpdated}/>
            ) : (
                <>
                    <AddContainer onClick={() => openAllergyModal()}>
                        <FaPlus style={{color: '#714FFF', height: '12px', width: '12px'}} />
                        <AddText>Add Allergies</AddText>
                    </AddContainer>
                    <Line />
                </>
            )
        }
        {
            (chronicAilment && chronicAilment.length > 0) ? (
                <ChronicAilment authToken = {authToken} chronicAilment = {chronicAilment} logs = {chronicAilmentLogs} open = {openChronicAilmentModal} activeConsult = {activeConsult} deleted = {handleMedicalHistoryUpdated}/>
            ) : (
                <>
                    <AddContainer onClick={() => openChronicAilmentModal()}>
                        <FaPlus style={{color: '#714FFF', height: '12px', width: '12px'}} />
                        <AddText>Add Chronic Ailment</AddText>
                    </AddContainer>
                    <Line />
                </>
            )
        }
        {
            (pastSurgery && pastSurgery.length > 0) ? (
                <PastSurgery authToken={authToken} pastSurgery = {pastSurgery} logs = {pastSurgeryLogs} open = {openPastSurgeryModal} close = {closePastSurgeryModal} activeConsult = {activeConsult} deleted = {handleMedicalHistoryUpdated}/>
            ) : (
                <>
                    <AddContainer onClick={() => openPastSurgeryModal()}>
                        <FaPlus style={{color: '#714FFF', height: '12px', width: '12px'}} />
                        <AddText>Add Past Surgery</AddText>
                    </AddContainer>
                    <Line />
                </>
            )
        }
        {
            (familyHistory && familyHistory.length > 0) ? (
                <FamilyHistory authToken = {authToken} familyHistory = {familyHistory} logs = {familyHistoryLogs} open = {openFamilyHistoryModal} close = {closeFamilyHistoryModal} activeConsult = {activeConsult} deleted = {handleMedicalHistoryUpdated}/>
            ) : (
                <>
                    <AddContainer onClick={() => openFamilyHistoryModal()}>
                        <FaPlus style={{color: '#714FFF', height: '12px', width: '12px'}} />
                        <AddText>Add Family History</AddText>
                    </AddContainer>
                </>
            )
        }
    </OuterContainer>
  )
}

export default MedicalHistory;