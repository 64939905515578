import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import styled from 'styled-components';

import Modal from './containers/common/Modal';
import LoginPage from './containers/LoginPage';
import DashboardHome from './containers/DashboardHome';
import VideoCallContainer from './containers/VideoCallContainer';
import StyledTappable from './components/common/StyledTappable';
import { BoldText, RegularText } from './components/common/Text';
import modalIcon from './images/common/paymentFail.svg';
import Admin from './components/admin/admin'
import AdminTable from './components/admin/AdminTable';
import Digitiser from './components/admin/Digitiser';
import QcCases from './components/admin/QcCases';
import SuspiciousUsers from './components/admin/SuspiciousUsers';
import { hideError } from './actions/index';
import './App.css';

const OuterContainer = styled.div`
  display: flex;
  flex: 1 0;
  overflow-y: hidden;
  /* flex-direction: column;
  align-items: stretch; */
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  max-width: 400px;
  padding: 1.8rem 2rem 3rem 2rem;
  border-radius: 0.8rem;
  margin: 0 1.6rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
`;

const ModalIcon = styled.img`
  width: 9.1rem;
`;

const ModalText = styled(RegularText)`
  font-size: 1.6rem;
`;

const ModalTitle = styled(BoldText)`
  font-size: 1.1rem;
  margin: 0.625rem 0rem;
`;

class App extends Component {
  hideModal = () => {
    const { dispatch } = this.props;
    dispatch(hideError());
  };

  render() {
    return (
      <OuterContainer>
        <Router>
          <>
            <Route path="/" exact component={LoginPage} />
            <Route path="/home" component={DashboardHome} />
            <Route path="/video-call" component={VideoCallContainer} />
            <Route path ="/admin" component={Admin} />
            <Route path ="/admin-table" component={AdminTable} />
            <Route path ="/transcriber" component={Digitiser} />
            <Route path ="/qc-cases" component={QcCases} />
            <Route path ="/suspicious-users" component={SuspiciousUsers} />

          </>
        </Router>
        {this.props.appError.hasError && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <CloseContainer onTap={this.hideModal}>
                  <FaTimes />
                </CloseContainer>
                <ModalIcon src={modalIcon} />
                <ModalTitle>{this.props.appError.errorTitle}</ModalTitle>
                <ModalText>
                  {typeof this.props.appError.errorDesc === 'string'
                    ? this.props.appError.errorDesc
                    : 'Try again later'}
                </ModalText>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = state => ({
  appError: state.app.appError
});

export default connect(mapStateToProps)(App);
