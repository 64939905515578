export const defaultState = {
  loginState: 'LOGGED_OUT',
  transcriberAccountActive: false,
  appState: 'ONLINE',
  filterPane: false,
  loginNumber: null,
  loginCountryCode: null,
  appError: {
    hasError: false,
    errorTitle: 'Something went wrong',
    errorDesc: 'Check your internet connection and try again',
  },
};

export default function app(state = defaultState, action) {
  switch (action.type) {
    case 'OTP_SENT':
      return Object.assign({}, state, {
        loginState: action.type,
        loginNumber: action.payload.phoneNumber,
        loginCountryCode: action.payload.countryCode,
      });
    case 'LOGGED_IN':
      return Object.assign({}, state, {
        loginState: action.type,
      });
    case 'SHOW_ERROR': {
      return Object.assign({}, state, {
        appError: {
          hasError: true,
          errorTitle: action.payload.errorTitle,
          errorDesc: action.payload.errorDesc,
        },
      });
    }
    case 'HIDE_ERROR': {
      return Object.assign({}, state, {
        appError: {
          hasError: false,
          errorTitle: 'Something went wrong',
          errorDesc: 'Check your internet connection and try again',
        },
      });
    }
    case 'SHOW_FILTER_PANE': {
      return Object.assign({}, state, {
        filterPane: !state.filterPane,
      });
    }
    case 'HIDE_FILTER_PANE': {
      return Object.assign({}, state, {
        filterPane: false,
      });
    }
    case 'SET_APP_STATE': {
      return Object.assign({}, state, {
        appState: action.payload,
      });
    }
    case 'LOGOUT_USER': {
      return Object.assign({}, state, defaultState);
    }
    case 'TOGGLE_TRANSCRIBER_ACCOUNT': {
      const flag = !state.transcriberAccountActive;
      return Object.assign({}, state, {
        transcriberAccountActive: flag,
      });
    }
    default:
      return state;
  }
}
