import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';
import styled from 'styled-components';
import {markDuplicateRequest} from '../../src/services/consults'
import { FaTimes } from 'react-icons/fa';
import StyledTappable from '../components/common/StyledTappable';
import TappableCard from '../components/common/TappableCard';
import { PrimaryButton } from '../components/common/Buttons';
import { ToastContainer, toast } from 'react-toastify';
import { fetchConsults, resetPrescription } from '../actions';
import prescriptionIcon from '../images/consultations/pdf-icon.png';
import {
  BoldText,
  Text,
  ButtonText,
  RegularText,
  LinkText,
} from '../components/common/Text';
import {
  getConsultationInfo,
  offlineConsultationStatus,
  getDigitizeConditions,
  saveDigitizeConditions,
  deleteDigitizeCondition,
  removeUploadedPrescription,
  getPrescriptionFiles,
  getDigitiseRequest,
  getPolicyDetailsForStarHealthRequest,
   markDigitizationReqSuspicious,
   fetchTranscriptionConsults
} from '../services';
import GetOfflineConsultationStatusModal from '../components/consultations/GetOfflineConsultationStatusModal';
import {
  getConditions as getConditionsAction,
  resetConditions,
  addFilesToActiveConsult,
  setConsultationUserInfo,
  setConsultationPatientInfo,
  setDigitisationInvalidReasons,
} from '../actions';
import moment from 'moment';
import AddMedicalConditionModal from '../components/symptoms/AddMedicalConditionModal';
import purpleTick from '../images/common/purpleTick.svg';
import config from '../config';
import Modal from '../containers/common/Modal';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;
const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;
const CloseContainer = styled(StyledTappable)`
  align-self: center;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;
const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ReasonButton = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;
const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  padding: 1.6rem 2rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const SectionContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
`;
const PrescriptionRow = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding: 0.625rem 1rem;
  margin-top: 0.625rem;
  padding-bottom: 0rem;
  background: white;
  /* flex-wrap: wrap; */
  /* overflow: hidden; */
`;
const AddedPrescriptionContainer = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 0.625rem;
  background: #f4f4f4;
  box-shadow: 0 0.375rem 0.625rem 5 rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  padding: 0.625rem 1rem;
  border: 1px solid #f4f4f4;
  border-color: ${(props) => props.borderColor || '#f4f4f4'};
`;

const DoctorText = styled(Text)`
  font-size: 1.6rem;
  /* flex: 1; */
`;

const DoctorTextLight = styled(DoctorText)`
  color: #535353;
  flex: 1;
  font-size: 1.4rem;
  flex-wrap: wrap;
`;

const DoctorTextLightColored = styled(DoctorTextLight)`
  color: #4f34d2;
  text-align: center;
`;

const SectionTitle = styled(BoldText)`
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
`;

const SectionDescriptionText = styled(RegularText)`
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
`;

const StyledButton = styled(PrimaryButton)`
  margin: 0.825rem 0;
  align-self: flex-start;
`;

const SecondaryStyledButton = styled(StyledButton)`
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const SecondaryButtonText = styled(ButtonText)`
  color: #4f34d2;
`;

const MedicalHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.25rem 0rem;
`;

const HeaderRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const TickImage = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1.25rem;
`;

const DeleteButton = styled(StyledTappable)`
  margin-left: 3rem;
  /* align-self: flex-end; */
`;

const DeleteText = styled(ButtonText)`
  color: #b2b2b2;
`;

const SymptomName = styled(BoldText)`
  font-size: 1.4rem;
`;

const ConditionText = styled(RegularText)`
  font-size: 1.4rem;
  margin-left: 2.5rem;
`;
const PrescriptionImage = styled.img`
  width: 1.2rem;
  height: 1.5rem;
  margin-right: 0.625rem;
`;

const FileRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
`;

const FileUrl = styled(LinkText)`
  font-size: 1.4rem;
  word-break: break-all;
  margin-left: 1.25rem;
`;
const StyledInputBox = styled(Input)`
  padding: 0.9rem 0;
  flex: 1 0;
  border-bottom: 1px solid #cecece !important;
`;
class DoctorProfileContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consultationInfo: {},
      digitisationDetails: {},
      offlineConsultationStatusModal: false,
      addConditionsModalVisible: false,
      medicalHistory: [],
      lastDigitisedModal: null,
      isInvalid: false,
      invalidReason: '',
      policyInfoForStarHealth: '',
      showMorePolicyDetailsOfStar: '',
      sourceType: '',
      digitizationId :null,
      suspiciousModal:false,
      suspiciousReason:'',
      isLoadingSuspicious:false,
    };
  }

  componentDidMount = () => {
    if (this.props.activeConsult.digitisationRequestId) {
      this.getConsultationInfo();
      this.getConditions();
      this.fetchTranscriptionFiles();
    }
  };

  componentDidUpdate(prevProps) {
    console.log(
      prevProps.activeConsult.digitisationRequestId !==
        this.props.activeConsult.digitisationRequestId, prevProps.activeConsult.digitisationRequestId, this.props.activeConsult.digitisationRequestId
    , 'componentDidMountCheck-2');
    if (
      prevProps.activeConsult.digitisationRequestId !==
      this.props.activeConsult.digitisationRequestId
    ) {
      this.getConsultationInfo();
      this.getConditions();
      this.fetchTranscriptionFiles();
    }
  }

  getConditions = () => {
    const { authToken, activeConsult, dispatch } = this.props;
    if (activeConsult.user && activeConsult.user.userId) {
      getDigitizeConditions(authToken, activeConsult.user.userId).then(
        (res) => {
          dispatch(getConditionsAction(res.data));
        }
      );
    }
  };

  fetchTranscriptionFiles = () => {
    if (this.props.transcriberAccountActive) {
      const { authToken } = this.props;
      getPrescriptionFiles(
        authToken,
        this.props.activeConsult.digitisationRequestId
      )
        .then((result) => {
          console.log({ result }, 'fetchTranscriptionFiles');
          this.props.dispatch(addFilesToActiveConsult(result.documents));
          // this.setState({
          //   files: result.files
          // });
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  };

  getStarHealthPolicyDetailsForDigitisationRequest(token, digitisationId) {
    getPolicyDetailsForStarHealthRequest(token, digitisationId)
      .then((res) => {
        console.log(res.data, 'result');
        if (res.data && res.data.length) {
          this.setState({
            policyInfoForStarHealth: res.data,
          })
        }
      })
  }

  getConsultationInfo = () => {
    let authToken = this.props.authToken;
    getDigitiseRequest(
      authToken,
      this.props.activeConsult.digitisationRequestId,
    )
      .then((res) => {
        console.log({ res }, 'getCOnsultationInfo12');
        console.log(res.digitisationDetails.sourceType, 'consultation info source detail')
        this.setState({
          sourceType: res.digitisationDetails.sourceType,
          digitizationId : res.digitisationDetails.digitisationRequestId
        });

        console.log(config.starSponsorId, this.props.partnerSponsorId);
        
        if (
          (this.props.isPartnerDoctor &&
            parseInt(this.props.partnerSponsorId) === config.starSponsorId) ||
          (res.digitisationDetails.sponsorId === config.starSponsorId)
        ) {
          this.getStarHealthPolicyDetailsForDigitisationRequest(
            authToken,
            this.props.activeConsult.digitisationRequestId
          );
        } else {
          this.setState({
            policyInfoForStarHealth: [],
          });
        }

        if (res.sourceDetails) {
          this.setState({
            digitisationDetails: res.digitisationDetails,
            lastDigitisedModal: res.latestDigitisationInfo,
            consultationInfo: res.sourceDetails.consultationInfo,
          });
        }
        else {
          this.setState({
            digitisationDetails: res.digitisationDetails,
            consultationInfo: null,
            lastDigitisedModal: res.latestDigitisationInfo
          });
        }
        this.props.dispatch(
          setConsultationPatientInfo(res.digitisationDetails)
        );
        this.props.dispatch(
          setDigitisationInvalidReasons(res.invalidReasons.map(e=>{return { label:e.reasonDescription, value: e.reasonId}}))
        )
        this.props.dispatch({
          type: 'SET_DIGITISATION',
          payload: res.digitisationDetails,
        });

      })
      .catch((err) => {
        console.log({ err }, 'error in calling getCOnsultationInfo');
      });
  };

  getAppointmentDate = (date) => {
    const formatDate = Number.parseInt(date) * 1000;
    return moment(formatDate).format('MMM Do YYYY');
  };

  markDuplicate = (requestId) => {
    markDuplicateRequest(
      this.props.authToken,
      this.props.activeConsult.digitisationRequestId,
      requestId
    )
      .then((res) => {
        toast.success('Marked Duplicate')
        this.props.dispatch(
          fetchConsults({
            authToken: this.props.authToken,
            filters: this.props.filters,
            selectedSponsorId: this.props.selectedSponsorId,
          })
        );
      })
      .catch((err) => {
        console.log({ err }, 'error in calling getPastDigitisations');
      });
  };
  renderSuspiciousReasonInputBoxModal =()=>{
    return(
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Specify a reason to mark this Request suspicious</HeaderText>
              <CloseContainer 
              onTap={()=>this.setState({suspiciousModal:false})}
              >
                <CrossIcon />
              </CloseContainer>
            </ModalHeader>

            <Body>
            <input
                type="text"
                placeholder="Enter Reason"
                required
                class="bg-[#fff] border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-3 dark:placeholder-gray-400"
                style={{border:'2px solid #015ECC'}}
                onChange={(e)=>this.setState({suspiciousReason:e.target.value})}
/>         
<ButtonComponent>
                <ReasonButton
                    onTap={()=>this.markSuspicious(this.props.activeConsult)}
                  disabled={
                    !this.state.suspiciousReason || this.state.suspiciousReason.length < 5
                  }
                   loading={this.state.isLoadingSuspicious}
                >
                  <ButtonText>Confirm</ButtonText>
                </ReasonButton>
              </ButtonComponent>
            </Body>
            </ModalBody>
            </ModalContainer>
          
            </Modal>
    )
  }
  renderDoctorInfo = () => {
    return (
      <SectionContainer>
        <SectionTitle>Doctor Information</SectionTitle>
        <SectionDescriptionText>
          {`Doctor Name: ${this.state.consultationInfo.doctorName}`}
        </SectionDescriptionText>
        <SectionDescriptionText>
          {`Doctor Degrees: ${this.state.consultationInfo.doctorDegrees}`}
        </SectionDescriptionText>
        <SectionDescriptionText>
          {`Assistant Name: ${this.state.consultationInfo.assistantName}`}
        </SectionDescriptionText>
        <SectionDescriptionText>
          {`Appointment Date:
            ${this.getAppointmentDate(
              this.state.consultationInfo.appointmentTimeStamp
            )}`}
        </SectionDescriptionText>
        <SectionDescriptionText>
          {`Center Name: ${this.state.consultationInfo.centerName}`}
        </SectionDescriptionText>
        <SectionDescriptionText>
          {`Center Address: ${this.state.consultationInfo.centerAddress}`}
        </SectionDescriptionText>
      </SectionContainer>
    );
  };

  getConsultationType = () => {
    switch (this.props.activeConsult.type) {
      case 'doctorOnCall':
        return 'Doctor On Call';
      case 'onlineSpecialist':
        return 'Specialists Consultation';
      case 'offlineConfirmed':
        return 'Offline Confirmed';
      case 'offlineCancelled':
        return 'Offline Cancelled';
      case 'offlinePending':
        return 'Offline Pending';
      default:
        return '';
    }
  };

  hideModal = () => {
    this.setState({
      offlineConsultationStatusModal: false,
      addConditionsModalVisible: false,
    });
  };

  addConditions = (conditions) => {
    saveDigitizeConditions(
      this.props.authToken,
      conditions,
      this.props.activeConsult.user.userId,
      this.props.activeConsult.digitisationRequestId
    )
      .then(() => {
        this.props.dispatch(resetConditions());
        this.getConditions();
      })
      .finally(() => {
        this.hideModal();
      });
  };

  deleteCondition = (condition) => {
    deleteDigitizeCondition(
      this.props.authToken,
      condition.conditionId,
      this.props.activeConsult.user.userId
    ).then(() => {
      this.getConditions();
    });
  };

  setOfflineConsultationStatus = (status) => {
    offlineConsultationStatus(
      this.props.authToken,
      this.props.activeConsult.consultationId,
      status,
      this.state.consultationInfo.requestId
    )
      .then((result) => {
        console.log({ result }, 'setOfflineConsultationStatus');
        this.hideModal();
      })
      .catch((err) => {
        console.log({ err });
      });
  };
  markSuspicious = async(data)=>{
    this.setState({isLoadingSuspicious:true})
  const {digitisationRequestId,userId}= data;
  const {suspiciousReason} =this.state;
  const body={
    digitisationId:digitisationRequestId,
    userId,
    reason:suspiciousReason
  }
  if(suspiciousReason){
    await markDigitizationReqSuspicious( this.props.authToken,body)
    .then(async(res)=>{
      this.setState({isLoadingSuspicious:false})
     window.location.reload();
     toast.success('Marked Suspicious')
    }).catch((err) => {
        console.log({ err });
      });
    }
    else{
      window.alert('Reason is required')
    }
  }

  renderFiles = () => {
    const { files } = this.props.activeConsult;
    
    const filesComponentArray = [];
    files.forEach((file) => {
      filesComponentArray.push(
        <FileRow>
          <SectionDescriptionText>{file.fileId}</SectionDescriptionText>
          <StyledTappable
            onTap={() => {
              window.open(file.url);
            }}
          >
            <FileUrl>{file.url}</FileUrl>
          </StyledTappable>
        </FileRow>
      );
    });
    

    return (
      <SectionContainer>
        <SectionTitle>Files Section</SectionTitle>
        {filesComponentArray}
        {filesComponentArray.length === 0 && (
          <SectionDescriptionText>
            No Uploaded Prescription found for this consultation
          </SectionDescriptionText>
        )}
      </SectionContainer>
    );
  };

  render() {
    console.log(
      { props: this.props, state: this.state },
      'log in render [DoctorProfileContainer]'
    );

    const renderMedicalHistory = (ele, idx) => {
      const renderQuestionAnswer = (ques) => {
        return (
          <ConditionText key={ques.questionId}>{`${
            ques.questionName
          }: ${ques.answers.join()}`}</ConditionText>
        );
      };

      return (
        <MedicalHistoryContainer key={ele.conditionId}>
          <HeaderRow>
            <TickImage src={purpleTick} />
            <SymptomName>{ele.conditionName}</SymptomName>
            <DeleteButton onTap={() => this.deleteCondition(ele)}>
              <DeleteText>Delete</DeleteText>
            </DeleteButton>
          </HeaderRow>
          {ele.questions.map(renderQuestionAnswer)}
        </MedicalHistoryContainer>
      );
    };

    return (
      <OuterContainer>
        <BodyContainer>
          <SectionContainer>
            <SectionTitle>Source Type</SectionTitle>
            <SectionDescriptionText>
              {this.state.sourceType } - {this.state.digitizationId}
            </SectionDescriptionText>
            {
              this.state.digitisationDetails.orders && this.state.digitisationDetails.orders.placedOrders &&
              <div>
                <SectionDescriptionText style={{ color: 'green' }}>Placed Orders</SectionDescriptionText>
                {this.state.digitisationDetails.orders.placedOrders.map((order) => (
                  <SectionDescriptionText style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
                    OrderId: {order.orderId}, Partner: {order.partnerName}, Delivered: {order.isDelivered}
                  </SectionDescriptionText>
                ))
                }
              </div>
            }
            {
              this.state.digitisationDetails.orders && this.state.digitisationDetails.orders.failedOrders &&
              <div>
                <SectionDescriptionText style={{ color: 'maroon' }}>Failed Orders</SectionDescriptionText>
                {this.state.digitisationDetails.orders.failedOrders.map((order) => (
                  <SectionDescriptionText style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
                    OrderId: {order.orderId}, Partner: {order.partnerName}
                  </SectionDescriptionText>
                ))
                }
              </div>
            }
          </SectionContainer>
          <SectionTitle>Last Digitised Info</SectionTitle>
          {this.state.lastDigitisedModal ? (
            <SectionContainer>
              <SectionDescriptionText>
                {`Patient Name: ${this.state.lastDigitisedModal.nameOfPatient}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Source: ${this.state.lastDigitisedModal.sourceValue}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Date: ${this.state.lastDigitisedModal.appointmentDate}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Doctor Name: ${this.state.lastDigitisedModal.doctorName}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Digitisation Id: ${this.state.lastDigitisedModal.digitisedId}`}
              </SectionDescriptionText>
              {
                this.state.lastDigitisedModal.orders && this.state.lastDigitisedModal.orders.placedOrders &&
                <div>
                  <SectionDescriptionText style={{ color: 'green' }}>Placed Orders</SectionDescriptionText>
                  {this.state.lastDigitisedModal.orders.placedOrders.map((order) => (
                    <SectionDescriptionText style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
                      OrderId: {order.orderId}, Partner: {order.partnerName}, Delivered: {order.isDelivered}
                    </SectionDescriptionText>
                  ))
                  }
                </div>
              }
              {
                this.state.lastDigitisedModal.orders && this.state.lastDigitisedModal.orders.failedOrders &&
                <div>
                  <SectionDescriptionText style={{ color: 'maroon' }}>Failed Orders</SectionDescriptionText>
                  {this.state.lastDigitisedModal.orders.failedOrders.map((order) => (
                    <SectionDescriptionText style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
                      OrderId: {order.orderId}, Partner: {order.partnerName}
                    </SectionDescriptionText>
                  ))
                  }
                </div>
              }
              {!this.props.isPartnerDoctor && (
                // <FileRow>
                //   <StyledTappable
                //     onTap={() => {
                //       window.open(this.state.lastDigitisedModal.fileUrl);
                //     }}
                //   >
                //     <FileUrl>{this.state.lastDigitisedModal.fileUrl}</FileUrl>

                //   </StyledTappable>
                // </FileRow>
                <AddedPrescriptionContainer
                  onTap= {() => window.open(this.state.lastDigitisedModal.fileUrl)}
                >
                <PrescriptionImage src={prescriptionIcon} />
                <DoctorTextLight>Document</DoctorTextLight>
              </AddedPrescriptionContainer>
              )}

              {this.state.lastDigitisedModal.isInvalid === 1 ? (
                <>
                  {this.state.lastDigitisedModal.invalidReason ? (
                    <div>
                      <SectionDescriptionText>
                        Invalid Reason :
                        {this.state.lastDigitisedModal.invalidReason}
                        </SectionDescriptionText>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <PrescriptionRow>
                  <AddedPrescriptionContainer
                    borderColor="#4F34D2"
                    onTap={() =>
                      this.markDuplicate(
                        this.state.lastDigitisedModal.digitisedId
                      )
                    }
                  >
                    <DoctorTextLightColored>
                      Mark Duplicate
                    </DoctorTextLightColored>
                  </AddedPrescriptionContainer>
                </PrescriptionRow>
              )}

              <ToastContainer />
            </SectionContainer>
          ) : (
            <SectionTitle>N.A</SectionTitle>
          )}

          {this.props.activeConsult.files && this.renderFiles()}
          {this.state.consultationInfo &&
          this.state.consultationInfo.relativeId ? (
            <SectionContainer>
              <SectionTitle>Patient Details</SectionTitle>
              <SectionDescriptionText>
                {`Name: ${this.state.consultationInfo.relativeName}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Gender: ${this.state.consultationInfo.relativeGender}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Age: ${this.state.consultationInfo.relativeAge}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Relation: ${this.state.consultationInfo.relativeRelation}`}
              </SectionDescriptionText>
            </SectionContainer>
          ) : null}
          {this.props.activeConsult.user ? (
            <SectionContainer>
              <SectionTitle>User Details</SectionTitle>
              <SectionDescriptionText>
                {`Name: ${this.props.activeConsult.user.userName}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Gender: ${this.props.activeConsult.user.gender}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Age: ${this.props.activeConsult.user.age}`}
              </SectionDescriptionText>
              {!this.props.activeConsult.isSuspicious && this.props.activeConsult.status!=='complete'?
              <AddedPrescriptionContainer
              borderColor="#4F34D2"
              onTap={()=>this.setState({suspiciousModal:true})}>
            <DoctorTextLightColored
            >
            Mark Suspicious
                    </DoctorTextLightColored>
                    </AddedPrescriptionContainer>
  :('')}
            </SectionContainer>
            
            
          ) : null}
          
          { this.state.suspiciousModal?
          
          this.renderSuspiciousReasonInputBoxModal():('')}

          {(this.props.isPartnerDoctor || (this.state.policyInfoForStarHealth && this.state.policyInfoForStarHealth.length > 0)) && (
            <SectionContainer>
              <SectionTitle>Policy Details</SectionTitle>
              {(Array.isArray(this.state.policyInfoForStarHealth) && this.state.policyInfoForStarHealth.length > 0) ? 
                <div>
                  {this.state.policyInfoForStarHealth.map((p, index) => {
                    return (
                      <div   
                        style={{
                          border: '1px solid grey',
                          borderRadius: '8px',
                          padding: '6px',
                          margin: '8px 0px',
                          background: 'rgba(0,0,0,.03)'
                        }}
                      >
                        <SectionTitle>Policy {index + 1}</SectionTitle>
                        <SectionDescriptionText> <b> Policy Number:</b> {p.policyNumber} </SectionDescriptionText>
                        <SectionDescriptionText> <b> Policy Name:</b> {p.policyName} </SectionDescriptionText>
                        <SectionDescriptionText> <b> Policy Start Date:</b> {p.policyStartDate} </SectionDescriptionText>
                        <SectionDescriptionText> <b> Policy End Date:</b> {p.policyEndDate} </SectionDescriptionText>
                        <SectionDescriptionText> <b> Waiting Period: </b> {p.inEligibleForClaim === 'true' ? p.claimIneligibilityReason : 'No Waiting Period'} </SectionDescriptionText>
                        {/* <SectionDescriptionText> <b> Insured Under Ped Waiting Period: </b> {p.insuredUnderPedWaitingPeriod === 1 ? "Yes" : "No"} </SectionDescriptionText> */}
                        
                        {this.state.showMorePolicyDetailsOfStar === index && (
                          <div className="flex flex-col my-6px w-full"
                          style={{
                            fontSize: '1.2rem'
                          }}>
                            <br></br>
                            {showStarPolicyDependentDetails(p.insuredDetails)}
                          </div>
                        )}

                        <div
                          className="flex flex-row m-2 items-center justify-center"
                          style={{
                            background: '#17a2b8',
                            width: '40%',
                            color: 'white',
                            borderRadius: '4px',
                            padding: '4px 2px',
                            cursor: 'pointer',
                            fontSize: '1.4rem'
                          }}
                          onClick={() => {
                            let value = index;
                            if (this.state.showMorePolicyDetailsOfStar === index) {
                              value = null;
                            }
                            this.setState({
                              showMorePolicyDetailsOfStar: value,
                            })
                          }}
                        >
                          {this.state.showMorePolicyDetailsOfStar === index ? 'Less Details' : 'More Details'}
                        </div>
                      </div>
                    )
                  })}
                </div>
              : <SectionDescriptionText>No Policies Found</SectionDescriptionText>}
            </SectionContainer>
          )}

          {this.state.consultationInfo ? this.renderDoctorInfo() : null}

          <SectionContainer>
            <SectionTitle>Medical History</SectionTitle>
            {this.props.savedConditions.map(renderMedicalHistory)}
            <SecondaryStyledButton
              onTap={() => {
                this.setState({ addConditionsModalVisible: true });
              }}
            >
              <SecondaryButtonText>Add Medical History</SecondaryButtonText>
            </SecondaryStyledButton>
          </SectionContainer>
        </BodyContainer>
        {this.state.offlineConsultationStatusModal && (
          <GetOfflineConsultationStatusModal
            onSubmit={(status) => this.setOfflineConsultationStatus(status)}
            hideModal={() => this.hideModal()}
          />
        )}
        {this.state.addConditionsModalVisible && (
          <AddMedicalConditionModal
            onSubmit={(conditions) => this.addConditions(conditions)}
            hideModal={() => this.hideModal()}
          />
        )}
      </OuterContainer>
    );
  }
}

const  showStarPolicyDependentDetails = (dependents) => {
  return dependents.map((dep, index) => {
    return (
      <div className="mt-6px">
        <b>Dependent {index + 1}</b>
        <div className="flex flex-row my-6px w-full">
          <div>
            <b>Name:</b> {dep.name}
          </div>
        </div>
        <div className="flex flex-row my-6px w-full">
          <div>
            <b>Gender: </b> {dep.gender}{' '}
          </div>
        </div>
        { dep.pedList !== null && ( 
          <div className="flex flex-row my-6px w-full">
            <div>
              <b>Ped-List: </b> {dep.pedList}
            </div>
          </div>
          )
        }
         <div className="flex flex-row my-6px w-full">
          <div>
            <b>PED Covered: </b> {dep.insuredUnderPedWaitingPeriod=='1' ?"No" : "Yes"}{' '}
          </div>
        </div>
      </div>
    );
  });
};

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
  user: state.user,
  savedConditions: state.conditions.savedConditions,
  transcriberAccountActive: state.app.transcriberAccountActive,
  isPartnerDoctor: state.user.isPartnerDoctor,
  partnerSponsorId: state.user.partnerSponsorId,
});

export default connect(mapStateToProps)(DoctorProfileContainer);

