import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from 'valuelink';
import Select from 'react-select';
import { FaCircleNotch } from 'react-icons/fa';
import { IoMdAttach, IoMdSend } from 'react-icons/io';
import { Input } from 'valuelink/tags';
import styled from 'styled-components';

import LoadingComponent from '../components/common/LoadingComponent';
import UploadingComponent from '../components/common/UploadingComponent';
import StyledTappable from '../components/common/StyledTappable';
import UserMessage from '../components/consultations/chat-bubbles/UserMessage';
import DoctorMessage from '../components/consultations/chat-bubbles/DoctorMessage';
import UserImageMessage from '../components/consultations/chat-bubbles/UserImageMessage';
import UserFileMessage from '../components/consultations/chat-bubbles/UserFileMessage';
import DoctorImageMessage from '../components/consultations/chat-bubbles/DoctorImageMessage';
import DoctorFileMessage from '../components/consultations/chat-bubbles/DoctorFileMessage';
import ConsultationConfirmedMessage from '../components/consultations/chat-bubbles/ConsultationConfirmedMessage';
import SpecialistSuggestMessage from '../components/consultations/chat-bubbles/SpecialistSuggestMessage';
import CaseDetailsMessage from '../components/consultations/chat-bubbles/CaseDetailsMessage';
import ChatInfoMessage from '../components/consultations/chat-bubbles/ChatInfoMessage';
import ScheduleCardMessage from '../components/consultations/chat-bubbles/ScheduleCardMessage';
import { sendMessage, getQuickReplySuggestions, resetChat, setTypingState, setDoctorLastReadTimetokenForPrevConsult, updateConsults, showError, setVideoCallData, setChatMessages } from '../actions';
import { RegularText, BoldText, Text } from '../components/common/Text';
import RecommendSpecialistModal from '../components/consultations/RecommendSpecialistModal';
import {
  uploadImageAttachment,
  uploadDietAttachment,
  recommendSpecialist,
  scheduleCall,
  fetchCallBacksForConsult,
  doctorInitiateVideoCall,
  markCallbackComplete,
  voiceCallUser,
  closeConsultationNew,
  submitDiagnosisWhileClosingConsultation,
} from '../services';
import { initializeChatClient } from '../services/chatClient';

import star from '../images/attachments/star.svg';
import doctor from '../images/attachments/doctor.svg';
import message from '../images/attachments/message.svg';
import followup from '../images/attachments/followup.svg';
import gallery from '../images/attachments/gallery.svg';
import opd from '../images/attachments/opd.svg';
import specialistSuggestIcon from '../images/consultations/specialist.svg';
import TypingIndicator from '../components/consultations/TypingIndicator';
import { reverseDebounce } from '../utils';
import moment from 'moment';
import VideoCallCard from '../components/consultations/chat-bubbles/VideoCallCard';
import VideoFeedback from '../components/consultations/VideoFeedback';
import ScheduleModal from '../components/consultations/ScheduleModal';
import { store } from '../store';
import voice from '../images/voiceCallbacks.svg';
import video from '../images/videoCallbacks.svg';
import chatVoice from '../images/chats/call.svg';
import chatVideo from '../images/chats/video.svg';
import chatAdd from '../images/chats/add.svg';
import chatCamera from '../images/chats/camera.svg';
import chatMore from '../images/chats/more.svg';
import lucide from '../images/chats/lucide.svg';
import stethoscope from '../images/chats/stethoscope.svg';
import prescription from '../images/chats/prescription.svg';
import documentFile from '../images/chats/documentFile.svg';
import NoActiveChat from '../components/NoActiveChat';
import VideoCallContainer from './VideoCallContainer';
import EmergencyContactModal from '../components/consultations/EmergencyContacts/EmergencyContactModal';
import CancelConsultationModal from '../components/consultations/CancelConsultationModal';
import CloseConsultationModal from '../components/consultations/CloseConsultationModal';
import config from '../config';


const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2.5;
  position: relative;
  overflow: hidden;
  padding: 30px 24px 25px;
`;

const ChatContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    max-height: 100%;
    border-radius: 20px;
    background: linear-gradient(180deg, #F4F2FF 0%, rgba(255, 255, 255, 0.00) 45%);
    box-shadow: 2px 2px 8px 0px rgba(113, 79, 255, 0.21);
    padding: 0px 0px 20px;
`

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: white;
  padding: 0.6rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(180deg, #F4F2FF 0%, rgba(255, 255, 255, 0.00) 45%);
`;

const StyledUploadingComponent = styled(UploadingComponent)`
  margin: 0;
`;

const InputContainer = styled.div`
  position:relative;
  display: flex;
  align-items: stretch;
  /* box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.1);
  background: white;
  border-top: 1px solid #dfdfdf; */
  padding: 0 1.6rem;
  border-radius: 20px;
  background: #F4F5F6;
  margin: 10px 20px 0px;
  height: 45px;
`;

const AttachIcon = styled(IoMdAttach)`
  font-size: 2rem;
`;

const InputBox = styled(Input)`
  color: #585969;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 156%; /* 24.96px */
  letter-spacing: -0.165px;
  padding: 1.6rem;
  display: flex;
  align-self: stretch;
  flex: 1;
  ::placeholder {
    color: #bababa;
  }
  border-radius: 20px;
  background: #F4F5F6;
  :focus {
    outline: none;
  }
`;

const OptionContainer = styled(StyledTappable)`
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 1.25rem;
  /* align-items: flex-start; */
`;

const Icon = styled.img`
  width: 2rem;
  margin-right: 0.625rem;
`;

const AttachmentText = styled(RegularText)`
  margin: 2rem 0.625rem;
  font-size: 1.6rem;
`;

const AttachmentContainer = styled.div`
  position: absolute;
  flex: 1 0;
  bottom: 5rem;
  left: 3rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  &:focus {
    border: none;
    outline: none;
  }
`;

const SendIcon = styled(IoMdSend)`
  opacity: 0.5;
  font-size: 2rem;
`;

const SpecialistSuggestContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.25rem;
  align-self: center;
  align-items: center;
`;

const SpecialistSuggestIcon = styled.img`
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.625rem;
`;

const SpecialistSuggestText = styled(BoldText)`
  font-size: 1.4rem;
`;

const TimestampText = styled(RegularText)`
  color: #000;
  opacity: 0.5;
  font-size: 1.2rem;
  margin-left: 0.625rem;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`;

const LoadingIcon = styled(FaCircleNotch)`
  margin-right: 0.5rem;
`;

const DateElement = styled.div`
  align-self: center;
  padding: 0.5rem;
  margin: 0.5rem 0rem;
  /* background-color: #d8d8d8; */
  /* border-radius: 1.5rem; */
  border-radius: 16px;
  background: rgba(15, 11, 40, 0.30);
  padding: 2px 12px;
`;

const DateText = styled(RegularText)`
  text-align: center;
  /* font-size: 1.2rem;
  color: #000; */
  color: #FFF;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 156%; /* 15.6px */
  letter-spacing: -0.165px;
`;

const QuickReplySuggestionBox = styled.div`
  position: absolute;
  bottom: 6.7rem;
  font-size: 1.3rem;
  max-width: 100%;
  min-width: 90%;
  min-height: fit-content;
  max-height: 30vh;
  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.1);
  background: white;
  border-top: 1px solid #dfdfdf;
  border-radius:5px;
  padding: 0.5rem 0rem;
`;

const SelectElement = styled.div`
  align-self: auto;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
`;

const CallBackContainer = styled.div`
height: 100px;
border-bottom: 2px solid #714FFF;
background: #EEECFF;
box-shadow: 2px 2px 8px 0px rgba(113, 79, 255, 0.24);
`

const CallBackInfoContainer = styled.div`
display: flex;
justify-content: space-between;
`

const CallBackText = styled.p`
color: var(--primary-text-new, #0F0B28);
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 140%;
letter-spacing: 0.25px;
margin-top: 8px;
margin-left: 16px;
`

const CallBackDate = styled.p`
color: var(--text-secondary-8, #A5A6BB);
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 140%;
margin-top: 10px;
margin-right: 16px;
`

const Line = styled.div`
height: 1px;
background: rgba(165, 166, 187, 0.20);
margin-top: 8px;
`

const CallBackButtons = styled.div`
margin-top: 12px;
display: flex;
gap: 16px;
`

const CallBackVideoContainer = styled.div`
display: flex;
align-items: center;
gap: 5px;
width: 154px;
height: 36px;
border-radius: 12px;
border: 1px solid #E4E4E4;
background: #FFF;
margin-left: 15px;
cursor: pointer;
`

const CallBackVoiceContainer = styled.div`
display: flex;
align-items: center;
gap: 5px;
width: 154px;
height: 36px;
border-radius: 12px;
border: 1px solid #E4E4E4;
background: #FFF;
cursor: pointer;
`

const CallBackComplete = styled.button`
display: flex;
width: 145px;
height: 36px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 12px;
background: #714FFF;
color: var(--white, #FFF);
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 150%; 
letter-spacing: 0.25px;
margin-right: 15px;
`

const VideoText = styled.p`
color: var(--primary-text-new, #0F0B28);
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 120%; 
letter-spacing: 0.25px;
`

const VoiceText = styled.p`
color: var(--primary-text-new, #0F0B28);
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 120%;
letter-spacing: 0.25px;
`

const UserInfoContainer = styled.div`
position: relative;
display: flex;
justify-content: space-between;
height: 70px;
border-radius: 20px 20px 0px 0px;
background: #FFF;
padding: 24px;
`

const UserInfoName = styled.p`
color: #0F0B28;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 120%; 
`

const UserInfoCallContainer = styled.div`
display: flex;
gap: 20px;
`

const InputOptionsContainer = styled.div`
display: flex;
align-items: center;
gap: 12px;
`

const ChatVideo = styled.img`
cursor: pointer;
`

const ChatVoice = styled.img`
cursor: pointer;
`

const ChatMore = styled.img`
cursor: pointer;
`

const CameraIcon = styled.img`
width: 24px;
height: 24px;
cursor: pointer;
`

const PlusIcon = styled.img`
width: 24px;
height: 24px;
cursor: pointer;
`

const MoreContainer = styled.div`
position: absolute;
width: 194px;
top: 55px
right: 31px;
display: flex;
flex-direction: column;
border-radius: 12px 0px 12px 12px;
background: #FFF;
box-shadow: 2px 2px 10px 0px rgba(48, 46, 107, 0.25);
padding: 16px;
gap: 12px;
`

const MoreOptionsContainer = styled.div`
display: flex;
border-bottom: 1px solid #F0F3F6;
background: black;
`

const MoreText = styled.p`
color: #0F0B28;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 156%; /* 21.84px */
letter-spacing: -0.165px;
cursor: pointer;
`

const AddContainer = styled.div`
position: absolute;
right: 20px;
top: -210px;
display: flex;
flex-direction: column;
border-radius: 12px 0px 12px 12px;
background: #FFF;
box-shadow: 2px 2px 10px 0px rgba(48, 46, 107, 0.25);
padding: 16px;
gap: 12px;
`

const AddInfoContainer = styled.div`
display: flex;
align-items: center;
gap: 12px;
cursor: pointer;
`

const InfoImg = styled.img`
`

const Info = styled.p`
color: #585969;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 150%;
`

const GoBackBtn = styled.button`
color: #FFF;
font-size: 14.5px;
font-style: normal;
font-weight: 600;
line-height: 156%; /* 24.96px */
letter-spacing: -0.165px;
display: flex;
margin: 0px 24px;
margin-top: 35px;
padding: 16px 96px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 12px;
background: #714FFF;
`

const ConsultIdElement = styled.div`
display: flex;
padding: 2px 8px;
align-self: center;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 8px;
background: rgba(255, 255, 255, 0.80);
box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.12);
`;

const ConsultIdText = styled.div`
color: #38A169;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 156%;
letter-spacing: -0.165px;
`

const ConsultIdTextSpan = styled.span`
color: #A5A6BB;
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: 156%;
letter-spacing: -0.165px;
`

const initialState = {
  chatMessage: '',
  loading: true,
  uploading: false,
  uploadProgress: 0,
  showAttachmentContainer: false,
  stopHistoryFetch: false,
  attachmentTypes: [
    { type: 'file', name: 'Send File' },
    { type: 'diet', name: 'Diet Plan' },
    { type: 'specialist', name: 'Recommend a Specialist' },
    { type: 'messages', name: 'Standard Messages' },
    // { type: 'followups', name: 'Add Follow-up' },
    { type: 'rating', name: 'Rating Card' },
    { type: 'opd', name: 'Group Outpatient Insurance Card' },
    { type: 'schedule', name: 'Schedule Call' },
  ],
  showSpecialistModal: false,
  fetchMessages: false,
  showVideoFeedbackModal: false,
  showScheduleModal: false,
  showQuickReplySuggestionBox: false,
  callBacksForConsult: {},
  moreModal: false,
  addModal: false,
  showEmergencyContactModal: false,
  currentChannelIndex: 0,
  currentBatchIndex: 1,
};

class PastChatContainer extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
    this.conversationBodyRef = React.createRef();

    this.chatClient = initializeChatClient();
    this.channel = props.activeConsult.channel;
    if (props.chatInfo.pastChatMessages.length === 0) {
      this.chatClient.history({
        channel: this.channel,
        count: 30,
      });
    }
    // this.chatClient.subscribe(this.channel);
    console.log({ props: this.props, state: this.state });

    this.throttledTypingStatusUpdate = reverseDebounce(() => {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.chatClient.setState('TYPING', this.props.user.doctorId);
      this.timeoutId = setTimeout(() => {
        // send setState to pubnub with status: ‘STOP_TYPING’
        this.chatClient.setState('STOP_TYPING', this.props.user.doctorId);
      }, 10000);
    }, 2000);
    this.attachmentContainerRef = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.handleKeyPress, false);
    const { chatInfo } = this.props;
    if (chatInfo.pastChatMessages.length > 0) {
      if (this.conversationBodyRef) {
        const bodyList = this.conversationBodyRef.current;
        bodyList.scrollTop = bodyList.scrollHeight;
      }
    }
    if (this.props.user && !this.props.user.canReschedule) {
      this.setState({
        attachmentTypes: this.state.attachmentTypes.filter(
          (ele) => ele.type !== 'schedule'
        ),
      });
    }
    // this.checkForVideoFeedback();
    this.getCallBacksForConsult();
  };
  checkForVideoFeedback = () => {
    if (this.props.videoFeedback.sessionId) {
      this.setState({ showVideoFeedbackModal: true });
    }
  };

  getSnapshotBeforeUpdate = (prevProps, prevState) => {
    const { pastChatMessages } = this.props.chatInfo;
    const { fetchMessages } = this.state;
    const previousChatMessages = prevProps.chatInfo.pastChatMessages;
    if (fetchMessages && pastChatMessages.length > previousChatMessages.length) {
      const list = this.conversationBodyRef.current;
      return list.scrollHeight - list.scrollTop;
    }
    return null;
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (snapshot) {
      const list = this.conversationBodyRef.current;
      list.scrollTop = list.scrollHeight - snapshot - 50;
      return;
    }
    const { chatInfo } = this.props;
    const previousChatInfo = prevProps.chatInfo;
    if (chatInfo.consultationId !== previousChatInfo.consultationId) {
      // Chat has changed
      this.setState({ ...initialState });
      if (this.props.user && !this.props.user.canReschedule) {
        this.setState({
          attachmentTypes: this.state.attachmentTypes.filter(
            (ele) => ele.type !== 'schedule'
          ),
        });
      }
    }
    if (chatInfo.pastChatMessages.length > previousChatInfo.pastChatMessages.length) {
      if (this.conversationBodyRef) {
        const bodyList = this.conversationBodyRef.current;
        bodyList.scrollTop = bodyList.scrollHeight;
      }
    }
    if (
      this.props.videoFeedback.sessionId &&
      !this.state.showVideoFeedbackModal
    ) {
      this.checkForVideoFeedback();
    }
    if(this.props.activeConsult !== prevProps.activeConsult) {
      this.getCallBacksForConsult();
    }
  };

  componentWillUnmount = () => {
    console.log('componentWillUnmount');
    const { chatInfo, dispatch } = this.props;
    if (this.chatClient) {
      this.chatClient.unsubscribe(chatInfo.chatChannel);
    }
    this.setState({ stopHistoryFetch: false });
    dispatch(resetChat());
  };

  handleKeyPress = (event) => {
    // console.log({ event, focus: document.activeElement.id });
    if (event.code === 'Enter') {
      this.sendMessage();
    } else if( event.code === 'Slash') {
      // if user presses forward slash, open the selection thing rt above the message input box
      this.setState({
        showQuickReplySuggestionBox: true
      });
      const { dispatch, activeConsult, user } = this.props;
      console.log(
        {
          searchKey:'',
          vertical: user.verticalId,
          sponsorId: activeConsult.sponsorId,
        },
        'getting quick reply'
      );
      dispatch(getQuickReplySuggestions(
        '',
        user.verticalId,
        activeConsult.sponsor.id));
    } else {
      setTimeout(this.handleKeyPressDebounced, 100);
    }
  };

  handleKeyPressDebounced = () => {
    // check here whether user is typing the short-code for a quick reply
    const { chatMessage } = this.state;
    const indexOfSlash = chatMessage.indexOf('/');
    if (
      indexOfSlash > -1 &&
      ((chatMessage.length - 1 > indexOfSlash &&
        chatMessage[indexOfSlash + 1] !== ' ') ||
        chatMessage.length - 1 === indexOfSlash)
    ) {
      const searchKey =
        chatMessage.length - 1 === indexOfSlash
          ? ''
          : chatMessage.substring(indexOfSlash + 1);
      this.setState({
        showQuickReplySuggestionBox: true,
      });
      const { dispatch, activeConsult, user } = this.props;
      console.log(
        {
          searchKey,
          vertical: user.verticalId,
          sponsorId: activeConsult.sponsorId,
        },
        'getting quick reply'
      );
      dispatch(
        getQuickReplySuggestions(
          searchKey,
          user.verticalId,
          activeConsult.sponsor.id
        )
      );
    } else {
      this.setState({
        showQuickReplySuggestionBox: false,
      });
    }
  }

  sendMessage = () => {
    const { chatMessage } = this.state;
    const { dispatch, activeConsult } = this.props;
    if (chatMessage.trim() === '') {
      return;
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.chatClient.setState('STOP_TYPING', this.props.user.doctorId);
    // dispatch(setTypingState('STOP_TYPING'));
    const messagePayload = {
      text: chatMessage,
    };
    dispatch(sendMessage(messagePayload));
    // store.dispatch(updateConsults());
    this.setState({
      chatMessage: '',
    });
  };

  attachFile = () => {
    // this.setState(
    //   {
    //     showAttachmentContainer: !this.state.showAttachmentContainer,
    //   },
    //   () => {
    //     this.attachmentContainerRef.current &&
    //       this.attachmentContainerRef.current.focus();
    //   }
    // );
    this.openAttachment();
  };

  attachImage = () => {
    this.openImageAttachmemt();
  }

  attachDietPlan = () => {
    this.setState({addModal: false});
    this.openDietAttachment();
  }

  openAttachment = () => {
    this.setState({addModal: false});
    document.querySelector('#documentAttachment').click();
  };

  openDietAttachment = () => {
    this.setState({addModal: false});
    document.querySelector('#dietAttachment').click();
  };

  openImageAttachmemt = () => {
    this.setState({addModal: false});
    document.querySelector('#imageAttachment').click();
  }

  openAttachmentType = (ele, idx) => {
    console.log({ ele, idx });
    switch (ele.type) {
      case 'specialist':
        this.setState({
          showAttachmentContainer: false,
          showSpecialistModal: true,
        });
        break;
      case 'diet': {
        this.setState({ showAttachmentContainer: false });
        this.openDietAttachment();
        break;
      }
      case 'file': {
        this.setState({ showAttachmentContainer: false });
        this.openAttachment();
        break;
      }
      case 'schedule': {
        this.setState({
          showScheduleModal: true,
          showAttachmentContainer: false,
        });
      }
      default:
        break;
    }
  };

  hideModal = () => {
    this.setState({
      showAttachmentContainer: false,
      showSpecialistModal: false,
      showScheduleModal: false,
      showEmergencyContactModal: false,
      showCancelConsultationModal: false,
      showCloseConsultationModal: false,
    });
  };

  scheduleCall = (date) => {
    console.log(new Date(date).valueOf());
    const body = {
      datetime: Number(new Date(date).valueOf()),
      consultationId: this.props.activeConsult.consultationId,
    };
    scheduleCall(this.props.authToken, body)
      .then((res) => {
        this.hideModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  recommendSpecialist = (body) => {
    console.log({ body, activeConsult: this.props.activeConsult });

    recommendSpecialist(
      this.props.authToken,
      this.props.activeConsult.user.userId,
      body.vertical,
      body.consultType,
      this.props.activeConsult.consultationId
    )
      .then((result) => {
        console.log({ result }, 'recommendSpecialist');
        this.hideModal();
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  handleAttachment = () => {
    const { user, authToken } = this.props;
    const { consultationId, receiverId } = this.props.chatInfo;
    const file = document.querySelector('#documentAttachment').files[0];
    if (file) {
      this.setState({
        uploading: true,
      });
      document.querySelector('#documentAttachment').value = null;
      const onUploadProgress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const { loaded, total } = progressEvent;
          this.setState({
            uploadProgress: (loaded / total) * 100,
          });
        } else {
          this.setState({
            uploadProgress: 100,
          });
        }
      };
      uploadImageAttachment(
        file,
        user.doctorId,
        consultationId,
        receiverId,
        onUploadProgress,
        authToken
      )
        .then((res) => {
          this.setState({
            uploading: false,
            uploadProgress: 0,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleImageAttachment = () => {
    const { user, authToken } = this.props;
    const { consultationId, receiverId } = this.props.chatInfo;
    const file = document.querySelector('#imageAttachment').files[0];
    if (file) {
      this.setState({
        uploading: true,
      });
      document.querySelector('#imageAttachment').value = null;
      const onUploadProgress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const { loaded, total } = progressEvent;
          this.setState({
            uploadProgress: (loaded / total) * 100,
          });
        } else {
          this.setState({
            uploadProgress: 100,
          });
        }
      };
      uploadImageAttachment(
        file,
        user.doctorId,
        consultationId,
        receiverId,
        onUploadProgress,
        authToken
      )
        .then((res) => {
          this.setState({
            uploading: false,
            uploadProgress: 0,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleDietAttachment = () => {
    const { user, authToken } = this.props;
    const { consultationId, receiverId } = this.props.chatInfo;
    const file = document.querySelector('#dietAttachment').files[0];
    if (file) {
      this.setState({
        uploading: true,
      });
      document.querySelector('#dietAttachment').value = null;
      const onUploadProgress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const { loaded, total } = progressEvent;
          this.setState({
            uploadProgress: (loaded / total) * 100,
          });
        } else {
          this.setState({
            uploadProgress: 100,
          });
        }
      };
      uploadDietAttachment(
        file,
        user.doctorId,
        consultationId,
        receiverId,
        onUploadProgress,
        authToken
      )
        .then((res) => {
          this.setState({
            uploading: false,
            uploadProgress: 0,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  fetchMessages = () => {
    const { pastChatMessages } = this.props.chatInfo;
    const { channels } = this.props.activeConsultChannels;
    const { currentChannelIndex, currentBatchIndex } = this.state;
    const chatChannels = channels;
    console.log({ currentChannelIndex, currentBatchIndex, chatChannels});
    console.log(this.props.activeConsult.consultationId);
    if(currentChannelIndex < chatChannels.length) {
      const currentChannelId = chatChannels[currentChannelIndex];
      this.setState({
        fetchMessages: true,
      });
      this.chatClient.getNextHistoryBatch(currentChannelId, pastChatMessages[0].timetoken * 10000 - 100)
        .then((formattedMessages) => {
          // Update the state for the next batch or channel
          console.log(formattedMessages);
          let stopHistoryFetch = false;
          // const allMessagesFetched = currentBatchIndex * 30 >= formattedMessages.length;
          const allMessagesFetched = (formattedMessages < 30);
          if (!stopHistoryFetch) {
            this.setState({
              currentChannelIndex: allMessagesFetched ? this.state.currentChannelIndex + 1 : this.state.currentChannelIndex,
              currentBatchIndex: allMessagesFetched ? 1 : this.state.currentBatchIndex + 1
            }, () => {
              this.fetchMessages();
            });
          }
          if (!formattedMessages || formattedMessages.length === 0) {
            // Tried fetching history, found nothing. stop fetching
            console.log('hit');
            stopHistoryFetch = true;
          }
          this.setState({
            stopHistoryFetch,
            fetchMessages: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  onListScroll = (event) => {
    const { pastChatMessages } = this.props.chatInfo;
    const { scrollTop } = event.target;
    console.log(scrollTop);
    const { fetchMessages, stopHistoryFetch } = this.state;
    if (!stopHistoryFetch && scrollTop <= 0 && pastChatMessages.length >= 10) {
      if (!fetchMessages) {
        this.fetchMessages();
      }
    }
  };

  getDateElement = (currentMsgTT, prevMsgTT) => {
    const currentMsgTime = moment(currentMsgTT);
    const prevMsgTime = moment(prevMsgTT);

    let dateText = null;

    if (
      currentMsgTime.format('DD') > prevMsgTime.format('DD') ||
      currentMsgTime.format('MM') > prevMsgTime.format('MM') ||
      currentMsgTime.format('YYYY') > prevMsgTime.format('YYYY')
    ) {
      const todayTT = moment().startOf('day');
      const yesterdayTT = moment().startOf('day').subtract(1, 'day');
      if (currentMsgTime.valueOf() > todayTT.valueOf()) {
        dateText = 'Today';
      } else if (currentMsgTime.valueOf() > yesterdayTT.valueOf()) {
        dateText = 'Yesterday';
      } else {
        dateText = currentMsgTime.format('Do MMM YYYY');
      }
    }

    if (dateText) {
      return (
        <DateElement>
          <DateText>{dateText}</DateText>
        </DateElement>
      );
    }
    return null;
  };

  getPrevConsultId = (idx, array) => {
    console.log('hit');
    for (let i = idx - 1; i >= 0; i--) {
      if (array[i].consultationId) {
        return array[i].consultationId;
      }
    }
    return null;
  };

  getConsultTag = ({currentMsg, prevMsg, start, idx, array}) => {
    const currentConsultId = currentMsg;
    const prevConsultId = this.getPrevConsultId(idx, array);
    console.log(currentConsultId, prevConsultId);
    if (currentConsultId && (prevConsultId || start) && currentConsultId !== prevConsultId) {
      return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '14px', margin: '14px'}}>
          {
            prevConsultId && (
              <ConsultIdElement>
                <ConsultIdText style={{color: '#FF754C'}}>Consultation Closed</ConsultIdText>
              </ConsultIdElement>
            )
          }
          {
            currentConsultId && (
              <ConsultIdElement>
                <ConsultIdText>Consultation Started<ConsultIdTextSpan> | Id: {currentConsultId}</ConsultIdTextSpan></ConsultIdText>
              </ConsultIdElement>
            )
          }
        </div>
      )
    }
  }

  closeAttachmentContainer = () => {
    this.setState({
      showAttachmentContainer: false,
    });
  };

  getCallBacksForConsult = async () => {
    try {
      const data = await fetchCallBacksForConsult(this.props.authToken, this.props.activeConsult.consultationId);
      console.log(data, this.props.activeConsult);
      this.setState({callBacksForConsult: data});
    }
    catch(err) {
      showError(err);
    }
  }

  initiateVideoCall = () => {
    doctorInitiateVideoCall(
      this.props.authToken,
      this.props.user.doctorId,
      this.props.activeConsult.consultationId
    )
      .then((result) => {
        console.log({ result }, 'result from initiateVideoCall');
        const { dispatch } = this.props;
        result.authToken = this.props.authToken;
        result.startCall = true;
        dispatch(setVideoCallData(result));
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  initiateVoiceCall = () => {
      voiceCallUser(
        this.props.authToken,
        this.props.activeConsult.consultationId
      )
        .then((result) => {
          console.log({ result }, 'voice call initiated');
          // this.setState({ showVoiceCallModal: true });
        })
        .catch((err) => {
          console.log({ err });
          showError(err);
        });
  };

  markCallbackAsComplete = async () => {
    try {
      await markCallbackComplete(this.props.authToken, this.state.callBacksForConsult.latestCallBack.connectionRequestId, this.props.activeConsult.consultationId);
      this.getCallBacksForConsult();
    }
    catch(err) {
      showError(err);
    }
  }

  handlePrescription = () => {
    this.props.renderPrescription();
  }

  closeConsult = (body) => {
    console.log({ body, activeConsult: this.props.activeConsult });

    closeConsultationNew(
      this.props.authToken,
      this.props.activeConsult.consultationId,
      // body.followDateTime,
      // body.followupNotes,
      body.endReason,
      body.closedType,
      body.consultationNote,
      body.othersRemark
    )
      .then((result) => {
        console.log({ result }, 'closeConsult');
        console.log('update called on cancel');
        store.dispatch(updateConsults());
        this.hideModal();
      })
      .catch((err) => {
        console.log({ err });
        this.hideModal();
      });
  };

  submitDiagnosis = (data, userInfo) => {
    console.log(userInfo, 'submitDiagnosis');
    const { consultationInfo, consultUserInfo, authToken } = this.props;
    const body = {
      'Lead date': moment().format('DD MMM YY'),
      'Employee Name': userInfo.userName,
      'Employee Phone number': userInfo.phone,
      'Patient Name': consultationInfo.relativeName,
      'Patient Age': consultationInfo.relativeAge,
      'Relationship with employee': consultationInfo.relativeRelation,
      'Doctor Name': `${this.props.doctorFirstName} ${this.props.doctorLastName}`,
      Sex: consultationInfo.relativeGender,
      Ecard: 'WEB',
      Company: userInfo.sponsorName,
      City: consultationInfo.orgAddress,
      Diagnosis: data.selectedDiagnosis,
    };
    console.log(body, 'The diagnosis data from doctor suggested to patient');
    if (config.apiBaseUrl === 'https://api.getvisitapp.com/v3') {
      submitDiagnosisWhileClosingConsultation(authToken, body)
        .then((res) => {
          console.log(res, 'submitted the surgery data');
        })
        .catch((err) => {
          console.log(err, 'error in submitting the surgery data');
        });
    }
  };

  render() {
    const { chatInfo, transcriberAccountActive, consultationInfo } = this.props;
    console.log(consultationInfo);
    console.log(chatInfo.pastChatMessages);
    const chatMessageLink = Link.state(this, 'chatMessage').onChange((val) => {
      // if (this.timeoutId) {
      //   clearTimeout(this.timeoutId);
      // }
      this.setState({
        chatMessage: val,
      });

      // send setState to pubnub with status: ‘TYPING’
      if (val !== '') {
        this.throttledTypingStatusUpdate();
      } else {
        // send setState to pubnub with status: ‘STOP_TYPING’
        this.chatClient.setState('STOP_TYPING', this.props.user.doctorId);
      }
    });
    const renderUserMessage = (ele) => {
      switch (ele.cardType) {
        case 'text':
          return (
            <UserMessage
              key={ele.messageId}
              message={ele.text}
              timestamp={ele.timetoken}
            />
          );
        case 'chatImage':
          return (
            <UserImageMessage
              key={ele.messageId}
              filePreviewUrl={ele.filePreviewUrl}
              url={ele.url}
              timestamp={ele.timetoken}
            />
          );
        case 'documentCard':
          return (
            <UserFileMessage
              key={ele.messageId}
              fileName={ele.documentFileName}
              url={ele.documentUrl}
              timestamp={ele.timetoken}
            />
          );
        case 'prescriptionCard':
          return (
            <UserFileMessage
              key={ele.messageId}
              fileName="Prescription Card"
              url={ele.treatmentPlanPDFLink}
              timestamp={ele.timetoken}
            />
          );
        case 'treatmentPlanCard': {
          return (
            <UserFileMessage
              key={ele.messageId}
              fileName={'Prescription'}
              url={ele.prescriptionLink}
              timestamp={ele.timetoken}
            />
          );
        }
        case 'case-summary':
          return <CaseDetailsMessage caseDetails={ele.caseDetails} />;
        case 'sp-suggest':
          return (
            <SpecialistSuggestContainer>
              <SpecialistSuggestIcon src={specialistSuggestIcon} />
              <SpecialistSuggestText>
                Doctors have been recommended to the patients. Mode -{' '}
                {ele.searchType}
              </SpecialistSuggestText>
              {ele.timetoken && (
                <TimestampText>
                  {new Date(ele.timetoken).toLocaleTimeString('nu', {
                    hour: 'numeric',
                    minute: '2-digit',
                  })}
                </TimestampText>
              )}
            </SpecialistSuggestContainer>
          );
        case 'video-card':
          return (
            <VideoCallCard
              sessionId={ele.sessionId}
              consultationId={ele.consultationId}
            />
          );
        case 'reschedule-card':
          return <ScheduleCardMessage cardDetails={ele} />;
        default:
          return false;
      }
    };
    const renderPatientMessage = (ele) => {
      switch (ele.cardType) {
        case 'text':
          return (
            <DoctorMessage
              doctorImage={`https://api.samuraijack.xyz/v3/users/${ele.senderId}/profilePicture`}
              doctorName={chatInfo.chatName}
              key={ele.messageId}
              message={ele.text}
              timestamp={ele.timetoken}
            />
          );
        case 'chatImage':
          return (
            <DoctorImageMessage
              key={ele.messageId}
              filePreviewUrl={ele.filePreviewUrl}
              url={ele.url}
              timestamp={ele.timetoken}
            />
          );
        case 'documentCard':
          return (
            <DoctorFileMessage
              key={ele.messageId}
              fileName={ele.documentFileName}
              url={ele.documentUrl}
              timestamp={ele.timetoken}
            />
          );
        case 'treatmentPlanCard': {
          return (
            <DoctorFileMessage
              key={ele.messageId}
              fileName={'Prescription'}
              url={ele.prescriptionLink}
              timestamp={ele.timetoken}
            />
          );
        }
        default:
          return false;
      }
    };
    const renderBotMessage = (ele) => {
      switch (ele.cardType) {
        case 'consult-confirm':
          return (
            <ConsultationConfirmedMessage
              key={ele.messageId}
              heading={ele.text}
              title={ele.topLabel}
              profileImg={ele.profileImg}
              subTitle={ele.bottomLabel}
            />
          );
        case 'info':
          return <ChatInfoMessage key={ele.messageId} message={ele.text} />;
        default:
          return false;
      }
    };
    const renderChatMessage = (ele, idx, array) => {
      let dateElement = null;
      let consultIdElement = null;
      if (idx > 0) {
        if(ele.text && array[idx - 1].text) {
          dateElement = this.getDateElement(
            ele.timetoken,
            array[idx - 1].timetoken
          );
        }
        consultIdElement = this.getConsultTag(
          {
            currentMsg: ele.consultationId,
            prevMsg: array[idx - 1].consultationId,
            start: false,
            idx,
            array
          }
        );
      } else {
        dateElement = this.getDateElement(ele.timetoken, 0);
        consultIdElement = this.getConsultTag({
          currentMsg: ele.consultationId,
          start: true,
        });
      }
      switch (ele.userType) {
        case 'doctor':
          return (
            <>
              {dateElement}
              {consultIdElement}
              {renderUserMessage(ele)}
            </>
          );
        case 'patient':
          return (
            <>
              {dateElement}
              {consultIdElement}
              {renderPatientMessage(ele)}
            </>
          );
        case 'user':
          return (
            <>
              {dateElement}
              {consultIdElement}
              {renderPatientMessage(ele)}
            </>
          );
        case 'bot':
          return (
            <>
              {dateElement}
              {consultIdElement}
              {renderBotMessage(ele)}
            </>
          );
        default:
          return (
            <>
              {dateElement}
              {consultIdElement}
              {renderUserMessage(ele)}
            </>
          );
      }
    };
    const getIcon = (ele) => {
      switch (ele.type) {
        case 'file':
          return gallery;
        case 'specialist':
          return doctor;
        case 'messages':
          return message;
        // case 'followups':
        //   return followup;
        case 'rating':
          return star;
        case 'opd':
          return opd;
        case 'schedule':
          return followup;
        default:
          return star;
      }
    };
    const renderAttachmentTypes = (ele, idx) => {
      const icon = getIcon(ele);
      return (
        <OptionContainer
          key={ele.name}
          onTap={() => this.openAttachmentType(ele, idx)}
        >
          <Icon src={icon} />
          <AttachmentText>{ele.name}</AttachmentText>
        </OptionContainer>
      );
    };
    return (
      <>
      {
        !this.props.videoSessionInfo.startCall ? (
        this.props.activeConsult.consultationId ? (
          <OuterContainer>
        {this.state.uploading && (
          <StyledUploadingComponent
            uploadProgress={`${this.state.uploadProgress}%`}
            borderRadius={0}
          />
        )}
        {
          <ChatContainer>
             <UserInfoContainer>
                <UserInfoName>{this.props.activeConsult && this.props.activeConsult.user.relativeName ? this.props.activeConsult.user.relativeName : this.props.activeConsult.user.userName}</UserInfoName>
                <UserInfoCallContainer>
                  <ChatVoice src={chatVoice} onClick={() => this.initiateVoiceCall()}/>
                  <ChatVideo src={chatVideo} onClick={() => this.initiateVideoCall()}/>
                  <ChatMore src={chatMore} onClick={() => this.setState({moreModal: !this.state.moreModal})}/>
                </UserInfoCallContainer>
                {
                  this.state.moreModal && 
                  (
                    <MoreContainer>
                      {/* <MoreText onClick={() => this.props.renderPastChats()} style={{color: '#FF754C'}}>Report Emergency</MoreText> */}
                      <MoreText onClick={() => this.setState({showEmergencyContactModal: true, moreModal: false})} style={{color: '#FF754C'}}>Report Emergency</MoreText>
                      <Line style={{marginTop: '0px'}}/>
                      <MoreText onClick={() => this.setState({showCancelConsultationModal: true, moreModal: false})}>Cancel Consultation</MoreText>
                      <Line  style={{marginTop: '0px'}}/>
                      <MoreText onClick={() => this.setState({showCloseConsultationModal: true, moreModal: false})}>Complete Consultation</MoreText>
                    </MoreContainer>
                  )
                }
              </UserInfoContainer>
            <BodyContainer
              ref={this.conversationBodyRef}
              onScroll={this.onListScroll}
            >
              {this.state.fetchMessages && (
                <LoadingContainer>
                  <LoadingIcon className="icon-spin" />
                  <Text>Fetching Messages...</Text>
                </LoadingContainer>
              )}
              {!this.state.fetchMessages && !this.state.stopHistoryFetch && (
                <StyledTappable onTap={this.fetchMessages}>
                  <Text>Load more messages</Text>
                </StyledTappable>
              )}
              {chatInfo.pastChatMessages.map(renderChatMessage)}
              {this.props.chatInfo.patientTyping && (
                <TypingIndicator
                  doctorImage={`https://api.samuraijack.xyz/v3/users/${this.props.chatInfo.patientTypingId}/profilePicture`}
                />
              )}
            </BodyContainer>
            <GoBackBtn onClick={() => this.props.renderPastChatsHandler(0)}>Go back to Current Consultation</GoBackBtn>
          </ChatContainer>
        }
      </OuterContainer>
        ) : (
          <NoActiveChat />
        ) ) : (
          <VideoCallContainer sessionData={this.props.videoSessionInfo}/>
        )
      }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  chatInfo: state.chat,
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
  user: state.user,
  videoFeedback: state.videoFeedback,
  transcriberAccountActive: state.app.transcriberAccountActive,
  quickReplySuggestions: state.consults.quickReplySuggestions,
  videoSessionInfo: state.videoSession,
  consultationInfo: state.consults.consultPatientInfo,
  consultUserInfo: state.consults.consultUserInfo,
  activeConsultChannels: state.consults.activeConsultChannels,
});

export default connect(mapStateToProps)(PastChatContainer);
