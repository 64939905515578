import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import responseIcon from '../../images/message-circle.svg';
import { fetchConsults, fetchPendingCallbacks } from '../../services';
import InfoCard from './InfoCard';
import { ThreeDots } from 'react-loader-spinner';
import { selectConsult, setDoctorLastReadTimetokenForPrevConsult, updateConsults } from '../../actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { showError } from '../../utils';
import CallbacksCard from './CallbacksCard';
import CallbacksModal from './CallbacksModal';
import CallbacksConfirmation from './CallbacksConfirmation';
import pana from '../../images/pana.svg';
import { store } from '../../store';

const ResponseCasesOuter = styled.div`
  width: 400px;
  height: 480px;
  background: #ffffff;
  margin-top: 16px;
  margin-left: 20px;
  box-shadow: 2px 2px 8px rgba(113, 79, 255, 0.21);
  border-radius: 20px;
  overflow-y: scroll;
  padding-bottom: 8px;
`;

const ResponseLogoContainer = styled.div`
  display: flex;
`;

const ResponseLogo = styled.img`
  margin-top: 26px;
  margin-left: 26px;
`;
const ResponseHead = styled.h1`
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #0f0b28;
  margin-top: 26px;
  margin-left: 14px;
`;

const HeadContainer = styled.div`
width: 352px;
height: 42px;
background: #F0F3F6;
border-radius: 12px;
display: flex;
align-items: center;
padding: 6px;
margin-top: 18px;
margin-left: 20px;
`

const CallBackContainer = styled.div`
position: relative;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 27px;
gap: 10px;
width: 167.5px;
height: 34px;
background: #FFFFFF;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
border-radius: 8px;
cursor: pointer;
`

const CText = styled.p`
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 150%;
text-align: center;
letter-spacing: 0.25px;
color: #0F0B28;
` 

const UnrepliedContainer = styled.div`
position: relative;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 27px;
gap: 10px;
width: 167.5px;
height: 34px;
margin-left: 3px;
background: #FFFFFF;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
border-radius: 8px;
cursor: pointer;
`

const UText = styled.p`
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 150%;
text-align: center;
letter-spacing: 0.25px;
color: #0F0B28;
`

const TextContainer = styled.div`
position: relative;
height: auto;
width: 167.5px;
padding: 8px 27px;
gap: 10px;
background: #F0F3F6;
`

const NoCases = styled.img`
width: 232.19px;
height: 171px;
margin-left: 82px;
margin-top: 130px;
`

const NoCasesText = styled.p`
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 120%;
color: #0F0B28;
margin-left: 128px;
margin-top: 20px;
`

const Notify = styled.div`
position: absolute;
border-radius: 100%;
/* background-color: #FF0000; */
background-color: #F84F31;
box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.7);
top: -5px;
left: 160px;
width: 12px;
height: 12px;
z-index: 1;
`

const ResponseAwaited = (props) => {

  const [loading, setIsLoading] = useState(false);
  const [consultationData, setConsultationData] = useState([]);
  const [pendingCallbacksData, setPendingCallbacksData] = useState([]);
  const [selectedSponsorId, setSelectedSponsorId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [consultationIdKey, setConsultationIdKey] = useState(null);
  const [page, setPage] = useState(1);
  const [callBacks, setCallBacks] = useState(true);
  const [unreplied, setUnreplied] = useState(false);
  const [filters, setFilters] = useState([{isSelected: true, name: 'Unreplied', verticalId: props.information.user.verticalId}]);
  const [id, setId] = useState(2.1);
  const [hasMore, setHasMore] = useState(true);
  const [markCallbackModal, setMarkCallbackModal] = useState(false);
  const [callBacksData, setCallBacksData] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [noData, setNoData] = useState(false);
  const [update, setUpdate] = useState(false);
  const [noDataCB, setNoDataCB] = useState(false);

  const dispatch = useDispatch();

  const activeConsult = useSelector((state) => state.consults.activeConsult);

  const updatedConsults = useSelector((state) => state.consults.consults);

  useEffect(() => {
    console.log(activeConsult, "activeConsultDatatata");
    if(activeConsult.consultationId) dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
  },[]);

  useEffect(() => {
    const getUpdatedPendingCallbacks = async () => {
      try {
        const obj = {
          authToken: props.authToken,
          verticalId: props.information.user.verticalId,
        }
        const callBacksData = await fetchPendingCallbacks(obj);
        if(!callBacksData.results || callBacksData.results.length === 0) {
          return;
        } 
        setPendingCallbacksData(callBacksData.results);
      }
      catch (err) {
        showError(err);
      }
    };
    getUpdatedPendingCallbacks();
    // const updatedArr = updatedConsults.filter((consult) => {
    //   return consult.unreplied > 0;
    // })

    // setConsultationData(updatedArr);
    setConsultationData([]);
    setUpdate((prev) => !prev);
    // setPage(1);

    if(activeConsult.consultationId) dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
  },[updatedConsults]);


  const observer = useRef();
  const lastRef = useCallback(node => {
    if(loading) return;
    if(observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && hasMore) {
        setTimeout(() => {
          setPage(prevPage => prevPage + 1);
        }, 200);
      }
    });
    if(node) observer.current.observe(node);
    console.log(node, 'node');
  },[loading, hasMore]);


  const handleConfirmModal = () => {
    setMarkCallbackModal(false);
    setConfirmModal(true);
  }

  const hideConfirmModal = () => {
    setCallBacksData({});
    setConfirmModal(false); 
    getPendingCallbacks();
  }

  const handleMarkCallbacks = (data) => {
    setCallBacksData(data);
    setMarkCallbackModal(true);
  }

  const hideCallbacksModal = () => {
    setCallBacksData({});
    setMarkCallbackModal(false);
    getPendingCallbacks();
  }

  const setActiveConsult = (consult) => {
    console.log(consult);
    if(consult.consultationId === activeConsult.consultationId) return;
    consult.unreadMessageCount = 0;
    dispatch(selectConsult(consult));
    // dispatch action here to make api call to update doctor_last_read_timetoken
    // partner doc check to be implemented
    dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
    store.dispatch(updateConsults());
   
    // setHasMore(true);
    // setPage(1);
    setConsultationData([]);
    setHasMore(true);
    setPage(1);
    props.renderChats();
  }

  const setActiveConsultCallBacks = async (data) => {
    try {
      console.log(data);
      const consult = await fetchConsults(props.authToken, [{isSelected: true, name: 'none'}], selectedSponsorId, searchText,data.consultationId);
      console.log(consult.responseBody.consultations[0]);
      consult.responseBody.consultations[0].unreadMessageCount = 0;
      dispatch(selectConsult(consult.responseBody.consultations[0]));
      // dispatch action here to make api call to update doctor_last_read_timetoken
      // partner doc check to be implemented
      dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
      store.dispatch(updateConsults());
    }
    catch(err) {
      showError(err);
    }
  }

  const getPendingCallbacks = async () => {
    setIsLoading(true);
    setNoDataCB(false);
    try {
      const obj = {
        authToken: props.authToken,
        verticalId: props.information.user.verticalId,
      }
      console.log(props);
      const callBacksData = await fetchPendingCallbacks(obj);
      console.log(callBacksData);
      if(!callBacksData.results || callBacksData.results.length === 0) {
        setIsLoading(false);
        setNoDataCB(true);
      } 
      setPendingCallbacksData(callBacksData.results);
      setIsLoading(false);
    }
    catch (err) {
      showError(err);
      setIsLoading(false);
    }
  }

  const getAllConsults = async () => {
    if(!hasMore) return;
    if(unreplied) setIsLoading(true);
    setNoData(false);
    try {
      const consultsData = await fetchConsults(props.authToken, filters, selectedSponsorId, searchText, consultationIdKey, page);
      if(!consultsData.responseBody.consultations) {
        setHasMore(false);
        if(unreplied) {
          setIsLoading(false);
          setNoData(true);
        }
        return;
      }
     
      console.log(consultsData, 'consultsData', 'ResponseAwaited');
     
      setConsultationData((prevData) => {
        const consultationIdMap = new Map(consultsData.responseBody.consultations.map(obj => [obj.consultationId, true]));
        if(page === 1) prevData = []
        const uniqueData = prevData.filter(obj => !consultationIdMap.has(obj.consultationId));
        return [...uniqueData, ...consultsData.responseBody.consultations];
      });
      if(consultsData.responseBody.consultations.length === 0){
        setNoData(true);
      }
      setIsLoading(false);
    }
    catch (err) {
      showError(err);
      console.log(err);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAllConsults();
  },[filters ,page, hasMore]);

  useEffect(() => {
    getPendingCallbacks();
  },[]);

  useEffect(() => {
    if(page === 1) {
      setTimeout(() => {
        getAllConsults();
      }, 250);
    }
    setTimeout(() => {
      setHasMore(true);
      setPage(1);
    }, 400);
  }, [ update ]);

  console.log(pendingCallbacksData, 'callBacksData');

  useEffect(() => {
    if(callBacks) {
      setId(2.1);
    } else if(unreplied) {
      setId(2.2);
    }
  },[callBacks, unreplied]);

  const handleSwitch = (id) => {
    if(id === 1) {
      setCallBacks(true);
      setUnreplied(false);
      // setFilters([{isSelected: true, name: 'Connections'}]);
      // setPage(1);
      setConsultationData([]);
      // setHasMore(true);
    } else if(id === 2) {
      setCallBacks(false);
      setUnreplied(true);
      setFilters([{isSelected: true, name: 'Unreplied', verticalId: props.information.user.verticalId}]);
      setConsultationData([]);
      setHasMore(true);
      setPage(1);
    }
  };

  console.log(noData,pendingCallbacksData);

  console.log(page, filters, consultationData, 'page, filters');

  return (
    <ResponseCasesOuter id = 'response-awaited'>
      <ResponseLogoContainer onClick={props.close} style={{cursor: 'pointer'}}>
        <ResponseLogo src={responseIcon} />
        <ResponseHead>Response Awaited</ResponseHead>
      </ResponseLogoContainer>
      <HeadContainer>
        {
          callBacks ? (
            <CallBackContainer>
              <CText>Callback Requests</CText>
              {pendingCallbacksData && pendingCallbacksData.length > 0 && (<Notify />)}
            </CallBackContainer>
          ) : (
            <TextContainer style={{padding: '0px'}}>
            <CText onClick={() => handleSwitch(1)} style={{marginLeft: '5px', cursor: 'pointer'}}>Callback Requests</CText>
            </TextContainer>
          )
        }
        {
          unreplied ? (
            <UnrepliedContainer>
              <UText>Unreplied</UText>
              {consultationData && consultationData.length > 0 && (<Notify />)}
            </UnrepliedContainer>
          ) : (
            <TextContainer>
            <UText onClick={() => handleSwitch(2)} style={{cursor: 'pointer'}}>Unreplied</UText>
            </TextContainer>
          )
        }
      </HeadContainer>
      {
        markCallbackModal && (<CallbacksModal closeModal = {hideCallbacksModal} callBacksData = {callBacksData} confirmMarked = {handleConfirmModal} authToken = {props.authToken} doctorId = {props.information.user.doctorId}/>)
      }
      {
        confirmModal && (<CallbacksConfirmation closeModal = {hideConfirmModal} callBacksData = {callBacksData} authToken = {props.authToken}/>)
      }
      {
        callBacks && (
          pendingCallbacksData && pendingCallbacksData.length !== 0 && pendingCallbacksData.map((data, idx) => {
            return (
              <div key={data.connectionRequestId} onClick = {() => setActiveConsultCallBacks(data)}>
                <CallbacksCard id={data.connectionRequestId} callBacksData = {data} activeConsult = {activeConsult}/>
              </div>
            )
          })
        )
      }
      {
        callBacks && (noDataCB && pendingCallbacksData && pendingCallbacksData.length === 0) && (
          <>
          <NoCases src={pana} alt='no cases'/>
          <NoCasesText>No Cases Found</NoCasesText>
          </>
        )
      }
      {
        unreplied && (consultationData && consultationData.length !== 0 && consultationData.map((data, idx) => {
          if(consultationData.length === idx + 1) {
            return (
              <div ref={lastRef} onClick = {() => setActiveConsult(data)}>
              <InfoCard consultationData = {data} id = {id} activeConsult = {activeConsult}/>
              </div>
            )
          }
          return (
            <div onClick={() => setActiveConsult(data)}>
              <InfoCard consultationData = {data} id = {id} activeConsult = {activeConsult}/>
            </div>
          )
        }))
      }
      {
        unreplied && (noData && consultationData && consultationData.length === 0) && (
          <>
          <NoCases src={pana} alt='no cases'/>
          <NoCasesText>No Cases Found</NoCasesText>
          </>
        )
      }
      {
        loading && (
          <div style={{width: '400', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ThreeDots 
              height='60'
              width='60'
              radius='9'
              color='#714FFF'
              ariaLabel="progress-bar-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
          />
          </div>
        )
      }
    </ResponseCasesOuter>
  )
}

export default ResponseAwaited