import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/lib/Async';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { RegularText, Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';
import TappableCard from '../common/TappableCard';
import { fetchMedicines } from '../../services/';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: center;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const RemoveIcon = styled(FaTimes)`
  font-size: 1.5rem;
  margin-top: 1.725rem;
  margin-right: 0.625rem;
`;

const ModalText = styled(RegularText)`
  font-size: 2rem;
`;
const StyledInputBox = styled(Input)`
  padding: 0.9rem 0;
  flex: 1 0;
  border-bottom: 1px solid #cecece !important;
`;
const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const StyledInputBoxSmall = styled(Input)`
  /* flex: 1; */
  border-bottom: 1px solid #979797 !important;
  padding: 0.33rem 0;
  margin: 0rem 0.625rem;
  width: 8rem;
`;

const StyledTappableCard = styled(TappableCard)`
  align-self: flex-start;
  padding: 1rem 3rem;
  margin: 0rem 0.625rem;
  border: ${(props) => (props.isSelected ? '0.1rem solid #4F34D2' : 'null')};
`;

const TappableText = styled(Text)`
  font-size: 1.4rem;
  text-align: center;
  color: ${(props) => (props.isSelected ? '#4F34D2' : 'black')};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 1;
`;

const LabelText = styled(Text)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const SelectElement = styled(Select)`
  /* flex: 1; */
  width: 25rem;
  /* border: 0.1rem solid #4F34D2; */
`;

const DosageContainer = styled.div`
  display: flex;
  /* align-self: stretch; */
  flex-direction: row;
`;

// const dosageOptions = [
//   { value: '1-0-0', label: '1-0-0' },
//   { value: '0-1-0', label: '0-1-0' },
//   { value: '0-0-1', label: '0-0-1' },
//   { value: '1-0-1', label: '1-0-1' },
//   { value: '1-1-1', label: '1-1-1' },
//   { value: '1-1-1-1', label: '1-1-1-1' },
//   { value: '1-1-1-1-1', label: '1-1-1-1-1' },
//   { value: 'SOS', label: 'SOS' }
// ];

// const durationOptions = [
//   { value: 'Days', label: 'Days' },
//   { value: 'Weeks', label: 'Weeks' },
//   { value: 'Months', label: 'Months' }
// ];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 16,
    textAlign: 'left',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 12,
    textAlign: 'left',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   width: 200,
  // }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // }
};

class AddDiagnosisModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      diagnosis: '',
      icdCode:'OTHER'
    };
  }

  clearFields = () => {
    this.setState({
        diagnosis: '',
    });
  };

  
 

  promiseOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getMedicines(input));
      }, 500);
    });
  };

  opdCheckBoxChange = (ev) => {
    this.setState({
      coveredUnderOpd: ev.currentTarget.checked,
    });
  };

  render() {
    // const medicineNameLink = Link.state(this, "medicineName");
    const diagnosisLink = Link.state(this, 'diagnosis');
 
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Add Diagnosis</HeaderText>
              <CloseContainer onTap={this.props.hideModal}>
                <CrossIcon />
              </CloseContainer>
            </ModalHeader>

            <Body>
              <StyledInputBox
                valueLink={diagnosisLink}
                className="visit-input"
                placeholder="Enter Diagnosis"
              />
              <ButtonComponent>
                <Button
                  onTap={() => {
                    this.props.addDiagnosis(this.state.diagnosis, true);
                  }}
                  disabled={!this.state.diagnosis || this.state.diagnosis.length < 1}
                  // loading={this.state.loading}
                >
                  <ButtonText>Save</ButtonText>
                </Button>
              </ButtonComponent>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  prescriptions: state.info.prescriptions,
});

export default connect(mapStateToProps)(AddDiagnosisModal);
