import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';
import styled from 'styled-components';

import { FaBullseye, FaTimes } from 'react-icons/fa';
import Select from 'react-select';
import StyledTappable from '../components/common/StyledTappable';
import TappableCard from '../components/common/TappableCard';
import { PrimaryButton } from '../components/common/Buttons';
import {
  BoldText,
  Text,
  ButtonText,
  RegularText,
  LinkText,
} from '../components/common/Text';
import {
  getConsultationInfo,
  offlineConsultationStatus,
  getConditions,
  saveConditions,
  deleteCondition,
  removeUploadedPrescription,
  updateUserDetails,
  getPrescriptionFiles,
  getDigitiseRequest,
  getUserRelative,
  updateConsultationRelative,
  addConsultationRelative,
  bookConsultation,
} from '../services';
import GetOfflineConsultationStatusModal from '../components/consultations/GetOfflineConsultationStatusModal';
import AddRelativeModal from '../components/consultations/AddRelativeModal';

import {
  getConditions as getConditionsAction,
  resetConditions,
  addFilesToActiveConsult,
  setActiveConsult,
  fetchConsults,
  setConsultationUserInfo,
  setConsultationPatientInfo,
} from '../actions';
import moment from 'moment';
import AddMedicalConditionModal from '../components/symptoms/AddMedicalConditionModal';
import purpleTick from '../images/common/purpleTick.svg';
import ConfirmAddModal from '../components/consultations/ConfirmAddModal';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  padding: 1.6rem 2rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const SectionContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
`;

const SectionTitle = styled(BoldText)`
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
`;

const SectionDescriptionText = styled(RegularText)`
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
  color: ${(props) => props.color || '#000'};
`;

const StyledButton = styled(PrimaryButton)`
  margin: 0.825rem 0;
  align-self: flex-start;
`;

const SecondaryStyledButton = styled(StyledButton)`
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #4f34d2;
`;

const SecondaryButtonText = styled(ButtonText)`
  color: #4f34d2;
`;

const MedicalHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.25rem 0rem;
`;

const HeaderRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-top: ${(props) => props.marginTop || 0};
`;

const TickImage = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1.25rem;
`;

const DeleteButton = styled(StyledTappable)`
  margin-left: 3rem;
  /* align-self: flex-end; */
`;

const DeleteText = styled(ButtonText)`
  color: #b2b2b2;
`;

const SymptomName = styled(BoldText)`
  font-size: 1.4rem;
`;

const ConditionText = styled(RegularText)`
  font-size: 1.4rem;
  margin-left: 2.5rem;
`;

const FileRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
`;

const FileUrl = styled(LinkText)`
  font-size: 1.4rem;
  word-break: break-all;
  margin-left: 1.25rem;
`;

const SelectElement = styled(Select)`
  flex: 1;
  max-width: 30rem;
  margin-left: 20px;
  /* border: 0.1rem solid #4F34D2; */
`;

const StyledNormalInputBox = styled.input`
  padding: 4px;
  flex: 1 0;
  max-width: 30rem;
  margin-left: 35px;
  border-bottom: 1px solid #cecece !important;
`;

const genderOptions = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];

class DoctorProfileContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consultationInfo: {},
      offlineConsultationStatusModal: false,
      addConditionsModalVisible: false,
      medicalHistory: [],
      selectedGender: null,
      selectedDob: '',
      selectedRelative: null,
      selectedRelativeName: '',
      relatives: [],
      relations: [],
      showAddRelativeModal: false,
      showConfirmAddModal: false,
      prescriptionAdded: false,
      addedRelativeId: '',
      showRelationSelect: false,
      showAddRelative: false,
    };
  }

  componentDidMount = () => {
    if (
      this.props.activeConsult.consultationId ||
      this.props.activeConsult.digitisationRequestId
    ) {
      this.getConsultationInfo();
      this.getConditions();
      this.fetchTranscriptionFiles();
      this.getUserRelative();
    }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeConsult.consultationId !==
        this.props.activeConsult.consultationId ||
      prevProps.activeConsult.digitisationRequestId !==
        this.props.activeConsult.digitisationRequestId
    ) {
      this.getConsultationInfo();
      this.getConditions();
      this.fetchTranscriptionFiles();
      this.getUserRelative();
      this.setState({
        selectedRelative: '',
        addedRelativeId: '',
      });
    }
  }

  getConditions = () => {
    const { authToken, activeConsult, dispatch } = this.props;
    if (activeConsult.user && activeConsult.user.userId) {
      getConditions(authToken, activeConsult.user.userId).then((res) => {
        dispatch(getConditionsAction(res.data));
      });
    }
  };

  fetchTranscriptionFiles = () => {
    if (this.props.transcriberAccountActive) {
      const { authToken } = this.props;
      getPrescriptionFiles(
        authToken,
        this.props.activeConsult.digitisationRequestId
      )
        .then((result) => {
          console.log({ result }, 'fetchTranscriptionFiles');
          this.props.dispatch(addFilesToActiveConsult(result.documents));
          // this.setState({
          //   files: result.files
          // });
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  };

  getConsultationInfo = () => {
    let authToken = this.props.authToken;
    this.setState({
      showRelationSelect: false,
      showAddRelative: false,
    });
    if (this.props.transcriberAccountActive) {
      getDigitiseRequest(
        authToken,
        this.props.activeConsult.consultationId,
        this.props.user.doctorId
      )
        .then((res) => {
          this.props.dispatch({
            type: 'SET_CONSULTATION',
            payload: res.sourceDetails.consultationInfo
          });
          this.setState({
            consultationInfo: res.sourceDetails.consultationInfo,
          });
        })
        .catch((err) => {
          console.log({ err }, 'error in calling getCOnsultationInfo');
        });
    } else if (this.props.activeConsult.consultationId) {
      getConsultationInfo(
        authToken,
        this.props.activeConsult.consultationId,
        this.props.user.doctorId
      )
        .then((res) => {
          console.log({ res }, 'getCOnsultationInfo');
          this.props.dispatch(setConsultationUserInfo(res.userInfo));
          this.props.dispatch(setConsultationPatientInfo(res.consultationInfo));
          const showRelationSelect =
            res.userInfo.canUpdateRelativesInOnlineConsult;
          const showAddRelative = res.userInfo.canAddRelativesInOnlineConsult;
          this.setState({
            consultationInfo: res.consultationInfo,
            prescriptionAdded:
              res.prescriptionSentAtleastOnce || res.consultationInfo.isOver,
            showRelationSelect,
            showAddRelative,
          });
        })
        .catch((err) => {
          console.log({ err }, 'error in calling getCOnsultationInfo');
        });
    }
  };

  getUserRelative = () => {
    let authToken = this.props.authToken;

    getUserRelative(this.props.activeConsult.user.userId, authToken)
      .then((res) => {
        console.log(res);
        this.setState({
          relatives: res.relatives,
          relations: res.relations,
        });
      })
      .catch((err) => {
        console.log(err, 'error while getting user relatives');
      });
  };

  getAppointmentDate = (date) => {
    const formatDate = Number.parseInt(date) * 1000;
    return moment(formatDate).format('MMM Do YYYY');
  };

  renderDoctorInfo = () => {
    if (this.props.activeConsult.type === 'doctorOnCall') {
      return (
        <SectionContainer>
          <SectionTitle>Doctor Information</SectionTitle>
          <SectionDescriptionText>
            {`Doctor Name: ${this.state.consultationInfo.assistantName}`}
          </SectionDescriptionText>
          <SectionDescriptionText>
            {`Doctor Degrees: ${this.state.consultationInfo.assistantDegrees}`}
          </SectionDescriptionText>
        </SectionContainer>
      );
    } else if (this.props.activeConsult.type.indexOf('offline') > -1) {
      return (
        <SectionContainer>
          <SectionTitle>Doctor Information</SectionTitle>
          <SectionDescriptionText>
            {`Doctor Name: ${this.state.consultationInfo.doctorName}`}
          </SectionDescriptionText>
          <SectionDescriptionText>
            {`Doctor Degrees: ${this.state.consultationInfo.doctorDegrees}`}
          </SectionDescriptionText>
          <SectionDescriptionText>
            {`Appointment Date:
            ${this.getAppointmentDate(
              this.state.consultationInfo.appointmentDate
            )}`}
          </SectionDescriptionText>
          <SectionDescriptionText>
            {`Appointment Time: ${this.state.consultationInfo.appointmentTime}`}
          </SectionDescriptionText>
          <SectionDescriptionText>
            {`Organization Name: ${this.state.consultationInfo.orgName}`}
          </SectionDescriptionText>
          <SectionDescriptionText>
            {`Organization Address: ${this.state.consultationInfo.orgAddress}`}
          </SectionDescriptionText>
          <StyledButton
            onTap={() => {
              this.setState({ offlineConsultationStatusModal: true });
            }}
          >
            <ButtonText>Submit Consultation Status</ButtonText>
          </StyledButton>
        </SectionContainer>
      );
    } else if (this.props.activeConsult.type === 'onlineSpecialist') {
      return (
        <SectionContainer>
          <SectionTitle>Doctor Information</SectionTitle>
          <SectionDescriptionText>
            {`Doctor Name: ${this.state.consultationInfo.doctorName}`}
          </SectionDescriptionText>
          <SectionDescriptionText>
            {`Doctor Degrees: ${this.state.consultationInfo.doctorDegrees}`}
          </SectionDescriptionText>
        </SectionContainer>
      );
    }
  };

  getConsultationType = () => {
    switch (this.props.activeConsult.type) {
      case 'doctorOnCall':
        return 'Doctor On Call';
      case 'onlineSpecialist':
        return 'Specialists Consultation';
      case 'offlineConfirmed':
        return 'Offline Confirmed';
      case 'offlineCancelled':
        return 'Offline Cancelled';
      case 'offlinePending':
        return 'Offline Pending';
      default:
        return '';
    }
  };

  hideModal = () => {
    this.setState({
      offlineConsultationStatusModal: false,
      addConditionsModalVisible: false,
      showAddRelativeModal: false,
      addedRelativeId: '',
      showConfirmAddModal: false,
    });
  };

  addConditions = (conditions) => {
    saveConditions(
      this.props.authToken,
      conditions,
      this.props.activeConsult.user.userId,
      this.props.activeConsult.consultationId
    )
      .then(() => {
        this.props.dispatch(resetConditions());
        this.getConditions();
      })
      .finally(() => {
        this.hideModal();
      });
  };

  deleteCondition = (condition) => {
    deleteCondition(
      this.props.authToken,
      condition.conditionId,
      this.props.activeConsult.user.userId
    ).then(() => {
      this.getConditions();
    });
  };

  setOfflineConsultationStatus = (status) => {
    offlineConsultationStatus(
      this.props.authToken,
      this.props.activeConsult.consultationId,
      status,
      this.state.consultationInfo.requestId
    )
      .then((result) => {
        console.log({ result }, 'setOfflineConsultationStatus');
        this.hideModal();
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  removeUploadedFile = (fileId) => {
    const { activeConsult, authToken } = this.props;
    // const authToken = activeConsult.offlineInfo.doctorAuth;
    removeUploadedPrescription(authToken, activeConsult.consultationId, fileId)
      .then((result) => {
        console.log({ result }, 'removeUploadedFile');
        this.fetchTranscriptionFiles();
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  renderFiles = () => {
    const { files } = this.props.activeConsult;
    const filesComponentArray = [];
    files.forEach((file) => {
      filesComponentArray.push(
        <FileRow>
          <SectionDescriptionText>{file.fileId}</SectionDescriptionText>
          <StyledTappable
            onTap={() => {
              window.open(file.url);
            }}
          >
            <FileUrl>{file.url}</FileUrl>
          </StyledTappable>
          {/* <DeleteButton onTap={() => this.removeUploadedFile(file.fileId)}>
            <DeleteText>Remove</DeleteText>
          </DeleteButton> */}
        </FileRow>
      );
    });

    return (
      <SectionContainer>
        <SectionTitle>Files Section</SectionTitle>
        {filesComponentArray}
        {filesComponentArray.length === 0 && (
          <SectionDescriptionText>
            No Uploaded Prescription found for this consultation
          </SectionDescriptionText>
        )}
      </SectionContainer>
    );
  };

  updateUserDetails = () => {
    const { activeConsult, authToken } = this.props;
    const { userId } = activeConsult.user;
    const { selectedDob, selectedGender } = this.state;
    const dob = selectedDob || this.props.activeConsult.user.dob;
    const gender = selectedGender || this.props.activeConsult.user.gender;
    updateUserDetails(authToken, userId, dob, gender)
      .then((result) => {
        console.log({ result }, 'updateUserDetails');
        this.props.dispatch(
          setActiveConsult({
            ...this.props.activeConsult,
            user: {
              ...this.props.activeConsult.user,
              dob: dob,
              gender: gender,
            },
          })
        );
        this.setState({
          updatedText: 'Details Updated Successfully!',
          selectedGender: null,
          selectedDob: '',
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  onDobChange = (text) => {
    console.log({ text }, 'onDobChange');
    const { selectedDob } = this.state;

    if (text.length < selectedDob.length) {
      // means deleting
      if (text.length === 5 || text.length === 8) {
        text = text.slice(0, -1);
      }
    } else {
      if (text.length === 4 || text.length === 7) {
        text += '-';
      }
    }
    this.setState({
      selectedDob: text,
      updatedText: null,
    });
  };

  fetchConsults(consultationIdKey) {
    const { dispatch, authToken, location, filters } = this.props;
    dispatch(fetchConsults({ authToken, consultationIdKey, filters }));
  }

  updateConsultationRelative = () => {
    const { activeConsult, authToken } = this.props;
    const { userId } = activeConsult.user;
    const body = {
      userId,
      consultationId: activeConsult.consultationId,
      relativeId:
        this.state.selectedRelative === 'self'
          ? null
          : this.state.selectedRelative,
    };
    if (!this.state.selectedRelative) {
      body.relativeId = this.state.addedRelativeId;
    }
    updateConsultationRelative(authToken, body)
      .then((res) => {
        console.log(res);
        this.setState({
          selectedRelative: null,
        });
        this.getConsultationInfo();
        this.fetchConsults(activeConsult.consultationId);
        this.hideModal();
      })
      .catch((err) => {
        console.log({ err });
      });
  };
  addRelative = (body) => {
    const { activeConsult, authToken } = this.props;
    const { userId } = activeConsult.user;
    const requestBody = {
      ...body,
      userId,
    };
    console.log(requestBody);
    addConsultationRelative(authToken, requestBody)
      .then((res) => {
        this.hideModal();
        this.getConsultationInfo();
        this.getUserRelative();
        if (res.relativeId) {
          this.setState({
            addedRelativeId: res.relativeId,
            showConfirmAddModal: true,
          });
        }
      })
      .catch((err) => {
        this.hideModal();
        console.log(err);
      });
  };

  bookConsultationForRelative = () => {
    const { activeConsult, authToken, user } = this.props;
    const { userId } = activeConsult.user;
    const requestBody = {
      userId,
      doctorId: user.doctorId,
      relativeId:
        this.state.selectedRelative === 'self'
          ? null
          : this.state.selectedRelative,
    };
    if (!this.state.selectedRelative) {
      requestBody.relativeId = this.state.addedRelativeId;
    }
    bookConsultation(authToken, requestBody)
      .then((res) => {
        const consultationId =
          res.response.data.consultationInfo.consultationId;
        console.log(res);
        this.fetchConsults(consultationId);
        this.hideModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    console.log(
      { props: this.props, state: this.state },
      'log in render [DoctorProfileContainer]'
    );

    const renderMedicalHistory = (ele, idx) => {
      const renderQuestionAnswer = (ques) => {
        return (
          <ConditionText key={ques.questionId}>{`${
            ques.questionName
          }: ${ques.answers.join()}`}</ConditionText>
        );
      };

      return (
        <MedicalHistoryContainer key={ele.conditionId}>
          <HeaderRow>
            <TickImage src={purpleTick} />
            <SymptomName>{ele.conditionName}</SymptomName>
            <DeleteButton onTap={() => this.deleteCondition(ele)}>
              <DeleteText>Delete</DeleteText>
            </DeleteButton>
          </HeaderRow>
          {ele.questions.map(renderQuestionAnswer)}
        </MedicalHistoryContainer>
      );
    };

    return (
      <OuterContainer>
        <BodyContainer>
          {this.props.activeConsult.files && this.renderFiles()}
          {this.state.consultationInfo.relativeId ? (
            <SectionContainer>
              <SectionTitle>Patient Details</SectionTitle>
              <SectionDescriptionText>
                {`Name: ${this.state.consultationInfo.relativeName}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Gender: ${this.state.consultationInfo.relativeGender}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Age: ${this.state.consultationInfo.relativeAge}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Relation: ${this.state.consultationInfo.relativeRelation}`}
              </SectionDescriptionText>
            </SectionContainer>
          ) : null}
          {this.props.activeConsult.user ? (
            <SectionContainer>
              <SectionTitle>User Details</SectionTitle>
              <SectionDescriptionText>
                {`Name: ${this.props.activeConsult.user.userName}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Age: ${this.props.activeConsult.user.age}`}
              </SectionDescriptionText>
              <SectionDescriptionText>
                {`Gender: ${this.props.activeConsult.user.gender}`}
              </SectionDescriptionText>
              <Row>
                <SectionDescriptionText>Update Gender:</SectionDescriptionText>
                <SelectElement
                  options={genderOptions}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary50: '#d5d5d5',
                      primary25: '#f5f5f5',
                      primary: '#4F34D2',
                    },
                  })}
                  defaultValue={{
                    label: this.props.activeConsult.user.gender,
                    value: this.props.activeConsult.user.gender,
                  }}
                  onChange={(option) =>
                    this.setState({
                      selectedGender: option.value,
                      updatedText: null,
                    })
                  }
                />
              </Row>
              <Row marginTop="10px">
                <SectionDescriptionText>Update DOB:</SectionDescriptionText>
                <StyledNormalInputBox
                  value={
                    this.state.selectedDob || this.props.activeConsult.user.dob
                  }
                  maxLength={10}
                  onChange={(e) => this.onDobChange(e.target.value)}
                  className="visit-input"
                  placeholder="YYYY-MM-DD"
                />
              </Row>
              {this.state.selectedGender ||
              this.state.selectedDob.length === 10 ? (
                <SecondaryStyledButton onTap={() => this.updateUserDetails()}>
                  <SecondaryButtonText>Update</SecondaryButtonText>
                </SecondaryStyledButton>
              ) : null}
              {this.state.updatedText && (
                <SectionDescriptionText color="#32CD32">
                  {this.state.updatedText}
                </SectionDescriptionText>
              )}
            </SectionContainer>
          ) : null}
          {this.state.showRelationSelect ? (
            <>
              <SectionContainer>
                <Row>
                  <SectionDescriptionText>
                    {!this.state.prescriptionAdded
                      ? 'Update Patient :'
                      : 'Book Consultation For:'}
                  </SectionDescriptionText>
                  <SelectElement
                    options={[
                      ...this.state.relatives.map((ele) => {
                        return {
                          label: `${ele.relativeName} (${ele.relativeRelation})`,
                          value: ele.relativeId,
                        };
                      }),
                      {
                        label: `${this.props.activeConsult.user.userName} (User)`,
                        value: 'self',
                      },
                    ]}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary50: '#d5d5d5',
                        primary25: '#f5f5f5',
                        primary: '#4F34D2',
                      },
                    })}
                    onChange={(option) =>
                      this.setState({
                        selectedRelative: option.value,
                      })
                    }
                  />
                </Row>
                {this.state.selectedRelative &&
                !this.state.prescriptionAdded ? (
                  <SecondaryStyledButton
                    onTap={() => this.updateConsultationRelative()}
                  >
                    <SecondaryButtonText>Update Patient </SecondaryButtonText>
                  </SecondaryStyledButton>
                ) : null}
                {this.state.selectedRelative && this.state.prescriptionAdded ? (
                  <SecondaryStyledButton
                    onTap={() => this.bookConsultationForRelative()}
                  >
                    <SecondaryButtonText>
                      Book Consultation{' '}
                    </SecondaryButtonText>
                  </SecondaryStyledButton>
                ) : null}
              </SectionContainer>
              <Text>OR</Text>
            </>
          ) : (
            ''
          )}
          {this.state.showAddRelative ? (
            <SecondaryStyledButton
              onTap={() =>
                this.setState({
                  showAddRelativeModal: true,
                })
              }
            >
              <SecondaryButtonText>Add Relative</SecondaryButtonText>
            </SecondaryStyledButton>
          ) : (
            ''
          )}

          {this.props.activeConsult.type && (
            <SectionContainer>
              <SectionTitle>Type of Consultation</SectionTitle>
              <SectionDescriptionText>
                {this.getConsultationType()}
              </SectionDescriptionText>
            </SectionContainer>
          )}

          {this.props.activeConsult.type && this.renderDoctorInfo()}

          <SectionContainer>
            <SectionTitle>Medical History</SectionTitle>
            {this.props.savedConditions.map(renderMedicalHistory)}
            <SecondaryStyledButton
              onTap={() => {
                this.setState({ addConditionsModalVisible: true });
              }}
            >
              <SecondaryButtonText>Add Medical History</SecondaryButtonText>
            </SecondaryStyledButton>
          </SectionContainer>
        </BodyContainer>
        {this.state.offlineConsultationStatusModal && (
          <GetOfflineConsultationStatusModal
            onSubmit={(status) => this.setOfflineConsultationStatus(status)}
            hideModal={() => this.hideModal()}
          />
        )}
        {this.state.addConditionsModalVisible && (
          <AddMedicalConditionModal
            onSubmit={(conditions) => this.addConditions(conditions)}
            hideModal={() => this.hideModal()}
          />
        )}
        {this.state.showAddRelativeModal && (
          <AddRelativeModal
            hideModal={() => this.hideModal()}
            relations={this.state.relations}
            onSubmit={this.addRelative}
          />
        )}
        {this.state.showConfirmAddModal && (
          <ConfirmAddModal
            message={
              this.state.prescriptionAdded
                ? 'Do you want to book a consultation for this dependent'
                : 'Do you want to update the patient for current consultation as this dependent'
            }
            status={
              this.state.prescriptionAdded
                ? 'Book Consultation for the new dependent'
                : 'Update Patient for this consultation'
            }
            hideModal={this.hideModal}
            confirmRequest={
              this.state.prescriptionAdded
                ? this.bookConsultationForRelative
                : this.updateConsultationRelative
            }
          />
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
  filters: state.filters.filters,
  user: state.user,
  savedConditions: state.conditions.savedConditions,
  transcriberAccountActive: state.app.transcriberAccountActive,
});

export default connect(mapStateToProps)(DoctorProfileContainer);
