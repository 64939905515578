import React, { Component } from 'react';
import styled from 'styled-components';
import { Text } from '../common/Text';
import StyledTappable from '../common/StyledTappable';
import ConfirmModal from '../ConfirmModal';

const OuterContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding: 1.2rem;
  background: ${(props) =>
    props.active ? 'RGBA(136, 82, 204, 0.3)' : 'white'};
  border: 1px solid #dfdfdf;
  border-radius: 1rem;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
`;
const ActionContainer = styled.div`
  display: flex;
`;

const TitleText = styled(Text)`
  font-size: 1.6rem;
`;

const DeleteText = styled(Text)`
  font-size: 1.4rem;
  color: #d23f3f;
`;
const UpdateText = styled(Text)`
  font-size: 1.4rem;
  color: #4e34d1;
`;
const UpdateButton = styled(StyledTappable)`
  margin-right: 1.4rem;
`;

class TemplateCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmModalVisible: false,
      status: '',
      headerColor: '#2a2a2a',
    };
  }
  confirmRequest = (status) => {
    const { updateTemplate, deleteTemplate, template } = this.props;
    if (status === 'Update') {
      updateTemplate(template.value);
    }
    if (status === 'Delete') {
      deleteTemplate(template.value);
    }
    this.setState({ status: '', confirmModalVisible: false });
  };
  updateTemplate = () => {
    this.setState({
      status: 'Update',
      confirmModalVisible: true,
      headerColor: '#4e34d1',
    });
  };
  deleteTemplate = () => {
    this.setState({
      status: 'Delete',
      confirmModalVisible: true,
      headerColor: '#d23f3f',
    });
  };
  render() {
    const { template } = this.props;
    return (
      <OuterContainer>
        <TitleContainer>
          <TitleText>{template.label}</TitleText>
          {!template.isPublic ? (
            <ActionContainer>
              <UpdateButton onTap={this.updateTemplate}>
                <UpdateText>Update</UpdateText>
              </UpdateButton>
              <StyledTappable onTap={this.deleteTemplate}>
                <DeleteText>Delete</DeleteText>
              </StyledTappable>
            </ActionContainer>
          ) : null}
        </TitleContainer>
        {this.state.confirmModalVisible && (
          <ConfirmModal
            status={this.state.status}
            confirmRequest={(status) => this.confirmRequest(status)}
            headerColor={this.state.headerColor}
            hideModal={() => this.setState({ confirmModalVisible: false })}
          />
        )}
      </OuterContainer>
    );
  }
}

export default TemplateCard;
