import axios from 'axios';
import config from '../../config';
import { showError } from '../../utils';

const baseUrl = `${config.apiBaseUrl}/vision/dr`;

export const fetchVisionCardDetails = (subUrl, authToken) => {
    
    return axios.get(`${baseUrl}/${subUrl}`, { headers: { authorization: authToken } })
        .then(res => {
            if (res.data.message === 'success') {
                return res.data.data;
            }

            const err = new Error('Invalid vision card response');
            err.data = res.data;
            throw err;
        })
        .catch(err => {
            showError(err);
        });
}

export const submitVisionCardDetails = (subUrl, details, authToken) => {

    return axios.post(`${baseUrl}/${subUrl}`, { eye: details }, { headers: { authorization: authToken } })
        .then(res => {
            if (res.data.message === 'success') {
                return true;
            }

            const err = new Error('Invalid vision card addition response');
            err.data = res.data;
            throw err;
        })
        .catch(err => {
            showError(err);
        });
}

export const fetchRequestedProcedures = (subUrl, authToken) => {
    return axios.get(`${baseUrl}/${subUrl}`, { headers: { authorization: authToken } })
        .then(res => {
            if (res.data.message === 'success') {
                return res.data.data;
            }

            const err = new Error('Invalid procedure details response');
            err.data = res.data;
            throw err;
        })
        .catch(err => {
            showError(err);
        });
}

export const fetchAvailableProcedures = (subUrl, authToken) => {
    return axios.get(`${baseUrl}/${subUrl}`, { headers: { authorization: authToken } })
        .then(res => {
            if (res.data.message === 'success') {
                return res.data.data;
            }

            const err = new Error('Invalid procedure details response');
            err.data = res.data;
            throw err;
        })
        .catch(err => {
            showError(err);
        });
}

export const rejectVisionProcedure = (subUrl, data, authToken) => {
    return axios.put(`${baseUrl}/${subUrl}`, data, { headers: { authorization: authToken } })
        .then(res => {
            if (res.data.message === 'success') {
                return true;
            }

            const err = new Error('Please retry rejecting the procedure');
            err.data = res.data;
            throw err;
        })
        .catch(err => {
            showError(err);
        });
}

export const approveVisionProcedure = (subUrl, data, authToken) => {
    return axios.put(`${baseUrl}/${subUrl}`, data, { headers: { authorization: authToken } })
        .then(res => {
            if (res.data.message === 'success') {
                return true;
            }

            const err = new Error('Please retry approving the procedure');
            err.data = res.data;
            throw err;
        }).catch(showError);
}

export const addVisionProcedure = (subUrl, data, authToken) => {
    return axios.post(`${baseUrl}/${subUrl}`, data, { headers: { authorization: authToken } })
        .then(res => {
            if (res.data.message === 'success') {
                return true;
            }

            const err = new Error('Please retry adding the procedure');
            err.data = res.data;
            throw err;
        }).catch(showError);
}

export const getEyeDetails = (cardDetails, side) => {
    const detailsObject = cardDetails.find(item => item.eye === side) || {};
    return {
        spherical: detailsObject.sphericalDetails,
        cylindrical: detailsObject.cylindricalDetails,
        axis: detailsObject.axisDetails,
        vA: detailsObject.vA
    };
}
