import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: center;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const StyledInputBox = styled(Input)`
  padding: 0.9rem 0;
  flex: 1 0;
  border-bottom: 1px solid #cecece !important;
`;
const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

class AddCenterModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      centerName: '',
      centerAddress: '',
    };
  }

  clearFields = () => {
    this.setState({
        centerName: '',
        centerAddress: '',
    });
  };



  render() {
    // const medicineNameLink = Link.state(this, "medicineName");
    const centerAddressLink = Link.state(this, 'centerAddress');
    const centerNameLink = Link.state(this, 'centerName');
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Add Center Details</HeaderText>
              <CloseContainer onTap={this.props.hideModal}>
                <CrossIcon />
              </CloseContainer>
            </ModalHeader>

            <Body>
              <StyledInputBox
                valueLink={centerNameLink}
                className="visit-input"
                placeholder="Enter Center Name"
              />
              <StyledInputBox
                valueLink={centerAddressLink}
                className="visit-input"
                placeholder="Enter Center Address"
              />
              <ButtonComponent>
                <Button
                  onTap={() => {
                    const centerName=this.state.centerName;
                    const centerAddress=this.state.centerAddress;
                    this.props.addCenterDetails({centerName,centerAddress}, true)
                  }}
                  disabled={!this.state.centerName || !this.state.centerAddress || this.state.centerName.length < 3 || this.state.centerAddress.length < 3}
                  // loading={this.state.loading}
                >
                  <ButtonText>Save</ButtonText>
                </Button>
              </ButtonComponent>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  prescriptions: state.info.prescriptions,
});

export default connect(mapStateToProps)(AddCenterModal);
