import React, { Component } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 40%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: center;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => (props.color ? props.color : 'black')};
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;
const ModalFooter = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
  color: ${(props) => (props.color ? props.color : 'black')};
`;
const SelectElement = styled(Select)`
  /* flex: 1; */
  width: 25rem;
  /* border: 0.1rem solid #4F34D2; */
`;
const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 1;
`;

const LabelText = styled(Text)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const customStyles = {
  option: (provided) => ({
    ...provided,
    padding: 10,
    fontSize: 16,
    textAlign: 'left',
  }),
  placeholder: (provided) => ({
    ...provided,
    // padding: 10,
    fontSize: 12,
    textAlign: 'left',
  }),
  valueContainer: (provided) => ({
    ...provided,
    // padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
};

class ConfirmAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText color={this.props.headerColor}>
                {this.props.message}
              </HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>
            <Body>
              <ButtonComponent>
                <Button
                  onTap={() => {
                    this.props.confirmRequest();
                  }}
                >
                  <ButtonText>{this.props.status}</ButtonText>
                </Button>
              </ButtonComponent>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

export default ConfirmAddModal;
