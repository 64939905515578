import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { BoldText, Text } from '../components/common/Text';
import StyledTappable from '../components/common/StyledTappable';
import DoctorProfileContainer from './DoctorProfileContainer';
import PastTreatmentContainer from './PastTreatmentContainer';
import { connect } from 'react-redux';
import DigitizeDoctorProfileContainer from './DigitizeDoctorProfileContainer';
import DigitizePastTreatmentContainer from './DigitizePastTreatmentContainer';
import PolicyStructureContainer from './PolicyStructureContainer';


const OuterContainer = styled.div`
  display: flex;
  flex: 2 0;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid #d8d8d8;
  overflow: hidden;
`;

const TabContainer = styled.div`
  display: flex;
  background: #f5f5f5;
  align-items: stretch;
  justify-content: space-between;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.23);
`;


const TabItemContainer = styled(StyledTappable)`
  padding: 1rem 4rem;
  border-bottom: ${(props) => (props.active ? '2px solid #4F34D2' : 'none')};
`;

const TabActiveItemText = styled(BoldText)`
  font-size: 1.6rem;
  color: #4f34d2;
`;

const TabItemText = styled(Text)`
  font-size: 1.6rem;
  opacity: 0.4;
  /* z-index: -1; */
`;

const ProfileContainer = ({ location, history, transcriberAccountActive }) => {
  const urlParams = new URLSearchParams(location.search);
  const profileSearchKey = urlParams.get('p');
  const renderTab = () => {
    switch (profileSearchKey) {
      case 'profile':
        if (transcriberAccountActive) return <DigitizeDoctorProfileContainer />;
        else return <DoctorProfileContainer />;
      case 'past-treatments':
        if (transcriberAccountActive) return <DigitizePastTreatmentContainer />;
        else return <PastTreatmentContainer />;
        case 'policy-structure' : 
        if (transcriberAccountActive) return <PolicyStructureContainer/>;
        else return null;
      default:
        if (transcriberAccountActive) return <DigitizeDoctorProfileContainer />;
        else return <DoctorProfileContainer />;
    }
  };
  const showTab = (search) => {
    if (profileSearchKey) {
      if (profileSearchKey !== search) {
        urlParams.set('p', search);
      }
    } else {
      urlParams.append('p', search);
    }
    history.replace(`${location.pathname}?${urlParams}`);
  };
  return (
    <OuterContainer>
      <TabContainer>
        <TabItemContainer
          active={profileSearchKey === 'profile' || !profileSearchKey}
          onTap={() => showTab('profile')}
        >
          {profileSearchKey === 'profile' || !profileSearchKey ? (
            <TabActiveItemText>Profile</TabActiveItemText>
          ) : (
            <TabItemText>Profile</TabItemText>
          )}
        </TabItemContainer>
        <TabItemContainer
          active={profileSearchKey === 'past-treatments'}
          onTap={() => showTab('past-treatments')}
        >
          {profileSearchKey === 'past-treatments' ? (
            <TabActiveItemText>
              {transcriberAccountActive
                ? 'Past Digitizations'
                : 'Past Treatments'}
            </TabActiveItemText>
          ) : (
            <TabItemText>
              {transcriberAccountActive
                ? 'Past Digitizations'
                : 'Past Treatments'}
            </TabItemText>
          )}
        </TabItemContainer>
        {transcriberAccountActive ? 
        <TabItemContainer
          active={profileSearchKey === 'policy-structure'}
          onTap={() => showTab('policy-structure')}
        
        >
{profileSearchKey === 'policy-structure' || !profileSearchKey ? (
            <TabActiveItemText>Policy Structure</TabActiveItemText>
          ) : (
            <TabItemText>Policy Structure</TabItemText>
          )}
        </TabItemContainer>
         :('')}
      </TabContainer>

      {renderTab()}
    </OuterContainer>
  );
};

const mapStateToProps = (state) => ({
  transcriberAccountActive: state.app.transcriberAccountActive,
});

export default connect(mapStateToProps)(withRouter(ProfileContainer));
