import React from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import file from '../../images/medicalFile.svg';
import Prescription from './Prescription';

const OuterContainer = styled.div`
position: absolute;
width: 100%;
height: 100%;
background: rgba(15, 11, 40, 0.73);
z-index: 9999999;
top: 0;
left: 0;
`;

const InnerContainer = styled.div`
position: absolute;
width: 350px;
max-height: 370px;  
border-radius: 14.799px;
background: #FFF;
box-shadow: 0px 0px 12.332610130310059px 0px rgba(0, 0, 0, 0.05);
left: 50%;
top: 50%;
padding: 20px;
transform: translate(-50%, -50%);
/* overflow-y: auto; */
`;

const HeadContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`;

const Head = styled.p`
color: #3A2CA0;
font-size: 18.017px;
font-style: normal;
font-weight: 600;
line-height: 120%;
letter-spacing: -0.36px;
`;

const Line = styled.div`
display: flex;
flex: 1;
height: 1px;
background: #F4F6F9;
margin-top: 28px; 
`;

const DataContainer = styled.div`
display: flex;
max-height: 290px;
/* height: 150px; */
overflow-y: auto;
`

const PrescriptionViewer = ({ close, data }) => {
  console.log(data);
  return (
    <OuterContainer>
        <InnerContainer>
            <HeadContainer>
                <div style={{display: 'flex', gap: '8px'}}>
                    <img src={file} alt='file'/>
                    <Head>Documents</Head>
                </div>
                <FaTimes style={{height: '16px', width: '16px', cursor: 'pointer'}} onClick={close}/>
            </HeadContainer>
            <Line />
            <DataContainer>
            {
                data.prescription && data.prescription.length > 0 && (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {
                            data.prescription.map((prescription, index) => {
                                return <Prescription key={index} link={prescription} flag = {true}/>;
                            })
                        }
                    </div>
                )
            }
            </DataContainer>
        </InnerContainer>
    </OuterContainer>
  )
}

export default PrescriptionViewer