import React from 'react';
import styled from 'styled-components';
import EmergencyUser from '../../../images/EmergencyUser.svg';
import EmergencyRelation from '../../../images/EmergencyRelation.svg';
import EmergencyPerson2 from '../../../images/EmergencyPerson2.svg';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 440px;
  height: 90px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  margin-top: 25px;
  margin-left: 20px;
`;

const InfoBody = styled.div`
  width: 440px;
  height: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #585969;
  margin-left: 16px;
  margin-top: 8px;
`;

const InfoTextContainer = styled.div`
  display: flex;
  width: 440px;
  height: 37px;
  background: rgba(252, 234, 234, 0.5);
  border-radius: 12px 12px 0px 0px;
`;

const InfoText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #0f0b28;
  margin-left: 12px;
  margin-top: 13px;
`;

const InfoImg = styled.img`
  margin-top: 13.33px;
  margin-left: 16.9px;
  margin-bottom: 12.67px;
  height: 16px;
  width: 16px;
`;

const NoDetailsCard = (props) => {
  return (
    <InfoContainer>
      <InfoTextContainer>
        <InfoImg src={EmergencyPerson2} />
        <InfoText>No Emergency Contact Available!</InfoText>
      </InfoTextContainer>
      {props.reportedReasons && props.reportedReasons.length ? (
        <InfoBody>
          We are doing everything in our ability to handle this emergency case.
        </InfoBody>
      ) : (
        <InfoBody>
          You can still report emergency we will take required action on this
          case.
        </InfoBody>
      )}
    </InfoContainer>
  );
};

export default NoDetailsCard;
