import React, { useState } from 'react';

const TipButton = ({
    tipText = '',
    style = {},
    tipStyle = {},
    ...props
}) => {

    const [isHovering, setIsHovering] = useState(false);

    return <button
        style={{
            ...style,
            position: 'relative',
        }}
        {...props}
        onMouseOver={() => setIsHovering(true)}
        onMouseOut={() => setIsHovering(false)}
    >
        {props.children}
        {(isHovering && tipText && !props.disabled) ? <span style={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translate(-75%, -110%)',
            padding: '2.5px 5px',
            width: '100px',
            background: '#ffffff',
            borderRadius: '2.5px',
            border: '1px solid #aaa',
            ...tipStyle,
        }}>{tipText}</span> : <></>}
    </button>
};

export default TipButton;