import React from 'react';
import styled from 'styled-components';
import EmergencyPerson1 from '../../../images/EmergencyPerson1.svg';
import EmergencyUser from '../../../images/EmergencyUser.svg';
import EmergencyRelation from '../../../images/EmergencyRelation.svg';
import EmergencyPerson2 from '../../../images/EmergencyPerson2.svg';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 440px;
  height: 107px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  margin-top: 25px;
  margin-left: 20px;
`;

const InfoRelationContainer = styled.div`
  display: flex;
`;

const InfoBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-top: 16px;
`;

const NameContainer = styled.div`
  display: flex;
`;
const NameIcon = styled.img`
  margin-right: 10px;
`;

const NameText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
`;

const NameHolder = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #585969;
  margin-left: 24px;
`;

const RelationBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 150px;
  margin-top: 16px;
`;

const RelationContainer = styled.div`
  display: flex;
`;

const RelationIcon = styled.img`
  margin-right: 10px;
`;

const RelationText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
`;

const RelationHolder = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #585969;
  margin-left: 26px;
`;

const LineBody = styled.div`
  width: 440px;
  height: 0px;
  opacity: 0.6;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 40px;
  margin-left: 20px;
`;

const ReasonsContainer = styled.div`
  box-sizing: border-box;
  width: 440px;
  height: 236px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  margin-left: 20px;
  margin-top: 25px;
`;
const InfoTextContainer = styled.div`
  display: flex;
  width: 440px;
  height: 40px;
  background: rgba(207, 200, 255, 0.32);
  border-radius: 12px 12px 0px 0px;
`;

const InfoText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #0f0b28;
  margin-left: 12px;
  margin-top: 13px;
`;

const InfoImg = styled.img`
  margin-top: 13.33px;
  margin-left: 16.9px;
  margin-bottom: 12.67px;
  height: 16px;
  width: 16px;
`;

const DetailsCard = (props) => {
  console.log(props, 'DetailsCard');
  return (
    <InfoContainer>
      <InfoTextContainer>
        <InfoImg src={EmergencyPerson1} />
        <InfoText>Emergency Contact Details</InfoText>
      </InfoTextContainer>
      <InfoRelationContainer>
        <InfoBody>
          <NameContainer>
            <NameIcon src={EmergencyUser} />
            <NameText>Name</NameText>
          </NameContainer>
          <NameHolder>
            {props.contactInfo.name.charAt(0).toUpperCase() +
              props.contactInfo.name.slice(1).toLowerCase()}
          </NameHolder>
        </InfoBody>
        <RelationBody>
          <RelationContainer>
            <RelationIcon src={EmergencyRelation} />
            <RelationText>Relation</RelationText>
          </RelationContainer>
          <RelationHolder>
            {props.contactInfo.relation.charAt(0) +
              props.contactInfo.relation.slice(1).toLowerCase()}
          </RelationHolder>
        </RelationBody>
      </InfoRelationContainer>
    </InfoContainer>
  );
};

export default DetailsCard;
