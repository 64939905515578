import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { RegularText, Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';
import TappableCard from '../common/TappableCard';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const StyledTappableCard = styled(TappableCard)`
  align-self: flex-start;
  padding: 1rem 3rem;
  margin: 0rem 0.625rem;
  border: ${(props) => (props.isSelected ? '0.1rem solid #4F34D2' : 'null')};
`;

const TappableText = styled(Text)`
  font-size: 1.4rem;
  text-align: center;
  color: ${(props) => (props.isSelected ? '#4F34D2' : 'black')};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-left: 0.625rem;
  flex: 1;
`;

const LabelText = styled(Text)`
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: center;
  align-self: center;
  margin-bottom: 2rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const SelectElement = styled(Select)`
  flex: 1;
  max-width: 30rem;
  /* border: 0.1rem solid #4F34D2; */
`;

class VoiceCallSuccessModal extends Component {
  render() {
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Voice Call</HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <LabelText>Voice call has been initiated successfully!</LabelText>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

export default VoiceCallSuccessModal;
