import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled,{ keyframes }  from 'styled-components';
import { getPolicyStructureOfUser } from '../services';
import StyledTappable from '../components/common/StyledTappable';
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from 'react-icons/bs';
const Text = styled.p`
  margin: 0;
  font-family: BrandonText-Medium, Lato, sans-serif;
  font-size: 1.6rem;
`;
const Heading = styled.h2`
  margin: 0;
  font-family: BrandonText-Medium, Lato, sans-serif;
  font-size: 1.7rem;
  font-weight: normal;
`;
const BoldText = styled(Text)`
  font-family: BrandonText-Bold, Lato, sans-serif;
`;
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #4F34D2;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 2s linear infinite;
`;
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;
const ButtonText = styled(BoldText)`
  font-size: 1.6rem;
  color: white;
  text-align: center;
`;
const LeftArrow = styled(BsFillArrowLeftCircleFill)`
  font-size: 3rem;
  font-weight: normal;
  color: ${(props) => (props.disabled ? '#B9C8FC' : '#5a68e7')};
  padding-right: 2px;
`;
const RightArrow = styled(BsFillArrowRightCircleFill)`
  font-size: 3rem;
  font-weight: normal;
  color: ${(props) => (props.disabled ? '#B9C8FC' : '#5a68e7')};
  padding-left: 2px;
`;
const StyledButton = styled(StyledTappable)`
  width: 20rem;
  height: 5rem;
  justify-content: center;
  margin: 0rem 0rem;
  border-radius: 5px;
  background-color: ${(props) => (props.disabled ? '#B9C8FC' : '#5a68e7')};
`;
class PolicyStructureContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policyStructureFile: [],
      currentIndex: 0,
      isLoading:true,

    };
  }
  componentDidMount = () => {
    if (this.props.activeConsult.digitisationRequestId) {
      this.getPolicyDetail();
    }
  };

  componentDidUpdate(prevProps) {
    console.log(
      prevProps.activeConsult.digitisationRequestId !==
        this.props.activeConsult.digitisationRequestId,
        prevProps.activeConsult.digitisationRequestId,this.props.activeConsult.digitisationRequestId,
      'componentDidMountCheck-value'
    );
    if (
      prevProps.activeConsult.digitisationRequestId !==
      this.props.activeConsult.digitisationRequestId
    ) {
      this.getPolicyDetail();
    }
   
  }

  getPolicyDetail = async () => {
    const { digitisationRequestId } = this.props.activeConsult;
    await getPolicyStructureOfUser(digitisationRequestId, this.props.authToken)
      .then((res) => {
        
        this.setState({
          policyStructureFile: res.map((data) => {
            return data;
          }),
        });
        this.setState({
          isLoading:false
        })
      })
      .catch((err) => {
        console.log('err : ', err);
      });
  };
  nextFile = () => {
    this.setState({ currentIndex: this.state.currentIndex + 1 });
  };
  previousFile = () => {
    this.setState({ currentIndex: this.state.currentIndex - 1 });
  };

  render() {
    const { policyStructureFile,isLoading } = this.state;
    return  isLoading ? (
      <LoaderWrapper>
       <Loader className=''/>
       </LoaderWrapper>
      
    ):(
      <>
       <OuterContainer>
        {policyStructureFile.length > 0 ? (
          <div>
            <div className="flex flex-row">
              <div className="basis-4/5 ml-1">
                <h2
                  className="mt-3"
                  style={{
                    fontFamily: 'BrandonText-Medium, Lato, sans-serif',
                    fontWeight: 'bold',
                    fontSize: '22px',
                    color: '#5a68e7',
                  }}
                >
                  {policyStructureFile[this.state.currentIndex]?.policyName}

                </h2>
              </div>
              {policyStructureFile.length>1?
              <div className="basis-1/2 mr-2 float-right">
                <button
                  className="mt-3 float-right"
                  style={{
                    borderRadius: '5px',
                    backgroundColor: `${(props) =>
                      props.disabled ? '#B9C8FC' : '#5a68e7'}`,
                  }}
                  onClick={this.nextFile}
                  disabled={
                    policyStructureFile.length - 1 <= this.state.currentIndex
                  }
                >
                  <RightArrow
                    disabled={
                      policyStructureFile.length - 1 <= this.state.currentIndex
                    }
                  />
                </button>
                <Heading className="ml-1 mr-1 mt-4 float-right">
                  Policy {this.state.currentIndex + 1} of{' '}
                  {policyStructureFile.length}
                </Heading>
                <button
                  className="ml-3 mt-3  float-right"
                  onClick={this.previousFile}
                  disabled={
                    this.state.currentIndex > this.policyStructureFile ||
                    this.state.currentIndex === 0
                  }
                >
                  <LeftArrow
                    disabled={
                      this.state.currentIndex > this.policyStructureFile ||
                      this.state.currentIndex === 0
                    }
                  />
                </button>
              </div>
              :('')}
            </div>
            <div>
            {policyStructureFile.length > 0 ?(
                     <h2
                     className="mt-3 ml-3"
                     style={{
                       fontFamily: 'BrandonText-Medium, Lato, sans-serif',
                       fontWeight: 'normal',
                       fontSize: '16px',
                       color:'black',
                       border:'2px solid #5a68e7',
                       borderRadius:'4px',
                       width:'130px',
                       padding:'2px'
                     
                     }}
                   >{policyStructureFile.length} {policyStructureFile.length>1? 'Policies':'Policy'} found</h2>
                ):('')}
            </div>
            <div className="flex flex-row ml-1">
              <Heading className="mt-1 mb-1" style={{ fontSize: '18px' }}>
                Policy tenure -
              </Heading>
              <Heading className="mt-1.5 ml-2" style={{ fontSize: '18px' }}>
                {policyStructureFile[this.state.currentIndex]?.policyStartDate}{' '}
                - {policyStructureFile[this.state.currentIndex]?.policyEndDate}{' '}
              </Heading>
            </div>
            { policyStructureFile[this.state.currentIndex].policyStructureUrl?
            <iframe
              title="PolicyStructure"
              src={
                policyStructureFile[this.state.currentIndex]?.policyStructureUrl
              }
              
              style={{
                height: '100%',
                width: '100%',
                minHeight: '80vh',
              }}
            />
            :(<h3
              className="mt-10 ml-3 text-center"
              style={{
                fontFamily: 'BrandonText-Medium, Lato, sans-serif',
                fontWeight: 'normal',
                fontSize: '20px',
                color:'#fff',
                border:'1px solid #5a68e7',
                borderRadius:'4px',
                padding:'2px',
                backgroundColor:'#5a68e7'
              
              }}
            >
              Policy document not found
            </h3>)}
          </div>
        ) : (
          <div
            className="mt-10 text-center"
            style={{
              backgroundColor: '#B9C8FC',
              padding: '15px',
              borderRadius: '3px',
            }}
          >
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '15px',
                fontFamily: 'BrandonText-Medium, Lato, sans-serif',
              }}
            >
              {' '}
              No results found{' '}
            </p>
          </div>
        )}
      </OuterContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
  user: state.user,


});

export default connect(mapStateToProps)(PolicyStructureContainer);
