import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RegularText, Text } from '../../common/Text';
import moment from 'moment';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0.4rem 0;
  margin-left: 12px;
`;

const DoctorImage = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.6rem;
  border-radius: 2.4rem;
`;

const BubbleContainer = styled.div`
  display: flex;
  /* flex: 1; */
  flex-wrap: wrap;
  flex-direction: column;
  padding: 1rem 1.2rem;
  /* background: #f7f7f7;
  border-radius: 1.4rem; */
  border-radius: 12px 12px 12px 2px;
  background: var(--primary, #714FFF);
`;

const DoctorName = styled(Text)`
  font-size: 1.2rem;
  opacity: 0.5;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MessageText = styled(RegularText)`
  font-size: 1.6rem;
  color: #FFF;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 156%; /* 21.84px */
  letter-spacing: -0.165px;
`;

const TimestampContainer = styled.div`
  display: flex;
  min-width: 45px;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1.6rem;
`;

const TimestampText = styled(RegularText)`
  /* opacity: 0.5;
  font-size: 1.2rem; */
  color: #A5A6BB;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 156%; /* 15.6px */
  letter-spacing: -0.165px;
`;

const DoctorMessage = ({ message, doctorImage, doctorName, timestamp }) => (
  <OuterContainer>
    <BubbleContainer>
        <MessageText>{message}</MessageText>
    </BubbleContainer>
    {timestamp && (
          <TimestampContainer>
            <TimestampText>
              {moment(timestamp).format('HH:mm A')}
            </TimestampText>
          </TimestampContainer>
        )}
  </OuterContainer>
);

DoctorMessage.propTypes = {
  doctorName: PropTypes.string,
  doctorImage: PropTypes.string,
  message: PropTypes.string,
  timestamp: PropTypes.number,
};

export default DoctorMessage;
