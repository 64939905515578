import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactTable from 'react-table';
import selectTableHoc from 'react-table/lib/hoc/selectTable';
import "react-table/react-table.css";  
import styled from 'styled-components';
import Header from '../../components/common/AdminHeader';
import  {getAllAdminDoctor} from  '../../services/consults';
import {removeDoctorAdmin} from '../../services/consults';
import {getAllDoctor} from '../../services/consults';
import {addDoctorAdmin} from   '../../services/consults';
import { PrimaryButton } from '../../components/common/Buttons';
import { Async as AsyncSelect } from 'react-select';
import Modal from '../admin/AdminModal';
import { FaFileUpload, FaPowerOff, FaSignature, FaTimes } from 'react-icons/fa';
import StyledTappable from '../../components/common/StyledTappable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
   width: 16rem; 
`;
const AddButton = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const styleobj = { 
    "font-size": "17px",
   "overflow": 'hidden',
   "background-color":"#F5EDDC",
   "textAlign":"center"
   }
   const Text = styled.p`
   margin: 0;
   font-family: Inter, Lato, sans-serif;
   color: ${(props) => props.color || 'black'};
   font-size: ${(props) => props.fontSize || '1.6rem'};
 `;

 const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;
const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;
const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;
const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;
const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;
const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;
const HeaderText = styled.text`
  font-size: 2.4rem;
  margin: 14px 0;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;
class AdminTable extends Component {  

    constructor(props){
        super(props);
        this.state={
            doctorAdmin:[],
            doctorArray:[],
            addDoctorAdminModal :false,
            doctorValue: null,
            doctorAdminId:-1,
        }
        this.getData()
    }
 
    removeDoctorList(doctorId){

        return removeDoctorAdmin(doctorId,this.props.authToken).then((res)=>{
            this.getData().then(()=>{
                if(res.message === 'success'){
                    toast.success('Doctor is Removed.')
                }
                else{
                    toast.error('Error.')
                }
            })
            .catch((err) => {
                console.log({ err });
                return [];
    
              });

        })
        .catch((err) => {
            console.log({ err });
            return [];

          });
    }
    getAllDoctorList=(input)=>{
        return getAllDoctor(input.toLowerCase(),this.props.authToken)

        .then((result) => {
            console.log({ result }, 'getDoctors result');
            result.forEach((doc) => {
              doc.label = doc.name;
              doc.value = doc.doctorId;
            });
        
            return result;
          })
        .catch((err) => {
            console.log({ err });
            return [];

          });
    }
    removeDoctor = (doctorId) => () => {
        this.removeDoctorList(doctorId)
      };
    //   componentDidMount() {
    //     this.props.history.push('/home');
    // }

    getData(){
        return getAllAdminDoctor(this.props.authToken).then((res)=>{
            const doctorAdmin =res.result;
            if(res.message ==='success'){
            this.setState({
                doctorAdmin,
           })

            }
            else{
            toast.error('Error')
            }
        })
        .catch((err)=>{
            console.log({ err });
        })
    }

    hideModal = () => {
        this.setState({
            addDoctorAdminModal: false,
        });
    };
    showAddDoctorAdmin = () => {
        this.setState({
            addDoctorAdminModal: true,
        });
        
    };
    onDocSelect =(option) =>{
        this.setState({
            doctorValue: {
              label: option.doctorName,
              value: option.doctorId,
            },
            doctorAdminId:option.doctorId
          });
    }

    promiseOptions = (input) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(this.getAllDoctorList(input));
          }, 500);
        });
    };
    addDdoctorAdmin = () =>{
    
    return addDoctorAdmin(this.state.doctorAdminId,this.props.authToken).then((result)=>{
        this.getData().then(()=>{
            if(result.message === 'success'){
             toast.success('Doctor Added.')
            this.setState({
                addDoctorAdminModal:false,
            })
            
        }
        else{
            toast.error('Error')
        }
        })
        .catch((err)=>{
            console.log({ err });
        })
        
    })
    .catch((err)=>{
        console.log({ err });
    })
 }

    render(){

        const {doctorAdmin,addDoctorAdminModal,doctorArray,doctorAdminId} = this.state;

        const columns = [
            {  
              Header: 'DoctorId',  
              accessor: 'doctorId' ,
              }, 
            
            {
            Header:'DoctorName',
            accessor: 'name'
            },
            {
                Header:'Action',
    
                Cell: ( row ) => (
                   <Button onTap={this.removeDoctor(row.original.doctorId)}>
                   <Text fontSize="16px" color="white">
                  Remove Doctor
                </Text>
                   </Button>
                  )
            }
          ]  


        return(

      <div className="container-fluid  overflow-auto ... w-full ... px-2">
      <Header> 
  
      </Header>
      <div className="flex flex-row pt-3">
      <div className="basis-1/6">
      <PrimaryButton
                  type="button"
                  className="mt-2 mb-2 float-left text-center align-self-end "
        onTap={this.showAddDoctorAdmin}
        >
       <Text fontSize="16px" color="white">
                    Add Doctor
                  </Text>
      
                  </PrimaryButton>
        </div>
        </div>
     
        
      <ReactTable
     data={doctorAdmin} 
     columns={columns} 
     style={styleobj}
     showPaginationTop={true}
     showPaginationBottom={false}
      
      />
      

{addDoctorAdminModal && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>
                    {`Please select a doctor`}
                  </HeaderText>
                  <ButtonsComponent>   
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body>
                <AsyncSelect
         //defaultOptions={doctorArray}
        placeholder='Select Doctor'
        isClearable={true}
        loadOptions={this.promiseOptions}
        //onChange={this.onDoctorSelected}
        onChange={(option, cb) => this.onDocSelect(option, cb)}
      />  
                  <ButtonComponent>
                    <AddButton
                       onTap={this.addDdoctorAdmin}
                       disabled={doctorAdminId === -1}
                    >
                      <Text fontSize="16px" color="white">
                        Add Admin
                      </Text>
                    </AddButton>
                  </ButtonComponent>
                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
        
          <ToastContainer /> 
      </div>
            
        )
    }


}
const mapStateToProps = (state) => ({
  user: state.user,
  authToken: state.user.authToken,
});
export default  connect(mapStateToProps)(AdminTable);