import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import liveCasesIcon from '../../images/live-cases.svg';
import { fetchConsults } from '../../services';
import InfoCard from './InfoCard';
import { ThreeDots } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import { selectConsult, setDoctorLastReadTimetokenForPrevConsult, updateConsults } from '../../actions/index';
import pana from '../../images/pana.svg';
import { store } from '../../store';
import { showError } from '../../utils';

const LiveCasesOuter = styled.div`
  width: 400px;
  height: 480px;
  background: #ffffff;
  margin-top: 16px;
  margin-left: 20px;
  box-shadow: 2px 2px 8px rgba(113, 79, 255, 0.21);
  border-radius: 20px;
  overflow-y: scroll;
  padding-bottom: 8px;
`;

const LiveCasesLogoContainer = styled.div`
  display: flex;
`;

const LiveCasesLogo = styled.img`
  margin-top: 26px;
  margin-left: 26px;
`;
const LiveCasesHead = styled.h1`
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #0f0b28;
  margin-top: 26px;
  margin-left: 14px;
`;

const NoCases = styled.img`
width: 232.19px;
height: 171px;
margin-left: 82px;
margin-top: 140px;
`

const NoCasesText = styled.p`
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 120%;
color: #0F0B28;
margin-left: 128px;
margin-top: 30px;
`

const LiveCases = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedSponsorId, setSelectedSponsorId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [consultationIdKey, setConsultationIdKey] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [noData, setNoData] = useState(false);
  const [preActiveConsult, setPreActiveConsult] = useState(null);
  const [update, setUpdate] = useState(false);

  const skipEffect = useRef(false);
  
  const dispatch = useDispatch();

  const activeConsult = useSelector((state) => state.consults.activeConsult);
  // const activeConsult = null;
  const [consultationData, setConsultationData] = useState(activeConsult.consultationId ? [activeConsult] : []);

  const updatedConsults = useSelector((state) => state.consults.consults);

  const scrollContainerRef = useRef(null);

  const scrollToTop = () => {
    setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0;
      }
    },400);
  };

  useEffect(() => {
    console.log('mounted');
    return () => {
      console.log('unmounted');
      setConsultationData([]);
    }
  },[]);

  useEffect(() => {
    console.log(activeConsult, "activeConsultDatatata");
    if(activeConsult.consultationId) dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
  },[]);

  useEffect(() => {
    console.log(activeConsult, "activeConsultUnreadMsgs");
    if(activeConsult.consultationId) dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
    // store.dispatch(updateConsults());
  },[activeConsult.unreadMessageCount]);

  useEffect(() => {
    if(activeConsult.consultationId) dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
    skipEffect.current = true;
    if(skipEffect.current) {
      setConsultationData([]);
      setUpdate((prev) => !prev);
    }
  },[updatedConsults]);

  const observer = useRef();
  const lastRef = useCallback((node) => {
    if(loading || !node) return;
    if(observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    })
    if(node) observer.current.observe(node);
  },[loading, hasMore]);

  const filters = [{isSelected: true, name: 'Live Cases', verticalId: props.information.user.verticalId}];
  const check = searchText !== props.searchText

  useEffect(() => {
    setHasMore(true);
    setPage(1);
    if(props.searchText) {
      setSearchText(props.searchText);
      setConsultationData(activeConsult.consultationId ? [activeConsult] : []);
    }
  },[props.searchText, check]);

  console.log(searchText, "searchText");

  const setActiveConsult = async (consult) => {
    consult.unreadMessageCount = 0;
    if(consult.consultationId === activeConsult.consultationId) return;
    dispatch(selectConsult(consult));
    // dispatch action here to make api call to update doctor_last_read_timetoken
    dispatch(setDoctorLastReadTimetokenForPrevConsult(activeConsult));
    store.dispatch(updateConsults())
    setPreActiveConsult(activeConsult);
    setConsultationData([]);
    setHasMore(true);
    setPage(1);
    props.renderChats();
  }


  const getAllConsults = async () => {
    if(!hasMore) return;
    setLoading(true);
    setNoData(false);
    try {
      console.log(page);
      const consultsData = await fetchConsults(props.authToken, filters, selectedSponsorId, searchText, consultationIdKey, page);
      console.log(consultsData, page, "consultsData testtttt");
      if(!consultsData.responseBody.consultations) {
        setHasMore(false);
        setLoading(false);
        return;
      }

      let filterArr = consultsData.responseBody.consultations;

      console.log(filterArr, "consultationData");
      
      setConsultationData((prevData) => {
        const consultationIdMap = new Map(filterArr.map(obj => [obj.consultationId, true]));
        if(page === 1) prevData = []
        const uniqueData = prevData.filter(obj => !consultationIdMap.has(obj.consultationId));
        console.log(uniqueData);
        // const resultArr = [...uniqueData,...filterArr].filter((c) => c.unreadMessageCount > 0 || (activeConsult.consultationId && c.consultationId === activeConsult.consultationId));
        return [...uniqueData,...filterArr];
      }); 
     
      if(consultsData.responseBody.consultations.length === 0) {
        setTimeout(() => {
          setNoData(true);
        },450);
      }
      setTimeout(() => {
        setLoading(false);
      },400);
    }
    catch (err) {
      console.log(err);
      showError(err);
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllConsults();
  }, [page, searchText, hasMore]);

  useEffect(() => {
    if(page === 1) {
      setTimeout(() => {
        getAllConsults();
      }, 100);
    }
    setTimeout(() => {
      setHasMore(true);
      setPage(1);
    }, 100);
  }, [ update ]);

  console.log(page, 'page');
  console.log(consultationData, noData,"consultationData");
  console.log(props.information, 'props live cases');
  

  return (
    <LiveCasesOuter ref={scrollContainerRef}>
      <LiveCasesLogoContainer onClick={props.close} style = {{cursor: 'pointer'}}>
        <LiveCasesLogo src={liveCasesIcon} />
        <LiveCasesHead>Live Cases</LiveCasesHead>
      </LiveCasesLogoContainer>
      {
        consultationData && consultationData.length !== 0 && consultationData.map((data, idx) => {
          if(consultationData.length === idx + 1) {
            return (
              <div ref = {lastRef} onClick = {() => setActiveConsult(data)}>
                <InfoCard consultationData = {data} id = {1} activeConsult = {activeConsult}/>
              </div>
            )
          }
          return (
            <div onClick = {() => setActiveConsult(data)}>
              <InfoCard consultationData = {data} id = {1} activeConsult = {activeConsult}/>
            </div>
          )
        })
      }
      {
        noData && consultationData && consultationData.length === 0 && (
          <>
          <NoCases src={pana} alt='no cases'/>
          <NoCasesText>No Cases Found</NoCasesText>
          </>
        )
      }
      {
        loading && (
          <div style={{width: '400', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ThreeDots 
              height='60'
              width='60'
              radius='9'
              color='#714FFF'
              ariaLabel="progress-bar-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
          />
          </div>
        )
      }
    </LiveCasesOuter>
    )
};

export default LiveCases;
