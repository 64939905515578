import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import video from '../../images/video.svg';
import voice from '../../images/voice.svg';
import schedule from '../../images/schedule.svg'

const Container = styled.div`
padding: 16px;
width: 352px;
height: auto;
background: #FFFFFF;
border: 1px solid #F0F3F6;
box-shadow: 2px 2px 8px rgba(58, 44, 160, 0.1);
border-radius: 12px;
margin-left: 20px;
margin-top: 19px;
cursor: pointer;
`

const HeadContainer = styled.div`
display: flex;
word-break: break-word;
`

const Name = styled.h1`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 150%;
color: #0F0B28;
letter-spacing: 0.05rem;
`

const DateTimeContainer = styled.div`
height: 15px;
width: 140px;
margin-top: 5px;
margin-left: auto;
word-break: break-word;
`

const DateTime = styled.h1`
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 150%;
color: #0F0B28;
opacity: 0.4;
`

const Sponsor = styled.h1`
font-style: normal;
font-weight: 600;
font-size: 11px;
line-height: 150%;
color: #A5A6BB;
margin-top: 4px;
letter-spacing: 0.05rem;
`

const Line = styled.div`
width: 328px;
height: 0px;
border: 1px solid rgba(228, 228, 228, 0.25);
flex: none;
order: 1;
flex-grow: 0;
margin-top: 8px;
`

const InfoContainer = styled.div`
display: flex;
flex-wrap: wrap;
gap: 8px;
`

const MsgContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
max-width: 150px;
height: auto;
margin-top: 8px;
border-radius: 12px;
padding: 5px 8px;
`

const LabelContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 120px;
height: auto;
margin-top: 8px;
border-radius: 12px;
padding: 5px 8px;
background: #E7FAFF;
`

const Label = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 106px;
height: auto;
font-style: normal;
font-weight: 800;
font-size: 10px;
color: #3F8CFF;
`

const Msg = styled.div`
display: flex;
justify-content: center;
align-items: center;
max-width: 150px;
height: auto;
font-style: normal;
font-weight: 800;
font-size: 10px;
`

const InfoCard = (props) => {
  console.log(props.consultationData,'data');

  const [label, setLabel] = useState({});

  useEffect(() => {
    const labelArray = props.consultationData.labels;
    const labelObj = {};
    labelArray.forEach((label) => {
      if(label.text === 'Video Call Request') {
        labelObj.text = 'Video Call Request';
        labelObj.color = '#38A169';
      } else if (label.text === 'Voice Call Request') {
        labelObj.text = 'Voice Call Request';
        labelObj.color = '#38A169';
      }
    });
    setLabel(labelObj);
  },[]);

  const getTimeText = (doctorTimetoken, userTimetoken) => {
    doctorTimetoken = doctorTimetoken / 10000000;
    userTimetoken = userTimetoken / 10000000;

    // const docMin = doctorTimetoken / 60;
    const userMin = userTimetoken / 60;

    if (userTimetoken > doctorTimetoken) {
      const currentMin = moment().valueOf() / 60000;
      const min = parseInt(currentMin - userMin);
      if (min < 60) {
        return `${min}min`;
      } else if (min < 3600) {
        return `${parseInt(min / 60)}h`;
      } else {
        return `${parseInt(min / (60 * 24))}days`;
      }
    }

    return null;
  };

  const getDateText = (createdAt) => {
    // converting in moment format
    // createdAt = createdAt * 1000;

    if (!createdAt) {
      return null;
    }

    const latestTime = moment(createdAt);

    const currentTime = moment();
    if (latestTime.format('DD') !== currentTime.format('DD')) {
      return latestTime.format('hh:mm A | DD[th] MMM YYYY');
    } else {
      return latestTime.format('h:mm A | DD[th] MMM YYYY');
    }
  };

  return (
    <Container style={props.activeConsult && props.consultationData.consultationId === props.activeConsult.consultationId ? {background: '#F7F5FF', border: '2px solid #714FFF'} : {background: '#FFFFFF'}}>
        <HeadContainer>
            <Name>{props.consultationData.user.relativeName ? props.consultationData.user.relativeName : props.consultationData.user.userName}</Name>
            <DateTimeContainer>
            <DateTime>{getDateText(props.consultationData.timeStamp)}</DateTime>
            </DateTimeContainer>
        </HeadContainer>
        <Sponsor>{props.consultationData.user.relativeName ? `${props.consultationData.user.userName} | ` : ''}{props.consultationData.sponsor && props.consultationData.sponsor.sponsorName ? props.consultationData.sponsor.sponsorName : 'B2C'}</Sponsor>
        {
          props.id === 0 && props.activeConsult && props.consultationData.consultationId !== props.activeConsult.consultationId && (
            <>
              <Line />
              <InfoContainer>
                  <MsgContainer style={{background: '#daece9'}}>
                    <Msg style={{color: '#27ac9c'}}>{`Call Preference: ${props.consultationData.requestedCallType === 'audio' ? 'voice' : 'video'}`}</Msg>
                  </MsgContainer>
                  {
                    props.consultationData.consultationCallScheduled.isConsultationCallScheduled && (
                      <MsgContainer style={{background: '#FFEBF7', gap: '4px', maxWidth: '170px'}}>
                        <img src={schedule} alt='time'/>
                        <Msg style={{color: '#DA5E86'}}>{`${props.consultationData.consultationCallScheduled.scheduledAt}`}</Msg>
                      </MsgContainer>
                    )
                  }
                  {
                    label.text && (
                      <LabelContainer style={{background: '#C6F6D5', width: '140px'}}>
                      {
                        label.text === 'Video Call Request' && (
                          <div style={{display: 'flex', gap: '2px'}}>
                            <img src={video} alt='video'/>
                            <Label style={{color: `${label.color}`}}>{label.text}</Label>
                          </div>
                        )
                      }
                      {
                        label.text === 'Voice Call Request' && (
                          <div style={{display: 'flex', gap: '2px'}}>
                            <img src={voice} alt='voice'/>
                            <Label style={{color: `${label.color}`}}>{label.text}</Label>
                          </div>
                        )
                      }
                      </LabelContainer>
                    )
                  }
                  {
                    props.consultationData.unreadMessageCount > 0 && (
                      <MsgContainer style={props.consultationData.unreadMessageCount < 6 ? {background: '#E7FAFF'} : {background: '#FCEAEA'}}>
                        <Msg style={props.consultationData.unreadMessageCount < 6 ? {color: '#3F8CFF'} : {color: '#FF754C'}}>{props.consultationData.unreadMessageCount} unread messages</Msg>
                      </MsgContainer>
                    )
                  }
                  {
                    props.consultationData.daysLeft > 0 && !props.consultationData.closedOn && (
                      <LabelContainer style={{backgroundColor: '#F5F4FF'}}>
                        <Label style={{color: '#714FFF'}}>{`${props.consultationData.daysLeft} Days Left`}</Label>
                      </LabelContainer>
                    )
                  }
                  {
                    props.consultationData.closedOn && (
                      <LabelContainer>
                        <Label>Completed</Label>
                      </LabelContainer>
                    )
                  }
              </InfoContainer>
            </>
          )
        }
        {// made changes here
          (props.id !==0 && props.id !== 3.2 && (props.activeConsult && props.consultationData.consultationId !== props.activeConsult.consultationId)) && (
            <>
            <Line/>
              <InfoContainer>
                  {
                    props.id === 1 && (
                      <>
                      <MsgContainer style={{background: '#daece9'}}>
                        <Msg style={{color: '#27ac9c'}}>{`Call Preference: ${props.consultationData.requestedCallType === 'audio' ? 'voice' : 'video'}`}</Msg>
                      </MsgContainer>
                      {
                        props.consultationData.consultationCallScheduled.isConsultationCallScheduled && (
                          <MsgContainer style={{background: '#FFEBF7', gap: '4px', maxWidth: '170px'}}>
                            <img src={schedule} alt='time'/>
                            <Msg style={{color: '#DA5E86'}}>{`${props.consultationData.consultationCallScheduled.scheduledAt}`}</Msg>
                          </MsgContainer>
                        )
                      }
                      </>
                    )
                  }
                  {
                    props.consultationData.unreadMessageCount > 0 && (props.id === 1 || props.id === 2.2) && (
                      <MsgContainer style={props.consultationData.unreadMessageCount < 6 ? {background: '#E7FAFF'} : {background: '#FCEAEA'}}>
                        <Msg style={props.consultationData.unreadMessageCount < 6 ? {color: '#3F8CFF'} : {color: '#FF754C'}}>{props.consultationData.unreadMessageCount ? props.consultationData.unreadMessageCount : '0'} unread messages</Msg>
                      </MsgContainer>
                    )
                  }
                  {
                    props.id === 3.1 && (
                      <LabelContainer>
                        <Label>{`${props.consultationData.daysLeft} Days Left`}</Label>
                      </LabelContainer>
                    )
                  }
            </InfoContainer>
            </>
          )
        }
    </Container>
  )
}

export default InfoCard