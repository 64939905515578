import React, { useState, useEffect } from "react";
import { AiFillDelete } from 'react-icons/ai';
import { connect } from 'react-redux';
import { removeMedicineGroupByMasterItemCode } from '../../services/';

const providerIdImageMapping = {
    '7': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/pharmeasy_logo.png',
    '1': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/apollo-logo.png',
    '5': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/1mg-logo.png',
    '9': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/wellness_logo.png'
}

const medicineListStyles = {
    display: 'flex',
    flexDirection: 'column',
    height: '65vh',
    overflowY: 'scroll',
    gap: '10px',
    margin: '5px',
    border: '0.7px solid lightgrey',
    padding: '0.9rem',
    borderRadius: '12px',
};

const medicineCardStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',


};

const medicineNameStyles = {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '13px',
    letterSpacing: '0.27px',
    textAlign: 'left',
    textTransform: 'capitalize',
    color: '#0F0B28',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '200px'
};


const medicinePackStyles = {
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#767787',
    fontWeight: '600',
    fontSize: '10px',
    textAlign: 'left',
    letterSpacing: '0.25px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

const providerLogoContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',
    justifyContent: 'flex-end',
    gap: '5px',
    position: 'relative'
};

const providerLogoStyles = {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    border: '1px solid lightgray',
    position: 'relative',
    flexShrink: 0
};

const groupMedicineByMasterItemCode = (medicines) => {
    const groupedData = medicines.reduce((acc, curr) => {
        if (!acc[curr.master_group_item_code]) {
            acc[curr.master_group_item_code] = {
                masterGroupName: curr.master_group_name || "",
                substituteMedicines: [],
                nonSubstituteMedicines: [],
                notAvailableMedicines: []
            };
        }
        if (curr.is_available) {
            if (curr.is_substitute) {
                acc[curr.master_group_item_code].substituteMedicines.push(curr);
            } else {
                acc[curr.master_group_item_code].nonSubstituteMedicines.push(curr);
            }
        }
        else {
            acc[curr.master_group_item_code].notAvailableMedicines.push(curr);
        }

        return acc;
    }, {});
    return groupedData;
}



const MedicineNameBasedCartItemListModel = (props) => {
    const [medicines, setMedicines] = useState(props.result);
    const [groupedData, setGroupedData] = useState(groupMedicineByMasterItemCode(medicines));

    useEffect(() => {
        setMedicines(props.result);
        setGroupedData(groupMedicineByMasterItemCode(props.result));
    }, [props.result]);

    const deleteMedicineGroup = async (dRequestId, masterItemCode) => {

        const medicineBody = {
            dRequestId: dRequestId,
            masterItemCode: masterItemCode
        }
        const meds = await removeMedicineGroupByMasterItemCode(props.authToken, medicineBody);
        props.reloadCartItems(meds);
    }
    return (
        <div style={medicineListStyles}>
            {Object.keys(groupedData).reverse().map((masterGroupItemCode) => (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    padding: '10px',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#FFFFFF'
                }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingBottom: '0.7rem' }}><button style={{ fontWeight: 'bolder', fontSize: "12px" }} onClick={() => deleteMedicineGroup(medicines[0].digitisation_request_id, masterGroupItemCode)}><AiFillDelete style={{ color: '#ff754c' }} size={15}></AiFillDelete></button></div>
                    <div style={medicineCardStyles} key={masterGroupItemCode}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            {!!(groupedData[masterGroupItemCode].nonSubstituteMedicines.length || groupedData[masterGroupItemCode].substituteMedicines.length) &&
                                <div style={medicineNameStyles}>{groupedData[masterGroupItemCode].masterGroupName?.toUpperCase()}</div>}
                            {groupedData[masterGroupItemCode].nonSubstituteMedicines.length == 0 && groupedData[masterGroupItemCode].nonSubstituteMedicines[0]?.is_availabe == 1 && <div style={medicineNameStyles}>{groupedData[masterGroupItemCode].masterGroupName?.toUpperCase()}</div>}
                            <div
                                style={{
                                    display: 'flex', flexDirection: 'row',
                                    color: '#585969', fontSize: '9px',
                                }}
                            >{groupedData[masterGroupItemCode].nonSubstituteMedicines?.length > 0 && groupedData[masterGroupItemCode].nonSubstituteMedicines[0].is_covered == 1 ? `${groupedData[masterGroupItemCode].nonSubstituteMedicines[0]?.dosage} Daily  ${groupedData[masterGroupItemCode].nonSubstituteMedicines[0]?.meal} food for ${groupedData[masterGroupItemCode].nonSubstituteMedicines[0]?.duration} ${groupedData[masterGroupItemCode].nonSubstituteMedicines[0]?.duration_type}` : ''}</div>
                            <div>
                                {groupedData[masterGroupItemCode].nonSubstituteMedicines.length > 0 && groupedData[masterGroupItemCode].nonSubstituteMedicines[0].is_covered == 0 && <div style={{ ...medicinePackStyles, color: '#FF754C', alignSelf: 'flex-start' }}>{'Not Covered'}</div>}
                            </div>

                        </div>
                        <div style={providerLogoContainerStyles}>
                            {/* Render substitute medicines */}
                            {groupedData[masterGroupItemCode].nonSubstituteMedicines.map((item, index, array) => (
                                <div key={item.id}>
                                    <img src={providerIdImageMapping[item.ph_partner_id]} style={{ ...providerLogoStyles, zIndex: 1, right: `${(array.length - 1 - index) * -20}px` }} />
                                </div>
                            ))}
                        </div>
                    </div>

                    {groupedData[masterGroupItemCode].substituteMedicines.map((item) => (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTop: '0.5px solid lightgray',
                            marginTop: '1rem',
                            paddingTop: '0.5rem'
                        }}>

                            {
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>

                                    <div style={{
                                        ...medicineNameStyles, color: '#714FFF'
                                    }}><sup
                                        style={{
                                            color: '#ff754c',
                                            fontSize: '12.5px',
                                            fontWeight: 900,
                                        }}>&bull;</sup>
                                        {item.name.toUpperCase()}</div>
                                    {item.is_covered == 1 && <div
                                        style={{
                                            display: 'flex', flexDirection: 'row',
                                            color: '#585969', fontSize: '9px',
                                        }}
                                    >{`${item.dosage} Daily  ${item.meal} food for ${item.duration} ${item.duration_type}`}

                                    </div>
                                    }
                                    {item.is_covered == 0 && <div style={{ ...medicinePackStyles, color: '#FF754C', alignSelf: 'flex-start' }}>{'Not Covered'}</div>}
                                </div>
                            }

                            <div style={providerLogoContainerStyles}>
                                <div key={item.id}>
                                    <img src={providerIdImageMapping[item.ph_partner_id]} style={{ ...providerLogoStyles, zIndex: 1 }} />
                                </div>

                            </div>
                        </div>
                    ))}
                    {groupedData[masterGroupItemCode].notAvailableMedicines.slice(0, 1).map((item) => (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTop: (groupedData[masterGroupItemCode].nonSubstituteMedicines.length || groupedData[masterGroupItemCode].substituteMedicines.length) ? '0.5px solid lightgray' : '',
                            marginTop: (groupedData[masterGroupItemCode].nonSubstituteMedicines.length || groupedData[masterGroupItemCode].substituteMedicines.length) ? '1rem' : '',
                            paddingTop: (groupedData[masterGroupItemCode].nonSubstituteMedicines.length || groupedData[masterGroupItemCode].substituteMedicines.length) ? '0.5rem' : '',
                        }}>

                            {
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <div style={medicineNameStyles}>{item.master_group_name?.toUpperCase()}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                        {item.is_covered == 0 && <div style={{ ...medicinePackStyles, color: '#FF754C', alignSelf: 'flex-start' }}>{'Not Covered,'}</div>}
                                        <div style={{ ...medicinePackStyles, color: '#FF754C', alignSelf: 'flex-start' }}>{'Not Available'}</div>
                                    </div>
                                </div>
                            }
                            <div style={providerLogoContainerStyles}>
                                {/* Render substitute medicines */}
                                {groupedData[masterGroupItemCode].notAvailableMedicines.map((item, index, array) => (
                                    <div key={item.id}>
                                        <img src={providerIdImageMapping[item.ph_partner_id]} style={{ ...providerLogoStyles, zIndex: 1, right: `${(array.length - 1 - index) * -20}px` }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                    )}
                </div>
            ))}
        </div>
    );
};

const mapStateToProps = (state) => ({
    authToken: state.user.authToken,
});

export default connect(mapStateToProps)(MedicineNameBasedCartItemListModel);