import React, { useCallback } from 'react';
import styled from 'styled-components';
import Modal from '../../containers/common/Modal';
import { FaTimes } from 'react-icons/fa';
import StyledTappable from '../../components/common/StyledTappable';
import { Text } from '../../components/common/Text';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.25rem 1.25rem;
`;

const HeaderText = styled(Text)`
  font-size: 17.5px;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  flex-direction: column;
`;

const PrimaryButton = styled.button`
    color: #fff;
    width: fit-content;
    padding: 10px;
    border-radius: 2.5px;
    font-weight: 600;
    border: ${(props) => (props.disabled ? '1px solid #aaa' : '1px solid #714FFF')};
    background: ${(props) => (props.disabled ? '#aaa' : '#714FFF')};
`;

const StyledInput = styled.textarea`
    border: none;
    border: 1px solid #ccc;
    border-radius: 0;
    outline: none;
    font-size: 12px;
    width: 100%;
    border-radius: 5px;
    padding: 9px;
`;


const RejectModal = ({
    isOpen,
    onClose = () => {},
    onReject = () => {},
    remark = '',
    onRemarkChange = () => {},
}) => {

    const closeModal = useCallback(() => {
        onClose()
    }, [ onClose ]);

    if (!isOpen) {
        return <></>;
    }

    return <Modal>
        <ModalContainer>
            <ModalBody>
                <ModalHeader>
                    <HeaderText>Add Rejection Remarks</HeaderText>
                    <ButtonsComponent>
                        <StyledTappable onTap={closeModal}>
                            <CrossIcon />
                        </StyledTappable>
                    </ButtonsComponent>
                </ModalHeader>

                <Body>
                    <StyledInput
                        value={remark} rows={2} style={{ resize: 'none' }}
                        onChange={e => onRemarkChange(e.target.value)}
                        placeholder='Enter rejection remark'
                    />
                    <PrimaryButton 
                        className='mt-2 w-100' onClick={onReject}
                        disabled={!remark || remark.length < 4}
                    >
                        Submit and deduct amount from user wallet
                    </PrimaryButton>
                </Body>
            </ModalBody>
        </ModalContainer>
    </Modal>
};

export default RejectModal;
