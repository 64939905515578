import React, { Component } from 'react';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { RegularText, Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import moment from 'moment';
import { PrimaryButton } from '../common/Buttons';
import Select from 'react-select';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const OptionContainer = styled(StyledTappable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-right: 3rem;
`;

const OptionText = styled(RegularText)`
  /* color: white; */
  font-size: 1.4rem;
  text-align: center;
  margin: 0rem;
  margin-left: 0.25rem;
  padding: 0rem;
`;

const SelectLinkText = styled(StyledTappable)`
  padding: 0.45rem;
`;

const SelectIcon = styled.img`
  width: 2rem;
  align-self: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
  border-bottom: 1px solid black !important;
  padding: 0.33rem 0;
  width: ${(props) => (props.width ? props.width : 'auto')};
`;

const InputBoxContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  justify-content: space-around;
  margin-left: 0.625rem;
  padding-right: 10rem;
  /* border-bottom: 1px solid black !important; */
`;
const SelectElement = styled(Select)`
  flex: 1;
  width: ${(props) => (props.width ? props.width : 'auto')};
  padding: 0.33rem 0;

  /* border: 0.1rem solid #4F34D2; */
`;

class AddRelativeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dob: '',
      name: '',
      relationId: '',
      phone: '',
    };
  }

  optionClicked = (option) => {
    this.setState({ status: option });
  };

  render() {
    const dobLink = Link.state(this, 'dob');
    const nameLink = Link.state(this, 'name');
    const phoneLink = Link.state(this, 'phone');

    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Add Relative</HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <InputBoxContainer>
                <Text style={{ marginRight: '10px', width: '100px' }}>
                  Name
                </Text>
                <StyledInputBox
                  type="text"
                  className="visit-input"
                  valueLink={nameLink}
                  placeholder="Name"
                />
              </InputBoxContainer>
              {this.props.relations && (
                <InputBoxContainer>
                  <Text style={{ marginRight: '10px', width: '100px' }}>
                    Relation
                  </Text>
                  <SelectElement
                    options={this.props.relations.map((ele) => {
                      return { label: ele.title, value: ele.relationId };
                    })}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary50: '#d5d5d5',
                        primary25: '#f5f5f5',
                        primary: '#4F34D2',
                      },
                    })}
                    onChange={(option) =>
                      this.setState({
                        relationId: option.value,
                      })
                    }
                  />
                </InputBoxContainer>
              )}
              <InputBoxContainer>
                <Text style={{ marginRight: '10px', width: '100px' }}>
                  Date of birth
                </Text>
                <StyledInputBox
                  type="date"
                  className="visit-input"
                  valueLink={dobLink}
                  placeholder="Date Of Birth"
                />
              </InputBoxContainer>
              <InputBoxContainer>
                <Text style={{ marginRight: '10px', width: '100px' }}>
                  Phone Number (Optional)
                </Text>
                <StyledInputBox
                  type="number"
                  className="visit-input"
                  valueLink={phoneLink}
                  placeholder="Phone"
                />
              </InputBoxContainer>

              <InputContainer>
                <ButtonComponent>
                  <Button
                    onTap={() =>
                      this.props.onSubmit({
                        name: this.state.name,
                        dob: this.state.dob,
                        phone: this.state.phone,
                        relationId: this.state.relationId,
                      })
                    }
                    disabled={
                      this.state.name.length > 0 &&
                      this.state.dob &&
                      this.state.relationId
                        ? false
                        : true
                    }
                    // loading={this.state.loading}
                  >
                    <ButtonText>Submit</ButtonText>
                  </Button>
                </ButtonComponent>
              </InputContainer>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

export default AddRelativeModal;
