import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import voiceCallbacks from '../../images/voiceCallbacks.svg';
import videoCallbacks from '../../images/videoCallbacks.svg';
import { showError } from '../../utils';
import { doctorInitiateVideoCall, markCallbackComplete, voiceCallUser } from '../../services';


const ModalContainer = styled.div`
position: absolute;
width: 100%;
height: 100vh;
right: 0px;
top: 0;
  flex: 1;
  display: flex;
  background: rgba(12, 11, 56, 0.73);
  backdrop-filter: blur(2px);
  z-index: 100;
  cursor: default;
`

const ModalBody = styled.div`
position: absolute;
top: 300px;
left: 530px;
width: 380px;
height: 248px;
background: #FFFFFF;
box-shadow: 2px 2px 12px rgba(58, 44, 160, 0.12);
border-radius: 12px;
`

const HeadContainer = styled.div`
display: flex;
`

const Head = styled.p`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 140%;
letter-spacing: 0.25px;
color: #0F0B28;
margin-top: 20px;
margin-left: 44px;
`

const Line = styled.div`
height: 0px;
border-bottom: 1px solid #F0F3F6;
margin-top: 16px;
`

const ImgContainer = styled.div`
display: flex;
align-items: center;
box-sizing: border-box;
background: #FFFFFF;
border: 1px solid #E4E4E4;
border-radius: 12px;
width: 160px;
height: 75px;
margin-top: 25px;
margin-left: 20px;
cursor: pointer;
`

const Img = styled.img`
height: 45px;
width: 45px;
`

const Text = styled.p`
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 120%;
letter-spacing: 0.25px;
color: #0F0B28;
`

const Button = styled.button`
box-sizing: border-box;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: auto;
gap: 10px;
width: 340px;
height: 40px;
background: #714FFF;
border-radius: 12px;
margin-top: 30px;
color: #FFFFFF;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 140%;
letter-spacing: 0.25px;
margin-left: 20px;
`

const CallbacksModal = (props) => {

  console.log(props, 'callbacks modal props');

  const handleVideoCallback = async () => {
    try {
        doctorInitiateVideoCall(props.authToken, props.callBacksData.doctorId, props.callBacksData.consultationId)
        .then(async (result) => {
            console.log({ result }, 'result from initiateVideoCall');
            props.history.push('/video-call', {
              sessionId: result.sessionId,
              token: result.doctorToken,
              roomName: result.roomName,
              authToken: props.authToken,
              userId: result.userId,
              userName: result.userName,
              doctorName: result.doctorName,
              profileImg: result.profileImg,
              doctorId: props.doctorId,
            });
            await markCallbackComplete(props.authToken, props.callBacksData.connectionRequestId);
            props.closeModal();
          })
          .catch((err) => {
            showError(err);
          });
    }
    catch (err) {
        showError(err);
    }
  }

  const handleVoiceCallback = async () => {
    try {
        await voiceCallUser(props.authToken, props.callBacksData.consultationId);
        await markCallbackComplete(props.authToken, props.callBacksData.connectionRequestId);
        props.closeModal();
    }
    catch (err) {
        showError(err);
    }
  }

  return (
    <ModalContainer>
        <ModalBody>
            <HeadContainer>
                <Head>Place a Call</Head>
                <FaTimes onClick={() => props.closeModal()} style={{height: '16px', width: '16px', marginTop: '22.5px', marginLeft: '200px', cursor: 'pointer'}}/>
            </HeadContainer>
            <Line />
            <div style={{display: 'flex'}}>
            {
                (
                    <ImgContainer onClick={() => handleVideoCallback()}>
                    <div style={{marginLeft: '28px'}}>
                        <Img src={videoCallbacks} alt='voice'/>
                    </div>
                    <div style={{width: '100px', marginLeft: '18px'}}>
                        <Text>Make a video call</Text>
                    </div>
                    </ImgContainer>
                )
            }
            {
                (
                    <ImgContainer onClick={() => handleVoiceCallback()}>
                <div style={{marginLeft: '28px'}}>
                    <Img src={voiceCallbacks} alt='voice'/>
                </div>
                <div style={{width: '100px', marginLeft: '18px'}}>
                    <Text>Make a voice call</Text>
                </div>
            </ImgContainer>
                )
            }
            </div>
            <Button onClick={() => props.confirmMarked()}>Mark as Completed</Button>
        </ModalBody>
    </ModalContainer>
  )
}

export default CallbacksModal