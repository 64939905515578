import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import DateTimePicker from 'react-datetime-picker';
import Link from 'valuelink';
import Select from 'react-select';
import { Input } from 'valuelink/lib/tags';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { RegularText, Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import moment from 'moment';
import { PrimaryButton } from '../common/Buttons';
import TappableCard from '../common/TappableCard';
import greyTick from '../../images/common/greyTick.svg';
import purpleTick from '../../images/common/purpleTick.svg';
import { fetchConsultationEndReasons } from '../../services/consults';
import { getUserDetailsUsingConsultId } from '../../services/consults';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const StyledTappableCard = styled(TappableCard)`
  align-self: flex-start;
  padding: 1rem 3rem;
  margin: 0rem 0.625rem;
  border: ${(props) => (props.isSelected ? '0.1rem solid #4F34D2' : 'null')};
`;

const TappableText = styled(Text)`
  font-size: 1.4rem;
  text-align: center;
  color: ${(props) => (props.isSelected ? '#4F34D2' : 'black')};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const OptionContainer = styled(StyledTappable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-right: 3rem;
`;

const DateTimeComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-left: 0.625rem;
  margin-top: 2rem;
`;

const OptionText = styled(RegularText)`
  /* color: white; */
  font-size: 1.4rem;
  text-align: center;
  margin: 0rem;
  margin-left: 0.25rem;
  padding: 0rem;
`;

const SelectLinkText = styled.div`
  padding: 0.45rem;
`;

const SelectIcon = styled.img`
  width: 2rem;
  align-self: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DateTimeText = styled(BoldText)`
  font-size: 1.6rem;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
  border-bottom: 1px solid black !important;
  padding: 0.33rem 0;
  width: ${(props) => (props.width ? props.width : 'auto')};
`;

const DobContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 2rem;
  margin-left: 0.625rem;
  padding-right: 10rem;
  /* border-bottom: 1px solid black !important; */
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 1.25rem;
  margin-right: 1rem;
  margin-left: 0.625rem;
  flex: 1;
`;

const SelectElement = styled(Select)`
  flex: 1;
  max-width: 30rem;
  /* border: 0.1rem solid #4F34D2; */
`;

const EndRegularText = styled(RegularText)`
  font-size: 1.4rem;
  text-align: left;
`;

const styles = {
  datePicker: {
    backgroundColor: 'dark blue',
  },
};

class CloseConsultationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionChosen: false,
      consultationNote: '',
      date: new Date(),
      endReason: '',
      endReasons: [],
      surgery: false,
      surgeryList: [
        {
          value: 'General Surgery & Practology',
          label: 'General Surgery & Practology',
        },
        {
          value: 'Gynaecology',
          label: 'Gynaecology',
        },
        {
          value: 'ENT',
          label: 'ENT',
        },
        {
          value: 'Urology',
          label: 'Urology',
        },
        {
          value: 'Vascular',
          label: 'Vascular',
        },
        {
          value: 'Aesthetics',
          label: 'Aesthetics',
        },
        {
          value: 'Ortho',
          label: 'Ortho',
        },
        {
          value: 'Ophthalmology',
          label: 'Ophthalmology',
        },
        {
          value: 'Fertility',
          label: 'Fertility',
        },
        {
          value: 'Others',
          label: 'Others',
        },
      ],
      selectedDiagnosis: '',
      consultUserInfo: null,
    };

    this.state.date.setDate(this.state.date.getDate() + 5);
    console.log(this.props.currentUserInfo, 'main here');
    this.fetchUserDetailsFromConsulId();
  }
  componentDidMount = () => {
    this.fetchConsultationEndReasons();
  };
  fetchConsultationEndReasons = () => {
    fetchConsultationEndReasons(this.props.authToken, 'end').then((result) => {
      console.log({ result }, 'fetchConsultationEndReasons');
      if (result.results && result.results.length > 0) {
        const endReasons = result.results.map((element) => {
          return { label: element, value: element };
        });
        this.setState({
          endReasons,
        });
      }
    });
  };

  fetchUserDetailsFromConsulId = () => {
    getUserDetailsUsingConsultId(
      this.props.authToken,
      this.props.currentUserInfo.id
    )
      .then((res) => {
        console.log('The resp', res);
        this.setState({ consultUserInfo: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChange = (date) => {
    console.log({ date }, 'onChange');
    this.setState({
      date,
    });
  };

  optionClicked = (option) => {
    this.setState({ optionChosen: option });
  };

  closeConsultation = (digitisation) => {
    if (!digitisation) {
      const body = {
        consultationNote: this.state.consultationNote,
        endReason: this.state.endReason,
        closedType: 'end',
      };
      this.props.closeConsult(body);
    }

    // if (this.state.optionChosen === 'addDate') {
    //   body.followDateTime = moment(this.state.date).format(
    //     'YYYY/MM/DD HH:mm:ss'
    //   );
    // } else {
    //   body.followDateTime = null;
    // }

    if (this.state.surgery === 'type2' && this.state.selectedDiagnosis) {
      const surgeryData = {
        selectedDiagnosis: this.state.selectedDiagnosis,
      };
      this.props.submitDiagnosis(surgeryData, this.state.consultUserInfo);
    }
  };

  onOptionSelect = (option) => {
    this.setState({
      endReason: option.value,
    });
  };

  render() {
    const getDateTimeToShow = () => {
      // const format = moment(this.state.date).format('h:mm a, Do MMM YYYY');
      // return format;
      return (
        <DateTimePicker
          style={styles.datePicker}
          onChange={this.onChange}
          value={this.state.date}
          disableClock
          required
          clearIcon={null}
        />
      );
    };

    const consultationNoteLink = Link.state(this, 'consultationNote');
    const { digitisation } = this.props;
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>
                {digitisation
                  ? 'Are you sure you want to mark this prescription Digitized'
                  : 'Are you sure you want to end this consultation?'}
              </HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              {/*  <OptionsContainer>
                <OptionContainer
                  onTap={() => {
                    this.optionClicked('addDate');
                  }}
                >
                  <SelectLinkText>
                    {this.state.optionChosen === 'addDate' ? (
                      <SelectIcon src={purpleTick} alt="Success" />
                    ) : (
                      <SelectIcon src={greyTick} alt="Success" />
                    )}
                  </SelectLinkText>
                  <OptionText>Add follow-up date</OptionText>
                </OptionContainer>
                <OptionContainer
                  onTap={() => this.optionClicked('notRequired')}
                >
                  <SelectLinkText>
                    {this.state.optionChosen === 'notRequired' ? (
                      <SelectIcon src={purpleTick} alt="Success" />
                    ) : (
                      <SelectIcon src={greyTick} alt="Success" />
                    )}
                  </SelectLinkText>
                  <OptionText>Follow-up not required</OptionText>
                </OptionContainer>
              </OptionsContainer>

              {this.state.optionChosen === 'addDate' ? (
                <DateTimeComponent onTap={this.handleClick}>
                  <DateTimeText>
                    Date and Time: {getDateTimeToShow()}
                  </DateTimeText>
                </DateTimeComponent>
              ) : null}

              <AgeContainer>
                <EndRegularText>Select End Reason</EndRegularText>
                <SelectElement
                  options={this.props.endReasons}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary50: '#d5d5d5',
                      primary25: '#f5f5f5',
                      primary: '#4F34D2',
                    },
                  })}
                  defaultValue={this.props.endReasons[0]}
                  onChange={(option) => this.onOptionSelect(option)}
                />
              </AgeContainer>

              <DobContainer>
                <StyledInputBox
                  type="text"
                  className="visit-input"
                  valueLink={consultationNoteLink}
                  placeholder="Follow-up Notes"
                />
              </DobContainer> */}
              {!digitisation && (
                <>
                  <DobContainer>
                    <StyledInputBox
                      type="text"
                      className="visit-input"
                      valueLink={consultationNoteLink}
                      placeholder="Remark"
                    />
                  </DobContainer>
                  {/* <AgeContainer>
                    <EndRegularText>Reason</EndRegularText>
                    <SelectElement
                      options={this.state.endReasons}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: '#d5d5d5',
                          primary25: '#f5f5f5',
                          primary: '#4F34D2',
                        },
                      })}
                      defaultValue={this.state.endReasons[0]}
                      onChange={(option) => this.onOptionSelect(option)}
                    />
                  </AgeContainer> */}
                </>
              )}
              {/* {
                !this.props.info && (
                  <InputContainer
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  fontSize: '16px',
                  fontFamily: 'BrandonText,Lato,sans-serif',
                  marginLeft: `${digitisation ? '16px' : ''}`,
                }}
              >
                <p>
                  What can be the plan of further management for this patient:
                </p>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                  onChange={(e) => {
                    this.setState({ surgery: e.target.value });
                  }}
                >
                  <div style={{ marginBottom: '12px' }}>
                    <input
                      type="radio"
                      value="type1"
                      name="surgery"
                      style={{ cursor: 'pointer' }}
                    />
                    Treatment with medicines
                  </div>
                  <div style={{ marginBottom: '12px' }}>
                    <input
                      type="radio"
                      value="type2"
                      name="surgery"
                      style={{ cursor: 'pointer' }}
                    />
                    Interventional procedures
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="type3"
                      name="surgery"
                      style={{ cursor: 'pointer' }}
                    />
                    Decision on followup
                  </div>
                </div>
              </InputContainer>
                )
              } */}
              {/* {this.state.surgery === 'type2' && (
                <AgeContainer>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      fontSize: '16px',
                      fontFamily: 'BrandonText,Lato,sans-serif',
                      marginBottom: '6px',
                    }}
                  >
                    Category For Interventional Procedures
                  </div>
                  <SelectElement
                    options={this.state.surgeryList}
                    defaultValue={this.state.endReasons[0]}
                    className={'select-drop-down'}
                    onChange={
                      (option) =>
                        this.setState({ selectedDiagnosis: option.value })
                      // console.log(option.value)
                    }
                  />
                </AgeContainer>
              )} */}
              <InputContainer>
                <ButtonComponent>
                  {
                    // !this.props.info ? (
                      <Button
                    onTap={() => this.closeConsultation(digitisation)}
                    // disabled={
                    //   this.state.surgery === 'type1' ||
                    //   this.state.surgery === 'type3' ||
                    //   this.state.selectedDiagnosis
                    //     ? false
                    //     : true
                    // }
                    loading={this.state.loading}
                  >
                    <ButtonText>Mark Complete</ButtonText>
                  </Button>
                  //   ) : (
                  //     <Button
                  //   onTap={() => this.closeConsultation(digitisation)}
                  //   loading={this.state.loading}
                  // >
                  //   <ButtonText>Mark Complete</ButtonText>
                  // </Button>
                  //   )
                  }
                </ButtonComponent>
              </InputContainer>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  endReasons: state.info.endReasons,
  currentUserInfo: state.consults.currentUserInfo,
});

export default connect(mapStateToProps)(CloseConsultationModal);
