import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import pastCasesIcon from '../../images/past-cases.svg';
import { FaChevronRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { fetchConsults } from '../../services';

const PastCasesOuter = styled.div`
width: 400px;
height: 72px;
background: #FFFFFF;
box-shadow: 2px 2px 8px rgba(113, 79, 255, 0.21);
border-radius: 20px;
margin-top: 16px;
margin-left: 20px;
cursor: pointer;
`

const InfoContainer = styled.div`
position: relative;
display: flex;
`

const Img = styled.img`
margin-top: 26px;
margin-left: 26px;
`

const Text = styled.p`
margin-top: 26px;
margin-left: 18px;
/* font-family: 'Montserrat'; */
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 120%;
text-align: center;
color: #0F0B28;
`
const Notify = styled.div`
position: absolute;
border-radius: 100%;
background: #F84F31;
/* background-color: #FF0000; */
/* background-color: red; */
box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.7);
left: 380px;
top: -5px;
width: 20px;
height: 20px;
`

const PastCasesCard = (props) => {
  return (
    <PastCasesOuter onClick={props.switch}>
      <InfoContainer>
        <Img src={pastCasesIcon} />
        <Text>All Cases</Text>
        <FaChevronRight
          style={{
            height: '12px',
            width: '12px',
            marginTop: '28px',
            marginLeft: '200px',
          }}
        />
      </InfoContainer>
    </PastCasesOuter>
  )
}

export default PastCasesCard