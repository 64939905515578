export const defaultState = {};

export default function consultation(state = defaultState, action) {
    switch (action.type) {
        case 'SET_CONSULTATION': {
            return Object.assign({}, state, action.payload);
        }
        default:
            return state;
    }
}
